import { computed, observable, runInAction } from 'mobx';

import { EVENT_JOIN_STATUS, EVENT_PUBlIC_TYPE } from '../types/IEvent';
import { FEED_SPORT_TYPE } from '../types/IFeed';
import { TEAM_COMPANY_TYPE, TEAM_OFFICIAL, TEAM_STATUS } from '../types/ITeam';
import { ITeam } from './../types/ITeam';
import { ModelBase } from './ModelBase';

export default class Team extends ModelBase {
  teamId: number;
  name: string;
  avatar: string;
  cover: string;
  description: string;
  companyType: TEAM_COMPANY_TYPE;
  sportType: FEED_SPORT_TYPE;
  publicType: EVENT_PUBlIC_TYPE;
  status: TEAM_STATUS;
  officalType: TEAM_OFFICIAL;
  totalMember: number;
  totalMemberRequest: number;
  ownerId: number;

  //observable properties
  @observable userJoinStatus: EVENT_JOIN_STATUS;

  constructor(
    teamId: number,
    name: string,
    avatar: string,
    cover: string,
    description: string,
    companyType: TEAM_COMPANY_TYPE,
    sportType: FEED_SPORT_TYPE,
    publicType: EVENT_PUBlIC_TYPE,
    status: TEAM_STATUS,
    officalType: TEAM_OFFICIAL,
    totalMember: number,
    totalMemberRequest: number,
    ownerId: number,
    userJoinStatus: EVENT_JOIN_STATUS,
  ) {
    super();
    this.teamId = teamId;
    this.name = name;
    this.avatar = avatar;
    this.cover = cover;
    this.description = description;
    this.companyType = companyType;
    this.sportType = sportType;
    this.publicType = publicType;
    this.status = status;
    this.officalType = officalType;
    this.totalMember = totalMember;
    this.totalMemberRequest = totalMemberRequest;
    this.ownerId = ownerId;
    this.userJoinStatus = userJoinStatus;
  }

  static fromJson = (json: ITeam): Team => {
    return new Team(
      json.tmid,
      json.name,
      json.ava,
      json.cover,
      json.desc,
      json.lgid,
      json.sport,
      json.jpri,
      json.sts,
      json.offical,
      json.mem,
      json.rmem,
      json.owner,
      json.jsts,
    );
  };

  //return true if user already join event and else return false
  @computed get isJoined() {
    return (
      this.userJoinStatus != EVENT_JOIN_STATUS.NONE &&
      this.userJoinStatus != EVENT_JOIN_STATUS.INVITING &&
      this.userJoinStatus != EVENT_JOIN_STATUS.REQUESTING
    );
  }

  getCompanyTypeString(): any {
    switch (this.companyType) {
      case TEAM_COMPANY_TYPE.COMPANY:
        return 'companyType:company';
      case TEAM_COMPANY_TYPE.CLUB:
        return 'companyType:club';
      default:
        return 'companyType:school';
    }
  }

  isOwner(memberId: number): boolean {
    return this.ownerId === memberId;
  }
  isAdmin(): boolean {
    return this.userJoinStatus === EVENT_JOIN_STATUS.ADMIN;
  }
  isPublic(): boolean {
    return this.publicType === EVENT_PUBlIC_TYPE.PUBLIC;
  }
  isAllowView(): boolean {
    return this.isPublic() || this.isJoined;
  }
  setUserJoinStatus(status: EVENT_JOIN_STATUS) {
    runInAction(() => {
      this.userJoinStatus = status;
    });
  }
}
