export const API = {
  AUTHENTICATION: {
    LOGIN: 'auth/loginw',
  },
  EVENT: {
    RANK_ME: 'event/rank/me',
    LIST: 'event/list',
    SUGGEST: 'event/suggest',
    DETAIL: 'event/details',
    EVENT_RAKING: 'event/rank/list',
    CREATE: 'event/create',
    UPDATE: 'event/update',
    DELETE: 'event/delete',
    DISMISS: 'event/dismiss',
    REQUEST_JOIN: 'event/request-join',
  },
  GROUP: {
    LIST: 'event/group/list',
    CREATE: 'event/group/create',
    UPDATE: 'event/group/update',
    REQUEST_JOIN: 'event/group/request-join',
    DO_JOIN: 'event/group/do-join',
    DETAIL: 'event/group/details',
    DELETE: 'event/group/delete',
    APPROVE_ALL: 'event/group/approve-all',
    APPROVE_ALL_MEMBER: 'event/group/member/approve-all',
  },
  USER: {
    PROFILE_DETAIL: 'user/profile/details',
    GET_TOP: 'user/top',
    SEARCH: 'user/search',
    SUGGEST: 'user/suggest',
    DO_FOLLOW: 'user/do-follow',
    DO_LOCK: 'event/lock/user',
    FEEDBACK: 'app/feedback',
    STATISTICS: 'user/statistics',
  },
  SOCIAL: {
    POST: {
      BASIC: 'post/basic',
      DETAIL: 'post/details',
      CREATE: 'post/create',
      UPDATE: 'post/update',
      DELETE: 'post/delete',
      LIST: 'feed/post/list',
      PIN: 'post/mark',
      LIST_COMMENT: 'post/comment/list',
      WRITE_COMMENT: 'post/comment',
      LIST_LIKE: 'post/like/list',
      DO_LIKE: 'post/like',
    },
    FEED: {
      LIST: 'feed/activity/list',
      DETAILS: 'activity/details',
      DO_LIKE: 'activity/like',
      REPORT_ACTION: 'event/report/action',
      WRITE_COMMENT: 'activity/comment',
      LIST_COMMENT: 'feed/comment/list',
      LIST_LIKE: 'feed/like/list',
      ANALYSIS: 'ai/activity/analysis',
      MARK_LABEL: '/ai/activity/label',
    },
  },
  GLOBAL: {
    UPLOAD_IMAGE: 'webhook/upload',
  },
  JSON: {
    LIST_PARTNER: 'json/partner.json',
    LIST_NEWS: 'json/news.json',
    LIST_PEOPLE_SAY: 'json/peoplesay.json',
    LIST_NON_PROFIT_ORGAN: 'images/2023/jsons/orgs.json',
    LIST_SPONSORS: 'images/2023/jsons/sponsor.json',
    LIST_PEOPLE_SAY_UP22: 'images/2023/jsons/people_say.json',
    LIST_NEWS_UP22: 'images/2023/jsons/news_2023.json',
  },
};
