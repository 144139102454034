import { Modal } from 'antd';
import React, { useEffect } from 'react';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import pure from 'recompose/pure';

import { APP_CONFIG } from '../../../../configs/GlobalConst';
import { SCREEN_FEED_DETAIL } from '../../../../configs/Screens';
import { APIResponse } from '../../../../models/APIResponse';
import { useRootStore } from '../../../../providers/RootStoreProvider';
import {
  IFeed,
  IFeedAnalysis,
  IGetFeedAnalysisRequest,
  IGetFeedDetailRequest,
} from '../../../../types/IFeed';
import { APP_BUTTON_TYPE, ITabMenu } from '../../../../types/IGlobal';
//views
import { Login } from '../../../Login/Login';
//styles
import {
  BottomContainer,
  EmptyTitle,
  LogoutButton,
  SearchInputText,
  SearchWrapper,
  TabContainer,
  TabItem,
  TabLink,
  ToolbarContainer,
  ToolbarWrapper,
} from './Toolbar.styles';
interface Props {
  feedId: any;
  screen: SCREEN_FEED_DETAIL;
  onChangeScreen: (screen: SCREEN_FEED_DETAIL) => void;
}

const TABS: ITabMenu[] = [
  {
    id: SCREEN_FEED_DETAIL.INFO,
    title: 'feedDetail:toolbar.feedInfo',
  },
  {
    id: SCREEN_FEED_DETAIL.STATS,
    title: 'feedDetail:toolbar.feedStats',
  },
  {
    id: SCREEN_FEED_DETAIL.HELP,
    title: 'feedDetail:toolbar.feedHelp',
  },
];

export const Toolbar: ComponentType<Props> = pure(({ screen, feedId, onChangeScreen }) => {
  const store = useRootStore();
  const { t } = useTranslation();

  const { getFeedDetail, getFeedAnalysis, setFeedData, setFeedAnalysisData } = store.feedStore;
  const { logout } = store.authStore;

  const [valSearch, setValSearch] = React.useState(feedId); //2020679
  const [showEmpty, setShowEmpty] = React.useState(false);
  const [msgEmpty, setMsgEmpty] = React.useState('');
  const [showLogin, setShowLogin] = React.useState(false);

  useEffect(() => {
    if (feedId) onEnter();
  }, []);

  const onEnter = () => {
    setShowEmpty(false);
    onGetFeedDetail();
    onGetFeedAnalysis();
  };

  const onGetFeedDetail = async () => {
    const params: IGetFeedDetailRequest = {
      data: {
        atid: valSearch,
      },
    };
    const result: APIResponse<IFeed> = await getFeedDetail(params);
    //check if data not exist then navigate to not found page
    if (result.isError()) {
      setFeedData(null);
      setFeedAnalysisData(null);

      Modal.error({
        title: t('errors:notFound'),
        content: result.getDescription(),
        centered: true,
      });
    }
  };

  const onItemMenuClick = (screen: SCREEN_FEED_DETAIL) => {
    if (screen != SCREEN_FEED_DETAIL.HELP) onChangeScreen(screen);
  };

  const onGetFeedAnalysis = async () => {
    const params: IGetFeedAnalysisRequest = {
      data: {
        atid: valSearch,
      },
    };
    const result: APIResponse<IFeedAnalysis> = await getFeedAnalysis(params);
    //check if data not exist then navigate to not found page
    if (result.isError()) {
      setShowEmpty(true);
      setFeedAnalysisData(null);
      setMsgEmpty(result.getDescription());
    }
  };

  const onChange = (e: any) => {
    setValSearch(e.target.value);
  };

  const onLogin = () => {
    setShowLogin(true);
  };

  const onLogout = () => {
    logout();
  };

  const renderTabItem = (tab: ITabMenu) => {
    const active = tab.id === screen ? 'active' : 'disabled';
    return (
      <TabItem key={tab.id} onClick={() => onItemMenuClick(tab.id)}>
        {tab.id != SCREEN_FEED_DETAIL.HELP ? (
          <TabLink active={active}>{t(tab.title)}</TabLink>
        ) : (
          <TabLink href={`${APP_CONFIG.STATIC_URL}/docs/activity-analysis-anticheat.docx`}>
            {t(tab.title)}
          </TabLink>
        )}
      </TabItem>
    );
  };

  return (
    <ToolbarWrapper>
      <ToolbarContainer>
        <TabContainer>
          {TABS.map((tab) => {
            return renderTabItem(tab);
          })}
        </TabContainer>
      </ToolbarContainer>
      <BottomContainer>
        <SearchWrapper>
          <SearchInputText
            showIconLeft={true}
            value={valSearch}
            placeHolder='Search...'
            onEnter={onEnter}
            onChange={onChange}
          />
          {showEmpty && <EmptyTitle>{msgEmpty}</EmptyTitle>}
        </SearchWrapper>

        <LogoutButton typeButton={APP_BUTTON_TYPE.LIGHT} onClick={() => onLogout()}>
          Đăng xuất
        </LogoutButton>
      </BottomContainer>
      <Login
        visible={showLogin}
        onClose={() => setShowLogin(false)}
        onLoginSuccess={() => setShowLogin(false)}
      />
    </ToolbarWrapper>
  );
});
