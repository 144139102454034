import React, { FC } from 'react';
import {
    InfoTcxh,
    InfoTitle,
    ListItemDetails,
    ListItemDetailsMoney,
    ListItemDetailsMoneyNum,
    ListItemDetailsMoneyUnit,
    ListItemDetailsName,
    ListItemLogo,
    TcxhList,
    TcxhListItem
} from './EventInfo.styles';
import { useRootStore } from '../../providers/RootStoreProvider';
import { IEvent, IEventFund } from '../../types/IEvent';
import { observer } from 'mobx-react-lite';
import { formatNumber } from '../../utils';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
    eventInfo: IEvent;
}
const DonationsOrg: FC<Props> = observer(({ eventInfo }) => {
    const store = useRootStore();
    const { language } = store.globalStore;
    const { t } = useTranslation();

    const renderPopup = (data: IEventFund) => {
        const content = language == 'vi' ? data.desc : data.descen;
        return (
            <div>
                <img
                    src={data.ava}
                    style={{
                        display: 'block',
                        width: 120,
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}
                />
                <div style={{ width: 600, padding: 10 }}>
                    <h4 style={{ textAlign: 'center' }}>{data.name}</h4>
                    <p style={{ lineHeight: 2, fontSize: 16, color: '#000' }}>
                        {content}
                    </p>
                    <a
                        href={data.wurl}
                        target="_blank"
                        style={{ fontSize: 16 }}>
                        {data.wurl}
                    </a>
                </div>
            </div>
        );
    };

    return (
        <InfoTcxh className="tcxh">
            <InfoTitle>{t('eventInfo:funds.title')}</InfoTitle>
            {Object.entries(eventInfo.fund).map(([key, value]) => (
                <div key={key}>
                    <Popover content={renderPopup(value)} placement='left'>
                        <TcxhList>
                            <TcxhListItem className="">
                                <ListItemLogo>
                                    <img
                                        src={value?.ava}
                                        className="img-logo"
                                    />
                                </ListItemLogo>
                                <ListItemDetails>
                                    <ListItemDetailsName>
                                        {value.name}
                                    </ListItemDetailsName>
                                    <ListItemDetailsMoney>
                                        <ListItemDetailsMoneyNum>
                                            {formatNumber(value.rdis)}
                                        </ListItemDetailsMoneyNum>
                                        <ListItemDetailsMoneyUnit>
                                            {' '}
                                            km{' '}
                                        </ListItemDetailsMoneyUnit>
                                    </ListItemDetailsMoney>
                                </ListItemDetails>
                            </TcxhListItem>
                        </TcxhList>
                    </Popover>
                </div>
            ))}
        </InfoTcxh>
    );
});

export default DonationsOrg;
