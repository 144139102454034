import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { AuthHeader } from '../../../helpers/AuthHeader';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { IGetUserProfileDetailRequest } from '../../../types/IUser';

export const UserLogin: React.FC = observer(() => {
  const store = useRootStore();
  const { currentUser, getProfileDetail } = store.userStore;

  useEffect(() => {
    if (currentUser == null && AuthHeader() != null) doGetProfileUserLogin();
  }, []);

  const doGetProfileUserLogin = () => {
    const params: IGetUserProfileDetailRequest = {
      data: {
        pfid: AuthHeader()?.userId.toString()!,
      },
    };
    getProfileDetail(params);
  };

  return <div>Hello User: {currentUser?.name}</div>;
});
