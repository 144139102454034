import _ from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { AuthHeader } from '../helpers/AuthHeader';
import {
  ACCOUNT_ROLE,
  ACCOUNT_STATUS,
  FOLLOW_REQUEST_STATUS,
  IMemberFollower,
  IMemberFollowing,
  IMemberJoined,
  IMemberRequest,
  IMemberTop,
} from './../types/IMember';
import { DataList } from './DataList';

export class Member {
  memberId: number;
  name: string;
  code: string;
  avatar: string;
  sex: number;
  city: string;
  country: string;
  status: number;
  accountRole: ACCOUNT_ROLE;
  teamName: string;

  //observable properties
  @observable followingStatus?: FOLLOW_REQUEST_STATUS;
  @observable selected?: boolean = false; //determine in case  show checkbox for select member

  constructor(
    memberId: number,
    name: string,
    code: string,
    avatar: string,
    sex: number,
    city: string,
    country: string,
    status: number,
    accountRole: ACCOUNT_ROLE,
    teamName: string,
    followingStatus?: FOLLOW_REQUEST_STATUS,
  ) {
    makeObservable(this);
    this.memberId = memberId;
    this.name = name;
    this.code = code;
    this.avatar = avatar;
    this.sex = sex;
    this.city = city;
    this.country = country;
    this.status = status;
    this.accountRole = accountRole;
    this.teamName = teamName;
    this.followingStatus = followingStatus;
  }

  @computed get isFollowing(): boolean {
    return this.followingStatus === FOLLOW_REQUEST_STATUS.APPROVED;
  }

  @action setFollowingStatus(status: FOLLOW_REQUEST_STATUS) {
    runInAction(() => {
      this.followingStatus = status;
    });
  }

  isDelete = (): boolean => {
    return this.status === ACCOUNT_STATUS.DELETED || this.status === ACCOUNT_STATUS.DELETE_PENDING;
  };

  isMe = (): boolean => {
    return this.memberId === AuthHeader()?.userId;
  };

  setSelected = (val: boolean): void => {
    this.selected = val;
  };
}
export class MemberTop extends Member {
  static fromJson = (json: IMemberTop): MemberTop => {
    return new MemberTop(
      json.uid,
      json.name,
      json.code,
      json.ava,
      json.sex,
      json.city,
      json.ctry,
      json.sts,
      json.acrl,
      json.tmnm,
    );
  };
}
export class MemberRequest extends Member {
  static fromJson = (json: IMemberRequest): MemberRequest => {
    return new MemberRequest(
      json.uid,
      json.name,
      json.code,
      json.ava,
      json.sex,
      json.city,
      json.ctry,
      json.sts,
      json.acrl,
      json.tmnm,
    );
  };
}
export class MemberJoined extends Member {
  static fromJson = (json: IMemberJoined): MemberJoined => {
    return new MemberJoined(
      json.uid,
      json.name,
      json.code,
      json.ava,
      json.sex,
      json.city,
      json.ctry,
      json.sts,
      json.acrl,
      json.tmnm,
      json.fists,
    );
  };
}

export class MemberFollowing extends Member {
  static fromJson = (json: IMemberJoined): MemberFollowing => {
    return new MemberFollowing(
      json.uid,
      json.name,
      json.code,
      json.ava,
      json.sex,
      json.city,
      json.ctry,
      json.sts,
      json.acrl,
      json.tmnm,
      json.fists,
    );
  };
}

export class MemberFollower extends Member {
  static fromJson = (json: IMemberJoined): MemberFollower => {
    return new MemberFollower(
      json.uid,
      json.name,
      json.code,
      json.ava,
      json.sex,
      json.city,
      json.ctry,
      json.sts,
      json.acrl,
      json.tmnm,
      json.fists,
    );
  };
}
export class ListMemberTop extends DataList<MemberTop> {
  static fromJson = (jsonArray: Array<IMemberTop>): Array<MemberTop> => {
    const data = new Array<MemberTop>();
    jsonArray.forEach((event) => {
      data.push(MemberTop.fromJson(event));
    });
    return data;
  };
}
export class ListMemberRequest extends DataList<MemberRequest> {
  removeMember = (member: MemberRequest) => {
    runInAction(() => {
      _.remove(this.list, (item) => {
        return item.memberId === member.memberId;
      });
    });
  };

  static fromJson = (jsonArray: Array<IMemberRequest>): Array<MemberRequest> => {
    const data = new Array<MemberRequest>();
    jsonArray.forEach((member) => {
      data.push(MemberRequest.fromJson(member));
    });
    return data;
  };
}
export class ListMemberJoined extends DataList<MemberJoined> {
  @observable listSelected: Array<MemberJoined> = [];

  @action selectMember = (member: MemberJoined) => {
    runInAction(() => {
      this.listSelected.push(member);
    });
    console.log(this.listSelected);
  };

  @action unSelectMember = (member: MemberJoined) => {
    runInAction(() => {
      _.remove(this.listSelected, (item) => {
        return item.memberId === member.memberId;
      });
    });
  };

  addMember = (member: MemberJoined) => {
    runInAction(() => {
      this.list.push(member);
    });
  };

  constructor() {
    super();
    makeObservable(this);
  }

  static fromJson = (jsonArray: Array<IMemberJoined>): Array<MemberJoined> => {
    const data = new Array<MemberJoined>();
    jsonArray.forEach((member) => {
      data.push(MemberJoined.fromJson(member));
    });
    return data;
  };
}

export class ListMemberSuggest extends DataList<MemberJoined> {
  static fromJson = (jsonArray: Array<IMemberJoined>): Array<MemberJoined> => {
    const data = new Array<MemberJoined>();
    jsonArray.forEach((member) => {
      data.push(MemberJoined.fromJson(member));
    });
    return data;
  };
}

export class ListMemberFollower extends DataList<MemberFollower> {
  static fromJson = (jsonArray: Array<IMemberFollower>): Array<MemberFollower> => {
    const data = new Array<MemberFollower>();
    jsonArray.forEach((member) => {
      data.push(MemberFollower.fromJson(member));
    });
    return data;
  };
}

export class ListMemberFollowing extends DataList<MemberFollowing> {
  static fromJson = (jsonArray: Array<IMemberFollowing>): Array<MemberFollowing> => {
    const data = new Array<MemberFollowing>();
    jsonArray.forEach((member) => {
      data.push(MemberFollowing.fromJson(member));
    });
    return data;
  };
}
