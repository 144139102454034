import styled from 'styled-components';

import { device } from '../configs/MediaQuery';
import { TextBaseBlack, TextBaseGray } from './BaseStyle';

export const TextHeadingH1 = styled.h1`
  ${TextBaseBlack}
  font-weight: 600;
  @media ${device.mobileS} {
    font-size: 24px;
    line-height: 34px;
  }

  @media ${device.tablet} {
    font-size: 40px;
    line-height: 56px;
  }
`;

export const TextHeadingH2 = styled.h2`
  ${TextBaseBlack}
  font-weight: 500;
  @media ${device.mobileS} {
    font-size: 24px;
    line-height: 34px;
  }

  @media ${device.tablet} {
    font-size: 31px;
    line-height: 44px;
  }
`;

export const TextHeadingH3 = styled.h3`
  ${TextBaseBlack}
  font-weight: 400;
  @media ${device.mobileS} {
    font-size: 20px;
    line-height: 28px;
  }

  @media ${device.tablet} {
    font-size: 24px;
    line-height: 34px;
  }
`;

export const TextHeadingH5 = styled.h5`
  ${TextBaseBlack}
  @media ${device.mobileS} {
    font-size: 16px;
    line-height: 24px;
  }

  @media ${device.tablet} {
    font-size: 18px;
    line-height: 26px;
  }
`;

export const AppGrayLabel = styled.p`
  ${TextBaseGray}
`;
