import { API } from '../configs/APIEndPoint';
import { handleError } from '../helpers/APIHelper';
import { IGetFeedUserStats } from '../types/IFeed';
import { IGetUserProfileDetailRequest, IUserFeedbackRequest } from '../types/IUser';
import BaseAuthService from './BaseAuthService';

class UserService extends BaseAuthService {
  private static instance: UserService;

  public static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }

  //methods api
  sendFeedback = async (params: IUserFeedbackRequest) => {
    try {
      const response = await this.postData(API.USER.FEEDBACK, params);
      return this.handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  };

  //get user profile details
  getProfileDetail = async (params: IGetUserProfileDetailRequest) => {
    try {
      const response = await this.postData(API.USER.PROFILE_DETAIL, params);
      return this.handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  };

  getFeedUserStats = async (params: IGetFeedUserStats) => {
    try {
      const response = await this.postData(API.USER.STATISTICS, params);
      return this.handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  };
}
export default UserService;
