//define all screen for render conditions
export enum SCREEN_EVENT_HOME {
  HOME_EVENT,
  YOUR_EVENT,
  FILTER_EVENT,
}

export enum SCREEN_YOUR_EVENT {
  JOINED,
  OWNER,
  WAITING,
  FINISHED,
}

export enum SCREEN_EVENT_DETAIL {
  INFO_TAB,
  LEADERBOARD_GROUP,
  LEADERBOARD_USER,
  MANAGER_MEMBER,
  MANAGER_GROUP,
  MANAGER_COMPLAIN,
}

export enum SCREEN_FEED_DETAIL {
  INFO,
  STATS,
  HELP,
}

//define all tabs for render conditions
