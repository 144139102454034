import { observer } from 'mobx-react-lite';
import { ComponentType } from 'react';

import { Feed } from '../../../models/Feed';
import { BottomBlock } from './BottomBlock/BottomBlock';
//styles
import { PaceInfoContainer, PaceInfoWrapper } from './PaceInfo.styles';
//views
import { TopBlock } from './TopBlock/TopBlock';

interface Props {
  feedData: Feed;
}

export const PaceInfo: ComponentType<Props> = observer(({ feedData }) => {
  return (
    <PaceInfoWrapper>
      <PaceInfoContainer>
        <TopBlock feedData={feedData} />
        <BottomBlock feedData={feedData} />
      </PaceInfoContainer>
    </PaceInfoWrapper>
  );
});
