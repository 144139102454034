import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import * as AxiosLogger from 'axios-logger';

import { HTTP_STATUS } from '../configs/HttpStatus';

//create a instance of axios
const APIHelper = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-type': 'application/json',
  },
  timeout: 15000,
});


export const JSonHelper = axios.create({
  baseURL: process.env.REACT_APP_CDN_URL,
  headers: {
      'Content-type': 'application/json'
  },
  timeout: Number(process.env.REACT_APP_TIMEOUT_API)
});


//handle request and response interceptors
APIHelper.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    return AxiosLogger.requestLogger(config);
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

APIHelper.interceptors.response.use(
  (response: AxiosResponse) => {
    return AxiosLogger.responseLogger(response);
  },
  (error: AxiosError) => {
    // Log the error
    console.error('APIHelper.interceptors.response :' + JSON.stringify(error));
    //Check if the response is having error code as 401 then return login
    if (forceLogin(error)) {
      //window.location.href = '/login';
    }

    return Promise.reject(error);
  },
);

const forceLogin = (error: AxiosError) => {
  return (
    error.response?.status === HTTP_STATUS.UNAUTHORIZED ||
    error.response?.status === HTTP_STATUS.TOKEN_INVALID
  );
};

//handles response and error from server
export const handleResponse = (response: AxiosResponse) => {
  return response.data;
};

export const handleError = (error: AxiosError) => {
  console.error(error);
  AxiosLogger.errorLogger(error);
};

export default APIHelper;
