import { isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import { parse } from 'query-string';
import React, { useEffect } from 'react';
import { ComponentType } from 'react';

import { SCREEN_FEED_DETAIL } from '../../../configs/Screens';
import { AuthHeader } from '../../../helpers/AuthHeader';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { APP_BUTTON_TYPE } from '../../../types/IGlobal';
import { FeedInfo } from '../../FeedDetail/FeedInfo/FeedInfo';
import { Login } from '../../Login/Login';
//styles
import {
  ActivityStatsContainer,
  ActivityStatsWrapper,
  LoginButton,
  LoginWrapper,
} from './ActivityStats.styles';
import { StatsDetail } from './StatsDetail/StatsDetail';
//views
import { Toolbar } from './Toolbar/Toolbar';

interface Props {}

export const ActivityStats: ComponentType<Props> = observer(({}) => {
  //check valid token
  /*let paramsQuery = parse(window.location.search);
    if (isEmpty(paramsQuery) || !paramsQuery.tk)
    {
        paramsQuery.tk = process.env.REACT_APP_LOGIN_TOKEN!;
        console.log(paramsQuery.tk);
        //return <Navigate to="/not-found"></Navigate>;
    }
    
    if (paramsQuery.tk != null) {
        const token: LoginToken | null = parseJWT(paramsQuery.tk.toString());
        
        if (!token || !token.isValid())
            return <Navigate to="/not-found"></Navigate>;
        else {
            token.setAccessToken(paramsQuery.tk.toString());
            localStorage.setItem(LOCAL_KEY.CURRENT_USER, token.getValueJson());
            console.log(token);
        }
    }*/

  const store = useRootStore();

  const { currentUser, setUserData } = store.authStore;

  const [showLogin, setShowLogin] = React.useState(false);
  const [screen, setScreen] = React.useState(SCREEN_FEED_DETAIL.INFO);

  //get default activity id param
  const paramsQuery = parse(window.location.search);
  let feedId: any;
  if (!isEmpty(paramsQuery) || !paramsQuery.fid) feedId = paramsQuery.fid;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    //check token login
    if (!AuthHeader()) setShowLogin(true);
    else setUserData(AuthHeader());
  }, []);

  const onChangeScreen = (screen: SCREEN_FEED_DETAIL) => {
    setScreen(screen);
  };

  const renderScreen = () => {
    if (screen === SCREEN_FEED_DETAIL.INFO) return <FeedInfo />;
    else return <StatsDetail />;
  };

  const onShowLogin = () => {
    setShowLogin(true);
  };

  return (
    <ActivityStatsWrapper>
      {AuthHeader() || currentUser ? (
        <ActivityStatsContainer>
          <Toolbar screen={screen} feedId={feedId} onChangeScreen={onChangeScreen} />
          {renderScreen()}
        </ActivityStatsContainer>
      ) : (
        <LoginWrapper>
          <LoginButton typeButton={APP_BUTTON_TYPE.PRIMARY} onClick={() => onShowLogin()}>
            Đăng nhập
          </LoginButton>
          <p>Vui lòng đăng nhập để sử dụng chức năng này!</p>
        </LoginWrapper>
      )}
      <Login
        visible={showLogin}
        onClose={() => setShowLogin(false)}
        onLoginSuccess={() => setShowLogin(false)}
      />
    </ActivityStatsWrapper>
  );
});
