import React from 'react';

import { APP_LANGUAGE } from '../../../configs/GlobalConst';
import { IEventStats } from '../../../types/IJson';
import { ItemDescription, ItemTitle, StatsItemContainer } from './EventStats.styles';

interface Props {
  data: IEventStats;
  language: string | null;
}

export const EventStatsItem: React.FC<Props> = ({ data, language }) => {
  const title = language === APP_LANGUAGE.VIETNAMES ? data.title_vi : data.title_en;
  const desc = language === APP_LANGUAGE.VIETNAMES ? data.desc_vi : data.desc_en;

  return (
    <StatsItemContainer>
      <ItemTitle>{title}</ItemTitle>
      <ItemDescription>{desc}</ItemDescription>
    </StatsItemContainer>
  );
};
