import { ComponentType } from 'react';
import pure from 'recompose/pure';

import { APP_CONFIG } from '../../configs/GlobalConst';
//styles
import { Icon, InputContainer, InputText } from './UpraceInput.styles';

interface Props {
  value: any;
  placeHolder: string;
  showIconLeft: boolean;
  onEnter: () => void;
  onChange: (e: any) => void;
  className?: any;
}
export const UpraceInput: ComponentType<Props> = pure(
  ({ value, placeHolder, onEnter, onChange, className, showIconLeft }) => {
    const handleKeyDown = (e: any) => {
      if (e.key === 'Enter') {
        onEnter();
      }
    };

    return (
      <InputContainer className={className}>
        {showIconLeft ? (
          <Icon src={`${APP_CONFIG.STATIC_URL}/images/icon/ic_search.svg`} />
        ) : (
          <div style={{ marginRight: 12 }}></div>
        )}
        <InputText
          placeholder={placeHolder}
          value={value}
          onKeyDown={handleKeyDown}
          onChange={onChange}
        />
      </InputContainer>
    );
  },
);
