import { ComponentType } from 'react';
import pure from 'recompose/pure';

import { APP_CONFIG } from '../../configs/GlobalConst';
import { LOGIN_TYPE } from '../../types/ILogin';

interface Props {
  type: LOGIN_TYPE;
  className?: any;
}

export const LoginTypeIcon: ComponentType<Props> = pure(({ type, className }) => {
  let appSrc;
  switch (type) {
    case LOGIN_TYPE.FACEBOOK:
      appSrc = 'ic_facebook.png';
      break;
    case LOGIN_TYPE.GOOGLE:
      appSrc = 'ic_google.png';
      break;
    default:
      appSrc = 'ic_email.png';
      break;
  }

  const fullSrc = `${APP_CONFIG.STATIC_URL}/images/icon/${appSrc}`;

  const onLoadImageError = (event: any) => {
    event.target.src = `${APP_CONFIG.STATIC_URL}/images/icon/ic_email.png`;
    event.onerror = null;
  };

  return <img src={fullSrc} onError={onLoadImageError} className={className} />;
});
