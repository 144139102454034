import { action, makeObservable, observable, runInAction } from 'mobx';

import { APIResponse, APIResponseList } from '../models/APIResponse';
import { Feed, FeedAnalysis, ListFeed } from '../models/Feed';
import FeedService from '../services/FeedService';
import {
  IFeedAnalysis,
  IGetFeedAnalysisRequest,
  IGetFeedDetailRequest,
  IGetListFeedRequest,
  IMarkLabelRequest,
} from '../types/IFeed';
import { IResponseBase } from '../types/ITypeBase';
import { IFeed } from './../types/IFeed';
import RootStore from './RootStore';

class FeedStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  //declare variables
  @observable currentFeed: Feed | null = null;
  @observable feedAnalysis: FeedAnalysis | null = null;
  @observable loadFeed: boolean | null = false;
  @observable listFeed: ListFeed | null = null;

  //declare actions
  @action getFeedDetail = async (params: IGetFeedDetailRequest) => {
    runInAction(() => {
      this.loadFeed = true;
    });
    const result = (await FeedService.getInstance().getFeedDetail(params)) as APIResponse<IFeed>;

    if (result && result.isSuccess()) {
      runInAction(() => {
        this.currentFeed = Feed.fromJson(result.data!);
      });
    }
    this.loadFeed = false;
    return result;
  };

  @action getFeedAnalysis = async (params: IGetFeedAnalysisRequest) => {
    const result = (await FeedService.getInstance().getFeedAnalysis(
      params,
    )) as APIResponse<IFeedAnalysis>;

    if (result && result.isSuccess()) {
      runInAction(() => {
        this.feedAnalysis = FeedAnalysis.fromJson(result.data!);
      });
    }
    return result;
  };

  @action markLabel = async (params: IMarkLabelRequest) => {
    const result = (await FeedService.getInstance().markLabel(
      params,
    )) as APIResponse<IResponseBase>;
    return result;
  };

  @action setFeedData = async (feed: Feed | null) => {
    runInAction(() => {
      this.currentFeed = feed;
    });
  };

  @action setFeedAnalysisData = async (feedAnalysis: FeedAnalysis | null) => {
    runInAction(() => {
      this.feedAnalysis = feedAnalysis;
    });
  };

  @action getListFeed = async (params: IGetListFeedRequest, loadMore: boolean) => {
    //init list when load first or null and calc paging
    if (!loadMore || !this.listFeed)
      runInAction(() => {
        this.listFeed = new ListFeed();
      });
    else if (loadMore) {
      this.listFeed.pageIndex += 1;
      this.listFeed.isLoading = true; // determine if we're loading data is show process in UI
      params.data.from = params.data.size
        ? params.data.size * this.listFeed.pageIndex
        : this.listFeed.pageSize * this.listFeed.pageIndex;
    }

    //request api service
    const result = (await FeedService.getInstance().getListFeed(params)) as APIResponseList<
      Array<IFeed>
    >;

    //check result and binding data if success
    if (result && result.isSuccess()) {
      runInAction(() => {
        if (this.listFeed) {
          this.listFeed.list = this.listFeed.list?.concat(ListFeed.fromJson(result.data?.list!))!;
          this.listFeed.totalRecord = result.totalRecord();
          this.listFeed.isLoading = false;
        }
      });
    }
    return result;
  };
}
export default FeedStore;
