import styled from 'styled-components';

import { device } from '../../configs/MediaQuery';

export const InputContainer = styled.div`
  height: 40px;
  border-radius: 8px;
  border: 1px solid #dbdfe2;
  display: flex;

  @media ${device.mobileS} {
    width: 95%;
  }

  @media ${device.laptop} {
    width: 340px;
  }
`;

export const InputText = styled.input`
  border: 0;
  height: 38px;
  background: transparent;
  margin-right: 10px;
  border-radius: 8px;
  outline: none;
  width: 100%;
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin: 12px;
`;
