import styled from 'styled-components';

import { device } from '../../configs/MediaQuery';

export const UserProfileWrapper = styled.section`
  min-height: 800px;
  background: #f4f6f8;
`;

export const UserProfileContainer = styled.div`
  max-width: 1120px;
  margin: 0 auto;

  @media ${device.mobileS} {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media ${device.tablet} {
  }
  @media ${device.laptop} {
    padding-top: 40px;
    padding-bottom: 60px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;

  @media ${device.mobileS} {
    padding-bottom: 10px;
  }
  @media ${device.tablet} {
    padding-bottom: 20px;
  }
  @media ${device.laptop} {
    padding-bottom: 20px;
  }
`;

export const TitleBold = styled.h1`
  font-weight: 600;

  @media ${device.mobileS} {
    font-size: 18px;
    line-height: 20px;
  }
  @media ${device.tablet} {
    font-size: 20px;
    line-height: 24px;
  }
  @media ${device.laptop} {
    font-size: 26px;
    line-height: 30px;
  }
`;

export const TitleNormal = styled(TitleBold)`
  font-weight: 400;
  margin-left: 10px;
`;
