import styled from 'styled-components';

import { device } from '../configs/MediaQuery';
import { defaultTheme } from '../themes';
import { TextHeadingH1, TextHeadingH3 } from './TypographyStyles';

export const Section = styled.section`
  width: 100%;
  background: transparent;
`;

export const Container = styled.div`
  max-width: 1160px;
  padding-bottom: 60px;
  margin: 0 auto;

  @media ${device.mobileS} {
    padding-top: 32px;
    padding-left: 16px;
    padding-right: 16px;
  }
  @media ${device.tablet} {
    padding-top: 60px;
    padding-left: 30px;
    padding-right: 30px;
  }
  @media ${device.laptop} {
    padding-top: 60px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const TitleDash = styled.div`
  background-color: ${defaultTheme.colors.primary};
  width: 60px;
  height: 6px;
  border-radius: 5px;
  margin: 0 auto;
`;

export const TitleText = styled(TextHeadingH1)`
  padding-top: 12px;
  padding-bottom: 36px;

  strong {
    color: ${defaultTheme.colors.primary};
    font-weight: 600;
  }
`;

export const TitleTextSub = styled(TextHeadingH3)``;

export const TitleDescription = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${defaultTheme.colors.gray};
`;
