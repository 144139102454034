import styled from 'styled-components';

import { device } from '../../../configs/MediaQuery';
import { TextHeadingH1 } from '../../../styles/TypographyStyles';

export const NewsDetailWrapper = styled.section``;

export const NewsDetailContainer = styled.div`
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;

  @media ${device.mobileS} {
    max-width: 342px;
  }

  @media ${device.laptop} {
    max-width: 1163px;
  }
`;

export const TitleHeader = styled(TextHeadingH1)`
  font-size: 2.5rem;
  line-height: 3.25rem;
  color: #e95819;
  padding-bottom: 50px;
`;
