import './AlertModal.css';

import { Modal } from 'antd';

import { useGlobalModalContext } from '../../providers/GlobalModalProvider';
import { ALERT_TYPE } from '../../types/IGlobal';

export const AlertModal: any = () => {
  const { store, hideModal } = useGlobalModalContext();

  const title = store.modalProps?.title;
  const content = store.modalProps?.content;
  const alertType = store.modalProps?.alertType;

  if (alertType === ALERT_TYPE.INFO)
    Modal.info({
      title,
      content,
      okText: store.modalProps?.buttonConfirmText || 'OK',
      onOk() {},
    });
  else if (alertType === ALERT_TYPE.SUCCESS)
    Modal.success({
      title,
      content,
      okText: store.modalProps?.buttonConfirmText || 'OK',
      onOk() {},
    });
  else if (alertType === ALERT_TYPE.ERROR)
    Modal.error({
      title,
      content,
      okText: store.modalProps?.buttonConfirmText || 'OK',
      onOk() {},
    });
  else if (alertType === ALERT_TYPE.WARNING)
    Modal.warning({
      title,
      content,
      okText: store.modalProps?.buttonConfirmText || 'OK',
      onOk() {},
    });
};
