import { observer } from 'mobx-react-lite';
import { ComponentType } from 'react';

import { useRootStore } from '../../../../providers/RootStoreProvider';
import { AccelerationStats } from '../Charts/AccelerationStats';
import { DeltaAltitudeStats } from '../Charts/DeltaAltitudeStats';
import { DeltaAzimuthStats } from '../Charts/DeltaAzimuthStats';
//views
import { GpsStats } from '../Charts/GpsStats';
import { PowerStats } from '../Charts/PowerStats';
import { SpeedStats } from '../Charts/SpeedStats';
import { SplitStats } from '../Charts/SplitStats';
import { GeneralInfo } from '../GeneralInfo/GeneralInfo';
import { MarkLabel } from '../MarkLabel/MarkLabel';
//styles
import { StatsDetailContainer, StatsDetailWrapper } from './StatsDetail.styles';

interface Props {}

export const StatsDetail: ComponentType<Props> = observer(({}) => {
  const store = useRootStore();

  const { feedAnalysis } = store.feedStore;

  return (
    <StatsDetailWrapper>
      <StatsDetailContainer>
        {feedAnalysis ? <MarkLabel data={feedAnalysis} /> : null}
        {feedAnalysis ? <GeneralInfo data={feedAnalysis} /> : null}

        {feedAnalysis ? <PowerStats data={feedAnalysis} /> : null}
        {/* {feedAnalysis?.powerDaily ? (
                    <PowerDailyStats data={feedAnalysis.powerDaily} />
                ) : null} */}
        {feedAnalysis?.splits ? <SplitStats data={feedAnalysis?.splits} /> : null}
        {feedAnalysis?.gps ? <GpsStats data={feedAnalysis?.gps} /> : null}
        {feedAnalysis?.speed ? <SpeedStats data={feedAnalysis?.speed} /> : null}

        {feedAnalysis?.deltaAzimuth ? (
          <DeltaAzimuthStats data={feedAnalysis?.deltaAzimuth} />
        ) : null}
        {feedAnalysis?.acceleration ? (
          <AccelerationStats data={feedAnalysis?.acceleration} />
        ) : null}
        {feedAnalysis?.deltaAltitude ? (
          <DeltaAltitudeStats data={feedAnalysis?.deltaAltitude} />
        ) : null}
      </StatsDetailContainer>
    </StatsDetailWrapper>
  );
});
