import React from 'react';
import { useTranslation } from 'react-i18next';

export const WelcomeSection: React.FC = () => {
  const { t } = useTranslation();

  const onGotoContactForm = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <section className='contact-section'>
      <div className='container contact-section-block'>
        <div className='row g-md-4 align-items-md-center row-cols-1 row-cols-md-2'>
          <div className='col order-md-2 mb-5 mb-md-0'>
            <div className='card ms-md-5'>
              <img
                src='https://uprace2.vcdn.vn/web/uprace/contact-us/contact-section-01.png'
                className='card-img'
                alt='UPRACE'
              />
            </div>
          </div>
          <div className='col order-md-1 col-desc'>
            <h1 className='title'>{t('contact:welcome.title')}</h1>
            <div
              className='desc mt-3 mt-md-4'
              dangerouslySetInnerHTML={{
                __html: t('contact:welcome.description'),
              }}></div>
            <div className='desc mb-4'></div>
            <button className='btn btn-primary btn-contact' onClick={() => onGotoContactForm()}>
              {t('global:contact')}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
