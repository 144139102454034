import { action, makeObservable, observable, runInAction } from 'mobx';

import { APIResponse } from '../models/APIResponse';
import User from '../models/User';
import { UserProfile } from '../models/UserProfile';
import UserService from '../services/UserService';
import { IFeedUserStats, IGetFeedUserStats } from '../types/IFeed';
import { IResponseBase } from '../types/ITypeBase';
import { IGetUserProfileDetailRequest, IUserFeedbackRequest } from '../types/IUser';
import { IUserProfile } from '../types/IUserProfile';
import { FeedUserStats } from './../models/Feed';
import RootStore from './RootStore';

class UserStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  //declare variables
  @observable currentUser: User | null = null;
  @observable userProfile: UserProfile | null = null;
  @observable feedUserStats: FeedUserStats | null = null;

  //declare actions
  //get profile user and return an APIResponse with data type is IUser
  @action getProfileDetail = async (params: IGetUserProfileDetailRequest) => {
    const result = (await UserService.getInstance().getProfileDetail(
      params,
    )) as APIResponse<IUserProfile>;

    if (result && result.isSuccess())
      runInAction(() => {
        //convert json response to User object
        this.userProfile = UserProfile.fromJson(result.data!);
      });
    console.log(this.userProfile);
    return result;
  };

  @action getFeedUserStats = async (params: IGetFeedUserStats) => {
    const result = (await UserService.getInstance().getFeedUserStats(
      params,
    )) as APIResponse<IFeedUserStats>;

    if (result && result.isSuccess())
      runInAction(() => {
        //convert json response to User object
        this.feedUserStats = FeedUserStats.fromJson(result.data!);
        console.log(this.feedUserStats);
      });
  };

  @action sendFeedback = async (params: IUserFeedbackRequest) => {
    const result = (await UserService.getInstance().sendFeedback(
      params,
    )) as APIResponse<IResponseBase>;
    return result;
  };
}
export default UserStore;
