import { Collapse } from 'antd';
import { ComponentType, useEffect, useTransition } from 'react';
import React from 'react';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ReferenceLine,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import pure from 'recompose/pure';

import { IPowerDaily } from '../../../../types/IFeed';
import { convertShortDuration } from '../../../../utils/FeedUtils';
//styles
import {
  LineChartContainer,
  TooltipContainer,
  TooltipHeaderContainer,
  TooltipText,
  TooltipVal,
} from './Charts.styles';

interface Props {
  data: IPowerDaily[];
  limitCalo: number;
  limitMet: number;
}

const { Panel } = Collapse;

export const PowerDailyStats: ComponentType<Props> = pure(({ data, limitCalo, limitMet }) => {
  //check if data not exist...
  if (!data || data.length === 0) return null;
  const [isPending, startTransition] = useTransition();
  const [chartData, setChartData] = React.useState<IPowerDaily[]>([]);

  useEffect(() => {
    startTransition(() => setChartData(data));
  }, [data]);

  const CustomTooltip = ({ active, payload, label }: TooltipProps<any, any>) => {
    if (active) {
      return (
        <TooltipContainer>
          <TooltipHeaderContainer>
            <TooltipText>Tổng số </TooltipText>
            <TooltipVal>{Math.round(payload?.[0].value)}</TooltipVal>
            <TooltipText>kcal ở ngày</TooltipText>
            <TooltipVal>{label}</TooltipVal>
            <TooltipText></TooltipText>
          </TooltipHeaderContainer>
        </TooltipContainer>
      );
    }
    return null;
  };

  const chartWidth = data.length * 30 < 1024 ? 1024 : data.length * 30;

  const formatYAxisPace = (value: any) => {
    return convertShortDuration(value, true)!;
  };

  const CustomTooltipAMet = ({ active, payload, label }: TooltipProps<any, any>) => {
    if (active) {
      const data: IPowerDaily = payload?.[0].payload;
      return (
        <TooltipContainer>
          <TooltipHeaderContainer>
            <TooltipText>Trung bình </TooltipText>
            <TooltipVal>{Math.round(payload?.[0].value)}</TooltipVal>
            <TooltipText>MET/hoạt động ở ngày</TooltipText>
            <TooltipVal>{label}</TooltipVal>
            <TooltipText></TooltipText>
          </TooltipHeaderContainer>
        </TooltipContainer>
      );
    }
    return null;
  };

  return (
    <Collapse defaultActiveKey={[1]} style={{ marginBottom: 10 }}>
      <Panel header='Theo ngày' key={1}>
        <LineChartContainer>
          <BarChart
            width={chartWidth}
            height={300}
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='period'>
              <Label value='Thời gian (ngày)' offset={-2} position='insideBottomLeft' />
            </XAxis>
            <YAxis
              dataKey='scalo'
              //tickFormatter={formatYAxisPace}
            >
              <Label value='kcal' offset={0} angle={-90} position='insideLeft' />
            </YAxis>
            <Tooltip
              trigger='hover'
              wrapperStyle={{ pointerEvents: 'auto' }}
              cursor={{
                stroke: '#5fb07d',
                strokeWidth: 1,
                color: 'red',
              }}
              content={<CustomTooltip />}
            />
            <Bar dataKey='scalo' fill='#5fb07d' />
            <ReferenceLine y={limitCalo} stroke='red' ifOverflow='extendDomain' />
          </BarChart>

          <AreaChart
            width={chartWidth}
            height={300}
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}>
            <defs>
              <linearGradient id='colorBpm' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='5%' stopColor='#5fb07d' stopOpacity={0.8} />
                <stop offset='95%' stopColor='#5fb07d' stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='period' angle={0}>
              <Label value='Thời gian (ngày)' offset={-2} position='insideBottomLeft' />
            </XAxis>
            <YAxis dataKey='amet' type='number'>
              <Label
                value='MET trung bình'
                offset={10}
                x={-20}
                x1={-20}
                x2={-20}
                angle={-90}
                position='insideLeft'
              />
            </YAxis>

            <Tooltip
              cursor={{
                stroke: '#F44336',
                strokeWidth: 1,
                color: 'red',
              }}
              content={<CustomTooltipAMet />}
            />

            <Area
              type='monotone'
              dataKey='amet'
              stroke='#5fb07d'
              fillOpacity={1}
              activeDot={{ r: 8 }}
              fill='url(#colorBpm)'
            />
            <ReferenceLine y={limitMet} stroke='red' ifOverflow='extendDomain' />
          </AreaChart>
        </LineChartContainer>
      </Panel>
    </Collapse>
  );
});
