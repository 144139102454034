import React, { useEffect } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '../../hooks/useMediaQuery';
import { AnnualEventSection } from './AnnualEventSection/AnnualEventSection';
import { DownloadSection } from './DownloadSection/DownloadSection';
import { FeatureSection } from './FeatureSection/FeatureSection';
import { HeroSection } from './HeroSection/HeroSection';

export const LandingPage: React.FC = () => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery('(min-width: 960px)');

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    toggleButtonDownload();
  }, []);

  useEffect(() => {
    toggleButtonDownload();
  });

  const downloadApp = () => {
    if (isAndroid) {
      window.location.href = 'https://play.google.com/store/apps/details?id=vn.vng.uprace';
    } else if (isIOS) {
      window.location.href = 'https://apps.apple.com/us/app/id1476173971';
    }
  };

  const toggleButtonDownload = () => {
    const headerOffCanvas = document.getElementById('upraceNavbar');
    const downloadScroll = document.getElementById('downloadNowScroll');

    if (!downloadScroll) return;
    headerOffCanvas?.addEventListener('show.bs.offcanvas', function () {
      //hidden download-now-scroll
      downloadScroll.classList.add('d-none');
    });
    headerOffCanvas?.addEventListener('hide.bs.offcanvas', function () {
      //display download-now-scroll
      downloadScroll.classList.remove('d-none');
    });
  };

  return (
    <>
      <div className='landing_page'>
        <HeroSection />
        <FeatureSection />
        <AnnualEventSection />
        {/* <NewSection />
                <CommunitySection /> */}
        <DownloadSection />

        <Helmet>
          <script src={process.env.REACT_APP_CDN_URL + 'js/landing.js'}></script>
        </Helmet>
      </div>
      {!isDesktop ? (
        <div
          className='download-now-scroll'
          id='downloadNowScroll'
          style={{ paddingLeft: 10 }}
          onClick={() => downloadApp()}>
          <a className='download-now__link pulse-button'>
            <span
              className='text'
              dangerouslySetInnerHTML={{
                __html: t('landing:download.scrollDownloadTitle').toUpperCase(),
              }}></span>{' '}
            <br />
            <i className='fi fi-download'></i>
          </a>
        </div>
      ) : null}
    </>
  );
};
