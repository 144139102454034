import { Spin } from 'antd';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import pure from 'recompose/pure';

//styles
import { LoadMoreButton, LoadMoreWrapper } from './LoadMore.styles';
interface Props {
  onClick: () => void;
  loading: boolean;
}

export const LoadMore: ComponentType<Props> = pure(({ loading, onClick }) => {
  const { t } = useTranslation();

  return (
    <LoadMoreWrapper onClick={onClick}>
      {loading ? <Spin /> : <LoadMoreButton>{t('global:loadMore')}</LoadMoreButton>}
    </LoadMoreWrapper>
  );
});
