import { ComponentType } from 'react';
import pure from 'recompose/pure';

import { FEED_SPORT_TYPE } from '../../types/IFeed';
import { CycleIcon, RunIcon, SwimIcon, WalkIcon } from './SvgIcon';

interface Props {
  type: FEED_SPORT_TYPE;
}

export const SportIcon: ComponentType<Props> = pure(({ type }) => {
  const renderIcon = () => {
    if (type === FEED_SPORT_TYPE.RUNNING) return <RunIcon />;
    else if (type === FEED_SPORT_TYPE.SWIMMING) return <SwimIcon />;
    else if (type === FEED_SPORT_TYPE.WALKING) return <WalkIcon />;
    else if (type === FEED_SPORT_TYPE.CYCLING) return <CycleIcon />;
  };
  return <>{renderIcon()}</>;
});
