import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { MapImage } from '../../../components/Feed/MapImage';
import { AppIcon } from '../../../components/Icons/AppIcon';
import { AvatarImage } from '../../../components/User/AvatarImage';
import { device } from '../../../configs/MediaQuery';

export const ActivityItemWrapper = styled.div`
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 16px;
  border-radius: 8px;
`;

export const ActivityItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TopContainer = styled.div`
  display: flex;
`;

export const MiddleContainer = styled.div``;

export const BottomContainer = styled.div``;

export const AvatarContainer = styled.div`
  margin-right: 10px;
`;

export const AvatarUser = styled(AvatarImage)`
  border-radius: 50%;
  object-fit: cover;
  @media ${device.mobileS} {
    width: 32px;
    height: 32px;
  }
  @media ${device.tablet} {
    width: 40px;
    height: 40px;
  }
  @media ${device.laptop} {
    width: 48px;
    height: 48px;
  }
`;

export const AppSourceIcon = styled(AppIcon)`
  width: 20px;
  height: 20px;
  object-fit: cover;
  margin-left: 5px;
`;

export const InfoContainer = styled.div``;

export const UserName = styled.h5`
  line-height: 24px;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`;

export const CreateDate = styled.p`
  line-height: 22px;
  margin-top: 4px;
  color: #797979;
  font-size: 12px;
  margin-bottom: 0;
`;

export const FeedTitle = styled.h5`
  line-height: 24px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 0;
`;

export const SocialContainer = styled.div`
  display: none;
  justify-content: space-between;
  margin: 24px 4px 0 0;
  padding-top: 7px;
  border-top: 1px solid hsla(0, 0%, 84.7%, 0.3);
`;

export const LikeContainer = styled.div``;

export const CommentContainer = styled.div``;

export const LineDivider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #e7eaec;
`;

export const LabelText = styled.p`
  margin: 0;
  line-height: 21px;
  padding: 3px 3px 0;
  color: #797979;
  font-size: 14px;
`;

export const ValueText = styled.p`
  margin: 0;
  margin-top: 3px;
  font-weight: 600;
  color: #000;

  @media ${device.mobileS} {
    line-height: 21px;
    font-size: 16px;
  }
  @media ${device.tablet} {
    font-size: 18px;
    line-height: 27px;
  }
`;

export const MapActivityContainer = styled.div`
  margin-top: 15px;
`;

export const MapActivityImage = styled(MapImage)`
  width: 100%;
  object-fit: cover;
`;

export const ViewDetailLink = styled(Link)`
  text-decoration: none;
  display: inline-block;
  width: 100%;
`;
