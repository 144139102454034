import React, { createContext, useContext, useState } from 'react';

import { AlertModal, ConfirmModal } from '../components/Modals';
import { ALERT_TYPE } from '../types/IGlobal';

export enum GLOBAL_MODAL_TYPE {
  ALERT_MODAL,
  CONFIRM_MODAL,
}

const GLOBAL_MODAL_COMPONENT: any = {
  [GLOBAL_MODAL_TYPE.ALERT_MODAL]: AlertModal,
  [GLOBAL_MODAL_TYPE.CONFIRM_MODAL]: ConfirmModal,
};

interface StoreProps {
  modalType: GLOBAL_MODAL_TYPE | null;
  modalProps: ModalProps | null;
}

interface ContextType {
  showModal: (modalType: GLOBAL_MODAL_TYPE, modalProps?: any) => void;
  hideModal: () => void;
  store: StoreProps;
}

export interface ModalProps {
  title: string;
  content: string;
  buttonCloseText: string;
  buttonConfirmText: string;
  alertType: ALERT_TYPE;
  onClose: () => void;
  onConfirm: () => void;
}

const initState: ContextType = {
  showModal: () => {},
  hideModal: () => {},
  store: null!,
};

//create modal context
const GlobalModalContext = createContext(initState);
export const useGlobalModalContext = () => useContext(GlobalModalContext);

//create modal provider
interface GlobalModalProps {
  children: JSX.Element;
}
export const GlobalModalProvider: React.FC<GlobalModalProps> = ({ children }) => {
  const initStore: StoreProps = {
    modalType: null,
    modalProps: null,
  };
  const [store, setStore] = useState(initStore);
  const { modalType, modalProps } = store || {};

  const showModal = (modalType: number, modalProps: any = {}) => {
    setStore({
      ...store,
      modalType,
      modalProps,
    });
  };

  const hideModal = () => {
    setStore({
      ...store,
      modalType: null,
      modalProps: null,
    });
  };

  //render component base on type : AlertModal or ConfirmModal
  const renderComponent = () => {
    const ModalComponent = GLOBAL_MODAL_COMPONENT[modalType!];
    if (modalType === null || !ModalComponent) {
      return null;
    }
    return <ModalComponent id='global-modal' {...modalProps} />;
  };

  return (
    <GlobalModalContext.Provider value={{ store, showModal, hideModal }}>
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  );
};
