import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import {
  EVENT_FORM_TYPE,
  EVENT_JOIN_STATUS,
  EVENT_OFFICIAL,
  EVENT_PUBlIC_TYPE,
  EVENT_STATUS,
  EVENT_TYPE,
  IEvent,
} from '../types/IEvent';
import { SORT_ORDER_TYPE } from '../types/IGlobal';
import { DataList } from './DataList';
import { ModelBase } from './ModelBase';
import { ITeamRanking } from '../types/ILeaderBoard';

export class Event extends ModelBase {
  eventId: number;
  name: string;
  cover: string;
  startDate: any;
  endDate: any;
  publicType: EVENT_PUBlIC_TYPE;
  status: EVENT_STATUS;
  form: EVENT_FORM_TYPE;
  totalMember: number;
  ownerId: number;
  ownerName: string;

  //observable properties
  @observable userJoinStatus: EVENT_JOIN_STATUS;

  constructor(
    eventId: number,
    name: string,
    cover: string,
    startDate: any,
    endDate: any,
    publicType: EVENT_PUBlIC_TYPE,
    userJoinStatus: EVENT_JOIN_STATUS,
    status: EVENT_STATUS,
    form: EVENT_FORM_TYPE,
    totalMember: number,
    ownerId: number,
    ownerName: string,
  ) {
    super();
    this.eventId = eventId;
    this.name = name;
    this.cover = cover;
    this.startDate = startDate;
    this.endDate = endDate;
    this.publicType = publicType;
    this.userJoinStatus = userJoinStatus;
    this.status = status;
    this.form = form;
    this.totalMember = totalMember;
    this.ownerId = ownerId;
    this.ownerName = ownerName;
  }

  //method allows user views info such as leaderboard
  isAllowView = (): boolean => {
    return this.isPublic() || this.isJoined;
  };

  //true if event is public
  isPublic = (): boolean => {
    return this.publicType === EVENT_PUBlIC_TYPE.PUBLIC;
  };

  //return true if user already join event and else return false
  @computed get isJoined() {
    return (
      this.userJoinStatus != EVENT_JOIN_STATUS.NONE &&
      this.userJoinStatus != EVENT_JOIN_STATUS.INVITING &&
      this.userJoinStatus != EVENT_JOIN_STATUS.REQUESTING
    );
  }

  isFinished = (): boolean => {
    return this.status === EVENT_STATUS.FINISHED;
  };

  isOwner(memberId: number): boolean {
    return this.ownerId === memberId;
  }

  isAdmin(): boolean {
    return this.userJoinStatus === EVENT_JOIN_STATUS.ADMIN;
  }

  //method allow user action on event such as JOIN, LEAVE...
  setUserJoinStatus(status: EVENT_JOIN_STATUS) {
    this.userJoinStatus = status;
  }

  getCoverImage(): string {
    return this.cover
      .replace('.png', '@2x.png')
      .replace('.jpg', '@2x.jpg')
      .replace('.jpeg', '@2x.jpeg');
  }

  static fromJson = (json: IEvent): Event => {
    return new Event(
      json.evid,
      json.name,
      json.cover,
      json.betm,
      json.entm,
      json.jpri,
      json.jsts,
      json.sts,
      json.form,
      json.mem,
      json.owner,
      json.ownm,
    );
  };
}

export class EventFilter {
  @observable eventName: string = '';
  @observable eventStatus: EVENT_STATUS = EVENT_STATUS.ALL;
  @observable quantityMember: SORT_ORDER_TYPE = SORT_ORDER_TYPE.ASC;
  @observable eventType: EVENT_TYPE = EVENT_TYPE.ALL;
  @observable eventOfficial: EVENT_OFFICIAL = EVENT_OFFICIAL.ALL;

  //store old conditions for cancel filter
  private eventStatusOld: EVENT_STATUS = this.eventStatus.valueOf();
  private quantityMemberOld: SORT_ORDER_TYPE = this.quantityMember.valueOf();
  private eventTypeOld: EVENT_TYPE = this.eventType.valueOf();
  private eventOfficialOld: EVENT_OFFICIAL = this.eventOfficial.valueOf();

  constructor() {
    makeObservable(this);
  }

  @action applyFilter = (): void => {
    //every time apply filter then store old conditions for cancel filter
    this.eventStatusOld = this.eventStatus.valueOf();
    this.quantityMemberOld = this.quantityMember.valueOf();
    this.eventTypeOld = this.eventType.valueOf();
    this.eventOfficialOld = this.eventOfficial.valueOf();
  };

  @action cancelFilter = () => {
    runInAction(() => {
      this.eventName = '';
      this.eventStatus = EVENT_STATUS.ALL;
      this.quantityMember = SORT_ORDER_TYPE.ASC;
      this.eventType = EVENT_TYPE.ALL;
      this.eventOfficial = EVENT_OFFICIAL.ALL;
    });
  };

  @action changeEventName = (eventName: string) => {
    runInAction(() => {
      this.eventName = eventName;
    });
  };

  @action changeEventStatus = (eventStatus: EVENT_STATUS) => {
    runInAction(() => {
      this.eventStatus = eventStatus;
    });
  };

  @action changeQuantityMember = (quantityMember: SORT_ORDER_TYPE) => {
    runInAction(() => {
      this.quantityMember = quantityMember;
    });
  };

  @action changeEventType = (eventType: EVENT_TYPE) => {
    runInAction(() => {
      this.eventType = eventType;
    });
  };

  @action changeEventOfficial = (eventOfficial: EVENT_OFFICIAL) => {
    runInAction(() => {
      this.eventOfficial = eventOfficial;
    });
  };
}
export class ListEvent extends DataList<Event> {
  static fromJson = (jsonArray: Array<IEvent>): Array<Event> => {
    const data = new Array<Event>();
    jsonArray.forEach((event) => {
      data.push(Event.fromJson(event));
    });
    return data;
  };

  hasData(): boolean {
    return this.list.length > 0;
  }
}

export class ListEventRanking extends DataList<ITeamRanking> {
  static fromJson = (jsonArray: Array<ITeamRanking>): Array<ITeamRanking> => {
    return jsonArray;
  };

  hasData(): boolean {
    return this.list.length > 0;
  }
}
