import styled from 'styled-components';

import { device } from '../../../../configs/MediaQuery';
import { AppButton } from '../../../../styles/ButtonStyles';

export const GeneralInfoWrapper = styled.div``;

export const GeneralInfoContainer = styled.div``;

export const ItemInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelText = styled.p`
  margin: 0;
  line-height: 15px;
  padding: 3px 3px 0;
  color: #797979;
  font-size: 12px;
`;

export const ValueText = styled.p`
  margin: 0;
  margin-top: 3px;
  font-weight: 600;
  margin-left: 5px;

  @media ${device.mobileS} {
    line-height: 14px;
    font-size: 16px;
  }
  @media ${device.tablet} {
    font-size: 16px;
    line-height: 29px;
  }
`;

export const GeneralInfoButton = styled(AppButton)`
  padding: 10px;
  min-width: 120px;
  height: 30px;
`;
