export const defaultTheme = {
  colors: {
    primaryDark: '#2E54E6',
    primary: '#0254DC',
    secondary: '#E9EEFF',
    gray: '#314154',
    grayLight: '#727F8E',
    black: '#212B36',
    white: '#FFFF',
    disabledButton: '#F4F6F8',
    disabledText: '#AAB2BF',
    darkLight: '#DBDFE2',
  },
};

export const theme2022 = {
  colors: {
    primaryDark: '#2E54E6',
    primary: '#0254DC',
    secondary: '#E9EEFF',
    backgroundInput: '#E9EEFF',
    disabledButton: '#F4F6F8',
    disabledText: '#AAB2BF',
  },
};
