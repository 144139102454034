import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { AppIcon } from '../../../components/Icons/AppIcon';
import { AvatarImage } from '../../../components/User/AvatarImage';
import { device } from '../../../configs/MediaQuery';

export const UserInfoWrapper = styled.div`
  @media ${device.mobileS} {
    padding: 16px;
  }
  @media ${device.tablet} {
    padding: 24px;
  }
`;

export const UserInfoContainer = styled.div`
  display: flex;
`;

export const AvatarContainer = styled.div`
  margin-right: 32px;
`;

export const AvatarUser = styled(AvatarImage)`
  border-radius: 50%;
  object-fit: cover;
  @media ${device.mobileS} {
    width: 60px;
    height: 60px;
  }
  @media ${device.tablet} {
    width: 80px;
    height: 80px;
  }
  @media ${device.laptop} {
    width: 80px;
    height: 80px;
  }
`;

export const AppSourceIcon = styled(AppIcon)`
  width: 20px;
  height: 20px;
  object-fit: cover;
  margin-left: 5px;
`;

export const InfoContainer = styled.div``;

export const UserName = styled.h5`
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
`;

export const CreateDate = styled.p`
  line-height: 22px;
  margin-top: 4px;
  color: #797979;
  font-size: 14px;
  margin-bottom: 0;
`;

export const FeedTitle = styled.h5`
  line-height: 24px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 0;
`;

export const SocialContainer = styled.div`
  display: none;
  justify-content: space-between;
  margin: 24px 4px 0 0;
  padding-top: 7px;
  border-top: 1px solid hsla(0, 0%, 84.7%, 0.3);
`;

export const LikeContainer = styled.div``;

export const CommentContainer = styled.div``;

export const ViewProfileLink = styled(Link)`
  text-decoration: none;
  display: flex;
  width: 100%;
`;
