import { DownOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import styled from 'styled-components';

import { device } from '../../../../configs/MediaQuery';

export const StatsInfoWrapper = styled.div`
  padding-top: 24px;
`;

export const StatsInfoContainer = styled.div`
  border: solid 1px #dbdfe2;
  border-radius: 8px;
  overflow: hidden;
`;

export const HeaderWrapper = styled(Row)`
  background-color: #f4f6f8;
  border-bottom: 1px solid #dbdfe2;
`;

export const ContentWrapper = styled(Row)`
  &:not(:last-child) {
    border-bottom: 0.5px solid #dbdfe2;
    border-width: 90%;
  }
`;

export const TextHeaderWrapper = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const TextContentWrapper = styled(TextHeaderWrapper)`
  padding: 15px;
`;

export const LabelText = styled.p`
  margin: 0;
  @media ${device.mobileS} {
    font-size: 14px;
    line-height: 21px;
  }
  @media ${device.tablet} {
    font-size: 16px;
    line-height: 21px;
  }
`;

export const ValueText = styled.p`
  margin: 0;
  font-weight: 600;
  @media ${device.mobileS} {
    font-size: 14px;
    line-height: 21px;
  }
  @media ${device.tablet} {
    font-size: 16px;
    line-height: 21px;
  }
`;

export const ArrowButton = styled(DownOutlined)`
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
`;
