import { API } from '../configs/APIEndPoint';
import { handleError } from '../helpers/APIHelper';
import { IActionGroupRequest, IGetGroupDetailRequest, IGetListGroupRequest } from '../types/IGroup';
import { ICreateGroupRequest, IDeleteGroupRequest, IUpdateGroupRequest } from './../types/IGroup';
import BaseAuthService from './BaseAuthService';

class GroupService extends BaseAuthService {
  private static instance: GroupService;

  public static getInstance(): GroupService {
    if (!GroupService.instance) {
      GroupService.instance = new GroupService();
    }
    return GroupService.instance;
  }

  //methods api
  getGroupDetail = async (params: IGetGroupDetailRequest) => {
    try {
      const response = await this.postData(API.GROUP.DETAIL, params);
      return this.handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  };

  getListGroup = async (params: IGetListGroupRequest) => {
    try {
      const response = await this.postData(API.GROUP.LIST, params);
      return this.handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  };

  createGroup = async (params: ICreateGroupRequest) => {
    try {
      const response = await this.postData(API.GROUP.CREATE, params);
      return this.handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  };

  updateGroup = async (params: IUpdateGroupRequest) => {
    try {
      const response = await this.postData(API.GROUP.UPDATE, params);
      return this.handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  };

  deleteGroup = async (params: IDeleteGroupRequest) => {
    try {
      const response = await this.postData(API.GROUP.DELETE, params);
      return this.handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  };

  actionGroup = async (params: IActionGroupRequest) => {
    try {
      const response = await this.postData(API.GROUP.REQUEST_JOIN, params);
      return this.handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  };
}
export default GroupService;
