export enum TEAM_COMPANY_TYPE {
  NONE,
  COMPANY,
  CLUB,
  SCHOOL,
}

export enum TEAM_STATUS {
  ACTIVE,
  DELETE,
}

export enum TEAM_OFFICIAL {
  UNOFFICIAL,
  OFFICIAL,
}

export interface ITeam {
  owner: number;
  tmid: number;
  offical: number;
  lgid: number;
  jpri: number;
  ava: string;
  cover: string;
  sts: number;
  mem: number;
  rmem: number;
  name: string;
  jsts: number;
  sport: number;
  desc: string;
}
