import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { ComponentType } from 'react';

import { Feed } from '../../../models/Feed';
import { FEED_SPORT_TYPE, ILap } from '../../../types/IFeed';
import { calcLapsChart, convertPaceToSpeed, convertShortDuration } from '../../../utils/FeedUtils';
import { formatDistance } from '../../../utils/FormatUtils';
//styles
import {
  ChartBar,
  ChartBarContainer,
  ChartContainer,
  ChartItemContainer,
  DistanceContainer,
  HeaderContainer,
  HeaderText,
  LapChartWrapper,
  PageContainer,
} from './LapChart.styles';

interface Props {
  feedData: Feed;
}

export const LapChart: ComponentType<Props> = observer(({ feedData }) => {
  const [arrLaps, setArrLaps] = React.useState<ILap[]>([]);

  useEffect(() => {
    setArrLaps(calcLapsChart(feedData.laps));
  }, [feedData.laps]);

  return (
    <LapChartWrapper>
      <HeaderContainer>
        <DistanceContainer>
          <HeaderText>Km</HeaderText>
        </DistanceContainer>
        <PageContainer>
          <HeaderText>Speed</HeaderText>
        </PageContainer>
      </HeaderContainer>

      <ChartContainer>
        {arrLaps &&
          arrLaps.map((item: ILap) => {
            return (
              <ChartItemContainer key={item.index}>
                <DistanceContainer>
                  {item.index == arrLaps.length ? formatDistance(item.distance, 2) : item.index}
                </DistanceContainer>
                <PageContainer>
                  {feedData.feedType === FEED_SPORT_TYPE.CYCLING
                    ? convertPaceToSpeed(item.pace)
                    : convertShortDuration(item.pace, true)}
                </PageContainer>
                <ChartBarContainer>
                  <ChartBar
                    style={{
                      width: `${item.percent}%`,
                    }}></ChartBar>
                </ChartBarContainer>
              </ChartItemContainer>
            );
          })}
      </ChartContainer>
    </LapChartWrapper>
  );
});
