import styled from 'styled-components';

export const ActivityStatsWrapper = styled.div`
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-bottom: 30px;
  border-radius: 8px;
  background: #fff;
`;

export const ActivityContainer = styled.div``;
