import { Col, Row, Spin } from 'antd';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import { parse } from 'query-string';
import { ComponentType, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { APIResponse } from '../../models/APIResponse';
import { useRootStore } from '../../providers/RootStoreProvider';
import { IGetUserProfileDetailRequest } from '../../types/IUser';
import { IUserProfile } from '../../types/IUserProfile';
//views
import { ActivityList } from './ActivityList/ActivityList';
import { ActivityStats } from './ActivityStats/ActivityStats';
import { PersonalInfo } from './PersonalInfo/PersonalInfo';
import { TeamList } from './TeamList/TeamList';
//styles
import { UserProfileContainer, UserProfileWrapper } from './UserProfile.styles';

interface Props {}

export const UserProfile: ComponentType<Props> = observer(() => {
  const store = useRootStore();
  const navigate = useNavigate();

  const { userProfile, getProfileDetail } = store.userStore;

  const paramsQuery = parse(window.location.search);
  if (isEmpty(paramsQuery) || !paramsQuery.uid) return <Navigate to='/not-found'></Navigate>;
  const userId = paramsQuery.uid;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    onGetProfileDetail();
  }, [paramsQuery.uid]);

  const onGetProfileDetail = async () => {
    const params: IGetUserProfileDetailRequest = {
      data: {
        pfid: userId,
      },
    };
    const result: APIResponse<IUserProfile> = await getProfileDetail(params);
    if (result && result.isError()) {
      navigate('/not-found');
    }
  };

  const onRenderLoading = () => {
    return (
      <UserProfileWrapper>
        <UserProfileContainer>
          <Spin />
        </UserProfileContainer>
      </UserProfileWrapper>
    );
  };

  if (userProfile)
    return (
      <UserProfileWrapper>
        <UserProfileContainer>
          <Row>
            <Col xl={16} md={16} sm={24} xs={24}>
              <ActivityStats userProfile={userProfile} />
              <ActivityList userProfile={userProfile} />
            </Col>
            <Col xl={8} md={8} sm={24} xs={24}>
              <PersonalInfo userProfile={userProfile} />
              <TeamList teamList={userProfile.teamList} />
            </Col>
          </Row>
        </UserProfileContainer>
      </UserProfileWrapper>
    );
  else return onRenderLoading();
});
