import { FOLLOW_CATEGORY_TYPE } from '../types/IGlobal';

export const FOLLOW_CATEGORY = [
  {
    id: FOLLOW_CATEGORY_TYPE.FOLLOWING,
    title: 'social:following',
  },
  {
    id: FOLLOW_CATEGORY_TYPE.FOLLOWER,
    title: 'social:follower',
  },
];
