import { action, makeObservable, observable, runInAction } from 'mobx';

import { APIResponse, APIResponseList } from '../models/APIResponse';
import {
  ListMemberFollower,
  ListMemberFollowing,
  ListMemberJoined,
  ListMemberRequest,
  ListMemberSuggest,
  ListMemberTop,
  MemberTop,
} from '../models/Member';
import MemberService from '../services/MemberService';
import {
  IFollowMemberRequest,
  IGetTopMemberRequest,
  IMemberRequest,
  IMemberTop,
} from '../types/IMember';
import { IRequestBase, IResponseBase } from '../types/ITypeBase';
import {
  IGetListMemberFollowerRequest,
  IGetListMemberFollowingRequest,
  IMemberFollower,
  IMemberFollowing,
  IMemberJoined,
} from './../types/IMember';
import RootStore from './RootStore';

class MemberStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  //declare variables
  @observable listTopMember: Array<MemberTop> | null = null;

  @observable listMemberAdmin: ListMemberJoined | null = null;
  @observable listMemberRequest: ListMemberRequest | null = null;
  @observable listMemberJoined: ListMemberJoined | null = null;
  @observable listMemberSuggest: ListMemberSuggest | null = null;
  @observable listMemberFollower: ListMemberFollower | null = null;
  @observable listMemberFollowing: ListMemberFollowing | null = null;

  //declare actions
  @action getTopMember = async (params: IGetTopMemberRequest) => {
    const result = (await MemberService.getInstance().getTopMember(params)) as APIResponseList<
      Array<IMemberTop>
    >;
    if (result && result.isSuccess()) {
      runInAction(() => {
        this.listTopMember = ListMemberTop.fromJson(result.data?.list!);
      });
    }
    return result;
  };

  @action getListMemberRequest = async (params: IRequestBase, loadMore: boolean) => {
    //init list when load first or null and calc paging
    if (!loadMore || !this.listMemberRequest)
      runInAction(() => {
        this.listMemberRequest = new ListMemberRequest();
      });
    else if (loadMore) {
      this.listMemberRequest.pageIndex += 1;
      this.listMemberRequest.isLoading = true; // determine if we're loading data is show process in UI
      params.data.from = params.data.size * this.listMemberRequest.pageIndex;
    }

    //request api service
    const result = (await MemberService.getInstance().getListMemberRequest(
      params,
    )) as APIResponseList<Array<IMemberRequest>>;

    //check result and binding data if success
    if (result && result.isSuccess()) {
      runInAction(() => {
        if (this.listMemberRequest) {
          this.listMemberRequest.list = this.listMemberRequest.list?.concat(
            ListMemberRequest.fromJson(result.data?.list!),
          )!;
          this.listMemberRequest.totalRecord = result.totalRecord();
          this.listMemberRequest.isLoading = false;
        }
      });
    }
  };

  @action getListMemberSearch = async (params: IRequestBase, loadMore: boolean) => {
    //init list when load first or null and calc paging
    if (!loadMore || !this.listMemberJoined)
      runInAction(() => {
        this.listMemberJoined = new ListMemberJoined();
      });
    else if (loadMore) {
      this.listMemberJoined.pageIndex += 1;
      this.listMemberJoined.isLoading = true; // determine if we're loading data is show process in UI
      params.data.from = params.data.size * this.listMemberJoined.pageIndex;
    }

    //request api service
    const result = (await MemberService.getInstance().getListMemberSearch(
      params,
    )) as APIResponseList<Array<IMemberJoined>>;

    //check result and binding data if success
    if (result && result.isSuccess()) {
      runInAction(() => {
        if (this.listMemberJoined) {
          this.listMemberJoined.list = this.listMemberJoined.list?.concat(
            ListMemberJoined.fromJson(result.data?.list!),
          )!;
          this.listMemberJoined.totalRecord = result.totalRecord();
          this.listMemberJoined.isLoading = false;
        }
      });
    }
  };

  @action getListMemberAdmin = async (params: IRequestBase, loadMore: boolean) => {
    //init list when load first or null and calc paging
    if (!loadMore || !this.listMemberAdmin)
      runInAction(() => {
        this.listMemberAdmin = new ListMemberJoined();
      });
    else if (loadMore) {
      this.listMemberAdmin.pageIndex += 1;
      this.listMemberAdmin.isLoading = true; // determine if we're loading data is show process in UI
      params.data.from = params.data.size * this.listMemberAdmin.pageIndex;
    }

    //request api service
    const result = (await MemberService.getInstance().getListMemberSearch(
      params,
    )) as APIResponseList<Array<IMemberJoined>>;

    //check result and binding data if success
    if (result && result.isSuccess()) {
      runInAction(() => {
        if (this.listMemberAdmin) {
          this.listMemberAdmin.list = this.listMemberAdmin.list?.concat(
            ListMemberJoined.fromJson(result.data?.list!),
          )!;
          this.listMemberAdmin.totalRecord = result.totalRecord();
          this.listMemberAdmin.isLoading = false;
        }
      });
    }
  };

  @action getListMemberSuggest = async (params: IRequestBase, loadMore: boolean) => {
    //init list when load first or null and calc paging
    if (!loadMore || !this.listMemberSuggest)
      runInAction(() => {
        this.listMemberSuggest = new ListMemberSuggest();
      });
    else if (loadMore) {
      this.listMemberSuggest.pageIndex += 1;
      this.listMemberSuggest.isLoading = true; // determine if we're loading data is show process in UI
      params.data.from = params.data.size * this.listMemberSuggest.pageIndex;
    }

    //request api service
    const result = (await MemberService.getInstance().getListMemberSearch(
      params,
    )) as APIResponseList<Array<IMemberJoined>>;

    //check result and binding data if success
    if (result && result.isSuccess()) {
      runInAction(() => {
        if (this.listMemberSuggest) {
          this.listMemberSuggest.list = this.listMemberSuggest.list?.concat(
            ListMemberSuggest.fromJson(result.data?.list!),
          )!;
          this.listMemberSuggest.totalRecord = result.totalRecord();
          this.listMemberSuggest.isLoading = false;
        }
      });
    }
  };

  @action getListMemberFollower = async (
    params: IGetListMemberFollowerRequest,
    loadMore: boolean,
  ) => {
    //init list when load first or null and calc paging
    if (!loadMore || !this.listMemberFollower)
      runInAction(() => {
        this.listMemberFollower = new ListMemberFollower();
      });
    else if (loadMore) {
      this.listMemberFollower.pageIndex += 1;
      this.listMemberFollower.isLoading = true; // determine if we're loading data is show process in UI
      params.data.from = params.data.size
        ? params.data.size * this.listMemberFollower.pageIndex
        : this.listMemberFollower.pageSize * this.listMemberFollower.pageIndex;
    }

    //request api service
    const result = (await MemberService.getInstance().getListMemberSearch(
      params,
    )) as APIResponseList<Array<IMemberFollower>>;

    //check result and binding data if success
    if (result && result.isSuccess()) {
      runInAction(() => {
        if (this.listMemberFollower) {
          this.listMemberFollower.list = this.listMemberFollower.list?.concat(
            ListMemberFollower.fromJson(result.data?.list!),
          )!;
          this.listMemberFollower.totalRecord = result.totalRecord();
          this.listMemberFollower.isLoading = false;
        }
      });
    }
  };

  @action getListMemberFollowing = async (
    params: IGetListMemberFollowingRequest,
    loadMore: boolean,
  ) => {
    //init list when load first or null and calc paging
    if (!loadMore || !this.listMemberFollowing)
      runInAction(() => {
        this.listMemberFollowing = new ListMemberFollowing();
      });
    else if (loadMore) {
      this.listMemberFollowing.pageIndex += 1;
      this.listMemberFollowing.isLoading = true; // determine if we're loading data is show process in UI
      params.data.from = params.data.size
        ? params.data.size * this.listMemberFollowing.pageIndex
        : this.listMemberFollowing.pageSize * this.listMemberFollowing.pageIndex;
    }

    //request api service
    const result = (await MemberService.getInstance().getListMemberSearch(
      params,
    )) as APIResponseList<Array<IMemberFollowing>>;

    //check result and binding data if success
    if (result && result.isSuccess()) {
      runInAction(() => {
        if (this.listMemberFollowing) {
          this.listMemberFollowing.list = this.listMemberFollowing.list?.concat(
            ListMemberFollowing.fromJson(result.data?.list!),
          )!;
          this.listMemberFollowing.totalRecord = result.totalRecord();
          this.listMemberFollowing.isLoading = false;
        }
      });
    }
  };

  @action followMember = async (params: IFollowMemberRequest) => {
    const result = (await MemberService.getInstance().followMember(
      params,
    )) as APIResponse<IResponseBase>;
    return result;
  };
}
export default MemberStore;
