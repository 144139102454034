import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import pure from 'recompose/pure';

import { TIME_STATS_CATEGORY } from '../../../../data/timeStats';
import { ITabMenu, STATS_TIME_TYPE } from '../../../../types/IGlobal';
//styles
import {
  TabContainer,
  TabItem,
  TabLink,
  TabTimerContainer,
  TabTimerWrapper,
} from './TabTimer.styles';

interface Props {
  onChangeTimeTerm: (time: STATS_TIME_TYPE) => void;
}

export const TabTimer: ComponentType<Props> = pure(({ onChangeTimeTerm }) => {
  const { t } = useTranslation();

  const [timeTerm, setTimeTerm] = React.useState(STATS_TIME_TYPE.WEEKLY);

  const onTabItemTimeChange = (time: STATS_TIME_TYPE) => {
    setTimeTerm(time);
    onChangeTimeTerm(time);
  };

  const renderTabItem = (tab: ITabMenu) => {
    const active = tab.id === timeTerm ? 'active' : 'disabled';
    return (
      <TabItem key={tab.id} onClick={() => onTabItemTimeChange(tab.id)}>
        <TabLink active={active}>{t(tab.title)}</TabLink>
      </TabItem>
    );
  };

  return (
    <TabTimerWrapper>
      <TabTimerContainer>
        <TabContainer>
          {TIME_STATS_CATEGORY.map((tab: ITabMenu) => {
            return renderTabItem(tab);
          })}
        </TabContainer>
      </TabTimerContainer>
    </TabTimerWrapper>
  );
});
