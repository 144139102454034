import { action, makeObservable, observable, runInAction } from 'mobx';

import { SCREEN_EVENT_HOME } from './../configs/Screens';
import RootStore from './RootStore';

class ScreenStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  //declare variables
  @observable eventHomeScreen: SCREEN_EVENT_HOME | null = SCREEN_EVENT_HOME.HOME_EVENT;

  //declare actions
  @action setEventHomeScreen = (screen: SCREEN_EVENT_HOME) => {
    runInAction(() => {
      this.eventHomeScreen = screen;
    });
  };
}
export default ScreenStore;
