import { ComponentType } from 'react';
import pure from 'recompose/pure';

import Team from '../../../models/Team';
import { TitleBold, TitleContainer, TitleNormal } from '../UserProfile.styles';
//views
import { TeamItem } from './TeamItem/TeamItem';
//styles
import { LineDivider, TeamListContainer, TeamListWrapper } from './TeamList.styles';

interface Props {
  teamList: Array<Team>;
}

export const TeamList: ComponentType<Props> = pure(({ teamList }) => {
  return (
    <TeamListWrapper>
      <TeamListContainer>
        <TitleContainer>
          <TitleBold>Đội tham gia</TitleBold>
          <TitleNormal>({teamList.length})</TitleNormal>
        </TitleContainer>
        <LineDivider />
        {teamList &&
          teamList.map((team) => {
            return <TeamItem teamData={team} key={team.teamId} />;
          })}
      </TeamListContainer>
    </TeamListWrapper>
  );
});
