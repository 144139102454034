import { RightOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { AvatarImage } from '../../../../components/User/AvatarImage';
import { device } from '../../../../configs/MediaQuery';

export const TeamItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: solid 1px #e7eaec;
  }

  &:hover {
    //background-color: #e7eaec;
  }
`;

export const AvatarContainer = styled.div`
  margin-right: 8px;
`;

export const AvatarTeam = styled(AvatarImage)`
  border-radius: 50%;
  object-fit: cover;
  @media ${device.mobileS} {
    width: 40px;
    height: 40px;
  }
  @media ${device.tablet} {
    width: 50px;
    height: 50px;
  }
  @media ${device.laptop} {
    width: 50px;
    height: 50px;
  }
`;

export const InfoContainer = styled.div`
  padding-bottom: 10px;
  flex-grow: 1;
`;

export const LabelText = styled.p`
  margin: 0;
  font-weight: 400;
  color: #797979;

  @media ${device.mobileS} {
    font-size: 12px;
    line-height: 18px;
  }
  @media ${device.tablet} {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const ValueText = styled(LabelText)`
  margin: 0;
  font-weight: 600;
  color: #000;

  @media ${device.mobileS} {
    font-size: 14px;
    line-height: 24px;
    width: 100%;
  }
  @media ${device.tablet} {
    font-size: 16px;
    line-height: 30px;
    width: 100%;
  }
  @media ${device.laptop} {
    font-size: 16px;
    line-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 220px;
  }
`;

export const ArrowDetail = styled(RightOutlined)`
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
`;
