import { observer } from 'mobx-react-lite';
import { ComponentType } from 'react';

import { Like } from '../../models/Social';
//styles
import {
  AvatarContainer,
  AvatarListContainer,
  AvatarListWrapper,
  AvatarUser,
  TextOther,
} from './AvatarList.styles';

interface Props {
  list: Array<Like>;
  text?: string;
  clickable?: boolean;
}

export const AvatarList: ComponentType<Props> = observer(({ list, text, clickable }) => {
  if (list.length > 0)
    return (
      <AvatarListWrapper style={{ cursor: clickable ? 'pointer' : '' }}>
        <AvatarListContainer>
          {list.map((item: Like) => {
            return (
              <AvatarContainer key={item.likeId}>
                <AvatarUser src={item.getAvatarImage()} />
              </AvatarContainer>
            );
          })}
        </AvatarListContainer>
        {text && <TextOther>{text}</TextOther>}
      </AvatarListWrapper>
    );
  else return null;
});
