import { ComponentType } from 'react';
import pure from 'recompose/pure';

//styles
import {
  CoverImage,
  CoverWrapper,
  FundItemContainer,
  FundItemWrapper,
  FundTitleText,
  FundTitleWrapper,
  InfoText,
  InfoWrapper,
  LogoImage,
  LogoWrapper,
} from '../FundItem.styles';

interface Props {
  lang: string;
}

export const GreenViet: ComponentType<Props> = pure(({ lang }) => {
  const renderContent = () => {
    if (lang === 'en') return renderContentEN();
    else return renderContentVI();
  };

  const renderContentVI = () => {
    return (
      <FundTitleWrapper>
        <LogoWrapper>
          <LogoImage src='https://uprace2.vcdn.vn/web/uprace/images/2023/orgs/greenviet_logo.jpg'></LogoImage>
        </LogoWrapper>
        <FundTitleText>GreenViet</FundTitleText>
        <InfoWrapper>
          <InfoText>
            Trung tâm Bảo tồn đa dạng sinh học Nước Việt Xanh (GreenViet) là một trong ba tổ chức xã
            hội sẽ đồng hành cùng Dự án UpRace 2023. GreenViet hướng đến sứ mệnh bảo tồn hệ sinh
            thái tự thiên tại Việt Nam với lời kêu gọi "Mỗi bước chạy, kiến tạo mầm xanh, mang
            60.000 cây bản địa thay thế 50ha rừng keo tại địa bàn xã Hòa Bắc, Thành phố Đà Nẵng".{' '}
            <br />
            Tìm hiểu thêm về GreenViet: <a href='https://greenviet.org/'> https://greenviet.org/</a>
          </InfoText>
        </InfoWrapper>
        <CoverWrapper>
          <CoverImage src='https://uprace2.vcdn.vn/web/uprace/images/2023/orgs/greenviet_thumb.jpg'></CoverImage>
        </CoverWrapper>
      </FundTitleWrapper>
    );
  };

  const renderContentEN = () => {
    return (
      <FundTitleWrapper>
        <LogoWrapper>
          <LogoImage src='https://uprace2.vcdn.vn/web/uprace/images/2023/orgs/greenviet_logo.jpg'></LogoImage>
        </LogoWrapper>
        <FundTitleText>GreenViet</FundTitleText>
        <InfoWrapper>
          <InfoText>
            The Center for Biodiversity Conservation "GreenViet" is one of three community partners
            with the UpRace 2023 project. GreenViet is dedicated to the mission of conserving
            natural ecosystems in Vietnam, with a call to action that states, "With every step you
            take, create green seeds by planting 60,000 native trees to replace 50 hectares of
            acacia forest in Hoa Bac commune, Da Nang City". <br /> Learn more:
            <a href='https://greenviet.org/'> https://greenviet.org/</a>
          </InfoText>
        </InfoWrapper>
        <CoverWrapper>
          <CoverImage src='https://uprace2.vcdn.vn/web/uprace/images/2023/orgs/greenviet_thumb.jpg'></CoverImage>
        </CoverWrapper>
      </FundTitleWrapper>
    );
  };

  return (
    <FundItemWrapper>
      <FundItemContainer>{renderContent()}</FundItemContainer>
    </FundItemWrapper>
  );
});
