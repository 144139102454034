import { Col } from 'antd';
import styled from 'styled-components';

import { device } from '../../../../configs/MediaQuery';
import { AppButton } from './../../../../styles/ButtonStyles';

export const SportTypeWrapper = styled.div``;

export const SportTypeContainer = styled(Col)`
  @media ${device.mobileS} {
    &:nth-child(1),
    &:nth-child(3) {
      padding-right: 5px;
    }
    &:nth-child(2),
    &:nth-child(4) {
      padding-left: 5px;
    }
    &:nth-child(1),
    &:nth-child(2) {
      padding-bottom: 5px;
    }
    &:nth-child(3),
    &:nth-child(4) {
      padding-top: 5px;
    }
  }
  @media ${device.tablet} {
  }
  @media ${device.laptop} {
    &:not(:last-child) {
      padding-right: 10px;
      padding-left: 0px;
    }
    &:last-child {
      padding-left: 0px;
    }

    &:nth-child(1),
    &:nth-child(2) {
      padding-bottom: 0px;
    }
    &:nth-child(3),
    &:nth-child(4) {
      padding-top: 0px;
    }
  }
`;

export const SportTypeButton = styled(AppButton)`
  width: 100%;
`;
