import { IRequestBase } from './ITypeBase';

//leaderboard type get rank
export enum LEADERBOARD_TYPE {
  LEAGUE,
  TEAM,
  FUND,
  CUSTOM,
  GROUP_TEAM,
  USER_GROUP,
  GROUP_EVENT,
  USER_EVENT,
}

export enum EventRankingType {
  Company = 1,
  School = 3,
  Club = 2,
}
export interface IRankMeRequest extends IRequestBase {}

export interface ILeaderBoardUserRequest extends IRequestBase {
  size: number;
  evid: number;
  type: LEADERBOARD_TYPE;
  value: number;
  sex: number;
  name: string;
  day: number;
}

export interface ITeamRanking {
  id: number;
  ava: string;
  name: string;
  dis: number;
  rank: number;
  ddis: number;
}

export interface IGetEventRankingRequest extends IRequestBase {
  data: {
    day?: any;
    evid: number;
    from: number;
    size: number;
    type: number;
    name?: string;
    value: EventRankingType;
  };
}
