import { getUUID } from '../utils';

export const APP_CONFIG = {
  PAGE_SIZE: 100,
  CLIENT_ID: getUUID(),
  STATIC_URL: process.env.REACT_APP_CDN_URL,
};

export enum APP_LANGUAGE {
  ENGLISH = 'en',
  VIETNAMES = 'vi',
}

export enum FORMAT_DATE {
  FULL_DATE = 'DD/MM/YYYY',
  SHORT_DATE = 'DD/MM',
  FULL_TIME = 'HH:MM:SS',
  SHORT_TIME = 'hh:mm',
  SHORT_TIME_24 = 'HH:mm',
}
