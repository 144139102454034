import { observer } from 'mobx-react-lite';
import { ComponentType } from 'react';

import { INewsUp22 } from '../../../../types/IJson';
//styles

interface Props {
  data: INewsUp22;
}

export const NewsItem: ComponentType<Props> = observer(({ data }) => {
  return (
    <div className='col'>
      <div className='card card-hover h-100'>
        <a href={data.url} target='_blank' rel='noreferrer'>
          <img src={data.thumbnail_vi} className='card-img-top h-250' alt='Thumbnail' />
        </a>

        <div className='card-body'>
          <a
            href={data.url}
            className='link-ink-basic card-title fw-600'
            target='_blank'
            rel='noreferrer'>
            {data.title_vi}
          </a>
        </div>
        <div className='card-footer bg-transparent border-0'>
          <small className='text-muted'>{data.date}</small>
        </div>
      </div>
    </div>
  );
});
