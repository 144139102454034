import { FC, useEffect, useRef, useState } from 'react';
import { InfoBannerEvent } from './EventInfo.styles';
import { IEvent } from '../../types/IEvent';
import Countdown from 'react-countdown';
import './EventBanner.css';
import { useTranslation } from 'react-i18next';
import { convertTimestampToDate } from '../../utils/DateUtils';
import { observer } from 'mobx-react-lite';

interface Props {
    eventInfo: IEvent;
}

let now: any, start: any, end: any;

const EventBannerSection: FC<Props> = observer(({ eventInfo }) => {
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState<any>(null);
    const [isReady, setIsReady] = useState(false);
    const refCountdown = useRef<any>();

    useEffect(() => {
        now = Date.now();
        initDate();
    }, [eventInfo]);

    useEffect(() => {
        if (refCountdown.current) refCountdown.current.start();
    }, [startDate]);

    const initDate = () => {
        start = eventInfo.betm;
        end = eventInfo.entm;

        const date = start <= now ? end : start;
        setStartDate(date);
    };

    const renderEventFinished = () => {
        initDate();
        return (
            <div className="finish-container">
                <p>{t('eventInfo:countdown.msgEnd1')}</p>
                <p>{t('eventInfo:countdown.msgEnd2')}</p>
            </div>
        );
    };

    const formatNumber = (number: number) =>
        number < 10 ? `0${number}` : number;

    function getDateEvent() {
        const format = 'DD.MM.YYYY';
        const start = convertTimestampToDate(eventInfo.betm, format);
        const end = convertTimestampToDate(eventInfo.entm, format);
        return `${start} - ${end}`;
    }

    const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
        if (completed) {
            return renderEventFinished();
        } else {
            return (
                <div>
                    <div className="countdown-container">
                        <div className="countdown-item">
                            <div className="flip-card-countdown">
                                {formatNumber(days)}
                            </div>
                            <span>{t('eventInfo:countdown.day')}</span>
                        </div>
                        <div className="countdown-item">
                            <div className="flip-card-countdown">
                                {formatNumber(hours)}
                            </div>
                            <span>{t('eventInfo:countdown.hour')}</span>
                        </div>
                        <div className="countdown-item">
                            <div className="flip-card-countdown">
                                {formatNumber(minutes)}
                            </div>
                            <span>{t('eventInfo:countdown.minutes')}</span>
                        </div>
                        <div className="countdown-item">
                            <div className="flip-card-countdown">
                                {formatNumber(seconds)}
                            </div>
                            <span>{t('eventInfo:countdown.second')}</span>
                        </div>
                    </div>
                    <p className="event-date">{getDateEvent()}</p>
                </div>
            );
        }
    };

    const onTick = (value: any) => {
      if(start <= now) setIsReady(true);
    };

    return (
        <InfoBannerEvent backgroundImage={eventInfo.rsc2.banner}>
            <div className="countdown-container">
                {startDate ? (
                    <Countdown
                        ref={refCountdown}
                        autoStart={false}
                        date={startDate}
                        renderer={renderer}
                        onTick={onTick}
                    />
                ) : null}
            </div>
        </InfoBannerEvent>
    );
});

export default EventBannerSection;
