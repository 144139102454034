import { action, makeObservable, observable, runInAction } from 'mobx';

import { APIResponse, APIResponseList } from '../models/APIResponse';
import { Event, EventFilter, ListEvent } from '../models/Event';
import EventService from '../services/EventService';
import { ICreateEventRequest } from '../types/ICreateEvent';
import {
  EVENT_STATUS,
  IDeleteEventRequest,
  IDismissEventSuggestRequest,
  IEvent,
  IGetEventDetailRequest,
  IGetListEventBlockRequest,
  IGetListEventFilterRequest,
  IGetYourEventRequest,
  IRankMeRequest,
} from '../types/IEvent';
import { IResponseBase } from './../types/ITypeBase';
import RootStore from './RootStore';

class EventStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  //declare variables
  @observable currentEvent: Event | null = null;

  @observable listEventFilter: ListEvent | null = null;
  @observable listEventFinished: ListEvent | null = null;
  @observable listEventRegistering: ListEvent | null = null;
  @observable listEventRunning: ListEvent | null = null;

  @observable listYourEventFinished: ListEvent | null = null;
  @observable listYourEventRegistering: ListEvent | null = null;
  @observable listYourEventRunning: ListEvent | null = null;

  //store all conditions filter event
  @observable eventFilter: EventFilter = new EventFilter();

  @action setCurrentEventData = (data: any) => {
    runInAction(() => {
      this.currentEvent = data;
    });
  };

  //get block events
  @action getListEventsBlock = async (params: IGetListEventBlockRequest) => {
    const result = (await EventService.getInstance().getListEventBlock(params)) as APIResponseList<
      Array<IEvent>
    >;
    if (result && result.isSuccess()) {
      runInAction(() => {
        if (params.data.sts === EVENT_STATUS.FINISHED) {
          this.listEventFinished = new ListEvent();
          this.listEventFinished.totalRecord = result.totalRecord();
          this.listEventFinished.list = ListEvent.fromJson(result.data?.list!);
        } else if (params.data.sts === EVENT_STATUS.REGISTERING) {
          this.listEventRegistering = new ListEvent();
          this.listEventRegistering.totalRecord = result.totalRecord();
          this.listEventRegistering.list = ListEvent.fromJson(result.data?.list!);
        } else if (params.data.sts === EVENT_STATUS.RUNNING) {
          this.listEventRunning = new ListEvent();
          this.listEventRunning.totalRecord = result.totalRecord();
          this.listEventRunning.list = ListEvent.fromJson(result.data?.list!);
        }
      });
    }
    return result;
  };

  //get your events
  @action getListYourEvent = async (params: IGetYourEventRequest) => {
    const result = (await EventService.getInstance().getListYourEvent(params)) as APIResponseList<
      Array<IEvent>
    >;
    if (result && result.isSuccess()) {
      runInAction(() => {
        if (params.data.sts === EVENT_STATUS.FINISHED) {
          this.listYourEventFinished = new ListEvent();
          this.listYourEventFinished.list = ListEvent.fromJson(result.data?.list!);
        } else if (params.data.sts === EVENT_STATUS.REGISTERING) {
          this.listYourEventRegistering = new ListEvent();
          this.listYourEventRegistering.list = ListEvent.fromJson(result.data?.list!);
        } else if (params.data.sts === EVENT_STATUS.RUNNING) {
          this.listYourEventRunning = new ListEvent();
          this.listYourEventRunning.list = ListEvent.fromJson(result.data?.list!);
        }
      });
    }
    return result;
  };

  //get list event by filter conditions
  @action getListEventsFilter = async (params: IGetListEventFilterRequest, loadMore: boolean) => {
    //init list when load first or null and calc paging
    if (!loadMore || !this.listEventFilter)
      runInAction(() => {
        this.listEventFilter = new ListEvent();
      });
    else if (loadMore) {
      this.listEventFilter.pageIndex += 1;
      this.listEventFilter.isLoading = true; // determine if we're loading data is show process in UI
      params.data.from = params.data.size! * this.listEventFilter.pageIndex;
    }

    //request api service
    const result = (await EventService.getInstance().getListEventFilter(params)) as APIResponseList<
      Array<IEvent>
    >;

    //check result and binding data if success
    if (result && result.isSuccess()) {
      runInAction(() => {
        if (this.listEventFilter) {
          this.listEventFilter.list = this.listEventFilter.list?.concat(
            ListEvent.fromJson(result.data?.list!),
          )!;
          this.listEventFilter.totalRecord = result.totalRecord();
          this.listEventFilter.isLoading = false;
        }
      });
    }
    return result;
  };

  @action getEventDetail = async (params: IGetEventDetailRequest) => {
    const result = (await EventService.getInstance().getEventDetail(params)) as APIResponse<IEvent>;

    if (result && result.isSuccess()) {
      console.log('result.data', result.data);
      runInAction(() => {
        this.currentEvent = Event.fromJson(result.data!);
      });
    }
    return result;
  };

  @action createEvent = async (params: ICreateEventRequest) => {
    const result = (await EventService.getInstance().createEvent(
      params,
    )) as APIResponse<IResponseBase>;
    return result;
  };

  @action deleteEvent = async (params: IDeleteEventRequest) => {
    const result = (await EventService.getInstance().deleteEvent(
      params,
    )) as APIResponse<IResponseBase>;
    return result;
  };

  @action dismissEventSuggest = async (params: IDismissEventSuggestRequest) => {
    const result = (await EventService.getInstance().dismissEventSuggest(
      params,
    )) as APIResponse<IResponseBase>;
    return result;
  };

  @action getRankMe = async (params: IRankMeRequest) => {
    const result = await EventService.getInstance().getRankMe(params);
  };
}
export default EventStore;
