import styled from 'styled-components';

import { device } from '../../configs/MediaQuery';

export const FeedDetailWrapper = styled.section`
  width: 100%;
  min-height: 800px;
`;

export const FeedDetailContainer = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 100px;

  @media ${device.mobileS} {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media ${device.tablet} {
  }
  @media ${device.laptop} {
  }
`;
