import { ComponentType } from 'react';
import pure from 'recompose/pure';

//styles
import {
  CoverImage,
  CoverWrapper,
  FundItemContainer,
  FundItemWrapper,
  FundTitleText,
  FundTitleWrapper,
  InfoText,
  InfoWrapper,
  LogoImage,
  LogoWrapper,
} from '../FundItem.styles';

interface Props {
  lang: string;
}

export const SoSinhVN: ComponentType<Props> = pure(({ lang }) => {
  const renderContent = () => {
    if (lang === 'en') return renderContentEN();
    else return renderContentVI();
  };

  const renderContentVI = () => {
    return (
      <FundTitleWrapper>
        <LogoWrapper>
          <LogoImage src='https://uprace2.vcdn.vn/web/uprace/images/2023/orgs/sosinhvn.jpeg'></LogoImage>
        </LogoWrapper>
        <FundTitleText>Trung tâm Hỗ trợ Phát triển Chăm sóc Bà mẹ và Trẻ sơ sinh tại Việt Nam</FundTitleText>
        <InfoWrapper>
          <InfoText>
            <p>
            Sơ Sinh Việt Nam là tổ chức xã hội hoạt động trong lĩnh vực giáo dục y tế chăm sóc cho các bà mẹ và trẻ sơ sinh. Tổ chức tập trung nâng cao hợp tác với các đối tác trong nước và quốc tế để cung cấp các dự án đào tạo, dịch vụ chuyên nghiệp nhằm cải thiện kết quả điều trị, giảm thiểu tỷ lệ tử vong ở trẻ sơ sinh.
            </p>
            <p>
            Đồng hành cùng dự án UpRace 2024, Sơ Sinh Việt Nam đặt mục tiêu trang bị đầy đủ kiến thức chuyên môn hồi sức cho 120 giảng viên, nhân viên y tế Việt Nam, trao cơ hội sống cho 800.000 trẻ sơ sinh và thông qua đó kiến tạo nên những giá trị cộng đồng tốt đẹp. 
            </p>
            <br /> Tìm hiểu thêm về Sơ sinh Việt Nam tại:
            <a href='https://sosinhvn.org'> https://sosinhvn.org</a>
          </InfoText>
        </InfoWrapper>
        <CoverWrapper>
          <CoverImage src='https://uprace2.vcdn.vn/web/uprace/images/2023/orgs/hocbong_thumb.jpg'></CoverImage>
        </CoverWrapper>
      </FundTitleWrapper>
    );
  };

  const renderContentEN = () => {
    return (
      <FundTitleWrapper>
        <LogoWrapper>
          <LogoImage src='https://uprace2.vcdn.vn/web/uprace/images/2023/orgs/sosinhvn.jpeg'></LogoImage>
        </LogoWrapper>
        <FundTitleText>
          The Center for the Support of the Development of Maternal and Neonatal Care in Viet Nam
        </FundTitleText>
        <InfoWrapper>
          <InfoText>
            <p>
              Newborns Vietnam is a non-profit medical education organization taking care of mothers
              and newborns. The organization focuses on fostering partnerships with domestic and
              international partners to provide professional training projects and services that
              improve treatment outcomes and reduce infant mortality rates.
            </p>
            <p>
              Accompanying the UpRace 2024 project, Newborns Vietnam looks forward to fully
              equipping 120 Vietnamese lecturers and medical staff with resuscitation expertise,
              giving a chance to life to 800,000 newborns and generating good community values.
            </p>
            <br /> Learn more about Newborns Vietnam via:
            <a href='https://sosinhvn.org'> https://sosinhvn.org</a>
          </InfoText>
        </InfoWrapper>
        <CoverWrapper>
          <CoverImage src='https://uprace2.vcdn.vn/web/uprace/images/2023/orgs/ssvn.jpg'></CoverImage>
        </CoverWrapper>
      </FundTitleWrapper>
    );
  };

  return (
    <FundItemWrapper>
      <FundItemContainer>{renderContent()}</FundItemContainer>
    </FundItemWrapper>
  );
});
