import { API } from '../configs/APIEndPoint';
import { handleError } from '../helpers/APIHelper';
import { IGetTopMemberRequest } from '../types/IMember';
import { IRequestBase } from '../types/ITypeBase';
import { IFollowMemberRequest } from './../types/IMember';
import BaseAuthService from './BaseAuthService';

class MemberService extends BaseAuthService {
  private static instance: MemberService;

  public static getInstance(): MemberService {
    if (!MemberService.instance) {
      MemberService.instance = new MemberService();
    }
    return MemberService.instance;
  }

  //methods api
  getTopMember = async (params: IGetTopMemberRequest) => {
    try {
      const response = await this.postData(API.USER.GET_TOP, params);
      return this.handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  };

  getListMemberRequest = async (params: IRequestBase) => {
    try {
      const response = await this.postData(API.USER.SEARCH, params);
      return this.handleResponseList(response);
    } catch (error: any) {
      return handleError(error);
    }
  };

  getListMemberSearch = async (params: IRequestBase) => {
    try {
      const response = await this.postData(API.USER.SEARCH, params);
      return this.handleResponseList(response);
    } catch (error: any) {
      return handleError(error);
    }
  };

  followMember = async (params: IFollowMemberRequest) => {
    try {
      const response = await this.postData(API.USER.DO_FOLLOW, params);
      return this.handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  };
}
export default MemberService;
