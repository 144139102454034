import AuthStore from './AuthStore';
import EventInfoStore from './EventInfoStore';
import EventStore from './EventStore';
import FeedStore from './FeedStore';
import GlobalStore from './GlobalStore';
import GroupStore from './GroupStore';
import JSonStore from './JSonStore';
import MemberStore from './MemberStore';
import ScreenStore from './ScreenStore';
import SocialStore from './SocialStore';
import UserStore from './UserStore';

class RootStore {
  globalStore: GlobalStore;
  userStore: UserStore;
  authStore: AuthStore;
  eventStore: EventStore;
  feedStore: FeedStore;
  groupStore: GroupStore;
  memberStore: MemberStore;
  socialStore: SocialStore;
  jsonStore: JSonStore;
  screenStore: ScreenStore;
  eventInfoStore: EventInfoStore;

  constructor() {
    this.globalStore = new GlobalStore(this);
    this.userStore = new UserStore(this);
    this.authStore = new AuthStore(this);
    this.eventStore = new EventStore(this);
    this.feedStore = new FeedStore(this);
    this.groupStore = new GroupStore(this);
    this.memberStore = new MemberStore(this);
    this.socialStore = new SocialStore(this);
    this.jsonStore = new JSonStore(this);
    this.screenStore = new ScreenStore(this);
    this.eventInfoStore = new EventInfoStore(this);
  }
}
export default RootStore;
