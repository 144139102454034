import { ComponentType } from 'react';
import pure from 'recompose/pure';

//styles
import {
  CoverImage,
  CoverWrapper,
  FundItemContainer,
  FundItemWrapper,
  FundTitleText,
  FundTitleWrapper,
  InfoText,
  InfoWrapper,
  LogoImage,
  LogoWrapper,
} from '../FundItem.styles';

interface Props {
  lang: string;
}

export const AsvhoFund: ComponentType<Props> = pure(({ lang }) => {
  const renderContent = () => {
    if (lang === 'en') return renderContentEN();
    else return renderContentVI();
  };

  const renderContentVI = () => {
    return (
      <FundTitleWrapper>
        <LogoWrapper>
          <LogoImage src='https://uprace2.vcdn.vn/web/uprace/images/2023/orgs/asvho_logo.png'></LogoImage>
        </LogoWrapper>
        <FundTitleText>Hội bảo trợ người khuyết tật và trẻ mồ côi Việt Nam</FundTitleText>
        <InfoWrapper>
          <InfoText>
            Hội bảo trợ người khuyết tật và trẻ mồ côi Việt Nam (ASVHO) là một trong ba tổ chức xã
            hội sẽ đồng hành cùng Dự án UpRace 2023. ASVHO nỗ lực hướng tới một thế giới không rào
            cản đối với người khuyết tật với lời kêu gọi “Với mỗi bước chạy, bạn đang góp phần mang
            đến ánh sáng cho người khuyết tật và hỗ trợ sinh kế cho những hoàn cảnh kém may mắn. Hãy
            cùng chúng tôi chia sẻ yêu thương đến với họ”. <br />
            Tìm hiểu thêm về ASVHO: <a href='http://asvho.vn/'> http://asvho.vn/</a>
          </InfoText>
        </InfoWrapper>
        <CoverWrapper>
          <CoverImage src='https://uprace2.vcdn.vn/web/uprace/images/2023/orgs/asvho_thumb.jpg'></CoverImage>
        </CoverWrapper>
      </FundTitleWrapper>
    );
  };

  const renderContentEN = () => {
    return (
      <FundTitleWrapper>
        <LogoWrapper>
          <LogoImage src='https://uprace2.vcdn.vn/web/uprace/images/2023/orgs/asvho_logo.png'></LogoImage>
        </LogoWrapper>
        <FundTitleText>Association in support of Vietnamese handicapped and orphans</FundTitleText>
        <InfoWrapper>
          <InfoText>
            The Association for the Support of Disabled Persons and Orphans in Vietnam (ASVHO) is
            one of three community partners with the UpRace 2023 project. ASVHO strives for a
            barrier-free world for people with disabilities, calling on individuals to contribute by
            bringing light to them and supporting the less fortunate. Join us in sharing love with
            them. <br />
            Learn more: <a href='http://asvho.vn/'>http://asvho.vn/</a>
          </InfoText>
        </InfoWrapper>
        <CoverWrapper>
          <CoverImage src='https://uprace2.vcdn.vn/web/uprace/images/2023/orgs/asvho_thumb.jpg'></CoverImage>
        </CoverWrapper>
      </FundTitleWrapper>
    );
  };

  return (
    <FundItemWrapper>
      <FundItemContainer>{renderContent()}</FundItemContainer>
    </FundItemWrapper>
  );
});
