import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { ComponentType } from 'react';

import { useRootStore } from '../../../providers/RootStoreProvider';
import { FEED_SPORT_TYPE } from '../../../types/IFeed';
import { LapChart } from '../LapChart/LapChart';
import { MapActivity } from '../MapActivity/MapActivity';
import { PaceInfo } from '../PaceInfo/PaceInfo';
//views
import { UserInfo } from '../UserInfo/UserInfo';
//styles
import { BlockContainer, FeedInfoContainer } from './FeedInfo.styles';

interface Props {}

export const FeedInfo: ComponentType<Props> = observer(({}) => {
  const store = useRootStore();
  const { currentFeed } = store.feedStore;

  if (currentFeed)
    return (
      <FeedInfoContainer>
        <BlockContainer>
          <Row>
            <Col xl={12} md={12} sm={24} xs={24}>
              <UserInfo feedData={currentFeed} />
            </Col>
            <Col xl={12} md={12} sm={24} xs={24}>
              <PaceInfo feedData={currentFeed} />
            </Col>
          </Row>
        </BlockContainer>
        <Row>
          {currentFeed.feedType != FEED_SPORT_TYPE.SWIMMING ? (
            <>
              <Col xl={8} md={8} sm={24} xs={24}>
                <LapChart feedData={currentFeed} />
              </Col>
              <Col xl={16} md={16} sm={24} xs={24}>
                <MapActivity feedData={currentFeed} />
              </Col>
            </>
          ) : null}
        </Row>
      </FeedInfoContainer>
    );
  else return null;
});
