import styled from 'styled-components';

import { device } from '../../../configs/MediaQuery';

export const FeedInfoContainer = styled.div`
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

  @media ${device.mobileS} {
    padding: 5px;
  }
  @media ${device.tablet} {
    padding: 20px;
  }
`;

export const BlockContainer = styled.div`
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
`;
