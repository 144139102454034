import { ACCOUNT_ROLE } from '../types/IMember';
import { IPost } from '../types/ISocial';
import { IComment, ILike, LIKE_TYPE } from './../types/ISocial';
import { DataList } from './DataList';

export class SocialBase {
  memberId: number | undefined;
  name: string | undefined;
  avatar: string | undefined;
  createTime: number | undefined;

  getAvatarImage(): string {
    if (this.avatar)
      return this.avatar
        .replace('.png', '@1x.png')
        .replace('.jpg', '@1x.jpg')
        .replace('.jpeg', '@1x.jpeg');
    else return '';
  }
}
export class Post extends SocialBase {
  postId: number;
  title: string;
  description: string;
  hightLight: string;
  updateTime: number;
  photos: string[];
  totalLike: number;
  totalComment: number;
  listLike: Array<any>;
  listComments: Array<any>;
  userRole: ACCOUNT_ROLE;
  pin: number;
  postType: number;
  status: number;

  constructor(
    postId: number,
    memberId: number,
    name: string,
    avatar: string,
    title: string,
    description: string,
    hightLight: string,
    createTime: number,
    updateTime: number,
    photos: any,
    totalLike: number,
    totalComment: number,
    listLike: any,
    listComments: any,
    userRole: any,
    pin: number,
    postType: number,
    status: number,
  ) {
    super();
    this.postId = postId;
    this.memberId = memberId;
    this.name = name;
    this.avatar = avatar;
    this.title = title;
    this.description = description;
    this.hightLight = hightLight;
    this.createTime = createTime;
    this.updateTime = updateTime;
    this.photos = photos;
    this.totalLike = totalLike;
    this.totalComment = totalComment;
    this.listLike = listLike;
    this.listComments = listComments;
    this.userRole = userRole;
    this.pin = pin;
    this.postType = postType;
    this.status = status;
  }
  static fromJson = (json: IPost): Post => {
    const postData = new Post(
      json.psid,
      json.uid,
      json.name,
      json.ava,
      json.title,
      json.desc,
      json.hlight,
      json.crtm,
      json.uptm,
      json.photos,
      json.lkcnt,
      json.cmcnt,
      json.lkls,
      json.cmls,
      json.acrl,
      json.pin,
      json.pstp,
      json.sts,
    );

    //generate new array Like from json
    const listLike = new Array<Like>();
    json.lkls.forEach((item) => {
      listLike.push(Like.fromJson(item));
    });
    postData.listLike = listLike;
    return postData;
  };
}
export class Like extends SocialBase {
  likeId: number;
  likeType: LIKE_TYPE;

  constructor(
    likeId: number,
    likeType: LIKE_TYPE,
    memberId: number,
    name: string,
    avatar: string,
    createTime: number,
  ) {
    super();
    this.likeId = likeId;
    this.likeType = likeType;
    this.memberId = memberId;
    this.name = name;
    this.avatar = avatar;
    this.createTime = createTime;
  }
  static fromJson(json: ILike): Like {
    return new Like(json.uid, json.lktp, json.uid, json.name, json.ava, json.lktm);
  }
}
export class Comment extends SocialBase {
  commentId: number;
  comment: string;
  type: number;
  status: number;

  constructor(
    commentId: number,
    memberId: number,
    name: string,
    avatar: string,
    createTime: number,
    comment: string,
    type: number,
    status: number,
  ) {
    super();
    this.commentId = commentId;
    this.memberId = memberId;
    this.name = name;
    this.avatar = avatar;
    this.createTime = createTime;
    this.comment = comment;
    this.type = type;
    this.status = status;
  }
  static fromJson(json: IComment): Comment {
    return new Comment(
      json.cmid,
      json.uid,
      json.name,
      json.ava,
      json.cmtm,
      json.cmt,
      json.type,
      json.sts,
    );
  }
}
export class ListPost extends DataList<Post> {
  static fromJson = (jsonArray: Array<IPost>): Array<Post> => {
    const data = new Array<Post>();
    jsonArray.forEach((post) => {
      data.push(Post.fromJson(post));
    });
    return data;
  };
}
export class ListComment extends DataList<Post> {
  static fromJson = (jsonArray: Array<IComment>): Array<Comment> => {
    const data = new Array<Comment>();
    jsonArray.forEach((item) => {
      data.push(Comment.fromJson(item));
    });
    return data;
  };
}
export class ListLike extends DataList<Post> {
  static fromJson = (jsonArray: Array<ILike>): Array<Like> => {
    const data = new Array<Like>();
    jsonArray.forEach((item) => {
      data.push(Like.fromJson(item));
    });
    return data;
  };
}
