import { Col } from 'antd';
import styled from 'styled-components';

import { LoginTypeIcon } from '../../../components/Icons/LoginTypeIcon';
import { AvatarImage } from '../../../components/User/AvatarImage';
import { device } from '../../../configs/MediaQuery';
import { AppButton } from './../../../styles/ButtonStyles';

export const PersonalInfoWrapper = styled.div`
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-left: 30px;
  border-radius: 8px;
  background: #fff;
`;

export const PersonalInfoContainer = styled.div`
  padding-top: 24px;
  padding-bottom: 8px;
`;

export const AvatarContainer = styled.div`
  position: relative;
  width: 80px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.mobileS} {
    width: 60px;
  }
  @media ${device.tablet} {
    width: 80px;
    height: 80px;
  }
`;

export const AvatarUser = styled(AvatarImage)`
  border-radius: 50%;
  object-fit: cover;
  @media ${device.mobileS} {
    width: 60px;
    height: 60px;
  }
  @media ${device.tablet} {
    width: 80px;
    height: 80px;
  }
  @media ${device.laptop} {
    width: 80px;
    height: 80px;
  }
`;

export const LoginTypeImage = styled(LoginTypeIcon)`
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  object-fit: cover;
  right: -2px;
  bottom: 2px;
`;

export const UserNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
`;

export const LabelText = styled.p`
  margin: 0;
  font-weight: 400;
  color: #797979;

  @media ${device.mobileS} {
    font-size: 12px;
    line-height: 18px;
  }
  @media ${device.tablet} {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const ValueText = styled(LabelText)`
  margin: 0;
  font-weight: 600;
  color: #000;

  @media ${device.mobileS} {
    font-size: 16px;
    line-height: 24px;
  }
  @media ${device.tablet} {
    font-size: 18px;
    line-height: 30px;
  }
`;

export const FollowInfoWrapper = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 16px;
  cursor: pointer;

  &:nth-child(1) {
    border-right: solid 1px #dbdfe2;
  }
`;

export const FollowButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const FollowButton = styled(AppButton)`
  @media ${device.mobileS} {
    width: 90%;
  }
  @media ${device.tablet} {
    width: 60%;
  }
`;
