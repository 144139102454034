import { ComponentType } from 'react';
import pure from 'recompose/pure';

//styles
import {
  TermsContainer,
  TermsTitle,
  TermsTitleSub,
  TermsWrapper,
  TextIndex,
  TextInfo,
  TextWrapper,
  TextWrapperSub,
  TextWrapperSubL2,
  UpdateInfo,
} from './TermsView.styles';

interface Props {}

export const TermsView: ComponentType<Props> = pure(({}) => {
  return (
    <TermsWrapper>
      <TermsContainer>
        <TermsTitle>CHÍNH SÁCH BẢO MẬT THÔNG TIN</TermsTitle>
        <TermsTitleSub>1. GIỚI THIỆU</TermsTitleSub>
        <TextWrapper>
          <TextIndex>1.1</TextIndex>
          <TextInfo>
            Chào mừng Bạn đến với (các) sản phẩm, dịch vụ được vận hành bởi Công Ty Cổ Phần VNG và
            các công ty con, công ty liên kết, công ty thành viên của VNG (gọi riêng và gọi chung
            là, <b>“VNG”, “Chúng tôi”,</b> hay
            <b> “của Chúng tôi”</b>). VNG nghiêm túc thực hiện trách nhiệm của mình liên quan đến
            bảo mật thông tin theo các quy định về bảo vệ bí mật thông tin cá nhân của pháp luật và
            cam kết tôn trọng quyền riêng tư và sự quan tâm của tất cả người dùng đối với các dịch
            vụ của Chúng tôi. VNG hiểu rõ tầm quan trọng của dữ liệu cá nhân mà Bạn đã tin tưởng
            giao cho VNG và tin rằng VNG có trách nhiệm quản lý, bảo vệ và xử lý dữ liệu cá nhân của
            Bạn một cách thích hợp. Chính sách bảo mật thông tin này (<b>“Chính Sách Bảo Mật”</b>
            hay<b> “Chính Sách”</b>) được thiết kế để giúp Bạn hiểu được cách thức VNG xử lý dữ liệu
            cá nhân mà Bạn đã cung cấp cho VNG, cho dù là hiện nay hoặc trong tương lai, cũng như để
            giúp Bạn đưa ra quyết định sáng suốt trước khi cung cấp cho Chúng tôi bất kỳ dữ liệu cá
            nhân nào của Bạn. Đồng thời, Chính Sách Bảo Mật này cũng được xem là một thông báo xử lý
            dữ liệu cá nhân được gửi đến Bạn trước khi Chúng tôi tiến hành xử lý dữ liệu cá nhân của
            Bạn. Chính Sách Bảo Mật này cũng được thiết kế để hỗ trợ Khách hàng của VNG hiện đang
            sống tại Liên Minh Châu Âu (EU) hiểu được các quyền của họ đối với các dữ liệu cá nhân
            được quy định tại Quy định chung về bảo vệ dữ liệu của EU khi họ sử dụng Dịch vụ.
          </TextInfo>
        </TextWrapper>

        <TextWrapper>
          <TextIndex>1.2</TextIndex>
          <TextInfo>
            <b>“Dịch Vụ”</b> hoặc <b>“các Dịch Vụ”</b> có nghĩa là các trò chơi điện tử trên mạng
            của VNG <b>(“Trò Chơi”)</b> và/hoặc trang web <b>(“Trang Web”)</b>, và/hoặc bất kỳ sản
            phẩm, dịch vụ nào do VNG cung cấp.
          </TextInfo>
        </TextWrapper>

        <TextWrapper>
          <TextIndex>1.3</TextIndex>
          <TextInfo>
            <b>“Dữ Liệu Cá Nhân”</b> có nghĩa là thông tin dưới dạng ký hiệu, chữ viết, chữ số, hình
            ảnh, âm thanh hoặc dạng tương tự trên môi trường điện tử gắn liền với Bạn hoặc giúp xác
            định Bạn. Dữ Liệu Cá Nhân do Bạn cung cấp và chịu trách nhiệm về sự chính xác của dữ
            liệu.
          </TextInfo>
        </TextWrapper>

        <TextWrapper>
          <TextIndex>1.4</TextIndex>
          <TextInfo>
            <b>“Xử Lý Dữ Liệu Cá Nhân”</b> là một hoặc nhiều hoạt động tác động tới Dữ Liệu Cá Nhân
            của Bạn, như: thu thập, ghi, phân tích, xác nhận, lưu trữ, chỉnh sửa, công khai, kết
            hợp, truy cập, truy xuất, thu hồi, mã hóa, giải mã, sao chép, chia sẻ, truyền đưa, cung
            cấp, chuyển giao, xóa, hủy Dữ Liệu Cá Nhân hoặc các hành động khác có liên quan.
          </TextInfo>
        </TextWrapper>

        <TextWrapper>
          <TextIndex>1.5</TextIndex>
          <TextInfo>
            Bằng việc đánh dấu vào ô đồng ý Chính Sách Bảo Mật này trước khi sử dụng các Dịch Vụ,
            đăng ký một tài khoản với VNG (<b>“Tài Khoản”</b>) hoặc truy cập Trang Web, Bạn xác nhận
            và đồng ý rằng Bạn tự nguyện, khẳng định việc cho phép Xử Lý Dữ Liệu Cá Nhân và Bạn chấp
            nhận tất cả các phương pháp, yêu cầu, và/hoặc chính sách được mô tả trong Chính Sách Bảo
            Mật này, và theo đây Bạn đồng ý cho phép VNG Xử Lý Dữ Liệu Cá Nhân của Bạn như mô tả
            trong Chính Sách Bảo Mật này (<b>“Sự Đồng Ý”</b>).
          </TextInfo>
        </TextWrapper>

        <TextWrapper>
          <TextIndex>1.6</TextIndex>
          <TextInfo>
            NẾU BẠN KHÔNG ĐỒNG Ý CHO PHÉP VNG XỬ LÝ DỮ LIỆU CÁ NHÂN CỦA BẠN NHƯ MÔ TẢ TRONG CHÍNH
            SÁCH NÀY, VUI LÒNG KHÔNG ĐĂNG KÝ TÀI KHOẢN, SỬ DỤNG CÁC DỊCH VỤ CỦA VNG HAY TRUY CẬP TRÒ
            CHƠI HOẶC TRANG WEB CỦA VNG. Nếu Chúng tôi thay đổi Chính Sách Bảo Mật của mình, Chúng
            tôi sẽ đăng những thay đổi đó hoặc Chính Sách Bảo Mật sửa đổi trên Trang Web, bên trong
            Trò Chơi và/hoặc các Dịch Vụ của Chúng tôi. Chúng tôi có quyền sửa đổi bổ sung Chính
            Sách Bảo Mật này vào bất kỳ thời điểm nào mà không cần thông báo trước cho Bạn.
          </TextInfo>
        </TextWrapper>

        <TermsTitleSub>2. KHI NÀO VNG SẼ THU THẬP DỮ LIỆU CÁ NHÂN?</TermsTitleSub>
        <TextWrapper>
          <TextIndex>2.1. VNG sẽ/có thể thu thập Dữ Liệu Cá Nhân về Bạn:</TextIndex>
        </TextWrapper>
        <TextWrapperSub>
          <TextIndex>(a)</TextIndex>
          <TextInfo>
            khi Bạn đăng ký và/hoặc sử dụng các Dịch Vụ, Trò Chơi hoặc Trang Web của VNG, hoặc đăng
            ký một Tài Khoản với Chúng tôi;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(b)</TextIndex>
          <TextInfo>
            khi Bạn gửi bất kỳ biểu mẫu nào, bao gồm, nhưng không giới hạn ở đơn đăng ký hoặc các
            mẫu đơn khác liên quan đến bất kỳ sản phẩm và dịch vụ nào của VNG, bằng hình thức trực
            tuyến hay dưới hình thức khác;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(c)</TextIndex>
          <TextInfo>
            khi Bạn ký kết bất kỳ thỏa thuận nào hoặc cung cấp các tài liệu hoặc thông tin khác liên
            quan đến tương tác giữa Bạn với VNG, hoặc khi Bạn sử dụng các sản phẩm và dịch vụ của
            Chúng tôi;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(d)</TextIndex>
          <TextInfo>
            khi Bạn tương tác với Chúng tôi, chẳng hạn như thông qua các cuộc gọi điện thoại (có thể
            được ghi âm lại), thư từ, fax, gặp gỡ trực tiếp, các nền tảng ứng dụng truyền thông xã
            hội và thư điện tử (email);
          </TextInfo>
        </TextWrapperSub>

        <TextWrapperSub>
          <TextIndex>(e)</TextIndex>
          <TextInfo>
            khi Bạn sử dụng các dịch vụ điện tử của VNG, hoặc tương tác với Chúng tôi qua Trò Chơi
            hoặc Trang Web hoặc các Dịch Vụ của VNG. Trường hợp này bao gồm, nhưng không giới hạn,
            thông qua tập tin Cookie mà Chúng tôi có thể triển khai khi Bạn tương tác với các Trò
            Chơi, Trang Web hoặc các Dịch Vụ của Chúng tôi;
          </TextInfo>
        </TextWrapperSub>

        <TextWrapperSub>
          <TextIndex>(f)</TextIndex>
          <TextInfo>
            khi Bạn thực hiện các giao dịch thông qua trang Trò Chơi hoặc Trang Web của VNG;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(g)</TextIndex>
          <TextInfo>khi Bạn cung cấp ý kiến phản hồi hoặc gửi khiếu nại cho VNG;</TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(h)</TextIndex>
          <TextInfo>
            khi Bạn đăng ký tham gia một cuộc thi, trò chơi, sự kiện và/hoặc các chương trình khuyến
            mại có liên quan đến Trò Chơi, các Dịch Vụ của VNG;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(i)</TextIndex>
          <TextInfo>
            trong quá trình Bạn chơi (các) Trò Chơi, sử dụng Trang Web và các Dịch Vụ của VNG; và
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(j)</TextIndex>
          <TextInfo>khi Bạn gửi Dữ Liệu Cá Nhân của Bạn cho VNG vì bất kỳ lý do gì.</TextInfo>
        </TextWrapperSub>
        <TextWrapper>
          <TextIndex>2.2</TextIndex>
          <TextInfo>
            Trên đây chỉ là một số trường hợp phổ biến mà VNG thu thập Dữ Liệu Cá Nhân của Bạn,
            không phản ánh hết toàn bộ các trường hợp mà VNG sẽ thu thập Dữ Liệu Cá Nhân của Bạn.
          </TextInfo>
        </TextWrapper>

        <TermsTitleSub>3. VNG SẼ THU THẬP NHỮNG DỮ LIỆU CÁ NHÂN GÌ?</TermsTitleSub>
        <TextWrapper>
          <TextIndex>3.1</TextIndex>
          <TextInfo>
            Dữ Liệu Cá Nhân mà VNG có thể thu thập bao gồm, nhưng không giới hạn các dữ liệu cá nhân
            cơ bản sau:
          </TextInfo>
        </TextWrapper>
        <TextWrapperSub>
          <TextIndex>(a)</TextIndex>
          <TextInfo>họ và tên</TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(b)</TextIndex>
          <TextInfo>địa chỉ email;</TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(c)</TextIndex>
          <TextInfo>ngày, tháng, năm sinh;</TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(d)</TextIndex>
          <TextInfo>
            thông tin của Giấy tờ tùy thân (CMND, Căn cước công dân, Hộ chiếu) và ngày cấp, nơi cấp;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(e)</TextIndex>
          <TextInfo>địa chỉ (địa chỉ đăng ký thường trú, địa chỉ liên hệ);</TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(f)</TextIndex>
          <TextInfo>số điện thoại;</TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(g)</TextIndex>
          <TextInfo>giới tính;</TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(h)</TextIndex>
          <TextInfo>tình trạng hôn nhân;</TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(i)</TextIndex>
          <TextInfo>nghề nghiệp;</TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(j)</TextIndex>
          <TextInfo>ảnh chụp gương mặt của Bạn thông qua thiết bị; </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(k)</TextIndex>
          <TextInfo>thông tin liên quan đến việc thanh toán;</TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(l)</TextIndex>
          <TextInfo>
            bất kỳ thông tin nào khác về Bạn (i) khi Bạn đăng nhập để sử dụng các Dịch Vụ của VNG,
            và (ii) khi Bạn sử dụng các Dịch Vụ, cũng như (iii) thông tin về việc Bạn sử dụng các
            Dịch Vụ của VNG như thế nào; và
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(m)</TextIndex>
          <TextInfo>dữ liệu tổng hợp về nội dung người dùng sử dụng.</TextInfo>
        </TextWrapperSub>

        <TextWrapper>
          <TextIndex>3.2</TextIndex>
          <TextInfo>
            Để tránh nghi ngờ, trong mọi trường hợp, Chúng tôi sẽ không xử lý bất kỳ Dữ Liệu Cá Nhân
            nào của Bạn mà dữ liệu đó là dữ liệu cá nhân nhạy cảm theo các quy định hiện hành của
            pháp luật về bảo vệ dữ liệu cá nhân.
          </TextInfo>
        </TextWrapper>

        <TextWrapper>
          <TextIndex>3.3</TextIndex>
          <TextInfo>
            Bạn đồng ý không cung cấp cho Chúng tôi bất cứ dữ liệu nào là dữ liệu cá nhân nhạy cảm,
            dữ liệu không chính xác hoặc gây hiểu nhầm và Bạn đồng ý sẽ thông báo cho VNG về bất cứ
            dữ liệu nào không chính xác hoặc khi có sự thay đổi liên quan đến các dữ liệu này. VNG
            bảo lưu quyền (tùy theo quyết định riêng của VNG) được yêu cầu Bạn cung cấp các tài liệu
            cần thiết khác để xác minh thông tin mà Bạn cung cấp cho VNG.
          </TextInfo>
        </TextWrapper>

        <TextWrapper>
          <TextIndex>3.4</TextIndex>
          <TextInfo>
            Nếu Bạn sử dụng tài khoản mạng xã hội (như Facebook, Google), tài khoản ứng dụng (như
            tài khoản Apple) của Bạn (“Tài Khoản Mạng Xã Hội”) và/hoặc số điện thoại của Bạn để đăng
            nhập, sử dụng các Dịch Vụ của VNG hoặc liên kết với Tài Khoản mà Bạn đã đăng ký với VNG
            thì Bạn hiểu rõ và đồng ý rằng, VNG có thể truy cập Dữ Liệu Cá Nhân của Bạn khi và chỉ
            khi Bạn và nhà cung cấp dịch vụ Tài Khoản Mạng Xã Hội đồng ý và cho phép Chúng tôi được
            thực hiện điều đó.
          </TextInfo>
        </TextWrapper>

        <TextWrapper>
          <TextIndex>3.5</TextIndex>
          <TextInfo>
            Bạn có quyền chỉnh sửa, yêu cầu chỉnh sửa, yêu cầu cung cấp hoặc xóa Dữ Liệu Cá Nhân đã
            cung cấp cho Chúng tôi, hoặc Bạn cũng có thể rút lại Sự Đồng Ý vào bất kỳ lúc nào nếu
            Bạn không muốn Chúng tôi tiếp tục thu thập Dữ Liệu Cá Nhân nói trên. Tuy nhiên, Bạn cần
            lưu ý rằng, việc Bạn từ chối, rút lại Sự Đồng Ý cho phép Chúng tôi Xử Lý Dữ Liệu Cá Nhân
            của Bạn hoặc yêu cầu xóa Dữ Liệu Cá Nhân đã cung cấp cho Chúng tôi có thể ảnh hưởng đến
            việc Bạn sử dụng các Dịch Vụ của Chúng tôi. Bạn có thể tìm thấy thêm thông tin về nội
            dung này trong Mục 12 dưới đây.
          </TextInfo>
        </TextWrapper>

        <TermsTitleSub>4. THU THẬP CÁC DỮ LIỆU KHÁC</TermsTitleSub>
        <TextWrapper>
          <TextIndex>4.1</TextIndex>
          <TextInfo>
            Như với hầu hết các trang web và các ứng dụng di động khác, các dữ liệu mà thiết bị của
            Bạn gửi cho Chúng tôi có thể bao gồm: dữ liệu về Bạn, được một máy chủ ghi lại khi Bạn
            sử dụng các Dịch Vụ của VNG. Thông tin này thường bao gồm nhưng không giới hạn:
          </TextInfo>
        </TextWrapper>
        <TextWrapperSub>
          <TextIndex>(a)</TextIndex>
          <TextInfo>địa chỉ IP.</TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(b)</TextIndex>
          <TextInfo>hệ điều hành của máy tính/thiết bị di động;</TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(c)</TextIndex>
          <TextInfo>loại trình duyệt;</TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(d)</TextIndex>
          <TextInfo>
            loại thiết bị di động, các đặc điểm của thiết bị di động, mã định danh thiết bị duy nhất
            (UDID) hoặc mã định danh thiết bị di động (MEID) của thiết bị di động của Bạn;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(e)</TextIndex>
          <TextInfo>địa chỉ tham chiếu của Trang Web (nếu có);</TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(f)</TextIndex>
          <TextInfo>
            các trang web mà Bạn đã truy cập để dẫn đến trên trang web hoặc ứng dụng di động của VNG
            và thời gian truy cập, đôi khi là “Cookie” (có thể vô hiệu hóa bằng cách sử dụng tùy
            chọn trình duyệt của Bạn) để giúp trang web ghi nhớ lần truy cập cuối cùng của Bạn.
          </TextInfo>
        </TextWrapperSub>

        <TextWrapper>
          <TextIndex>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</TextIndex>
          <TextInfo>
            Nếu Bạn đăng nhập, các thông tin nêu trên sẽ được liên kết với Tài Khoản cá nhân của
            Bạn. Các thông tin này (không bao gồm Dữ Liệu Cá Nhân của Bạn) cũng được đưa vào các số
            liệu thống kê để giúp Chúng tôi thống kê, đánh giá được khách hàng đã truy cập sử dụng
            Trò Chơi và Trang Web của Chúng tôi như thế nào.
          </TextInfo>
        </TextWrapper>
        <TextWrapper>
          <TextIndex>4.2</TextIndex>
          <TextInfo>
            Các Dịch Vụ của VNG có thể sử dụng các công nghệ như ARKit (Agumented Reality hay gọi
            tắt là AR – Công nghệ thực tế ảo), Camera API, TrueDepth API … hoặc các công nghệ tương
            tự được cung cấp bởi hệ điều hành của thiết bị di động; các công nghệ này được sử dụng
            để ghi nhận thông tin biểu hiện trên khuôn mặt của người dùng và dùng cho tính năng
            trong ứng dụng. Đối với các thông tin này, Chúng tôi không sử dụng cho bất kỳ mục đích
            nào khác ngoài các mục đích được cung cấp trong ứng dụng; Bạn có quyền từ chối cấp quyền
            truy cập camera cho các tính năng này bất kỳ lúc nào (nhưng Bạn cần lưu ý rằng, khi Bạn
            ngưng cấp quyền truy cập camera cho ứng dụng thì một số tính năng của ứng dụng sẽ không
            thể hoạt động được); và Chúng tôi không lưu trữ cũng như không chia sẻ các thông tin này
            cho bất kỳ bên thứ ba nào.
          </TextInfo>
        </TextWrapper>

        <TermsTitleSub>5. COOKIES</TermsTitleSub>
        <TextWrapper>
          <TextIndex>5.1</TextIndex>
          <TextInfo>
            Đôi khi Chúng tôi có thể sử dụng “Cookie” hoặc các tính năng khác để cho phép Chúng tôi
            hoặc các bên thứ ba thu thập hoặc chia sẻ thông tin sẽ giúp Chúng tôi cải thiện Trang
            Web, Trò Chơi của mình và các Dịch Vụ mà Chúng tôi cung cấp, hoặc giúp Chúng tôi đưa ra
            các dịch vụ và tính năng mới. “Cookie” là các mã định danh Chúng tôi gửi đến máy tính
            hoặc thiết bị di động của Bạn, cho phép Chúng tôi nhận dạng máy tính hoặc thiết bị của
            Bạn và cho Chúng tôi biết khi nào các Dịch Vụ hoặc Trang Web được sử dụng hay truy cập,
            bởi bao nhiêu người và để theo dõi những hoạt động trong Trang Web hoặc các Trò Chơi của
            Chúng tôi. Chúng tôi có thể liên kết thông tin Cookie với Dữ Liệu Cá Nhân. Cookie cũng
            liên kết với thông tin về những nội dung Bạn đã chọn đối với các trang mua sắm bạn đã
            xem, hoặc các trò chơi điện tử mà Bạn đã chơi. Cookie cũng được sử dụng để cung cấp nội
            dung dựa trên quan tâm của Bạn và để theo dõi việc sử dụng của các Dịch Vụ.
          </TextInfo>
        </TextWrapper>
        <TextWrapper>
          <TextIndex>5.2</TextIndex>
          <TextInfo>
            Bạn có thể từ chối sử dụng Cookie bằng cách chọn các thiết lập thích hợp trên trình
            duyệt của Bạn. Tuy nhiên, vui lòng lưu ý rằng nếu Bạn thực hiện thao tác này Bạn có thể
            không sử dụng được các chức năng đầy đủ của Trang Web, Trò Chơi hoặc các Dịch Vụ của
            Chúng tôi.
          </TextInfo>
        </TextWrapper>

        <TermsTitleSub>
          6. VNG SỬ DỤNG DỮ LIỆU CÁ NHÂN BẠN CUNG CẤP CHO VNG NHƯ THẾ NÀO?
        </TermsTitleSub>
        <TextWrapper>
          <TextIndex>6.1</TextIndex>
          <TextInfo>
            VNG có thể Xử Lý Dữ Liệu Cá Nhân của Bạn vì một hoặc nhiều mục đích sau đây:
          </TextInfo>
        </TextWrapper>
        <TextWrapperSub>
          <TextIndex>(a)</TextIndex>
          <TextInfo>
            để đáp ứng các thủ tục pháp lý, tuân thủ các quy định của pháp luật hiện hành và theo
            các yêu cầu của cơ quan nhà nước có thẩm quyền;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(b)</TextIndex>
          <TextInfo>
            để xem xét và/hoặc xử lý đơn đăng ký/giao dịch của Bạn với Chúng tôi hoặc giao dịch hay
            thư từ của Bạn với các bên thứ ba qua các Dịch Vụ;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(c)</TextIndex>
          <TextInfo>
            để quản lý, điều hành, cung cấp và/hoặc quản lý việc Bạn sử dụng và/hoặc truy cập các
            Dịch Vụ, Trang Web và các Trò Chơi của Chúng tôi (bao gồm nhưng không giới hạn), các sở
            thích của Bạn, Tài Khoản, cũng như quan hệ của Bạn với Chúng tôi;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(d)</TextIndex>
          <TextInfo>
            để đáp ứng, xử lý, giải quyết, hoàn tất một giao dịch và/hoặc đáp ứng các yêu cầu của
            Bạn đối với các Dịch Vụ, sản phẩm nhất định;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(e)</TextIndex>
          <TextInfo>
            để thông báo cho Bạn các vấn đề về Dịch Vụ, cũng như các hoạt động Tài Khoản bất thường;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(f)</TextIndex>
          <TextInfo>
            để thực thi các Điều Khoản Dịch Vụ của Chúng tôi hoặc bất kỳ thỏa thuận, điều khoản khác
            được áp dụng liên quan đến người dùng cuối;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(g)</TextIndex>
          <TextInfo>
            để bảo vệ sự an toàn cá nhân và các quyền, tài sản hoặc sự an toàn của người khác, hoặc
            người được Bạn giám hộ;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(h)</TextIndex>
          <TextInfo>để nhận dạng và/hoặc xác minh Tài Khoản của Bạn;</TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(i)</TextIndex>
          <TextInfo>
            để duy trì và quản lý bất kỳ bản cập nhật phần mềm nào và/hoặc các bản cập nhật khác và
            sự hỗ trợ có thể được yêu cầu tùy lúc nhằm đảm bảo các Dịch Vụ của Chúng tôi hoạt động
            suôn sẻ;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(j)</TextIndex>
          <TextInfo>
            để giải quyết hoặc tạo điều kiện để bộ phận chăm sóc khách hàng thực hiện các yêu cầu
            hợp lý của Bạn, trả lời bất kỳ thắc mắc nào được gửi bởi Bạn hoặc gửi bởi một người khác
            thay mặt Bạn, với điều kiện người thay mặt Bạn có đầy đủ các giấy tờ chứng minh về việc
            Bạn đã ủy quyền cho họ thực hiện các công việc, yêu cầu này;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(k)</TextIndex>
          <TextInfo>
            để liên hệ với Bạn thông qua điện thoại, tin nhắn văn bản, tin nhắn fax, thư điện tử
            (email), thư hoặc cách thức khác nhằm mục đích quản trị/ quản lý mối quan hệ giữa Bạn
            với Chúng tôi hoặc việc Bạn sử dụng các Dịch Vụ của Chúng tôi, bao gồm nhưng không giới
            hạn ở việc truyền đạt các thông tin cho Bạn liên quan đến các Dịch Vụ của Chúng tôi. Bạn
            xác nhận và đồng ý rằng Chúng tôi có thể liên lạc với Bạn theo cách gửi thư qua đường
            bưu điện hoặc gửi thông báo cho Bạn thông qua các phương tiện liên lạc phù hợp, mà các
            cách thức này có thể tiết lộ Dữ Liệu Cá Nhân nhất định về Bạn (Ví dụ: tiết lộ Dữ Liệu Cá
            Nhân được ghi trên phong bì thư);
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(l)</TextIndex>
          <TextInfo>
            để thông báo cho Bạn khi một người dùng khác đã gửi cho Bạn một tin nhắn riêng tư hoặc
            đăng nhận xét cho Bạn trong các Trò Chơi, trên Trang Web hoặc các Dịch Vụ của Chúng tôi;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(m)</TextIndex>
          <TextInfo>
            để tiến hành các hoạt động nghiên cứu, phân tích và phát triển (bao gồm, nhưng không
            giới hạn ở việc phân tích dữ liệu, khảo sát, phát triển và/hoặc thiết lập đặc tính sản
            phẩm và Dịch Vụ), để phân tích cách thức Bạn sử dụng các Dịch Vụ của Chúng tôi, nhằm mục
            đích cải thiện các Dịch Vụ hoặc sản phẩm của Chúng tôi và/hoặc cải thiện trải nghiệm
            khách hàng của Bạn;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(n)</TextIndex>
          <TextInfo>
            để cho phép quảng cáo và các đợt kiểm tra và khảo sát khác, xác thực quy mô và thành
            phần của đối tượng mục tiêu của VNG, và hiểu được trải nghiệm của họ với các Dịch Vụ của
            VNG;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(o)</TextIndex>
          <TextInfo>
            vì mục đích tiếp thị. Trong trường hợp này, thông qua các phương thức liên lạc khác
            nhau, Chúng tôi sẽ gửi cho Bạn thông tin, tài liệu tiếp thị và quảng bá liên quan đến
            các sản phẩm và/hoặc Dịch Vụ của VNG và các công ty con, công ty liên kết với VNG, cho
            dù các sản phẩm hoặc Dịch Vụ đó là sản phẩm, Dịch Vụ hiện tại hoặc được tạo ra trong
            tương lai;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(p)</TextIndex>
          <TextInfo>
            để lập số liệu thống kê và nghiên cứu đáp ứng yêu cầu báo cáo và/hoặc duy trì sổ sách
            nội bộ của Chúng tôi hoặc theo quy chế, quy định có liên quan;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(q)</TextIndex>
          <TextInfo>
            để thực hiện quy trình tìm hiểu, xác minh hoặc thực hiện các hoạt động sàng lọc khác
            (bao gồm nhưng không giới hạn việc kiểm tra lý lịch) nhằm tuân thủ các nghĩa vụ theo quy
            định pháp luật hoặc chính sách quản lý, thủ tục kiểm soát rủi ro của Chúng tôi mà theo
            đó các quy định, chính sách này được pháp luật yêu cầu hoặc đã được Chúng tôi áp dụng;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(r)</TextIndex>
          <TextInfo>để kiểm tra các Dịch Vụ của Chúng tôi hoặc hoạt động của VNG;</TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(s)</TextIndex>
          <TextInfo>
            để ngăn chặn hoặc điều tra bất kỳ hành vi vi phạm thực tế hoặc nghi ngờ vi phạm đối với
            Điều Khoản Dịch Vụ của Chúng tôi, bao gồm nhưng không giới hạn hành vi gian lận, phi
            pháp, thiếu sót hay bất kỳ hành vi sai trái nào khác, cho dù có hoặc không liên quan đến
            việc Bạn sử dụng các Dịch Vụ của Chúng tôi hay bất kỳ vấn đề nào khác phát sinh từ mối
            quan hệ giữa Bạn với Chúng tôi;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(t)</TextIndex>
          <TextInfo>
            để lưu trữ, lập máy chủ, sao lưu (có thể vì mục đích khôi phục sau thảm họa, bao gồm
            nhưng không giới hạn chiến tranh, hỏa hoạn, thiên tai và/hoặc các sự cố nghiêm trọng
            khác..., hoặc mục đích khác) đối với Dữ Liệu Cá Nhân của Bạn;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(u)</TextIndex>
          <TextInfo>
            để xử lý và/hoặc tạo thuận tiện cho một Giao dịch tài sản kinh doanh hoặc một Giao dịch
            tài sản kinh doanh tiềm năng, trường hợp giao dịch đó liên quan đến VNG như một bên tham
            gia hoặc chỉ liên quan đến một công ty con hay công ty liên kết của VNG như một bên tham
            gia hoặc liên quan đến VNG và/hoặc bất kỳ một hay nhiều công ty con hoặc công ty liên
            kết của VNG như (các) bên tham gia, và có thể có các tổ chức bên thứ ba khác tham gia
            giao dịch này. “Giao dịch tài sản kinh doanh” là các giao dịch mua, bán, cho thuê, sáp
            nhập, hợp nhất hoặc bất kỳ hoạt động mua lại, thanh lý hay tài trợ nào của một tổ chức
            hoặc một phần của một tổ chức hoặc của bất kỳ hoạt động kinh doanh hay tài sản nào của
            một tổ chức; và/hoặc
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(v)</TextIndex>
          <TextInfo>
            bất kỳ mục đích nào mà Chúng tôi thông báo cho Bạn tại thời điểm xin phép Sự Đồng Ý của
            Bạn.
          </TextInfo>
        </TextWrapperSub>
        <TextWrapper>
          <TextIndex></TextIndex>
          <TextInfo>(gọi chung là “Mục Đích” hoặc “các Mục Đích”).</TextInfo>
        </TextWrapper>
        <TextWrapper>
          <TextIndex>6.2</TextIndex>
          <TextInfo>
            Vì các Mục Đích mà Chúng tôi sẽ/có thể thu thập, sử dụng, tiết lộ hoặc Xử Lý Dữ Liệu Cá
            Nhân của Bạn phụ thuộc vào hoàn cảnh hiện có, mục đích đó có thể không xuất hiện bên
            trên. Tuy nhiên, Chúng tôi sẽ thông báo cho Bạn biết mục đích khác đó tại thời điểm xin
            phép Sự Đồng Ý của Bạn, trừ khi việc Xử Lý Dữ Liệu Cá Nhân mà không cần có Sự Đồng Ý của
            Bạn là được phép theo các quy định của pháp luật về bảo vệ dữ liệu cá nhân hoặc theo các
            quy định pháp luật khác.
          </TextInfo>
        </TextWrapper>
        <TextWrapper>
          <TextIndex>6.3</TextIndex>
          <TextInfo>
            Bằng Sự Đồng Ý, Bạn đã đồng ý cho phép Chúng tôi được Xử Lý Dữ Liệu Cá Nhân của Bạn theo
            tất cả các Mục Đích nêu trên. Trường hợp Bạn chỉ đồng ý với một hoặc một số Mục Đích,
            Bạn vui lòng không đánh dấu vào ô đồng ý Chính Sách Bảo Mật này. Tuy nhiên, Bạn cần lưu
            ý rằng, việc này có thể ảnh hưởng đến việc Bạn sử dụng các Dịch Vụ của Chúng tôi.
          </TextInfo>
        </TextWrapper>

        <TermsTitleSub>
          7. VNG BẢO VỆ VÀ LƯU TRỮ DỮ LIỆU CÁ NHÂN CỦA BẠN BẰNG CÁCH NÀO?
        </TermsTitleSub>

        <TextWrapper>
          <TextIndex>7.1</TextIndex>
          <TextInfo>
            VNG thực hiện các biện pháp bảo mật khác nhau và luôn nỗ lực để đảm bảo sự an toàn Dữ
            Liệu Cá Nhân của Bạn trên các hệ thống của VNG. Dữ Liệu Cá Nhân của người dùng được lưu
            trữ đằng sau các mạng bảo mật và chỉ có thể được truy cập bởi một số nhân viên có quyền
            truy cập đặc biệt đến các hệ thống của VNG. Tuy nhiên, chắc chắn không thể có sự bảo đảm
            an toàn tuyệt đối.
          </TextInfo>
        </TextWrapper>

        <TextWrapper>
          <TextIndex>7.2</TextIndex>
          <TextInfo>
            Thời hạn VNG Xử Lý Dữ Liệu Cá Nhân của Bạn được tính từ khi Chúng tôi có được Sự Đồng Ý
            của Bạn cho đến thời điểm cuối cùng Bạn sử dụng các Dịch Vụ của Chúng tôi và tiếp tục
            kéo dài thêm tối thiểu 03 (ba) năm kể từ thời điểm đó. Kể cả trong trường hợp Bạn yêu
            cầu Chúng tôi xóa Dữ Liệu Cá Nhân của Bạn theo Mục 12.2, Chúng tôi có thể vẫn phải tiếp
            tục lưu trữ, sử dụng và/hoặc tiết lộ Dữ Liệu Cá Nhân của Bạn tuân theo Chính Sách Bảo
            Mật này để tuân thủ các nghĩa vụ của Chúng tôi theo các quy định của pháp luật về bảo vệ
            dữ liệu cá nhân và các quy định pháp luật khác có liên quan.
          </TextInfo>
        </TextWrapper>

        <TextWrapper>
          <TextIndex>7.3</TextIndex>
          <TextInfo>
            VNG sẽ duy trì Dữ Liệu Cá Nhân tuân theo các quy định của pháp luật về bảo vệ dữ liệu cá
            nhân và/hoặc các quy định pháp luật hiện hành khác. Có nghĩa là, VNG có quyền xóa Dữ
            Liệu Cá Nhân của Bạn khi VNG có lý do hợp lý để xác định rằng (i) việc lưu trữ Dữ Liệu
            Cá Nhân đó không còn phục vụ mục đích xử lý Dữ Liệu Cá Nhân đó nữa; (ii) việc lưu trữ
            không còn cần thiết cho bất kỳ mục đích hợp pháp hay mục đích kinh doanh nào và (iii)
            không còn các lợi ích hợp pháp nào khác để tiếp tục lưu trữ các Dữ Liệu Cá Nhân này. Tùy
            theo luật áp dụng, Chúng tôi có thể xóa Dữ Liệu Cá Nhân của Bạn một cách an toàn mà
            không cần thông báo trước cho Bạn.
          </TextInfo>
        </TextWrapper>

        <TermsTitleSub>8. VNG CÓ TIẾT LỘ DỮ LIỆU CÁ NHÂN CỦA BẠN HAY KHÔNG?</TermsTitleSub>
        <TextWrapper>
          <TextIndex>8.1</TextIndex>
          <TextInfo>
            Trong quá trình thực hiện hoạt động kinh doanh, Chúng tôi sẽ/có thể cần phải tiết lộ Dữ
            Liệu Cá Nhân của Bạn cho các nhà phát triển Trò Chơi, nhà cung cấp dịch vụ, bên thứ ba,
            đại lý và/hoặc các công ty con và/hoặc các công ty liên kết và/hoặc công ty thành viên
            của VNG, và/hoặc các bên thứ ba khác, vì một hay nhiều Mục Đích nói trên. Các nhà cung
            cấp dịch vụ bên thứ ba, đại lý và/hoặc các công ty con và/hoặc các công ty liên kết hoặc
            công ty thành viên của VNG và/hoặc các bên thứ ba khác như thế sẽ Xử Lý Dữ Liệu Cá Nhân
            của Bạn hoặc thay mặt Chúng tôi vì một hoặc nhiều Mục Đích nói trên. Các bên thứ ba như
            thế bao gồm, nhưng không giới hạn ở:
          </TextInfo>
        </TextWrapper>
        <TextWrapperSub>
          <TextIndex>(a)</TextIndex>
          <TextInfo>công ty con, công ty liên kết và công ty thành viên của VNG;</TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(b)</TextIndex>
          <TextInfo>
            nhà thầu, đại lý, nhà cung cấp dịch vụ và các bên thứ ba khác mà Chúng tôi thuê để hỗ
            trợ hoạt động kinh doanh của Chúng tôi. Những bên này bao gồm, nhưng không giới hạn ở
            những bên cung cấp các dịch vụ quản trị hoặc các dịch vụ khác cho Chúng tôi chẳng hạn
            như công ty bưu chính, công ty viễn thông, công ty công nghệ thông tin và trung tâm dữ
            liệu;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(c)</TextIndex>
          <TextInfo>
            người mua hoặc người thừa nhiệm khác trong trường hợp sáp nhập, thoái vốn, tái cơ cấu,
            tái tổ chức, giải thể hoặc bán hay chuyển nhượng một phần hoặc tất cả tài sản của VNG,
            cho dù là vấn đề đang diễn ra hay đang trong quá trình thực hiện thủ tục phá sản, thanh
            lý hoặc thủ tục tương tự, trong đó Dữ Liệu Cá Nhân VNG lưu trữ về người dùng của Chúng
            tôi nằm trong các tài sản được chuyển nhượng; hoặc cho một bên đối tác trong một Giao
            dịch tài sản kinh doanh mà VNG hoặc bất kỳ công ty con, công ty liên kết hay công ty
            thành viên của VNG có tham gia giao dịch;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(d)</TextIndex>
          <TextInfo>các cơ quan nhà nước có thẩm quyền; và</TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(e)</TextIndex>
          <TextInfo>
            bên thứ ba mà Chúng tôi tiết lộ thông tin vì một trong các Mục Đích và các bên thứ ba đó
            ngược lại họ sẽ thu thập và Xử Lý Dữ Liệu Cá Nhân của Bạn vì một hoặc nhiều mục đích.
          </TextInfo>
        </TextWrapperSub>

        <TextWrapper>
          <TextIndex>8.2</TextIndex>
          <TextInfo>
            Trường hợp này các bên thứ ba có thể yêu cầu Chúng tôi tiết lộ lịch sử chơi Trò Chơi của
            Bạn và/hoặc tiết lộ các hồ sơ cho thấy các địa chỉ IP nào đã xem các Trò Chơi nào. Chúng
            tôi cũng sẽ chia sẻ thông tin thống kê việc Bạn sử dụng các Dịch Vụ với các bên thứ ba
            này. Trường hợp này sẽ không bao gồm bất kỳ thông tin nào có thể được sử dụng để xác
            định danh tính cụ thể của Bạn hoặc tiết lộ Dữ Liệu Cá Nhân về Bạn.
          </TextInfo>
        </TextWrapper>

        <TextWrapper>
          <TextIndex>8.3</TextIndex>
          <TextInfo>
            Để làm rõ, Chúng tôi có thể tiết lộ Dữ Liệu Cá Nhân của Bạn mà không cần Sự Đồng Ý của
            Bạn trong trường hợp các quy định của pháp luật về bảo vệ dữ liệu cá nhân hoặc các điều
            luật hiện hành khác cho phép Chúng tôi thực hiện điều này.
          </TextInfo>
        </TextWrapper>

        <TextWrapper>
          <TextIndex>8.4</TextIndex>
          <TextInfo>
            Các bên thứ ba có thể chặn hoặc truy cập trái phép Dữ Liệu Cá Nhân được gửi đến hoặc có
            trên Trang Web, các phần mềm, ứng dụng, công nghệ được áp dụng có thể dẫn đến việc hoạt
            động không chính xác hoặc không hoạt động như dự kiến, hoặc có người có thể truy cập,
            lạm dụng hoặc sử dụng sai trái thông tin mà không phải lỗi của Chúng tôi. Tuy nhiên
            Chúng tôi sẽ triển khai các biện pháp bảo mật hợp lý để bảo vệ Dữ Liệu Cá Nhân của Bạn
            theo quy định của pháp luật về bảo vệ dữ liệu cá nhân; tuy nhiên Chúng tôi không thể
            hoàn toàn đảm bảo sự bảo mật tuyệt đối bởi vì một bên thứ ba có thể truy cập trái phép
            Dữ Liệu Cá Nhân được gửi đến hoặc có trong Trò Chơi, có trên Trang Web hoặc các phần
            mềm, ứng dụng, công nghệ được áp dụng có thể dẫn đến việc hoạt động không chính
            xác/không hoạt động như dự kiến, hoặc có người có thể truy cập, lạm dụng hoặc sử dụng
            sai trái Dữ Liệu Cá Nhân mà không phải lỗi của Chúng tôi.
          </TextInfo>
        </TextWrapper>

        <TermsTitleSub>
          9. DỮ LIỆU CÁ NHÂN CỦA TRẺ EM (TRỪ TRẺ EM ĐANG SINH SỐNG TẠI EU)
        </TermsTitleSub>
        <TextWrapper>
          <TextIndex>9.1</TextIndex>
          <TextInfo>
            Các Dịch Vụ của Chúng tôi rất đa dạng và cung ứng cho nhiều đối tượng với nhiều độ tuổi
            khác nhau. Đối với trẻ em dưới 16 tuổi, việc tạo Tài Khoản, sử dụng các Dịch Vụ của
            Chúng tôi cần được Sự Đồng Ý và giám sát của cha/mẹ hoặc người giám hộ của trẻ.
          </TextInfo>
        </TextWrapper>
        <TextWrapper>
          <TextIndex>9.2</TextIndex>
          <TextInfo>
            Cha/mẹ hoặc người giám hộ của trẻ em dưới 16 tuổi vui lòng giám sát và đảm bảo trẻ dưới
            16 tuổi mà mình đang giám hộ không tự đăng ký Tài Khoản để sử dụng các Dịch Vụ và/hoặc
            tự cung cấp các Dữ Liệu Cá Nhân của trẻ cho Chúng tôi mà không có Sự Đồng Ý, giám sát
            của cha/mẹ hoặc người giám hộ của trẻ.
          </TextInfo>
        </TextWrapper>
        <TextWrapper>
          <TextIndex>9.3</TextIndex>
          <TextInfo>
            Trong trường hợp Dữ Liệu Cá Nhân của trẻ em dưới 16 tuổi do cha/mẹ, người giám hộ đồng ý
            cung cấp cho Chúng tôi thì cha/mẹ hoặc người giám hộ theo đây đồng ý với việc xử lý, sử
            dụng Dữ Liệu Cá Nhân của trẻ em dưới 16 tuổi có liên quan, và đồng ý chịu sự điều chỉnh
            của Chính Sách này thay mặt cho người được giám hộ. Đồng thời, cha/mẹ hoặc người giám hộ
            cam kết và đảm bảo rằng Dữ Liệu Cá Nhân của trẻ em từ đủ 07 tuổi trở lên mà mình đang
            giám hộ đã có Sự Đồng Ý của trẻ, bên cạnh Sự Đồng Ý của cha/mẹ hoặc người giám hộ, trước
            khi dữ liệu đó được cung cấp cho Chúng tôi.
          </TextInfo>
        </TextWrapper>
        <TextWrapper>
          <TextIndex>9.4</TextIndex>
          <TextInfo>
            Cha/mẹ hoặc người giám hộ của trẻ em dưới 16 tuổi đồng ý rằng, ngoài Dữ Liệu Cá Nhân của
            trẻ em dưới 16 tuổi do cha/mẹ, người giám hộ cung cấp cho Chúng tôi, Chúng tôi có thể
            yêu cầu cung cấp Dữ Liệu Cá Nhân của chính Cha/mẹ hoặc người giám hộ của trẻ em dưới 16
            tuổi đó theo Mục Đích tại điểm (e) Mục 6.1 của Chính Sách này.
          </TextInfo>
        </TextWrapper>
        <TextWrapper>
          <TextIndex>9.5</TextIndex>
          <TextInfo>
            Chúng tôi sẽ khóa bất kỳ Tài Khoản nào được sử dụng bởi đối tượng trẻ em mà không tuân
            thủ những yêu cầu được nêu ở trên và sẽ gỡ/ xóa bất kỳ Dữ Liệu Cá Nhân nào mà Chúng tôi
            cho là đã được gửi bởi người dùng là trẻ em dưới 16 tuổi mà không đáp ứng những yêu cầu
            trên.
          </TextInfo>
        </TextWrapper>

        <TermsTitleSub>10. DỮ LIỆU CÁ NHÂN THU THẬP BỞI CÁC BÊN THỨ BA</TermsTitleSub>
        <TextWrapper>
          <TextIndex>10.1</TextIndex>
          <TextInfo>
            Các Dịch Vụ của Chúng tôi có thể sử dụng các công cụ phân tích (như Google Analytics,
            Facebook Pixel,…) (“Analytics Tools”). Các Analytics Tools này có thể sử dụng Cookie, là
            các tập tin văn bản được đặt vào thiết bị của Bạn, để giúp trang web phân tích cách thức
            người dùng sử dụng các Dịch Vụ của Chúng tôi. Thông tin được tạo bởi Cookies về việc Bạn
            sử dụng trang web (bao gồm địa chỉ IP của Bạn) sẽ được gửi đến và lưu bởi nhà cung cấp
            dịch vụ Analytics Tools. Nhà cung cấp dịch vụ Analytics Tools sẽ sử dụng thông tin này
            để đánh giá việc Bạn sử dụng các Dịch Vụ của Chúng tôi, soạn báo cáo về hoạt động trang
            web dành cho các nhà điều hành trang web và cung cấp các dịch vụ khác liên quan đến hoạt
            động trang web và việc sử dụng Internet.
          </TextInfo>
        </TextWrapper>
        <TextWrapper>
          <TextIndex>10.2</TextIndex>
          <TextInfo>
            Các Dịch Vụ của Chúng tôi có thể sử dụng các công cụ đo lường (như AppsFlyer, Adjust,
            Firebase, …) (“Mobile Measurement Partner”, gọi tắt là “MMP”). Các MMP sử dụng bộ
            Software Development Kit (“SDK”) được đặt vào thiết bị của Bạn để giúp Chúng tôi theo
            dõi và tối ưu hóa việc cung ứng các Dịch Vụ đến cho Bạn. Thông tin được tạo bởi SDK về
            việc Bạn sử dụng ứng dụng của Chúng tôi (bao gồm địa chỉ IP của Bạn) sẽ được gửi đến và
            lưu bởi nhà cung cấp MMP. Nhà cung cấp MMP sẽ sử dụng thông tin này để đánh giá việc Bạn
            sử dụng các Dịch Vụ của Chúng tôi, soạn báo cáo về hoạt động của ứng dụng dành cho các
            nhà phát triển, vận hành ứng dụng và cung cấp các dịch vụ khác liên quan đến hoạt động
            của ứng dụng và việc sử dụng Internet.
          </TextInfo>
        </TextWrapper>
        <TextWrapper>
          <TextIndex>10.3</TextIndex>
          <TextInfo>
            Các Dịch Vụ của Chúng tôi cũng có thể sử dụng TrueID, một ứng dụng nhận diện gương mặt
            và Giấy tờ tùy thân (như chứng minh nhân dân, căn cước công dân, hộ chiếu) được cung cấp
            bởi Công Ty TNHH Phát Triển Phần Mềm VNG – một công ty thành viên của VNG (“TrueID”) để
            tăng cường việc nhận diện nhằm bảo mật Tài Khoản của Bạn. TrueID sẽ sử dụng camera trên
            thiết bị của Bạn để ghi nhận, chụp lại gương mặt và Giấy tờ tùy thân của Bạn, sử dụng
            thuật toán và AI (trí tuệ nhân tạo) để xử lý nhằm phát hiện các điểm bất thường (như
            gian lận, gương mặt và ảnh trên Giấy tờ tùy thân không chính xác …). TrueID sẽ hỗ trợ
            Chúng tôi phát hiện các điểm bất thường, đưa ra các khuyến cáo, gợi ý để xác thực Tài
            Khoản của Bạn. TrueID có thể gửi các thông này cho bên thứ ba để lưu trữ, xử lý trong
            trường hợp luật pháp có quy định như thế hoặc trường hợp bên thứ ba đó xử lý thông tin
            thay mặt TrueID. TrueID cũng có nghĩa vụ bảo mật Dữ Liệu Cá Nhân của Bạn mà TrueID lưu
            trữ, xử lý tương tự như nghĩa vụ bảo mật của VNG.
          </TextInfo>
        </TextWrapper>
        <TextWrapper>
          <TextIndex>10.4</TextIndex>
          <TextInfo>
            Chúng tôi, và các bên thứ ba, có thể cung cấp các bản tải về ứng dụng phần mềm cho Bạn
            sử dụng trên hoặc thông qua các Dịch Vụ. Những ứng dụng này có thể truy cập riêng, và
            cho phép một bên thứ ba xem, thông tin nhận dạng của Bạn, chẳng hạn như tên, ID người
            dùng của Bạn, địa chỉ IP của thiết bị của Bạn hoặc thông tin khác chẳng hạn như Trò Chơi
            mà Bạn đang chơi trong bất kỳ phiên truy cập cụ thể nào, và bất kỳ cookie, bộ SDK nào
            trước đây Bạn có thể đã cài đặt hoặc đã được cài đặt cho Bạn bởi một ứng dụng phần mềm
            hoặc trang web của bên thứ ba. Ngoài ra, các ứng dụng này có thể yêu cầu Bạn cung cấp
            thêm thông tin trực tiếp cho các bên thứ ba. Các sản phẩm hoặc dịch vụ của bên thứ ba
            được cung cấp thông qua các ứng dụng này không thuộc sở hữu hay quyền kiểm soát của VNG.
            Bạn nên đọc các điều khoản và các chính sách khác được công bố bởi các bên thứ ba đó
            trên trang web của họ hoặc nơi khác.
          </TextInfo>
        </TextWrapper>

        <TermsTitleSub>
          11. LOẠI TRỪ TRÁCH NHIỆM VỀ NGHĨA VỤ BẢO MẬT VÀ CÁC TRANG WEB BÊN THỨ BA
        </TermsTitleSub>
        <TextWrapper>
          <TextIndex>11.1</TextIndex>
          <TextInfo>
            VNG KHÔNG ĐẢM BẢO TÍNH BẢO MẬT ĐỐI VỚI DỮ LIỆU CÁ NHÂN VÀ/HOẶC THÔNG TIN KHÁC MÀ BẠN
            CUNG CẤP TRÊN CÁC TRANG WEB, ỨNG DỤNG CỦA BÊN THỨ BA. Chúng tôi thực hiện các biện pháp
            bảo mật khác nhau để duy trì sự an toàn của Dữ Liệu Cá Nhân của Bạn mà Chúng tôi lưu trữ
            hoặc kiểm soát. Dữ Liệu Cá Nhân của Bạn được lưu đằng sau các mạng bảo mật và chỉ có thể
            được truy cập bởi một số cá nhân giới hạn có quyền truy cập đặc biệt đến các hệ thống
            của Chúng tôi, và những cá nhân này đã được yêu cầu bảo mật dữ liệu cá nhân đó. Khi Bạn
            bắt đầu sử dụng Dịch Vụ hoặc truy cập Dữ Liệu Cá Nhân của Bạn, Chúng tôi đề nghị sử dụng
            một thiết bị bảo mật. Tất cả Dữ Liệu Cá Nhân Bạn cung cấp sẽ được mã hóa vào các cơ sở
            dữ liệu của Chúng tôi để chỉ có thể được truy cập như mô tả bên trên.
          </TextInfo>
        </TextWrapper>
        <TextWrapper>
          <TextIndex>11.2</TextIndex>
          <TextInfo>
            Nhằm cung cấp cho Bạn giá trị gia tăng, Chúng tôi có thể chọn các trang web bên thứ ba
            khác nhau để liên kết, và đóng khung bên trong Trang Web hoặc Trò Chơi. Chúng tôi cũng
            có thể tham gia các quan hệ cùng tiếp thị và các quan hệ khác để cung cấp dịch vụ thương
            mại điện tử, quảng cáo và các dịch vụ và tính năng khác cho khách truy cập. Những trang
            được liên kết này có các chính sách về quyền riêng tư cũng như các biện pháp bảo mật
            riêng và độc lập. Ngay cả khi bên thứ ba đó có liên kết với Chúng tôi, Chúng tôi cũng
            không kiểm soát các trang web được liên kết này, mỗi trang đó có các phương pháp bảo vệ
            quyền riêng tư và thu thập dữ liệu riêng biệt, độc lập với Chúng tôi. Dữ liệu thu thập
            bởi các đối tác cùng tiếp thị của Chúng tôi hoặc các trang web bên thứ ba (ngay cả khi
            được cung cấp trên hoặc thông qua Trang Web hoặc Trò Chơi của Chúng tôi) có thể không
            được nhận bởi Chúng tôi.
          </TextInfo>
        </TextWrapper>
        <TextWrapper>
          <TextIndex>11.3</TextIndex>
          <TextInfo>
            Do đó Chúng tôi không chịu trách nhiệm hay trách nhiệm pháp lý đối với nội dung, các
            biện pháp bảo mật (hoặc sự thiếu biện pháp bảo mật) và các hoạt động của các trang web,
            ứng dụng của Bên thứ ba. Chúng tôi bảo vệ tính toàn vẹn của Trang Web và Trò Chơi của
            Chúng tôi và các liên kết được đặt trên từng trang web đó, và do đó Chúng tôi hoan
            nghênh ý kiến phản hồi về các trang web được liên kết này (bao gồm, nhưng không giới hạn
            nếu một trang web cụ thể không hoạt động) theo thông tin liên hệ có thể được tìm thấy
            tại Mục 15 của Chính Sách này.
          </TextInfo>
        </TextWrapper>

        <TermsTitleSub>
          12. BẠN CÓ THỂ RÚT LẠI SỰ ĐỒNG Ý, CHỈNH SỬA HOẶC YÊU CẦU CHỈNH SỬA, CUNG CẤP HOẶC XÓA DỮ
          LIỆU CÁ NHÂN BẠN ĐÃ CUNG CẤP CHO CHÚNG TÔI BẰNG CÁCH NÀO?
        </TermsTitleSub>
        <TextWrapper>
          <TextIndex>12.1</TextIndex>
          <TextInfo>Rút Lại Sự Đồng Ý</TextInfo>
        </TextWrapper>
        <TextWrapperSub>
          <TextIndex>(a)</TextIndex>
          <TextInfo>
            Bạn có thể rút lại Sự Đồng Ý cho phép thu thập, sử dụng và/hoặc tiết lộ dữ liệu cá nhân
            của Bạn mà Chúng tôi đang lưu trữ hoặc kiểm soát bằng các cách thức được hướng dẫn tại
            trang thiết lập Tài Khoản của Chúng tôi. Chúng tôi sẽ xử lý yêu cầu theo các quy định
            Chính Sách Bảo Mật này, theo hướng dẫn, quy trình tại trang thiết lập Tài Khoản và các
            nghĩa vụ của Chúng tôi được quy định bởi pháp luật.
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(b)</TextIndex>
          <TextInfo>
            Bạn hiểu rõ rằng, việc Bạn rút lại Sự Đồng Ý sẽ không ảnh hưởng đến tính hợp pháp của
            việc Chúng tôi xử lý dữ liệu đã được Bạn đồng ý trước khi rút lại sự đồng ý.
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(c)</TextIndex>
          <TextInfo>
            Tuy nhiên, Bạn cần lưu ý rằng việc Bạn rút lại Sự Đồng Ý đồng nghĩa với việc Chúng tôi
            sẽ không thể tiếp tục cung cấp các Dịch Vụ cho Bạn, và Chúng tôi có thể cần phải chấm
            dứt quan hệ hiện tại với Bạn và/hoặc hợp đồng của Bạn với Chúng tôi mà không phải bồi
            thường cho bất kỳ tổn thất nào của Bạn.
          </TextInfo>
        </TextWrapperSub>
        <TextWrapper>
          <TextIndex>12.2</TextIndex>
          <TextInfo>Chỉnh sửa, Yêu Cầu Chỉnh Sửa, Cung Cấp hoặc Xóa, Hủy Dữ Liệu Cá Nhân</TextInfo>
        </TextWrapper>
        <TextWrapperSub>
          <TextIndex>(a)</TextIndex>
          <TextInfo>
            Khi Bạn đã đăng ký một Tài Khoản với Chúng tôi, Bạn có thể truy cập để xem, chỉnh sửa Dữ
            Liệu Cá Nhân của Bạn mà Chúng tôi đã thu thập, đang lưu trữ hoặc kiểm soát theo Sự Đồng
            Ý, thông qua hệ thống sẵn có của Chúng tôi. Nếu Bạn chưa đăng ký Tài Khoản với Chúng
            tôi, hoặc đối với một số Dữ Liệu Cá Nhân của Bạn mà Chúng tôi có quy trình riêng biệt để
            chỉnh sửa, Bạn có thể yêu cầu chỉnh sửa Dữ Liệu Cá Nhân của Bạn mà Chúng tôi đang lưu
            trữ hoặc kiểm soát bằng các cách thức được quy định tại trang
            <a href='https://support.vnggames.com/' target='_blank'>
              {' '}
              Hỗ trợ và Chăm sóc Khách hàng
            </a>{' '}
            của Chúng tôi. Chúng tôi sẽ xử lý yêu cầu theo các quy định Chính Sách Bảo Mật này, theo
            quy trình Hỗ trợ và Chăm sóc Khách hàng và các nghĩa vụ của Chúng tôi được quy định bởi
            pháp luật.
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(b)</TextIndex>
          <TextInfo>
            Bạn có thể theo dõi Dữ Liệu Cá Nhân của Bạn mà Chúng tôi đang lưu trữ hoặc kiểm soát
            bằng cách kiểm tra Dữ Liệu Cá Nhân được hiển thị tại trang thiết lập Tài Khoản của Bạn
            hoặc yêu cầu Chúng tôi cung cấp theo các cách thức khác theo quy trình Hỗ trợ và Chăm
            sóc Khách hàng của Chúng tôi. Người giám hộ hợp pháp của Bạn hoặc người được Bạn ủy
            quyền cũng có thể thay mặt Bạn thực hiện yêu cầu. Chúng tôi sẽ xử lý yêu cầu theo các
            quy định Chính Sách Bảo Mật này, theo quy trình Hỗ trợ và Chăm sóc Khách hàng và các
            nghĩa vụ của Chúng tôi được quy định bởi pháp luật.
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(c)</TextIndex>
          <TextInfo>
            Bạn có thể yêu cầu Chúng tôi xóa Dữ Liệu Cá Nhân của Bạn mà Chúng tôi đang lưu trữ hoặc
            kiểm soát khi Bạn (i) nhận thấy Dữ Liệu Cá Nhân của Bạn không còn cần thiết cho các Mục
            Đích đã có Sự Đồng Ý, (ii) rút lại Sự Đồng Ý theo Mục 12.1, (iii) phản đối Chúng tôi xử
            lý Dữ Liệu Cá Nhân của Bạn và Chúng tôi không có lý do chính đáng để tiếp tục xử lý Dữ
            Liệu Cá Nhân của Bạn, (iv) việc Chúng tôi xử lý Dữ Liệu Cá Nhân của Bạn không đúng với
            các Mục Đích đã có Sự Đồng Ý, và/hoặc các lý do khác theo quy định của pháp luật về bảo
            vệ dữ liệu cá nhân. Cá nhân Bạn có thể yêu cầu xóa Dữ Liệu Cá Nhân của Bạn mà Chúng tôi
            đang lưu trữ hoặc kiểm soát bằng các cách thức được hướng dẫn tại trang thiết lập Tài
            Khoản của Chúng tôi. Tuy nhiên, Bạn cần lưu ý rằng việc Bạn yêu cầu xóa Dữ Liệu Cá Nhân
            của Bạn đồng nghĩa với việc Chúng tôi sẽ không thể tiếp tục cung cấp các Dịch Vụ cho
            Bạn, và Chúng tôi có thể cần phải chấm dứt quan hệ hiện tại với Bạn và/hoặc hợp đồng của
            Bạn với Chúng tôi mà không phải bồi thường cho bất kỳ tổn thất nào của Bạn.
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(d)</TextIndex>
          <TextInfo>
            Bạn cần lưu ý rằng, trong mọi trường hợp, Chúng tôi sẽ cần có đủ thông tin từ Bạn để xác
            định danh tính của Bạn cũng như bản chất yêu cầu của Bạn để có thể giải quyết yêu cầu
            của Bạn theo các quy trình của Chúng tôi.
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(e)</TextIndex>
          <TextInfo>
            Chúng tôi có thể tính một khoản chi phí hợp lý cho Bạn để giải quyết và xử lý yêu cầu
            của Bạn. Nếu Chúng tôi có tính phí, Chúng tôi sẽ cung cấp cho Bạn chi phí và thời hạn
            cần phải thanh toán bằng văn bản hoặc email. Bạn vui lòng thanh toán và thanh toán đúng
            thời hạn các khoản phí (nếu có) nêu trên để việc đáp ứng hay giải quyết yêu cầu của Bạn
            được diễn ra kịp thời.
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(f)</TextIndex>
          <TextInfo>
            Chúng tôi có quyền từ chối cung cấp hoặc xóa Dữ Liệu Cá Nhân của Bạn trong các trường
            hợp cụ thể theo quy định của pháp luật.{' '}
          </TextInfo>
        </TextWrapperSub>

        <TermsTitleSub>
          13. QUY ĐỊNH BỔ SUNG CHO NGƯỜI DÙNG TẠI CÁC QUỐC GIA LIÊN MINH CHÂU ÂU (EU)
        </TermsTitleSub>
        <TextWrapper>
          <TextIndex>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</TextIndex>
          <TextInfo>
            Quy định tại Mục này chỉ áp dụng nếu Bạn đang sinh sống tại Liên Minh Châu Âu (“EU”).
            Các quy định này được ưu tiên áp dụng hơn trong trường hợp có sự khác biệt trong các quy
            định khác của Chính Sách Bảo Mật này.
          </TextInfo>
        </TextWrapper>

        <TextWrapper>
          <TextIndex>13.1</TextIndex>
          <TextInfo>Tính Linh Động</TextInfo>
        </TextWrapper>

        <TextWrapperSub>
          <TextIndex>(a)</TextIndex>
          <TextInfo>
            Bạn có quyền nhận từ Chúng tôi các Dữ Liệu Cá Nhân mà Bạn đã cung cấp trước đó cho Chúng
            tôi và các thông tin đã được xử lý dựa trên sự cho phép của Bạn theo định dạng được cấu
            trúc, có khả năng sử dụng thông thường và dạng tương tự trên môi trường điện tử, và
            quyền yêu cầu Chúng tôi chuyển các Dữ Liệu Cá Nhân này cho Bên khác.
          </TextInfo>
        </TextWrapperSub>

        <TextWrapperSub>
          <TextIndex>(b)</TextIndex>
          <TextInfo>
            Nếu Bạn muốn Chúng tôi chuyển giao Dữ Liệu Cá Nhân cho Bên thứ ba, Bạn vui lòng đảm bảo
            các thông tin chi tiết của Bên thứ ba này và lưu ý rằng Chúng tôi chỉ có thể thực hiện
            theo yêu cầu của Bạn khi các yêu cầu này khả thi về mặt kỹ thuật. Chúng tôi sẽ không
            chịu trách nhiệm bảo mật các Dữ Liệu Cá Nhân hoặc các Dữ Liệu thu được từ kết quả xử lý
            Dữ Liệu Cá Nhân của Bạn khi Bên thứ ba nhận được các dữ liệu này. Chúng tôi cũng có thể
            không cung cấp cho Bạn các Dữ Liệu Cá Nhân nhất định của Bạn nếu việc cung cấp này gây
            trở ngại cho các quyền khác (ví dụ khi cung cấp Dữ Liệu Cá Nhân mà Chúng tôi đã thu thập
            được có thể làm lộ thông tin của cá nhân khác).
          </TextInfo>
        </TextWrapperSub>

        <TextWrapper>
          <TextIndex>13.2</TextIndex>
          <TextInfo>Xóa Dữ Liệu</TextInfo>
        </TextWrapper>

        <TextWrapperSub>
          <TextIndex>(a)</TextIndex>
          <TextInfo>
            Bạn có thể yêu cầu Chúng tôi xóa Dữ Liệu Cá Nhân của Bạn mà Chúng tôi đã thu thập được
            trong các trường hợp sau đây:
          </TextInfo>
        </TextWrapperSub>

        <TextWrapperSub>
          <TextIndex></TextIndex>
          <TextWrapperSubL2>
            <TextInfo>
              - Bạn tin rằng các Dữ liệu Cá nhân này không còn cần thiết để Chúng tôi nắm giữ các Dữ
              Liệu Cá Nhân của Bạn;
              <br />- Chúng tôi xử lý Dữ Liệu Cá Nhân của Bạn mà Chúng tôi đã thu thập được dựa trên
              sự cho phép của Bạn và Bạn mong muốn rút lại sự cho phép này. Đồng thời, Chúng tôi
              không còn bất cứ lý do, mục đích nào khác để tiếp tục xử lý Dữ liệu Cá Nhân này;
              <br />- Chúng tôi đang xử lý Dữ Liệu Cá Nhân của Bạn căn cứ vào các lợi ích hợp pháp
              của Chúng tôi và Bạn là đối tượng của việc xử lý này. Bạn vui lòng cung cấp các thông
              tin chi tiết mà Bạn cho là hợp lý để Chúng tôi có thể xem xét liệu đó có phải là một
              quyền lợi quan trọng hơn quyền lợi của Chúng tôi để xử lý và lưu trữ các Dữ Liệu Cá
              Nhân này;
              <br />- Bạn không còn muốn Chúng tôi tiếp tục sử dụng Dữ Liệu Cá Nhân của Bạn đã được
              thu thập bởi Chúng tôi để gửi các quảng cáo, các đề xuất đặc biệt, các chương trình
              bán hàng và các chương trình may mắn; hoặc
              <br />- Bạn tin tưởng rằng Dữ Liệu Cá Nhân của Bạn mà Chúng tôi thu thập được được xử
              lý một cách trái luật bởi Chúng tôi.
            </TextInfo>
          </TextWrapperSubL2>
        </TextWrapperSub>

        <TextWrapperSub>
          <TextIndex>(b)</TextIndex>
          <TextInfo>
            Vui lòng cung cấp càng chi tiết càng tốt các lý do của Bạn để hỗ trợ Chúng tôi trong
            việc xác định liệu Bạn có cơ sở hợp lý để yêu cầu xóa dữ liệu hay không. Tuy nhiên,
            Chúng tôi có thể giữ lại Dữ Liệu Cá Nhân nếu có cơ sở hợp pháp theo quy định của pháp
            luật để Chúng tôi thực hiện việc này (ví dụ: thực hiện theo quy định của pháp luật của
            quốc gia sở tại – nơi công ty con, công ty thành viên của VNG đặt trụ sở chính, hoặc để
            bảo vệ Chúng tôi trước các yêu cầu pháp lý hoặc đảm bảo quyền tự do ngôn luận) tuy nhiên
            Chúng tôi sẽ thông báo cho Bạn biết về các trường hợp này. Bạn vui lòng lưu ý rằng sau
            khi xóa Dữ Liệu Cá Nhân, Chúng tôi có thể không có khả năng cung cấp dịch vụ như trước
            khi xóa bởi vì Chúng tôi sẽ không thể biết về các sở thích của Bạn.
          </TextInfo>
        </TextWrapperSub>

        <TextWrapperSub>
          <TextIndex>(c)</TextIndex>
          <TextInfo>
            Bạn cần lưu ý rằng, quy định tại một số quốc gia (như Việt Nam), vùng lãnh thổ, quy định
            bắt buộc Nhà cung cấp dịch vụ như VNG buộc phải lưu trữ thông tin, Dữ Liệu Cá Nhân của
            người sử dụng trong một thời hạn nhất định (có thể lên đến 02 (hai) năm) (“Thời Hạn Lưu
            Trữ”) kể từ thời điểm cuối cùng mà người sử dụng sử dụng dịch vụ; Bạn đồng ý rằng, trong
            trường hợp này thì các quy định của các quốc gia, vùng lãnh thổ nêu trên sẽ được ưu tiên
            áp dụng; do đó, yêu cầu xóa Dữ Liệu Cá Nhân của Bạn sẽ chỉ có thể thực hiện sau khi kết
            thúc Thời Hạn Lưu Trữ nêu trên.
          </TextInfo>
        </TextWrapperSub>

        <TextWrapper>
          <TextIndex>13.3</TextIndex>
          <TextInfo>Lưu Trữ Dữ Liệu Cá Nhân</TextInfo>
        </TextWrapper>
        <TextWrapper>
          <TextIndex>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</TextIndex>
          <TextInfo>
            Chúng tôi lưu trữ Dữ Liệu Cá Nhân của Bạn miễn là các Dữ Liệu Cá Nhân này cần thiết để
            Chúng tôi cung cấp dịch vụ hoặc sản phẩm cho Bạn, hoặc theo yêu cầu hoặc được cho phép
            theo quy định của pháp luật, chẳng hạn như các quy định của pháp luật về trò chơi điện
            tử trên mạng, Thuế, Kế toán. Nếu Bạn thực hiện yêu cầu xóa bỏ Dữ Liệu Cá Nhân của Bạn và
            Chúng tôi xác định rằng đây là yêu cầu có căn cứ hợp lý, Chúng tôi sẽ cố gắng xóa Dữ
            Liệu Cá Nhân của Bạn trong vòng 30 ngày kể từ ngày Bạn yêu cầu và kết thúc Thời Hạn Lưu
            Trữ nêu trên, hoặc thông báo cho Bạn nếu việc này cần thêm nhiều thời gian hơn.
          </TextInfo>
        </TextWrapper>

        <TextWrapper>
          <TextIndex>13.4</TextIndex>
          <TextInfo>
            Phản Đối Chống Lại Các Chương Trình Bán Hàng Dựa Trên Hồ Sơ Khách Hàng Và Khách Hàng Mục
            Tiêu
          </TextInfo>
        </TextWrapper>
        <TextWrapper>
          <TextIndex>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</TextIndex>
          <TextInfo>
            Tại bất kỳ thời điểm nào Bạn cũng có quyền phản đối việc xử lý Dữ Liệu Cá Nhân của Bạn
            để gửi cho Bạn các chương trình quảng cáo, các đề xuất đặc biệt và các tin nhắn bán
            hàng, bao gồm việc Chúng tôi xây dựng Hồ sơ khách hàng cho các mục đích này và Chúng tôi
            sẽ dừng việc xử lý các Dữ Liệu Cá Nhân của Bạn để phục vụ cho các mục đích này. Bạn vui
            lòng cung cấp cho Chúng tôi các thông tin chi tiết về lý do Bạn yêu cầu để hỗ trợ Chúng
            tôi xác định liệu Bạn có cơ sở hợp pháp để phản đối hay không. Tuy nhiên, Chúng tôi có
            thể giữ lại Dữ Liệu Cá Nhân của Bạn nếu có các cơ sở hợp pháp để Chúng tôi thực hiện
            việc này.
          </TextInfo>
        </TextWrapper>

        <TextWrapper>
          <TextIndex>13.5</TextIndex>
          <TextInfo>Hạn Chế Xử Lý Dữ Liệu Cá Nhân Chỉ Để Lưu Trữ</TextInfo>
        </TextWrapper>
        <TextWrapper>
          <TextIndex>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</TextIndex>
          <TextInfo>
            Bạn có quyền yêu cầu rằng Chúng tôi giới hạn việc xử lý Dữ Liệu Cá Nhân của Bạn mà Chúng
            tôi đã có chỉ cho mục đích lưu trữ, khi:
          </TextInfo>
        </TextWrapper>

        <TextWrapperSub>
          <TextIndex>(a)</TextIndex>
          <TextInfo>
            Bạn tin rằng Dữ liệu Cá nhân của Bạn là không chính xác, trong khoảng thời gian cần
            thiết để Chúng tôi xác minh liệu Dữ Liệu Cá Nhân của Bạn có chính xác hay không;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(b)</TextIndex>
          <TextInfo>
            Chúng tôi muốn xóa Dữ Liệu Cá Nhân của Bạn nhưng Bạn muốn Chúng tôi chỉ Lưu trữ;
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(c)</TextIndex>
          <TextInfo>
            Chúng tôi muốn xóa Dữ Liệu Cá Nhân của Bạn do nó không còn cần thiết cho mục đích của
            Chúng tôi nhưng Bạn yêu cầu lưu trữ để thiết lập, thực hiện hoặc chống lại các yêu cầu
            pháp lý; hoặc
          </TextInfo>
        </TextWrapperSub>
        <TextWrapperSub>
          <TextIndex>(d)</TextIndex>
          <TextInfo>
            Bạn phản đối Chúng tôi xử lý Dữ Liệu Cá Nhân của Bạn trên cơ sở các lợi ích hợp pháp của
            Chúng tôi và Bạn muốn Chúng tôi chấm dứt việc xử lý Dữ Liệu Cá Nhân khi Chúng tôi xác
            định liệu có quan trọng trong việc xử lý và giữ lại các Dữ Liệu Cá Nhân này.
          </TextInfo>
        </TextWrapperSub>

        <TextWrapper>
          <TextIndex>13.6</TextIndex>
          <TextInfo>Chuyển Dữ Liệu Cá Nhân Ra Ngoài EU</TextInfo>
        </TextWrapper>
        <TextWrapper>
          <TextIndex>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</TextIndex>
          <TextInfo>
            Dữ Liệu Cá Nhân của Bạn có thể được chuyển ra ngoài EU. Chúng tôi sẽ thực hiện các bước
            cần thiết và thích hợp để đảm bảo rằng Người nhận Dữ Liệu Cá Nhân của Bạn sẽ có trách
            nhiệm bảo mật và Chúng tôi thực hiện các biện pháp chẳng hạn như các quy định trong Hợp
            đồng thích hợp để đảm bảo những người nhận dữ liệu được chuyển giao bảo vệ và xử lý Dữ
            Liệu Cá Nhân của Bạn tuân thủ tất cả các luật về bảo vệ dữ liệu cá nhân.
          </TextInfo>
        </TextWrapper>

        <TextWrapper>
          <TextIndex>13.7</TextIndex>
          <TextInfo>Thông Tin Trẻ Em</TextInfo>
        </TextWrapper>
        <TextWrapper>
          <TextIndex>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</TextIndex>
          <TextInfo>
            Các Dịch Vụ này không dành cho trẻ em dưới 16 tuổi hiện đang sinh sống tại EU. Chúng tôi
            không cố tình thu thập hay lưu trữ bất kỳ dữ liệu cá nhân hay thông tin không nhận dạng
            cá nhân nào của bất kỳ ai dưới 16 tuổi đang sinh sống tại EU, bất kỳ phần nào của Trang
            Web, Trò Chơi của Chúng tôi hoặc các Dịch Vụ khác cũng không dành cho trẻ em dưới 16
            tuổi đang sinh sống tại EU. Chúng tôi sẽ khóa bất kỳ Tài Khoản nào chỉ được sử dụng bởi
            đối tượng trẻ em như vậy và sẽ gỡ và/hoặc xóa bất kỳ dữ liệu cá nhân nào mà Chúng tôi
            cho là đã được gửi bởi bất kỳ trẻ em nào dưới 16 tuổi đang sinh sống tại EU.
          </TextInfo>
        </TextWrapper>

        <TermsTitleSub>
          14. NƠI LƯU TRỮ DỮ LIỆU CÁ NHÂN VÀ CHUYỂN GIAO SANG QUỐC GIA KHÁC
        </TermsTitleSub>
        <TextWrapper>
          <TextIndex>14.1</TextIndex>
          <TextInfo>
            Đối với các Dịch Vụ được cung ứng cho thị trường Việt Nam, bởi VNG và/hoặc các công ty
            con, công ty liên kết, công ty thành viên của VNG có trụ sở chính đặt tại Việt Nam thì
            Dữ Liệu Cá Nhân của Bạn sẽ được lưu trữ tại Việt Nam theo đúng quy định của pháp luật
            Việt Nam.
          </TextInfo>
        </TextWrapper>
        <TextWrapper>
          <TextIndex>14.2</TextIndex>
          <TextInfo>
            Đối với các Dữ Liệu Cá Nhân của người dùng EU thì sẽ áp dụng theo quy định tại Mục 13
            của Chính Sách này.
          </TextInfo>
        </TextWrapper>

        <TermsTitleSub>15. THẮC MẮC, PHẢN HỒI VÀ LIÊN HỆ VỚI VNG</TermsTitleSub>
        <TextWrapper>
          <TextIndex>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</TextIndex>
          <TextInfo>
            Nếu Bạn có bất kỳ thắc mắc, phản hồi hoặc câu hỏi nào về Chính Sách này, vui lòng liên
            hệ với Chúng tôi theo địa chỉ email &nbsp;
            <a href='mailto:privacy@vng.com.vn'>privacy@vng.com.vn</a>
          </TextInfo>
        </TextWrapper>
        <UpdateInfo>
          <TextInfo>
            <i>Cập nhật tháng 6 năm 2023</i>
          </TextInfo>
        </UpdateInfo>
      </TermsContainer>
    </TermsWrapper>
  );
});
