import { ComponentType } from 'react';
import pure from 'recompose/pure';

//styles
import {
  CoverImage,
  CoverWrapper,
  FundItemContainer,
  FundItemWrapper,
  FundTitleText,
  FundTitleWrapper,
  InfoText,
  InfoWrapper,
  LogoImage,
  LogoWrapper,
} from '../FundItem.styles';

interface Props {
  lang: string;
}

export const VuADinh: ComponentType<Props> = pure(({ lang }) => {
  const renderContent = () => {
    if (lang === 'en') return renderContentEN();
    else return renderContentVI();
  };

  const renderContentVI = () => {
    return (
      <FundTitleWrapper>
        <LogoWrapper>
          <LogoImage src='https://uprace2.vcdn.vn/web/uprace/images/2023/orgs/hocbong_logo.jpg'></LogoImage>
        </LogoWrapper>
        <FundTitleText>Quỹ học bổng Vừ A Dính</FundTitleText>
        <InfoWrapper>
          <InfoText>
            Quỹ Học bổng Vừ A Dính là một trong ba tổ chức xã hội sẽ đồng hành cùng Dự án UpRace
            2023. Quỹ ra đời góp phần vào sự nghiệp bồi dưỡng và phát triển nguồn nhân lực trẻ, trao
            niềm tin và cơ hội để các đối tượng có hoàn cảnh khó khăn mạnh dạn tiến đến một tương
            lai tốt đẹp hơn nhờ giáo dục. Với lời kêu gọi "Mỗi bước chạy mở hành trình tri thức",
            Quỹ học bổng Vừ A Dính đặt mục tiêu cùng cộng đồng hỗ trợ 8.000 suất học bổng cho HSSV
            dân tộc thiểu trên 63 tỉnh thành và con em vùng biển đảo. <br />
            Tìm hiểu thêm về Vừ A Dính:{' '}
            <a href='https://quyvuadinh.vn/hocbong'> https://quyvuadinh.vn/hocbong</a>
          </InfoText>
        </InfoWrapper>
        <CoverWrapper>
          <CoverImage src='https://uprace2.vcdn.vn/web/uprace/images/2023/orgs/hocbong_thumb.jpg'></CoverImage>
        </CoverWrapper>
      </FundTitleWrapper>
    );
  };

  const renderContentEN = () => {
    return (
      <FundTitleWrapper>
        <LogoWrapper>
          <LogoImage src='https://uprace2.vcdn.vn/web/uprace/images/2023/orgs/hocbong_logo.jpg'></LogoImage>
        </LogoWrapper>
        <FundTitleText>Vu A Dinh Educational Grant Program</FundTitleText>
        <InfoWrapper>
          <InfoText>
            The Vu A Dinh Scholarship Fund is one of three community partners with the UpRace 2023
            project. Their mission is to nurture and develop young talents by providing educational
            opportunities for disadvantaged individuals. With the call to action "Every step opens
            the journey of knowledge," the fund aims to support 8,000 scholarships for ethnic
            minority students across all provinces and children in coastal and island regions.{' '}
            <br /> Learn more:
            <a href='https://quyvuadinh.vn/hocbong'> https://quyvuadinh.vn/hocbong</a>
          </InfoText>
        </InfoWrapper>
        <CoverWrapper>
          <CoverImage src='https://uprace2.vcdn.vn/web/uprace/images/2023/orgs/hocbong_thumb.jpg'></CoverImage>
        </CoverWrapper>
      </FundTitleWrapper>
    );
  };

  return (
    <FundItemWrapper>
      <FundItemContainer>{renderContent()}</FundItemContainer>
    </FundItemWrapper>
  );
});
