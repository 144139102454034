import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';

import { FEED_SPORT_TYPE, ILap } from '../types/IFeed';
import { formatNumber } from './FormatUtils';

export function buildDistance(sport: FEED_SPORT_TYPE, distance: number, isRound?: boolean) {
  let result;
  if (sport === FEED_SPORT_TYPE.SWIMMING) {
    result = Math.round(distance * 100) / 100;
    return formatNumber(result, isRound) + ' m';
  } else {
    result = Math.round((distance / 1000) * 100) / 100;
    return formatNumber(result, isRound) + ' km';
  }
}

//format duration from number seconds to mm:ss
export function convertShortDuration(totalSeconds: number, isZero?: boolean) {
  try {
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = Math.round(totalSeconds % 60);
    if (seconds == 60) {
      minutes += 1;
      seconds = 0;
    }
    if (isZero)
      return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
    else return minutes.toString() + ':' + seconds.toString().padStart(2, '0');
  } catch {
    return null;
  }
}

export function convertLongDuration(totalSeconds: number, isZero: boolean) {
  try {
    let hours = Math.floor(totalSeconds / 3600).toString();
    if (isZero) hours = hours.toString().padStart(2, '0');
    const minutes = moment.utc(moment.duration(totalSeconds, 's').asMilliseconds()).format('mm:ss');
    return hours + ':' + minutes;
  } catch {
    return null;
  }
}

function buildAvgPaceForAll(duration: number, distance: number) {
  const avgPace = duration > 0 && distance > 0 ? duration / (distance / 1000) : 0;
  return convertShortDuration(avgPace) + ' /km';
}

function buildAvgPaceSwimming(duration: number, distance: number) {
  const avgPace = duration > 0 && distance > 0 ? duration / (distance / 100) : 0;
  return convertShortDuration(avgPace) + ' /100m';
}

export function buildAvgSpeed(duration: number, distance: number) {
  const avgSpeed = duration > 0 && distance > 0 ? distance / 1000 / (duration / 3600) : null;
  return numeral(avgSpeed).format('0,0.[0]') + ' km/h';
}

export function buildAvgPace(sport: FEED_SPORT_TYPE, duration: number, distance: number) {
  if (duration > 0 && distance > 0) {
    if (sport == FEED_SPORT_TYPE.SWIMMING) return buildAvgPaceSwimming(duration, distance);
    else if (sport == FEED_SPORT_TYPE.CYCLING) return buildAvgSpeed(duration, distance);
    else return buildAvgPaceForAll(duration, distance);
  } else return '-:--/km';
}

//convert pace to speed for act cycling
export function convertPaceToSpeed(pace: number) {
  try {
    const value = (60 * 60) / pace;
    return formatNumber(value, true);
  } catch {
    return null;
  }
}

//calc laps for chart render
export function calcLapsChart(data: ILap[]): ILap[] {
  if (!data || data.length == 0) return [];

  const minLap: ILap = _.minBy(data, (item) => {
    return item.pace;
  })!;
  const minVal = minLap.pace; // min pace

  const result: ILap[] = data.map((item) => {
    if (item.pace == minVal)
      item.percent = 98; //100: fix issue 100% padding
    else {
      const percent = 100 / (item.pace / minVal);
      item.percent = percent > 98 ? 98 : percent;
    }
    return item;
  });

  return result;
}
