import { action, makeObservable, observable, runInAction } from 'mobx';

import { APIResponse, APIResponseList } from '../models/APIResponse';
import { Group, GroupList } from '../models/Group';
import GroupService from '../services/GroupService';
import { IGetGroupDetailRequest, IGetListGroupRequest, IGroup } from '../types/IGroup';
import RootStore from './RootStore';

class GroupStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  //declare variables
  @observable currentGroup: Group | null = null;
  @observable listGroupJoined: GroupList | null = null;
  @observable listGroupRequest: GroupList | null = null;

  //declare actions
  @action getGroupDetail = async (params: IGetGroupDetailRequest) => {
    const result = (await GroupService.getInstance().getGroupDetail(params)) as APIResponse<IGroup>;
    if (result && result.isSuccess()) {
      runInAction(() => {
        this.currentGroup = Group.fromJson(result.data!);
      });
    }
    return result;
  };

  @action getListGroupJoined = async (params: IGetListGroupRequest, loadMore: boolean) => {
    //init list when load first or null and calc paging
    if (!loadMore || !this.listGroupJoined)
      runInAction(() => {
        this.listGroupJoined = new GroupList();
      });
    else if (loadMore) {
      this.listGroupJoined.pageIndex += 1;
      this.listGroupJoined.isLoading = true; // determine if we're loading data is show process in UI
      params.data.from = params.data.size * this.listGroupJoined.pageIndex;
    }

    //request api service
    const result = (await GroupService.getInstance().getListGroup(params)) as APIResponseList<
      Array<IGroup>
    >;

    //check result and binding data if success
    if (result && result.isSuccess()) {
      runInAction(() => {
        if (this.listGroupJoined) {
          this.listGroupJoined.list = this.listGroupJoined.list.concat(
            GroupList.fromJson(result.data?.list!),
          )!;
          this.listGroupJoined.totalRecord = result.totalRecord();
          this.listGroupJoined.isLoading = false;
        }
      });
    }
  };

  @action getListGroupRequest = async (params: IGetListGroupRequest, loadMore: boolean) => {
    //init list when load first or null and calc paging
    if (!loadMore || !this.listGroupRequest)
      runInAction(() => {
        this.listGroupRequest = new GroupList();
      });
    else if (loadMore) {
      this.listGroupRequest.pageIndex += 1;
      this.listGroupRequest.isLoading = true; // determine if we're loading data is show process in UI
      params.data.from = params.data.size * this.listGroupRequest.pageIndex;
    }

    //request api service
    const result = (await GroupService.getInstance().getListGroup(params)) as APIResponseList<
      Array<IGroup>
    >;

    //check result and binding data if success
    if (result && result.isSuccess()) {
      runInAction(() => {
        if (this.listGroupRequest) {
          this.listGroupRequest.list = this.listGroupRequest.list.concat(
            GroupList.fromJson(result.data?.list!),
          )!;
          this.listGroupRequest.totalRecord = result.totalRecord();
          this.listGroupRequest.isLoading = false;
        }
      });
    }
  };
}
export default GroupStore;
