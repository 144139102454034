import styled from 'styled-components';

import { device } from '../../configs/MediaQuery';

export const TermsWrapper = styled.div`
  width: 100%;
  padding-top: 50px;
`;

export const TermsContainer = styled.div`
  @media ${device.mobileS} {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
  @media ${device.laptop} {
    width: 1100px;
    margin: 0 auto;
  }
`;

export const TermsHeader = styled.div``;

export const TermsTitle = styled.h1`
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  padding-bottom: 20px;
`;

export const TermsTitleSub = styled.h1`
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  margin-bottom: 20px;
`;

export const TextWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const TextWrapperSub = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 50px;
`;

export const TextWrapperSubL2 = styled.div`
  display: flex;
  margin-left: 50px;
`;

export const TextIndex = styled.p`
  margin: 0;
  margin-right: 10px;
`;

export const TextInfo = styled.p`
  margin: 0;
`;

export const UpdateInfo = styled.div`
  display: flex;
  justify-content: end;
  margin: 40px;
`;
