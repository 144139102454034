import numeral from 'numeral';

import { FEED_SPORT_TYPE, ILap } from '../types/IFeed';
import { ACCOUNT_ROLE } from '../types/IMember';
import { timeActivityFromSecond } from '../utils/DateUtils';
import { convertShortDuration } from '../utils/FeedUtils';
import { formatNumber } from '../utils/FormatUtils';
import {
  FEED_GPS_STATUS_INVALID,
  FEED_POWER_TYPE,
  FEED_PREDICTION_RUNNING,
  FEED_PREDICTION_SPORT,
  FEED_SOURCE_APP,
  IAcceleration,
  IDeltaAltitude,
  IDeltaAzimuth,
  IFeed,
  IFeedAnalysis,
  IFeedStats,
  IFeedUserStats,
  IGps,
  IPowerDaily,
  IPowerMonthly,
  IPowerWeekly,
  ISpeed,
  ISplit,
} from './../types/IFeed';
import { DataList } from './DataList';
import { Comment, Like, SocialBase } from './Social';

export class Feed extends SocialBase {
  feedId: number;
  title: string;
  description: string;
  photos: string[];
  totalLike: number;
  totalComment: number;
  listLike: Array<any>;
  listComments: Array<any>;
  userRole: ACCOUNT_ROLE;
  feedType: FEED_SPORT_TYPE;
  laps: ILap[];
  movingTime: any;
  duration: any;
  elevation: any;
  calo: any;
  bpm: any;
  spm: any;
  distance: number;
  mapImage: string;
  sourceApp: FEED_SOURCE_APP;

  constructor(
    feedId: number,
    memberId: number,
    name: string,
    avatar: string,
    title: string,
    description: string,
    createTime: number,
    photos: string[],
    totalLike: number,
    totalComment: number,
    listLike: Array<any>,
    listComments: Array<any>,
    userRole: ACCOUNT_ROLE,
    feedType: FEED_SPORT_TYPE,
    laps: ILap[],
    movingTime: any,
    duration: any,
    elevation: any,
    calo: any,
    bpm: any,
    spm: any,
    distance: number,
    mapImage: string,
    sourceApp: FEED_SOURCE_APP,
  ) {
    super();
    this.feedId = feedId;
    this.memberId = memberId;
    this.name = name;
    this.avatar = avatar;
    this.title = title;
    this.description = description;
    this.createTime = createTime;
    this.photos = photos;
    this.totalLike = totalLike;
    this.totalComment = totalComment;
    this.listLike = listLike;
    this.listComments = listComments;
    this.userRole = userRole;
    this.feedType = feedType;
    this.laps = laps;
    this.movingTime = movingTime;
    this.duration = duration;
    this.elevation = elevation;
    this.calo = calo;
    this.bpm = bpm;
    this.spm = spm;
    this.distance = distance;
    this.mapImage = mapImage;
    this.sourceApp = sourceApp;
  }

  static fromJson = (json: IFeed): Feed => {
    const feedData = new Feed(
      json.atid,
      json.uid,
      json.name,
      json.ava,
      json.title,
      json.desc,
      json.betm,
      json.photos,
      json.lkcnt,
      json.cmcnt,
      json.lkls,
      json.cmls,
      json.acrl,
      json.sport,
      json.laps,
      json.mvtm,
      json.dur,
      json.elev,
      json.calo,
      json.bpm,
      json.spm,
      json.dis,
      json.img,
      json.src,
    );
    //generate new array Like from json
    const listLike = new Array<Like>();
    const listComments = new Array<Comment>();
    json.lkls.forEach((item) => {
      listLike.push(Like.fromJson(item));
    });
    json.cmls.forEach((item) => {
      listComments.push(Comment.fromJson(item));
    });
    feedData.listLike = listLike;
    feedData.listComments = listComments;
    return feedData;
  };

  buildDistance(isRound?: boolean): string {
    let result;
    if (this.feedType === FEED_SPORT_TYPE.SWIMMING) {
      result = Math.round(this.distance * 100) / 100;
      return formatNumber(result, isRound) + ' m';
    } else {
      result = Math.round((this.distance / 1000) * 100) / 100;
      return formatNumber(result, isRound) + ' km';
    }
  }

  buildAvgPace(duration: number): string {
    if (duration > 0 && this.distance > 0) {
      if (this.feedType === FEED_SPORT_TYPE.SWIMMING) return this.buildAvgPaceSwimming(duration);
      else if (this.feedType === FEED_SPORT_TYPE.CYCLING)
        return this.buildAvgSpeedCycling(duration);
      else return this.buildAvgPaceOthers(duration);
    } else return '-:--/km';
  }

  //calc avg page for act swimming
  buildAvgPaceSwimming(duration: number): string {
    const avgPace = duration > 0 && this.distance > 0 ? duration / (this.distance / 100) : 0;
    return convertShortDuration(avgPace) + ' /100m';
  }

  //calc avg speed for act cycle
  buildAvgSpeedCycling(duration: number): string {
    const avgSpeed =
      duration > 0 && this.distance > 0 ? this.distance / 1000 / (duration / 3600) : null;
    return numeral(avgSpeed).format('0,0.[0]') + ' km/h';
  }

  //calc avg pace for others: running, walk...
  buildAvgPaceOthers(duration: number): string {
    const avgPace = duration > 0 && this.distance > 0 ? duration / (this.distance / 1000) : 0;
    return convertShortDuration(avgPace) + ' /km';
  }

  buildTimeActivity(): string {
    return timeActivityFromSecond(this.movingTime);
  }
}

export class ListFeed extends DataList<Feed> {
  static fromJson = (jsonArray: Array<IFeed>): Array<Feed> => {
    const data = new Array<Feed>();
    jsonArray.forEach((feed) => {
      data.push(Feed.fromJson(feed));
    });
    return data;
  };

  hasData(): boolean {
    return this.list.length > 0;
  }
}

export class FeedAnalysis {
  avgHeartRateInBeat: number;
  splits: ISplit[];
  avgMovingTimeInSecond: number;
  avgElevationGainInMeter: number;
  totalElevationLossInMeter: number;
  deltaAltitude: IDeltaAltitude[];
  totalCalo: number;
  totalDurationInSecond: number;
  speed: ISpeed[];
  uid: number;
  acceleration: IAcceleration[];
  totalDistanceInMeter: number;
  avgDistanceInMeter: number;
  hasCalo: boolean;
  deltaAzimuth: IDeltaAzimuth[];
  avgElevationLossInMeter: number;
  map: string;
  atid: number;
  totalDeltaAzimuthInDegree: number;
  hasAltitude: boolean;
  src: number;
  totalMovingTimeInSecond: number;
  gps: IGps[];
  totalElevationGainInMeter: number;
  avgCadence: number;
  hasCadence: boolean;
  avgAccelerationInKmH: number;
  avgSpeedInKmH: number;
  hasHeartRate: boolean;
  avgDurationInSecond: number;
  avgDeltaAltitudeInMeter: number;
  sport: number;
  avgDeltaAzimuthInDegree: number;
  predictionRunning: FEED_PREDICTION_RUNNING;
  predictionSport: FEED_PREDICTION_SPORT;
  predictionPower: FEED_POWER_TYPE;

  totalGpsPoint: number;
  totalDistanceInMeterBaseGps: number;
  totalDurationInSecondBaseGps: number;
  totalMovingTimeInSecondBaseGps: number;
  invalidGpsSts: number;
  invalidGps: number;
  invalidGpsDistanceInMeter: number;
  lagGps: number;
  lagGpsDistanceInMeter: number;
  labelSport: number;
  labelRunning: number;
  labelPower: number;

  thresholdDailyCalo: number;
  thresholdWeeklyCalo: number;
  thresholdMonthlyCalo: number;
  thresholdAvgMET: number;
  powerDaily: IPowerDaily[];
  powerWeekly: IPowerWeekly[];
  powerMonthly: IPowerMonthly[];

  constructor(
    avgHeartRateInBeat: number,
    splits: ISplit[],
    avgMovingTimeInSecond: number,
    avgElevationGainInMeter: number,
    totalElevationLossInMeter: number,
    deltaAltitude: IDeltaAltitude[],
    totalCalo: number,
    totalDurationInSecond: number,
    speed: ISpeed[],
    uid: number,
    acceleration: IAcceleration[],
    totalDistanceInMeter: number,
    avgDistanceInMeter: number,
    hasCalo: boolean,
    deltaAzimuth: IDeltaAzimuth[],
    avgElevationLossInMeter: number,
    map: string,
    atid: number,
    totalDeltaAzimuthInDegree: number,
    hasAltitude: boolean,
    src: number,
    totalMovingTimeInSecond: number,
    gps: IGps[],
    totalElevationGainInMeter: number,
    avgCadence: number,
    hasCadence: boolean,
    avgAccelerationInKmH: number,
    avgSpeedInKmH: number,
    hasHeartRate: boolean,
    avgDurationInSecond: number,
    avgDeltaAltitudeInMeter: number,
    sport: number,
    avgDeltaAzimuthInDegree: number,
    predictionRunning: FEED_PREDICTION_RUNNING,
    predictionSport: FEED_PREDICTION_SPORT,
    predictionPower: FEED_POWER_TYPE,
    totalGpsPoint: number,
    totalDistanceInMeterBaseGps: number,
    totalDurationInSecondBaseGps: number,
    totalMovingTimeInSecondBaseGps: number,
    invalidGpsSts: number,
    invalidGps: number,
    invalidGpsDistanceInMeter: number,
    lagGps: number,
    lagGpsDistanceInMeter: number,
    labelSport: number,
    labelRunning: number,
    labelPower: number,

    thresholdDailyCalo: number,
    thresholdWeeklyCalo: number,
    thresholdMonthlyCalo: number,
    thresholdAvgMET: number,
    powerDaily: IPowerDaily[],
    powerWeekly: IPowerWeekly[],
    powerMonthly: IPowerMonthly[],
  ) {
    this.avgHeartRateInBeat = avgHeartRateInBeat;
    this.splits = splits;
    this.avgMovingTimeInSecond = avgMovingTimeInSecond;
    this.avgElevationGainInMeter = avgElevationGainInMeter;
    this.totalElevationLossInMeter = totalElevationLossInMeter;
    this.deltaAltitude = deltaAltitude;
    this.totalCalo = totalCalo;
    this.totalDurationInSecond = totalDurationInSecond;
    this.speed = speed;
    this.uid = uid;
    this.acceleration = acceleration;
    this.totalDistanceInMeter = totalDistanceInMeter;
    this.avgDistanceInMeter = avgDistanceInMeter;
    this.hasCalo = hasCalo;
    this.deltaAzimuth = deltaAzimuth;
    this.avgElevationLossInMeter = avgElevationLossInMeter;
    this.map = map;
    this.atid = atid;
    this.totalDeltaAzimuthInDegree = totalDeltaAzimuthInDegree;
    this.hasAltitude = hasAltitude;
    this.src = src;
    this.totalMovingTimeInSecond = totalMovingTimeInSecond;
    this.gps = gps;
    this.totalElevationGainInMeter = totalElevationGainInMeter;
    this.avgCadence = avgCadence;
    this.hasCadence = hasCadence;
    this.avgAccelerationInKmH = avgAccelerationInKmH;
    this.avgSpeedInKmH = avgSpeedInKmH;
    this.hasHeartRate = hasHeartRate;
    this.avgDurationInSecond = avgDurationInSecond;
    this.avgDeltaAltitudeInMeter = avgDeltaAltitudeInMeter;
    this.sport = sport;
    this.avgDeltaAzimuthInDegree = avgDeltaAzimuthInDegree;
    this.predictionRunning = predictionRunning;
    this.predictionSport = predictionSport;
    this.predictionPower = predictionPower;
    this.totalGpsPoint = totalGpsPoint;
    this.totalDistanceInMeterBaseGps = totalDistanceInMeterBaseGps;
    this.totalDurationInSecondBaseGps = totalDurationInSecondBaseGps;
    this.totalMovingTimeInSecondBaseGps = totalMovingTimeInSecondBaseGps;
    this.invalidGpsSts = invalidGpsSts;
    this.invalidGps = invalidGps;
    this.invalidGpsDistanceInMeter = invalidGpsDistanceInMeter;
    this.lagGps = lagGps;
    this.lagGpsDistanceInMeter = lagGpsDistanceInMeter;
    this.labelSport = labelSport;
    this.labelRunning = labelRunning;
    this.labelPower = labelPower;

    this.thresholdDailyCalo = thresholdDailyCalo;
    this.thresholdWeeklyCalo = thresholdWeeklyCalo;
    this.thresholdMonthlyCalo = thresholdMonthlyCalo;
    this.thresholdAvgMET = thresholdAvgMET;
    this.powerDaily = powerDaily;
    this.powerWeekly = powerWeekly;
    this.powerMonthly = powerMonthly;
  }

  static fromJson = (json: IFeedAnalysis): FeedAnalysis => {
    const feedData = new FeedAnalysis(
      json.avgHeartRateInBeat,
      json.splits,
      json.avgMovingTimeInSecond,
      json.avgElevationGainInMeter,
      json.totalElevationLossInMeter,
      json.deltaAltitude,
      json.totalCalo,
      json.totalDurationInSecond,
      json.speed,
      json.uid,
      json.acceleration,
      json.totalDistanceInMeter,
      json.avgDistanceInMeter,
      json.hasCalo,
      json.deltaAzimuth,
      json.avgElevationLossInMeter,
      json.map,
      json.atid,
      json.totalDeltaAzimuthInDegree,
      json.hasAltitude,
      json.src,
      json.totalMovingTimeInSecond,
      json.gps,
      json.totalElevationGainInMeter,
      json.avgCadence,
      json.hasCadence,
      json.avgAccelerationInKmH,
      json.avgSpeedInKmH,
      json.hasHeartRate,
      json.avgDurationInSecond,
      json.avgDeltaAltitudeInMeter,
      json.sport,
      json.avgDeltaAzimuthInDegree,
      json.predictionRunning,
      json.predictionSport,
      json.predictionPower,
      json.totalGpsPoint,
      json.totalDistanceInMeterBaseGps,
      json.totalDurationInSecondBaseGps,
      json.totalMovingTimeInSecondBaseGps,
      json.invalidGpsSts,
      json.invalidGps,
      json.invalidGpsDistanceInMeter,
      json.lagGps,
      json.lagGpsDistanceInMeter,
      json.labelSport,
      json.labelRunning,
      json.labelPower,

      json.thresholdDailyCalo ?? 2500,
      json.thresholdWeeklyCalo ?? 10000,
      json.thresholdMonthlyCalo ?? 30000,
      json.thresholdAvgMET ?? 15.3,
      json.powerDaily,
      json.powerWeekly,
      json.powerMonthly,
    );
    return feedData;
  };

  getInvalidGPSStatus(): string {
    switch (this.invalidGpsSts) {
      case FEED_GPS_STATUS_INVALID.NONE:
        return 'Bình thường';
      case FEED_GPS_STATUS_INVALID.INVALID_TIME:
        return 'Có GPS point không hợp lệ về thời gian';
      case FEED_GPS_STATUS_INVALID.INVALID_LOCATION:
        return 'Có GPS point giả';
      default:
        return '';
    }
  }

  getPredictionRunningString(): string {
    switch (this.predictionRunning) {
      case FEED_PREDICTION_RUNNING.NONE:
        return '-';
      case FEED_PREDICTION_RUNNING.CLEAN:
        return 'Bình thường';
      case FEED_PREDICTION_RUNNING.FUZZY:
        return 'Không rõ ràng';
      case FEED_PREDICTION_RUNNING.CHEAT:
        return 'Bất thường';
      default:
        return '-';
    }
  }

  getPredictionSportString(): string {
    switch (this.predictionSport) {
      case FEED_PREDICTION_SPORT.UNKNOWN:
        return '-';
      case FEED_PREDICTION_SPORT.RUNNING:
        return 'Chạy bộ';
      case FEED_PREDICTION_SPORT.SWIMMING:
        return 'Bơi lội';
      case FEED_PREDICTION_SPORT.CYCLING:
        return 'Đạp xe';
      case FEED_PREDICTION_SPORT.WALKING:
        return 'Đi bộ';
      default:
        return '-';
    }
  }

  getPredictionPowerString(): string {
    switch (this.predictionPower) {
      case FEED_POWER_TYPE.UNKNOWN:
        return '-';
      case FEED_POWER_TYPE.NORMAL:
        return 'Bình thường';
      case FEED_POWER_TYPE.OVERLOAD:
        return 'Quá sức';
      default:
        return '-';
    }
  }

  setLabelRunning(labelRunning: number): void {
    this.labelRunning = labelRunning;
  }

  setLabelSport(labelSport: number): void {
    this.labelSport = labelSport;
  }

  setLabelPower(labelPower: number): void {
    this.labelPower = labelPower;
  }
}

export class FeedUserStats {
  current: IFeedStats;
  other: IFeedStats;

  constructor(current: IFeedStats, other: IFeedStats) {
    this.current = current;
    this.other = other;
  }

  static fromJson = (json: IFeedUserStats): FeedUserStats => {
    return new FeedUserStats(json.cur, json.other);
  };
}
