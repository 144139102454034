import { Collapse, Select, Spin } from 'antd';
import React, { useEffect } from 'react';
import { ComponentType, useTransition } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  ReferenceLine,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import pure from 'recompose/pure';

import { Spinner } from '../../../../components/Spinner/Spinner';
import { IGps } from '../../../../types/IFeed';
import { APP_BUTTON_TYPE } from '../../../../types/IGlobal';
import { convertTimestampToDate } from '../../../../utils/DateUtils';
import { convertShortDuration } from '../../../../utils/FeedUtils';
//styles
import {
  FilterContainer,
  LineChartContainer,
  SpinContainer,
  TooltipContainer,
  TooltipDetailContainer,
  TooltipDetailItemWrapper,
  TooltipDetailText,
  TooltipDetailVal,
  TooltipHeaderContainer,
  TooltipText,
  TooltipVal,
  ZoomButton,
} from './Charts.styles';

interface Props {
  data: IGps[];
}

const { Panel } = Collapse;
const { Option } = Select;

export const GpsStats: ComponentType<Props> = pure(({ data }) => {
  //check if data not exist...
  if (!data || data.length === 0) return null;

  const [isPending, startTransition] = useTransition();
  const [chartData, setChartData] = React.useState<IGps[]>([]);

  const [value, setValue] = React.useState('spd');
  const [zoom, setZoom] = React.useState(false);
  const labelYAxis = value === 'spd' ? ' Vận tốc (km/h)' : ' Pace';

  useEffect(() => {
    startTransition(() => setChartData(data));
  }, [data]);

  const onZoomChart = (event: any) => {
    event.stopPropagation();
    setZoom(!zoom);
  };

  const renderFilter = () => {
    return (
      <FilterContainer>
        <Select
          defaultValue='spd'
          style={{ width: 120 }}
          onClick={(event) => {
            event.stopPropagation();
          }}
          onChange={onChange}>
          <Option value='spd'>Speed</Option>
          <Option value='pace'>Pace</Option>
        </Select>
        <ZoomButton typeButton={APP_BUTTON_TYPE.LIGHT} onClick={onZoomChart}>
          {zoom ? 'Zoom in' : 'Zoom out'}
        </ZoomButton>
      </FilterContainer>
    );
  };

  const onChange = (value: string) => {
    setValue(value);
  };

  const formatXAxis = (value: any) => {
    return convertTimestampToDate(value, 'hh:mm:ss')!;
  };

  const formatYAxisPace = (value: any) => {
    return convertShortDuration(value, true)!;
  };

  const getName = (data: any) => {
    if (value === 'spd') {
      return data.spd > 60 ? 60 : data.spd;
    } else return data.pace > 1000 ? 1000 : data.pace;
  };

  const CustomTooltip = ({ active, payload, label }: TooltipProps<any, any>) => {
    if (active) {
      const data: IGps = payload?.[0].payload;
      return (
        <TooltipContainer>
          <TooltipHeaderContainer>
            {value === 'spd' ? (
              <>
                <TooltipText>Hoạt động với vận tốc</TooltipText>
                <TooltipVal>
                  {Math.round(payload?.[0].value) >= 60 ? '>' : ''}
                  {Math.round(payload?.[0].value)}
                </TooltipVal>
                <TooltipText>
                  km/h (Pace: {convertShortDuration(data.pace, true)}) tại thời gian
                </TooltipText>
                <TooltipVal>{convertTimestampToDate(label, 'DD-MM-YYYY hh:mm:ss A')}</TooltipVal>
              </>
            ) : (
              <>
                <TooltipText>Hoạt động với pace</TooltipText>
                <TooltipVal>{convertShortDuration(data.pace)}</TooltipVal>
                <TooltipText>(Vận tốc: {Math.round(data.spd)} km/h) tại thời gian</TooltipText>
                <TooltipVal>{convertTimestampToDate(label, 'hh:mm:ss')}</TooltipVal>
              </>
            )}
          </TooltipHeaderContainer>
          <TooltipDetailContainer>
            <TooltipDetailItemWrapper>
              <TooltipDetailText>Latitude (Vĩ độ)</TooltipDetailText>
              <TooltipDetailVal>{data.lat}</TooltipDetailVal>
            </TooltipDetailItemWrapper>
            <TooltipDetailItemWrapper>
              <TooltipDetailText>Longitude (Kinh độ)</TooltipDetailText>
              <TooltipDetailVal>{data.lon}</TooltipDetailVal>
            </TooltipDetailItemWrapper>
            <TooltipDetailItemWrapper>
              <TooltipDetailText>Altitude (Cao độ)</TooltipDetailText>
              <TooltipDetailVal>{Math.round(data.alt)}m</TooltipDetailVal>
            </TooltipDetailItemWrapper>

            <TooltipDetailItemWrapper>
              <TooltipDetailText>Quãng đường cách GPS point trước</TooltipDetailText>
              <TooltipDetailVal>{Math.round(data.dis)}m</TooltipDetailVal>
            </TooltipDetailItemWrapper>
            <TooltipDetailItemWrapper>
              <TooltipDetailText>Quãng đường cộng dồn tới GPS point hiện tại</TooltipDetailText>
              <TooltipDetailVal>{Math.round(data.tdis)}m</TooltipDetailVal>
            </TooltipDetailItemWrapper>

            <TooltipDetailItemWrapper>
              <TooltipDetailText>Thời gian diễn từ GPS point trước</TooltipDetailText>
              <TooltipDetailVal>{data.dur}s</TooltipDetailVal>
            </TooltipDetailItemWrapper>
            <TooltipDetailItemWrapper>
              <TooltipDetailText>
                Thời gian diễn ra cộng dồn tới GPS point hiện tại
              </TooltipDetailText>
              <TooltipDetailVal>{data.tdur}s</TooltipDetailVal>
            </TooltipDetailItemWrapper>

            <TooltipDetailItemWrapper>
              <TooltipDetailText>
                Thời gian có di chuyển từ GPS point trước - Tạm nghỉ
              </TooltipDetailText>
              <TooltipDetailVal>{data.mov}s</TooltipDetailVal>
            </TooltipDetailItemWrapper>
            <TooltipDetailItemWrapper>
              <TooltipDetailText>
                Thời gian có di chuyển cộng dồn tới GPS point hiện tại - Tạm nghỉ
              </TooltipDetailText>
              <TooltipDetailVal>{data.tmov}s</TooltipDetailVal>
            </TooltipDetailItemWrapper>

            <TooltipDetailItemWrapper>
              <TooltipDetailText>
                Hướng giữa GPS point hiện tại và GPS trước đó so với vector Bắc{' '}
              </TooltipDetailText>
              <TooltipDetailVal>{Math.round(data.azi)}độ</TooltipDetailVal>
            </TooltipDetailItemWrapper>
            <TooltipDetailItemWrapper>
              <TooltipDetailText>Biến thiên góc giữa 3 GPS point liên tiếp</TooltipDetailText>
              <TooltipDetailVal>{Math.round(data.dazi)}độ</TooltipDetailVal>
            </TooltipDetailItemWrapper>
          </TooltipDetailContainer>
        </TooltipContainer>
      );
    }
    return null;
  };

  const CustomTooltipAlt = ({ active, payload, label }: TooltipProps<any, any>) => {
    if (active) {
      const data: IGps = payload?.[0].payload;
      return (
        <TooltipContainer>
          <TooltipHeaderContainer>
            {value === 'spd' ? (
              <>
                <TooltipText>Hoạt động với vận tốc</TooltipText>
                <TooltipVal>
                  {Math.round(data.spd) >= 60 ? '>' : ''}
                  {Math.round(data.spd)}
                </TooltipVal>
                <TooltipText>
                  km/h (Pace: {convertShortDuration(data.pace, true)}) tại thời gian
                </TooltipText>
                <TooltipVal>{convertTimestampToDate(label, 'hh:mm:ss')}</TooltipVal>
              </>
            ) : (
              <>
                <TooltipText>Hoạt động với pace</TooltipText>
                <TooltipVal>{convertShortDuration(data.pace)}</TooltipVal>
                <TooltipText>(Vận tốc: {Math.round(data.spd)} km/h) tại thời gian</TooltipText>
                <TooltipVal>{convertTimestampToDate(label, 'hh:mm:ss')}</TooltipVal>
              </>
            )}
          </TooltipHeaderContainer>
          <TooltipDetailContainer>
            <TooltipDetailItemWrapper>
              <TooltipDetailText>Biến thiên góc giữa 3 GPS point liên tiếp</TooltipDetailText>
              <TooltipDetailVal>{Math.round(data.dazi)}độ</TooltipDetailVal>
            </TooltipDetailItemWrapper>
            <TooltipDetailItemWrapper>
              <TooltipDetailText>Cao độ</TooltipDetailText>
              <TooltipDetailVal>{Math.round(data.alt)}m</TooltipDetailVal>
            </TooltipDetailItemWrapper>
          </TooltipDetailContainer>
        </TooltipContainer>
      );
    }
    return null;
  };

  const CustomTooltipBpm = ({ active, payload, label }: TooltipProps<any, any>) => {
    if (active) {
      const data: IGps = payload?.[0].payload;
      return (
        <TooltipContainer>
          <TooltipHeaderContainer>
            {value === 'spd' ? (
              <>
                <TooltipText>Hoạt động với vận tốc</TooltipText>
                <TooltipVal>
                  {Math.round(data.spd) >= 60 ? '>' : ''}
                  {Math.round(data.spd)}
                </TooltipVal>
                <TooltipText>
                  km/h (Pace: {convertShortDuration(data.pace, true)}) tại thời gian
                </TooltipText>
                <TooltipVal>{convertTimestampToDate(label, 'hh:mm:ss')}</TooltipVal>
              </>
            ) : (
              <>
                <TooltipText>Hoạt động với pace</TooltipText>
                <TooltipVal>{convertShortDuration(data.pace)}</TooltipVal>
                <TooltipText>(Vận tốc: {Math.round(data.spd)} km/h) tại thời gian</TooltipText>
                <TooltipVal>{convertTimestampToDate(label, 'hh:mm:ss')}</TooltipVal>
              </>
            )}
          </TooltipHeaderContainer>
          <TooltipDetailContainer>
            <TooltipDetailItemWrapper>
              <TooltipDetailText>Biến thiên góc giữa 3 GPS point liên tiếp</TooltipDetailText>
              <TooltipDetailVal>{Math.round(data.dazi)}độ</TooltipDetailVal>
            </TooltipDetailItemWrapper>
            <TooltipDetailItemWrapper>
              <TooltipDetailText>Nhịp tim</TooltipDetailText>
              <TooltipDetailVal>{data.bpm} bpm</TooltipDetailVal>
            </TooltipDetailItemWrapper>
          </TooltipDetailContainer>
        </TooltipContainer>
      );
    }
    return null;
  };

  const CustomTooltipSpm = ({ active, payload, label }: TooltipProps<any, any>) => {
    if (active) {
      const data: IGps = payload?.[0].payload;
      return (
        <TooltipContainer>
          <TooltipHeaderContainer>
            {value === 'spd' ? (
              <>
                <TooltipText>Hoạt động với vận tốc</TooltipText>
                <TooltipVal>
                  {Math.round(data.spd) >= 60 ? '>' : ''}
                  {Math.round(data.spd)}
                </TooltipVal>
                <TooltipText>
                  km/h (Pace: {convertShortDuration(data.pace, true)}) tại thời gian
                </TooltipText>
                <TooltipVal>{convertTimestampToDate(label, 'hh:mm:ss')}</TooltipVal>
              </>
            ) : (
              <>
                <TooltipText>Hoạt động với pace</TooltipText>
                <TooltipVal>{convertShortDuration(data.pace)}</TooltipVal>
                <TooltipText>(Vận tốc: {Math.round(data.spd)} km/h) tại thời gian</TooltipText>
                <TooltipVal>{convertTimestampToDate(label, 'hh:mm:ss')}</TooltipVal>
              </>
            )}
          </TooltipHeaderContainer>
          <TooltipDetailContainer>
            <TooltipDetailItemWrapper>
              <TooltipDetailText>Biến thiên góc giữa 3 GPS point liên tiếp</TooltipDetailText>
              <TooltipDetailVal>{Math.round(data.dazi)}độ</TooltipDetailVal>
            </TooltipDetailItemWrapper>
            <TooltipDetailItemWrapper>
              <TooltipDetailText>Nhịp độ</TooltipDetailText>
              <TooltipDetailVal>{data.spm} spm</TooltipDetailVal>
            </TooltipDetailItemWrapper>
          </TooltipDetailContainer>
        </TooltipContainer>
      );
    }
    return null;
  };

  const chartWidth = data.length * (zoom ? 40 : 2) < 1024 ? 1024 : data.length * (zoom ? 40 : 2);

  const renderChart = () => {
    return (
      <>
        <AreaChart
          width={chartWidth}
          height={400}
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <defs>
            <linearGradient id='colorSpd' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor='#8884d8' stopOpacity={0.8} />
              <stop offset='95%' stopColor='#8884d8' stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='utm' tickFormatter={formatXAxis} angle={0}>
            <Label value='Thời gian' offset={-2} position='insideBottomLeft' />
          </XAxis>
          {value === 'spd' ? (
            <YAxis domain={[0, 60]} type='number'>
              <Label value={labelYAxis} offset={0} angle={-90} position='insideLeft' />
            </YAxis>
          ) : (
            <YAxis type='number' domain={[0, 1000]} tickFormatter={formatYAxisPace}>
              <Label value={labelYAxis} offset={0} angle={-90} position='insideLeft' />
            </YAxis>
          )}

          <Tooltip
            cursor={{
              stroke: '#8884d8',
              strokeWidth: 1,
              color: 'red',
            }}
            content={<CustomTooltip />}
          />
          <Area
            type='monotone'
            dataKey={getName}
            stroke='#8884d8'
            fillOpacity={1}
            activeDot={{ r: 8 }}
            fill='url(#colorSpd)'
          />
          <ReferenceLine y={value === 'spd' ? 15 : 240} stroke='red' ifOverflow='extendDomain' />
        </AreaChart>

        <AreaChart
          width={chartWidth}
          height={200}
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <defs>
            <linearGradient id='colorAlt' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor='#2a8df7' stopOpacity={0.8} />
              <stop offset='95%' stopColor='#2a8df7' stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='utm' tickFormatter={formatXAxis} angle={0}>
            <Label value='Thời gian' offset={-2} position='insideBottomLeft' />
          </XAxis>
          <YAxis dataKey='alt' type='number'>
            <Label value='Cao độ (m)' offset={0} angle={-90} position='insideLeft' />
          </YAxis>

          <Tooltip
            cursor={{
              stroke: '#2a8df7',
              strokeWidth: 1,
              color: 'red',
            }}
            content={<CustomTooltipAlt />}
          />
          <Area
            type='monotone'
            dataKey='alt'
            stroke='#2a8df7'
            fillOpacity={1}
            activeDot={{ r: 8 }}
            fill='url(#colorAlt)'
          />
        </AreaChart>

        <AreaChart
          width={chartWidth}
          height={200}
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <defs>
            <linearGradient id='colorBpm' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor='#F44336' stopOpacity={0.8} />
              <stop offset='95%' stopColor='#F44336' stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='utm' tickFormatter={formatXAxis} angle={0}>
            <Label value='Thời gian' offset={-2} position='insideBottomLeft' />
          </XAxis>
          <YAxis dataKey='bpm' type='number'>
            <Label value='Nhịp tim (bpm)' offset={10} angle={90} position='insideLeft' />
          </YAxis>

          <Tooltip
            cursor={{
              stroke: '#F44336',
              strokeWidth: 1,
              color: 'red',
            }}
            content={<CustomTooltipBpm />}
          />
          <ReferenceLine y={100} stroke='red' ifOverflow='extendDomain' />
          <Area
            type='monotone'
            dataKey='bpm'
            stroke='#F44336'
            fillOpacity={1}
            activeDot={{ r: 8 }}
            fill='url(#colorBpm)'
          />
        </AreaChart>

        <AreaChart
          width={chartWidth}
          height={200}
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <defs>
            <linearGradient id='colorSpm' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor='#66BB6A' stopOpacity={0.8} />
              <stop offset='95%' stopColor='#66BB6A' stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='utm' tickFormatter={formatXAxis} angle={0}>
            <Label value='Thời gian' offset={-2} position='insideBottomLeft' />
          </XAxis>
          <YAxis dataKey='spm' type='number'>
            <Label value='Nhịp độ (spm)' offset={10} angle={90} position='insideLeft' />
          </YAxis>

          <Tooltip
            cursor={{
              stroke: '#66BB6A',
              strokeWidth: 1,
              color: 'red',
            }}
            content={<CustomTooltipSpm />}
          />
          <ReferenceLine y={100} stroke='red' ifOverflow='extendDomain' />
          <Area
            type='monotone'
            dataKey='spm'
            stroke='#66BB6A'
            fillOpacity={1}
            activeDot={{ r: 8 }}
            fill='url(#colorSpm)'
          />
        </AreaChart>
      </>
    );
  };

  const renderSpin = () => {
    return (
      <SpinContainer>
        <Spin size='large' />
        <p>Đang tải dữ liệu...</p>
      </SpinContainer>
    );
  };

  return (
    <Collapse defaultActiveKey={1} style={{ marginBottom: 10 }}>
      <Panel header='Biểu đồ GPS' key={1} extra={renderFilter()}>
        <LineChartContainer>{isPending ? <Spinner /> : renderChart()}</LineChartContainer>
      </Panel>
    </Collapse>
  );
});
