import { action, makeObservable, observable, runInAction } from 'mobx';

import { APIResponseList } from '../models/APIResponse';
import SocialService from '../services/SocialService';
import { IGetListPostRequest, IPost } from '../types/ISocial';
import { ListPost } from './../models/Social';
import RootStore from './RootStore';

class SocialStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  //declare variables
  @observable listPost: ListPost | null = null;

  //declare action
  @action getListPost = async (params: IGetListPostRequest, loadMore: boolean) => {
    //init list when load first or null and calc paging
    if (!loadMore || !this.listPost)
      runInAction(() => {
        this.listPost = new ListPost();
      });
    else if (loadMore) {
      this.listPost.pageIndex += 1;
      this.listPost.isLoading = true; // determine if we're loading data is show process in UI
      params.data.from = params.data.size * this.listPost.pageIndex;
    }

    //request api service
    const result = (await SocialService.getInstance().getListPost(params)) as APIResponseList<
      Array<IPost>
    >;

    //check result and binding data if success
    if (result && result.isSuccess()) {
      runInAction(() => {
        if (this.listPost) {
          this.listPost.list = this.listPost.list?.concat(ListPost.fromJson(result.data?.list!))!;
          this.listPost.totalRecord = result.totalRecord();
          this.listPost.isLoading = false;
        }
      });
    }
    console.log('xxx', this.listPost);
  };
}
export default SocialStore;
