import { Row } from 'antd';
import styled from 'styled-components';

import { device } from '../../../../configs/MediaQuery';

export const BottomBlockContainer = styled(Row)`
  border: 1px solid #e7eaec;
  border-radius: 10px;
  margin-top: 16px;

  @media ${device.mobileS} {
    padding: 8px 12px;
  }
  @media ${device.tablet} {
    padding: 10px 12px;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.mobileS} {
    margin-right: 0px;
  }
  @media ${device.tablet} {
    margin-right: 15px;
  }
`;

export const LabelText = styled.p`
  margin: 0;
  line-height: 15px;
  color: #797979;
  font-size: 12px;
`;

export const ValueText = styled.p`
  margin: 0;
  margin-top: 3px;
  font-weight: 600;

  @media ${device.mobileS} {
    line-height: 21px;
    font-size: 16px;
  }
  @media ${device.tablet} {
    font-size: 16px;
    line-height: 24px;
  }
`;
