import { yupResolver } from '@hookform/resolvers/yup';
import { message, Modal } from 'antd';
import md5 from 'blueimp-md5';
import { observer } from 'mobx-react-lite';
import { ComponentType } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { pure } from 'recompose';

import { APP_CONFIG } from '../../configs/GlobalConst';
import { APIResponse } from '../../models/APIResponse';
import { useRootStore } from '../../providers/RootStoreProvider';
import { APP_BUTTON_TYPE } from '../../types/IGlobal';
import {
  IFacebookResponse,
  ILoginEmail,
  ILoginEmailRequest,
  ILoginFacebookRequest,
  ILoginGoogleRequest,
  LOGIN_TYPE,
} from '../../types/ILogin';
import { IResponseBase } from '../../types/ITypeBase';
import { LoginSchema } from '../../validation/LoginSchema';
//styles
import {
  ForgetPass,
  LoginButton,
  LoginButtonIcon,
  LoginButtonText,
  LoginButtonWrapper,
  LoginContainer,
  LoginEmailText,
  LoginInputText,
  LoginModal,
  LoginTitle,
  LoginWrapper,
  LogoApp,
  NoAccountLink,
  NoAccountText,
  NoAccountWrapper,
  TitleContainer,
} from './Login.styles';

interface Props {
  visible: boolean;
  onClose: () => void;
  onLoginSuccess: () => void;
}

export const Login: ComponentType<Props> = pure(
  observer(({ visible, onClose, onLoginSuccess }) => {
    const store = useRootStore();
    const { t } = useTranslation();

    const { login } = store.authStore;

    const {
      register,
      handleSubmit,
      reset,
      setValue,
      formState: { errors },
    } = useForm<ILoginEmail>({
      resolver: yupResolver(LoginSchema),
    });

    const onSubmit = async (data: ILoginEmail) => {
      const params: ILoginEmailRequest = {
        type: LOGIN_TYPE.EMAIL,
        email: data.email,
        password: md5(data.password),
        lang: 'vi',
      };
      const result: APIResponse<IResponseBase> = await login(params);
      if (result && result.isSuccess()) handleLoginSuccess();
      else handleLoginFailure();
    };

    const onResponseFacebook = async (response: IFacebookResponse) => {
      const params: ILoginFacebookRequest = {
        type: LOGIN_TYPE.FACEBOOK,
        email: response.id,
        password: response.accessToken,
        lang: 'vi',
      };
      const result: APIResponse<IResponseBase> = await login(params);
      if (result && result.isSuccess()) handleLoginSuccess();
      else handleLoginFailure();
    };

    const onFailureGoogle = (response: any) => {
      console.log(response);
    };

    const onResponseGoogle = async (response: any) => {
      const params: ILoginGoogleRequest = {
        type: LOGIN_TYPE.GOOGLE,
        email: response.profileObj.email,
        password: response.accessToken,
        lang: 'vi',
      };
      const result: APIResponse<IResponseBase> = await login(params);
      if (result && result.isSuccess()) handleLoginSuccess();
      else handleLoginFailure();
    };

    const handleLoginSuccess = () => {
      message.success('Đăng nhập hệ thống thành công !');
      onLoginSuccess();
    };

    const handleLoginFailure = () => {
      Modal.error({
        title: 'Đăng nhập không thành công',
        content: 'Vui lòng kiểm tra lại thông tin và thử lại',
        centered: true,
      });
    };

    return (
      <LoginWrapper>
        <LoginContainer>
          <LoginModal centered onCancel={onClose} visible={visible} footer={null}>
            <TitleContainer>
              <LoginTitle>Đăng nhập</LoginTitle>
              <LogoApp src={`${APP_CONFIG.STATIC_URL}/logo/brand-blue.svg`}></LogoApp>
            </TitleContainer>

            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}
              buttonText='Login'
              render={(renderProps) => (
                <LoginButtonWrapper onClick={renderProps.onClick}>
                  <LoginButtonIcon
                    src={`${APP_CONFIG.STATIC_URL}/images/icon/ic_google.svg`}></LoginButtonIcon>
                  <LoginButtonText>Tiếp tục với google</LoginButtonText>
                </LoginButtonWrapper>
              )}
              onSuccess={onResponseGoogle}
              onFailure={onFailureGoogle}
              cookiePolicy={'single_host_origin'}
            />

            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APPID!}
              callback={onResponseFacebook}
              fields='email, name, picture'
              autoLoad={false}
              disableMobileRedirect={true}
              scope='public_profile,email'
              render={(renderProps) => (
                <LoginButtonWrapper onClick={renderProps.onClick}>
                  <LoginButtonIcon
                    src={`${APP_CONFIG.STATIC_URL}/images/icon/ic_facebook.svg`}></LoginButtonIcon>
                  <LoginButtonText>Tiếp tục với facebook</LoginButtonText>
                </LoginButtonWrapper>
              )}
            />
            <LoginEmailText>hoặc đăng nhập với email</LoginEmailText>

            <form onSubmit={handleSubmit(onSubmit)}>
              <LoginInputText
                {...register('email')}
                isError={errors.email ? 'error' : ''}
                placeHolder='Email'></LoginInputText>
              <LoginInputText
                {...register('password')}
                type='password'
                isError={errors.password ? 'error' : ''}
                placeHolder='Mật khẩu'></LoginInputText>

              <LoginButton typeButton={APP_BUTTON_TYPE.PRIMARY} type='submit'>
                Đăng nhập
              </LoginButton>
            </form>

            <ForgetPass href='https://event.uprace.org/forgot-pass' target='_blank'>
              Quên mật khẩu?
            </ForgetPass>
            <NoAccountWrapper>
              <NoAccountText>Bạn chưa có tài khoản</NoAccountText>
              <NoAccountLink href='https://event.uprace.org/register' target='_blank'>
                Đăng ký
              </NoAccountLink>
            </NoAccountWrapper>
          </LoginModal>
        </LoginContainer>
      </LoginWrapper>
    );
  }),
);
