import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import pure from 'recompose/pure';

import Team from '../../../../models/Team';
import { formatNumber } from '../../../../utils/FormatUtils';
//styles
import {
  ArrowDetail,
  AvatarContainer,
  AvatarTeam,
  InfoContainer,
  LabelText,
  TeamItemWrapper,
  ValueText,
} from './TeamItem.styles';

interface Props {
  teamData: Team;
}

export const TeamItem: ComponentType<Props> = pure(({ teamData }) => {
  const { t } = useTranslation();

  return (
    <TeamItemWrapper>
      <AvatarContainer>
        <AvatarTeam src={teamData.avatar} />
      </AvatarContainer>
      <InfoContainer>
        <ValueText>{teamData.name}</ValueText>
        <LabelText>
          {t(teamData.getCompanyTypeString())} - {formatNumber(teamData.totalMember)} VĐV
        </LabelText>
      </InfoContainer>
      <ArrowDetail />
    </TeamItemWrapper>
  );
});
