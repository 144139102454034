// login type user
export enum LOGIN_TYPE {
  FACEBOOK,
  GOOGLE,
  STRAVA,
  EMAIL,
  GARMIN,
  APPLE,
}
export interface ILoginResponse {
  code: number;
  accesstoken: string;
  userId: number;
}
export interface ILoginEmail {
  email: string;
  password: string;
  type: LOGIN_TYPE;
}

export interface ILoginToken {
  id: number;
  name: string;
  email: string;
}

export interface IFacebookResponse {
  id: string;
  email: string;
  accessToken: string;
  userID: string;
  name: string;
}

export interface IGoogleProfile {
  googleId: string;
  email: string;
  name: string;
  imageUrl: string;
}

export interface IGoogleResponse {
  accessToken: string;
  profileObj: IGoogleProfile;
}

export interface ILoginRequestBase {
  type: LOGIN_TYPE;
  email: string;
  password: string;
  lang: string;
}

export interface ILoginEmailRequest extends ILoginRequestBase {}
export interface ILoginGoogleRequest extends ILoginRequestBase {}
export interface ILoginFacebookRequest extends ILoginRequestBase {}
