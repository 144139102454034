import { observer } from 'mobx-react-lite';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

import { Feed } from '../../../../models/Feed';
import { convertLongDuration } from '../../../../utils/FeedUtils';
//styles
import {
  ColContainer,
  ColSubContainer,
  LabelText,
  RowSubContainer,
  TopBlockContainer,
  ValueText,
} from './TopBlock.styles';

interface Props {
  feedData: Feed;
}

export const TopBlock: ComponentType<Props> = observer(({ feedData }) => {
  const { t } = useTranslation();

  if (feedData)
    return (
      <TopBlockContainer>
        <ColContainer xl={7} md={7} sm={24} xs={24}>
          <LabelText>{t('feedDetail:feedInfo.distance')}</LabelText>
          <ValueText>{feedData.buildDistance()}</ValueText>
        </ColContainer>

        <ColSubContainer xl={7} md={7} sm={24} xs={24}>
          <RowSubContainer>
            <LabelText>{t('feedDetail:feedInfo.avgPage')}</LabelText>
            <ValueText>{feedData.buildAvgPace(feedData.duration)}</ValueText>
          </RowSubContainer>
          <RowSubContainer>
            <LabelText>{t('feedDetail:feedInfo.movingTime')}</LabelText>
            <ValueText>{convertLongDuration(feedData.movingTime, true)}</ValueText>
          </RowSubContainer>
        </ColSubContainer>

        <ColSubContainer xl={10} md={10} sm={24} xs={24}>
          <RowSubContainer>
            <LabelText>{t('feedDetail:feedInfo.totalTime')}</LabelText>
            <ValueText>{convertLongDuration(feedData.duration, true)}</ValueText>
          </RowSubContainer>
          <RowSubContainer>
            <LabelText>{t('feedDetail:feedInfo.avgMovingPage')}</LabelText>
            <ValueText>{feedData.buildAvgPace(feedData.movingTime)}</ValueText>
          </RowSubContainer>
        </ColSubContainer>
      </TopBlockContainer>
    );
  else return null;
});
