import { Col } from 'antd';
import { observer } from 'mobx-react-lite';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

import { Feed } from '../../../../models/Feed';
import { FEED_SPORT_TYPE } from '../../../../types/IFeed';
//styles
import { BottomBlockContainer, LabelText, RowContainer, ValueText } from './BottomBlock.styles';

interface Props {
  feedData: Feed;
}

export const BottomBlock: ComponentType<Props> = observer(({ feedData }) => {
  const { t } = useTranslation();

  const renderBlockSwimming = () => {
    return (
      <>
        <Col xl={12} md={12} sm={24} xs={24}>
          <RowContainer>
            <LabelText>{t('feedDetail:feedInfo.calories')}</LabelText>
            <ValueText>{feedData.calo} cal</ValueText>
          </RowContainer>
        </Col>

        <Col xl={12} md={12} sm={24} xs={24}>
          <RowContainer>
            <LabelText>{t('feedDetail:feedInfo.strokePerMin')}</LabelText>
            <ValueText>{feedData.spm} spm</ValueText>
          </RowContainer>
        </Col>
      </>
    );
  };

  const renderBlockCycling = () => {
    return (
      <>
        <Col xl={12} md={12} sm={24} xs={24}>
          <RowContainer>
            <LabelText>{t('feedDetail:feedInfo.elevation')}</LabelText>
            <ValueText>{feedData.elevation} m</ValueText>
          </RowContainer>
          <RowContainer>
            <LabelText>{t('feedDetail:feedInfo.avgHeart')}</LabelText>
            <ValueText>{feedData.bpm} bpm</ValueText>
          </RowContainer>
        </Col>

        <Col xl={12} md={12} sm={24} xs={24}>
          <RowContainer>
            <LabelText>{t('feedDetail:feedInfo.calories')}</LabelText>
            <ValueText>{feedData.calo} cal</ValueText>
          </RowContainer>
        </Col>
      </>
    );
  };

  const renderBlockWalking = () => {
    return <></>;
  };

  const renderBlockRunning = () => {
    return (
      <>
        <Col xl={12} md={12} sm={24} xs={24}>
          <RowContainer>
            <LabelText>{t('feedDetail:feedInfo.elevation')}</LabelText>
            <ValueText>{feedData.elevation} m</ValueText>
          </RowContainer>
          <RowContainer>
            <LabelText>{t('feedDetail:feedInfo.calories')}</LabelText>
            <ValueText>{feedData.calo} cal</ValueText>
          </RowContainer>
        </Col>

        <Col xl={12} md={12} sm={24} xs={24}>
          <RowContainer>
            <LabelText>{t('feedDetail:feedInfo.avgHeart')}</LabelText>
            <ValueText>{feedData.bpm} bpm</ValueText>
          </RowContainer>
          <RowContainer>
            <LabelText>{t('feedDetail:feedInfo.avgCadence')}</LabelText>
            <ValueText>{feedData.spm} spm</ValueText>
          </RowContainer>
        </Col>
      </>
    );
  };

  const renderContent = () => {
    if (feedData.feedType === FEED_SPORT_TYPE.SWIMMING) return renderBlockSwimming();
    else if (feedData.feedType === FEED_SPORT_TYPE.RUNNING) return renderBlockRunning();
    else if (feedData.feedType === FEED_SPORT_TYPE.CYCLING) return renderBlockCycling();
    else if (feedData.feedType === FEED_SPORT_TYPE.WALKING) return renderBlockWalking();
  };

  return <BottomBlockContainer>{renderContent()}</BottomBlockContainer>;
});
