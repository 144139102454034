import { LOCAL_KEY } from '../configs/AppKey';
import { ILoginResponse } from '../types/ILogin';

export const AuthHeader = (): ILoginResponse | null => {
  const userStr = localStorage.getItem(LOCAL_KEY.CURRENT_USER);
  if (userStr) {
    try {
      const objUser: ILoginResponse = JSON.parse(userStr);
      return objUser;
    } catch (err) {
      return null;
    }
  }
  return null;
};
