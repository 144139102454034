import { Collapse, Select } from 'antd';
import React from 'react';
import { ComponentType, useEffect, useTransition } from 'react';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ReferenceLine,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import pure from 'recompose/pure';

import { Spinner } from '../../../../components/Spinner/Spinner';
import { ISplit } from '../../../../types/IFeed';
import { APP_BUTTON_TYPE } from '../../../../types/IGlobal';
import { convertShortDuration } from '../../../../utils/FeedUtils';
import { formatNumber } from '../../../../utils/FormatUtils';
//styles
import {
  BarChartContainer,
  FilterContainer,
  TooltipContainer,
  TooltipDetailContainer,
  TooltipDetailItemWrapper,
  TooltipDetailText,
  TooltipDetailVal,
  TooltipHeaderContainer,
  TooltipText,
  TooltipVal,
  ZoomButton,
} from './Charts.styles';

interface Props {
  data: ISplit[];
}

const { Panel } = Collapse;
const { Option } = Select;

export const SplitStats: ComponentType<Props> = pure(({ data }) => {
  //check if data not exist...
  if (!data || data.length === 0) return null;

  const [isPending, startTransition] = useTransition();
  const [chartData, setChartData] = React.useState<ISplit[]>([]);

  const [value, setValue] = React.useState('spd');
  const [zoom, setZoom] = React.useState(false);
  const labelYAxis = value === 'spd' ? ' Vận tốc (km/h)' : ' Pace';

  useEffect(() => {
    startTransition(() => setChartData(data));
  }, [data]);

  const renderFilter = () => {
    return (
      <FilterContainer>
        <Select
          defaultValue='spd'
          style={{ width: 120 }}
          onClick={(event) => {
            event.stopPropagation();
          }}
          onChange={onChange}>
          <Option value='spd'>Speed</Option>
          <Option value='pace'>Pace</Option>
        </Select>
        <ZoomButton typeButton={APP_BUTTON_TYPE.LIGHT} onClick={onZoomChart}>
          {zoom ? 'Zoom in' : 'Zoom out'}
        </ZoomButton>
      </FilterContainer>
    );
  };

  const onZoomChart = (event: any) => {
    event.stopPropagation();
    setZoom(!zoom);
  };

  const onChange = (value: string) => {
    setValue(value);
  };

  const CustomTooltip = ({ active, payload, label }: TooltipProps<any, any>) => {
    if (active) {
      const data: ISplit = payload?.[0].payload;
      return (
        <TooltipContainer>
          <TooltipHeaderContainer>
            {value === 'spd' ? (
              <>
                <TooltipText>Hoạt động với vận tốc</TooltipText>
                <TooltipVal>
                  {Math.round(payload?.[0].value) >= 60 ? '>' : ''}
                  {Math.round(payload?.[0].value)}
                </TooltipVal>
                <TooltipText>
                  km/h (Pace: {convertShortDuration(data.pace, true)}) tại quãng thứ
                </TooltipText>
                <TooltipVal>{label}</TooltipVal>
              </>
            ) : (
              <>
                <TooltipText>Hoạt động với pace</TooltipText>
                <TooltipVal>{convertShortDuration(payload?.[0].value, true)}</TooltipVal>
                <TooltipText>(Vận tốc: {Math.round(data.spd)} km/h) tại quãng thứ</TooltipText>
                <TooltipVal>{label}</TooltipVal>
              </>
            )}
          </TooltipHeaderContainer>
          <TooltipDetailContainer>
            <TooltipDetailItemWrapper>
              <TooltipDetailText>Quãng đường của split, xê dịch gần 100m</TooltipDetailText>
              <TooltipDetailVal>{Math.round(data.dis)}m</TooltipDetailVal>
            </TooltipDetailItemWrapper>
            <TooltipDetailItemWrapper>
              <TooltipDetailText>Quãng đường cộng dồn tới split hiện tại</TooltipDetailText>
              <TooltipDetailVal>{formatNumber(data.tdis, true)}m</TooltipDetailVal>
            </TooltipDetailItemWrapper>

            <TooltipDetailItemWrapper>
              <TooltipDetailText>Thời gian diễn ra của split</TooltipDetailText>
              <TooltipDetailVal>{Math.round(data.dur)}s</TooltipDetailVal>
            </TooltipDetailItemWrapper>
            <TooltipDetailItemWrapper>
              <TooltipDetailText>Thời gian diễn ra cộng dồn tới split hiện tại</TooltipDetailText>
              <TooltipDetailVal>{Math.round(data.tdur)}s</TooltipDetailVal>
            </TooltipDetailItemWrapper>

            <TooltipDetailItemWrapper>
              <TooltipDetailText>Thời gian có di chuyển của split</TooltipDetailText>
              <TooltipDetailVal>{Math.round(data.mov)}s</TooltipDetailVal>
            </TooltipDetailItemWrapper>
            <TooltipDetailItemWrapper>
              <TooltipDetailText>
                Thời gian có di chuyển công dồn tới split hiện tại
              </TooltipDetailText>
              <TooltipDetailVal>{Math.round(data.tmov)}s</TooltipDetailVal>
            </TooltipDetailItemWrapper>

            <TooltipDetailItemWrapper>
              <TooltipDetailText>Tổng quãng tăng độ cao của split (lên dốc)</TooltipDetailText>
              <TooltipDetailVal>{Math.round(data.eleg)}m</TooltipDetailVal>
            </TooltipDetailItemWrapper>

            <TooltipDetailItemWrapper>
              <TooltipDetailText>Tổng quãng giảm độ cao của split (xuống dốc)</TooltipDetailText>
              <TooltipDetailVal>{Math.round(data.elel)}m</TooltipDetailVal>
            </TooltipDetailItemWrapper>
            <TooltipDetailItemWrapper>
              <TooltipDetailText>Tổng biến thiên hướng của split</TooltipDetailText>
              <TooltipDetailVal>{Math.round(data.dazi)}độ</TooltipDetailVal>
            </TooltipDetailItemWrapper>
          </TooltipDetailContainer>
        </TooltipContainer>
      );
    }
    return null;
  };

  const CustomTooltipBpm = ({ active, payload, label }: TooltipProps<any, any>) => {
    if (active) {
      const data: ISplit = payload?.[0].payload;
      return (
        <TooltipContainer>
          <TooltipHeaderContainer>
            {value === 'spd' ? (
              <>
                <TooltipText>Hoạt động với vận tốc</TooltipText>
                <TooltipVal>
                  {Math.round(data.spd) >= 60 ? '>' : ''}
                  {Math.round(data.spd)}
                </TooltipVal>
                <TooltipText>
                  km/h (Pace: {convertShortDuration(data.pace, true)}) tại quãng thứ
                </TooltipText>
                <TooltipVal>{label}</TooltipVal>
              </>
            ) : (
              <>
                <TooltipText>Hoạt động với pace</TooltipText>
                <TooltipVal>{convertShortDuration(data.pace)}</TooltipVal>
                <TooltipText>(Vận tốc: {Math.round(data.spd)} km/h) tại quãng thứ</TooltipText>
                <TooltipVal>{label}</TooltipVal>
              </>
            )}
          </TooltipHeaderContainer>
          <TooltipDetailContainer>
            <TooltipDetailItemWrapper>
              <TooltipDetailText>Nhịp tim</TooltipDetailText>
              <TooltipDetailVal>{data.bpm} bpm</TooltipDetailVal>
            </TooltipDetailItemWrapper>
          </TooltipDetailContainer>
        </TooltipContainer>
      );
    }
    return null;
  };

  const CustomTooltipSpm = ({ active, payload, label }: TooltipProps<any, any>) => {
    if (active) {
      const data: ISplit = payload?.[0].payload;
      return (
        <TooltipContainer>
          <TooltipHeaderContainer>
            {value === 'spd' ? (
              <>
                <TooltipText>Hoạt động với vận tốc</TooltipText>
                <TooltipVal>
                  {Math.round(data.spd) >= 60 ? '>' : ''}
                  {Math.round(data.spd)}
                </TooltipVal>
                <TooltipText>
                  km/h (Pace: {convertShortDuration(data.pace, true)}) tại quãng thứ
                </TooltipText>
                <TooltipVal>{label}</TooltipVal>
              </>
            ) : (
              <>
                <TooltipText>Hoạt động với pace</TooltipText>
                <TooltipVal>{convertShortDuration(data.pace)}</TooltipVal>
                <TooltipText>(Vận tốc: {Math.round(data.spd)} km/h) tại quãng thứ</TooltipText>
                <TooltipVal>{label}</TooltipVal>
              </>
            )}
          </TooltipHeaderContainer>
          <TooltipDetailContainer>
            <TooltipDetailItemWrapper>
              <TooltipDetailText>Nhịp độ</TooltipDetailText>
              <TooltipDetailVal>{data.spm} spm</TooltipDetailVal>
            </TooltipDetailItemWrapper>
          </TooltipDetailContainer>
        </TooltipContainer>
      );
    }
    return null;
  };

  const formatYAxisPace = (data: any) => {
    if (value === 'spd') return data;
    else return convertShortDuration(data, true)!;
  };

  const chartWidth = data.length * (zoom ? 40 : 10) < 1024 ? 1024 : data.length * (zoom ? 40 : 10);

  const renderChart = () => {
    return (
      <>
        <BarChart
          width={chartWidth}
          height={400}
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='splitIndex'>
            <Label value='Quãng (100m)' offset={-2} position='insideBottomLeft' />
          </XAxis>
          <YAxis
            dataKey={value}
            tickFormatter={formatYAxisPace}
            /*reversed={value === 'spd' ? false : true}*/
          >
            <Label value={labelYAxis} offset={0} angle={-90} position='insideLeft' />
          </YAxis>
          <Tooltip
            trigger='hover'
            wrapperStyle={{ pointerEvents: 'auto' }}
            cursor={{
              stroke: '#5fb07d',
              strokeWidth: 1,
              color: 'red',
            }}
            content={<CustomTooltip />}
          />
          <Bar dataKey={value} fill='#5fb07d' />
          <ReferenceLine y={value === 'spd' ? 15 : 240} stroke='red' ifOverflow='extendDomain' />
        </BarChart>

        <AreaChart
          width={chartWidth}
          height={200}
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <defs>
            <linearGradient id='colorBpm' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor='#F44336' stopOpacity={0.8} />
              <stop offset='95%' stopColor='#F44336' stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='splitIndex'>
            <Label value='Quãng (100m)' offset={-2} position='insideBottomLeft' />
          </XAxis>
          <YAxis dataKey='bpm' type='number'>
            <Label value='Nhịp tim (bpm)' offset={10} angle={90} position='insideLeft' />
          </YAxis>

          <Tooltip
            cursor={{
              stroke: '#F44336',
              strokeWidth: 1,
              color: 'red',
            }}
            content={<CustomTooltipBpm />}
          />
          <ReferenceLine y={100} stroke='red' ifOverflow='extendDomain' />
          <Area
            type='monotone'
            dataKey='bpm'
            stroke='#F44336'
            fillOpacity={1}
            activeDot={{ r: 8 }}
            fill='url(#colorBpm)'
          />
        </AreaChart>

        <AreaChart
          width={chartWidth}
          height={200}
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <defs>
            <linearGradient id='colorSpm' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor='#66BB6A' stopOpacity={0.8} />
              <stop offset='95%' stopColor='#66BB6A' stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='splitIndex'>
            <Label value='Quãng (100m)' offset={-2} position='insideBottomLeft' />
          </XAxis>
          <YAxis dataKey='spm' type='number'>
            <Label value='Nhịp độ (spm)' offset={10} angle={90} position='insideLeft' />
          </YAxis>

          <Tooltip
            cursor={{
              stroke: '#66BB6A',
              strokeWidth: 1,
              color: 'red',
            }}
            content={<CustomTooltipSpm />}
          />
          <ReferenceLine y={100} stroke='red' ifOverflow='extendDomain' />
          <Area
            type='monotone'
            dataKey='spm'
            stroke='#66BB6A'
            fillOpacity={1}
            activeDot={{ r: 8 }}
            fill='url(#colorSpm)'
          />
        </AreaChart>
      </>
    );
  };

  return (
    <Collapse defaultActiveKey={1} style={{ marginBottom: 10 }}>
      <Panel header='Biểu đồ quãng 100m' key={1} extra={renderFilter()}>
        <BarChartContainer>{isPending ? <Spinner /> : renderChart()}</BarChartContainer>
      </Panel>
    </Collapse>
  );
});
