import React from 'react';

import { useGlobalModalContext } from '../../providers/GlobalModalProvider';
import { ModalStyle } from './ConfirmModal.styles';

export const ConfirmModal: React.FC = () => {
  const { store, hideModal } = useGlobalModalContext();

  return (
    <ModalStyle
      title={store.modalProps?.title}
      visible={true}
      onOk={() => {
        store.modalProps?.onConfirm();
      }}
      okText={store.modalProps?.buttonConfirmText || 'OK'}
      onCancel={hideModal}>
      <p className='xxx'>Some contents...</p>
    </ModalStyle>
  );
};
