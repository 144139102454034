import { observer } from 'mobx-react-lite';
import { ComponentType } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { APIResponse } from '../../../../../models/APIResponse';
import { MemberFollower, MemberFollowing } from '../../../../../models/Member';
import { useRootStore } from '../../../../../providers/RootStoreProvider';
import { FOLLOW_CATEGORY_TYPE } from '../../../../../types/IGlobal';
import { FOLLOW_MEMBER_TYPE, IFollowMemberRequest } from '../../../../../types/IMember';
import { IResponseBase } from '../../../../../types/ITypeBase';
//views
import { UserItem } from '../UserItem/UserItem';
//styles
import { UserListContainer, UserListWrapper } from './UserList.styles';

interface Props {
  followType: FOLLOW_CATEGORY_TYPE;
  onViewUserProfile: (member: MemberFollowing | MemberFollower) => void;
  onLoadMoreFollowingList: () => void;
  onLoadMoreFollowerList: () => void;
}

export const UserList: ComponentType<Props> = observer(
  ({ followType, onViewUserProfile, onLoadMoreFollowingList, onLoadMoreFollowerList }) => {
    const store = useRootStore();

    const { listMemberFollowing, listMemberFollower, followMember } = store.memberStore;

    const onToggleFollow = async (member: MemberFollowing | MemberFollower) => {
      const action: FOLLOW_MEMBER_TYPE = member.isFollowing
        ? FOLLOW_MEMBER_TYPE.UNFOLLOW
        : FOLLOW_MEMBER_TYPE.FOLLOW;
      const params: IFollowMemberRequest = {
        data: {
          pfid: member.memberId,
          action,
        },
      };
      const result: APIResponse<IResponseBase> = await followMember(params);
      if (result && result.isSuccess()) {
        const data: any = result.data;
        member.setFollowingStatus(data?.fists);
      }
    };

    const renderFollowingList = () => {
      if (!listMemberFollowing) return null;
      return (
        <InfiniteScroll
          dataLength={listMemberFollowing.getLength()}
          next={onLoadMoreFollowingList}
          hasMore={listMemberFollowing?.isLoadMore!}
          style={{ overflowX: 'hidden' }}
          height={550}
          loader={undefined}>
          {listMemberFollowing?.list.map((member: MemberFollowing) => {
            return (
              <UserItem
                data={member}
                key={member.memberId}
                onToggleFollow={() => onToggleFollow(member)}
                onClick={() => onViewUserProfile(member)}
              />
            );
          })}
        </InfiniteScroll>
      );
    };

    const renderFollowerList = () => {
      if (!listMemberFollower) return null;
      return (
        <InfiniteScroll
          dataLength={listMemberFollower.getLength()}
          next={onLoadMoreFollowerList}
          hasMore={listMemberFollower?.isLoadMore!}
          style={{ overflowX: 'hidden' }}
          height={550}
          loader={undefined}>
          {listMemberFollower?.list.map((member: MemberFollowing) => {
            return (
              <UserItem
                data={member}
                key={member.memberId}
                onToggleFollow={() => onToggleFollow(member)}
                onClick={() => onViewUserProfile(member)}
              />
            );
          })}
        </InfiniteScroll>
      );
    };

    return (
      <UserListWrapper>
        <UserListContainer>
          {followType === FOLLOW_CATEGORY_TYPE.FOLLOWING
            ? renderFollowingList()
            : renderFollowerList()}
        </UserListContainer>
      </UserListWrapper>
    );
  },
);
