import { ComponentType } from 'react';
import pure from 'recompose/pure';

import { APP_CONFIG } from '../../configs/GlobalConst';
import { FEED_SOURCE_APP } from '../../types/IFeed';

interface Props {
  source: FEED_SOURCE_APP;
  className?: any;
}

export const AppIcon: ComponentType<Props> = pure(({ source, className }) => {
  let appSrc;
  switch (source) {
    case FEED_SOURCE_APP.STRAVA:
      appSrc = 'strava.png';
      break;
    case FEED_SOURCE_APP.GARMIN:
      appSrc = 'garmin.png';
      break;
    case FEED_SOURCE_APP.APPLE:
      appSrc = 'ic_health_apple.png';
      break;
    case FEED_SOURCE_APP.SUUNTO:
      appSrc = 'logo_suunto.png';
      break;
    case FEED_SOURCE_APP.COROS:
      appSrc = 'logo_coros.png';
      break;
    case FEED_SOURCE_APP.FITBIT:
      appSrc = 'logo_fitbit.png';
      break;
    case FEED_SOURCE_APP.POLAR:
      appSrc = 'logo_polar1.png';
      break;
    default:
      appSrc = 'uprace.png';
      break;
  }

  const fullSrc = `${APP_CONFIG.STATIC_URL}/icons/${appSrc}`;

  const onLoadImageError = (event: any) => {
    event.target.src = `${APP_CONFIG.STATIC_URL}/images/defaults/feed_map_default.jpg`;
    event.onerror = null;
  };

  return <img src={fullSrc} onError={onLoadImageError} className={className} />;
});
