import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextLeftCenterContainer } from '../../../styles/AppStyle';
import { Container, Section, TitleText } from '../../../styles/LandingPageStyle';
import {
  InfoBanner,
  InfoDescription,
  LeftContainer,
  RightContainer,
  RowContainer,
} from './InfoSection.styles';

export const InfoSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <Container>
        <RowContainer>
          <LeftContainer lg={12} md={12} sm={24} xs={24}>
            <TextLeftCenterContainer>
              <TitleText
                dangerouslySetInnerHTML={{
                  __html: t('about:info.title'),
                }}></TitleText>
            </TextLeftCenterContainer>
            <InfoDescription
              dangerouslySetInnerHTML={{
                __html: t('about:info.description'),
              }}></InfoDescription>
          </LeftContainer>
          <RightContainer lg={12} md={12} sm={24} xs={24}>
            <InfoBanner src='https://uprace2.vcdn.vn/web/uprace/about-us/vision-2x.png' />
          </RightContainer>
        </RowContainer>
      </Container>
    </Section>
  );
};
