import { EVENT_LOCK_MEMBER_TYPE } from './IEvent';
import { IRequestBase } from './ITypeBase';

export enum ACCOUNT_ROLE {
  NONE,
  NORMAL,
  PRO_LEVEL1,
  PRO_LEVEL2,
  PRO_LEVEL3,
}

export enum ACCOUNT_STATUS {
  NONE,
  NORMAL,
  LOCKED,
  DISABLED,
  DELETE_PENDING,
  DELETED,
}

export enum FOLLOW_MEMBER_TYPE {
  FOLLOW,
  UNFOLLOW,
}

export enum PERSONAL_PRIVACY {
  NONE,
  PUBLIC,
  FOLLOWING,
  ONLY_ME,
}

export enum FOLLOW_REQUEST_STATUS {
  NONE,
  REQUESTING,
  APPROVED,
}

export enum LOCK_MEMBER_TYPE {
  NONE,
  UNLOCK,
  LOCK,
}
export interface IMember {
  name: string;
  code: string;
  ava: string;
  sex: number;
  city: string;
  ctry: string;
  sts: ACCOUNT_STATUS;
  acrl: ACCOUNT_ROLE;
  tmnm: string;
}

export interface IMemberRank extends IMember {
  ddis: number;
  diff: number;
  dis: number;
  certsts: number;
  act: number;
  rank: number;
  id: number;
  win: number;
}
export interface IMemberTop extends IMember {
  fists: FOLLOW_REQUEST_STATUS;
  type: number;
  uid: number;
  fpri: PERSONAL_PRIVACY;
  evlsts: EVENT_LOCK_MEMBER_TYPE;
  fests: FOLLOW_REQUEST_STATUS;
}

export interface IMemberRequest extends IMember {
  uid: number;
}

export interface IMemberJoined extends IMemberTop {}

export interface IMemberFollowing extends IMemberTop {}

export interface IMemberFollower extends IMemberTop {}

export interface IGetTopMemberRequest extends IRequestBase {
  data: {
    size: number;
    evid: number;
  };
}
export interface IGetListMemberEventRequestRequest extends IRequestBase {
  data: {
    size: number;
    revid: number;
    from: number;
  };
}
export interface IGetListMemberEventJoinedRequest extends IRequestBase {
  data: {
    evad: number;
    evid: number;
    name?: string;
    from: number;
    size: number;
  };
}

export interface IGetListMemberSuggestRequest extends IRequestBase {
  data: {
    pfid: number;
    name: string;
    from: number;
    size: number;
  };
}

export interface IGetListMemberFollowerRequest extends IRequestBase {
  data: {
    feid: number;
    from?: number;
    size?: number;
  };
}

export interface IGetListMemberFollowingRequest extends IRequestBase {
  data: {
    fiid: number;
    from?: number;
    size?: number;
  };
}

export interface IFollowMemberRequest extends IRequestBase {
  data: {
    pfid: number;
    action: FOLLOW_MEMBER_TYPE;
  };
}
