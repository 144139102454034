import { IUser } from '../types/IUser';
export default class User {
  public userId: number;
  public name: string;
  public email: string;
  public code: string;
  public avatar: string;
  public team: boolean;

  public constructor(
    userId: number,
    name: string,
    email: string,
    code: string,
    avatar: string,
    team: boolean,
  ) {
    this.userId = userId;
    this.name = name;
    this.email = email;
    this.code = code;
    this.avatar = avatar;
    this.team = team;
  }

  static fromJson = (json: IUser): User => {
    return new User(json.uid, json.name, '', json.code, json.ava, true);
  };

  hasTeam = () => {
    return this.team;
  };
}

export class LoginToken {
  userId: number;
  name: string;
  email: string;
  accessToken: string;

  constructor(id: number, name: string, email: string, accessToken: string) {
    this.userId = id;
    this.name = name;
    this.email = email;
    this.accessToken = accessToken;
  }

  static fromJson = (json: any): LoginToken => {
    return new LoginToken(json.id, json.name, json.email, json);
  };

  setAccessToken(accessToken: string): void {
    this.accessToken = accessToken;
  }

  getValueJson(): string {
    const val = {
      accesstoken: this.accessToken,
      userId: this.userId,
    };
    return JSON.stringify(val);
  }

  isValid(): boolean {
    return this.userId != null && this.name != null && this.email != null;
  }
}
