import { FC, ReactElement } from 'react';

import useMediaQuery from '../../../hooks/useMediaQuery';
import { HeaderMobile, HeaderWeb } from './index';

export const Header: FC = (): ReactElement => {
  const isDesktop = useMediaQuery('(min-width: 960px)');

  return <>{isDesktop ? <HeaderWeb /> : <HeaderMobile />}</>;
};
