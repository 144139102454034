import { makeObservable } from 'mobx';

import { EVENT_JOIN_STATUS } from './../types/IEvent';

export abstract class ModelBase {
  abstract isOwner(memberId: number): boolean;
  abstract isAdmin(): boolean;
  abstract isPublic(): boolean;
  abstract isAllowView(): boolean;
  abstract setUserJoinStatus(status: EVENT_JOIN_STATUS): any;

  constructor() {
    makeObservable(this);
  }
}
