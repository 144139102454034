import { observer } from 'mobx-react-lite';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

import { MemberFollower, MemberFollowing } from '../../../../../models/Member';
import { APP_BUTTON_TYPE } from '../../../../../types/IGlobal';
//styles
import {
  AvatarContainer,
  AvatarTeam,
  FollowButton,
  InfoContainer,
  LabelText,
  UserItemContainer,
  UserItemWrapper,
  UserLink,
  ValueText,
} from './UserItem.styles';

interface Props {
  data: MemberFollowing | MemberFollower;
  onClick: () => void;
  onToggleFollow: () => void;
}

export const UserItem: ComponentType<Props> = observer(({ data, onClick, onToggleFollow }) => {
  const { t } = useTranslation();

  return (
    <UserItemWrapper>
      <UserLink to={`/user-profile?uid=${data.memberId}`} onClick={onClick}>
        <UserItemContainer>
          <AvatarContainer>
            <AvatarTeam src={data.avatar} />
          </AvatarContainer>
          <InfoContainer>
            <ValueText>{data.name}</ValueText>
            <LabelText>
              {data.city} - {data.country}
            </LabelText>
          </InfoContainer>
        </UserItemContainer>
      </UserLink>
      {!data.isMe() ? (
        <FollowButton
          typeButton={data.isFollowing ? APP_BUTTON_TYPE.SECONDARY_BORDER : APP_BUTTON_TYPE.PRIMARY}
          onClick={() => onToggleFollow()}>
          {data.isFollowing ? t('social:unFollow') : t('social:follow')}
        </FollowButton>
      ) : null}
    </UserItemWrapper>
  );
});
