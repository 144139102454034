import { action, makeObservable, observable, runInAction } from 'mobx';

import { APIResponse } from '../models/APIResponse';
import AuthService from '../services/AuthService';
import { ILoginRequestBase } from '../types/ILogin';
import { IResponseBase } from '../types/ITypeBase';
import { LOCAL_KEY } from './../configs/AppKey';
import RootStore from './RootStore';
class AuthStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  //declare variables
  @observable currentUser: any | null = null;

  //declare action
  @action login = async (params: ILoginRequestBase) => {
    const result = (await AuthService.getInstance().login(params)) as APIResponse<IResponseBase>;
    if (result && result.isSuccess()) {
      localStorage.setItem(LOCAL_KEY.CURRENT_USER, JSON.stringify(result.data));
      runInAction(() => {
        this.currentUser = result.data;
      });
    }
    return result;
  };

  @action logout = async () => {
    localStorage.removeItem(LOCAL_KEY.CURRENT_USER);
    runInAction(() => {
      this.currentUser = null;
    });
  };

  @action setUserData = async (data: any) => {
    runInAction(() => {
      this.currentUser = data;
    });
  };
}
export default AuthStore;
