import styled from 'styled-components';

export const ActivityListWrapper = styled.div`
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 24px;
  border-radius: 8px;
  background: #fff;
  min-height: 500px;
`;

export const ActivityListContainer = styled.div``;
