import { Col, Collapse, Row, Select } from 'antd';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import pure from 'recompose/pure';

import { FeedAnalysis } from '../../../../models/Feed';
import { roundNumber } from '../../../../utils/FormatUtils';
//styles
import {
  GeneralInfoContainer,
  GeneralInfoWrapper,
  ItemInfoWrapper,
  LabelText,
  ValueText,
} from './GeneralInfo.styles';
interface Props {
  data: FeedAnalysis;
}

const { Panel } = Collapse;
const { Option } = Select;

export const GeneralInfo: ComponentType<Props> = pure(({ data }) => {
  const { t } = useTranslation();

  return (
    <GeneralInfoWrapper>
      <Collapse style={{ marginBottom: 10 }}>
        <Panel header='Thông tin khác' key={1}>
          <GeneralInfoContainer>
            <Row>
              <Col xl={12} md={12} xs={24}>
                <ItemInfoWrapper>
                  <LabelText>Tổng số GPS point</LabelText>
                  <ValueText>{data.totalGpsPoint}</ValueText>
                </ItemInfoWrapper>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <ItemInfoWrapper>
                  <LabelText>Tổng quãng đường tính theo GPS data</LabelText>
                  <ValueText>{data.totalDistanceInMeterBaseGps}m</ValueText>
                </ItemInfoWrapper>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <ItemInfoWrapper>
                  <LabelText>Tổng thời gian diễn ra hoạt động đường tính theo GPS data</LabelText>
                  <ValueText>{data.totalDurationInSecondBaseGps}s</ValueText>
                </ItemInfoWrapper>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <ItemInfoWrapper>
                  <LabelText>Tổng thời gian chuyển động đường tính theo GPS data</LabelText>
                  <ValueText>{data.totalMovingTimeInSecondBaseGps}s</ValueText>
                </ItemInfoWrapper>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <ItemInfoWrapper>
                  <LabelText>Trạng thái GPS không hợp lệ</LabelText>
                  <ValueText>{data.getInvalidGPSStatus()}</ValueText>
                </ItemInfoWrapper>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <ItemInfoWrapper>
                  <LabelText>Tổng số GPS không hợp lệ</LabelText>
                  <ValueText>{data.invalidGps}</ValueText>
                </ItemInfoWrapper>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <ItemInfoWrapper>
                  <LabelText>Tổng quãng đường của GPS không hợp lệ</LabelText>
                  <ValueText>{data.invalidGpsDistanceInMeter}m</ValueText>
                </ItemInfoWrapper>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <ItemInfoWrapper>
                  <LabelText>Tổng số GPS lag</LabelText>
                  <ValueText>{data.lagGps}</ValueText>
                </ItemInfoWrapper>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <ItemInfoWrapper>
                  <LabelText>Tổng quãng đường của GPS lag</LabelText>
                  <ValueText>{data.lagGpsDistanceInMeter}m</ValueText>
                </ItemInfoWrapper>
              </Col>

              <Col xl={12} md={12} xs={24}>
                <ItemInfoWrapper>
                  <LabelText>Quãng đường trung bình giữa 2 GPS point liên tiếp</LabelText>
                  <ValueText>{roundNumber(data.avgDistanceInMeter, 6)}m</ValueText>
                </ItemInfoWrapper>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <ItemInfoWrapper>
                  <LabelText>Thời gian trung bình giữa 2 GPS point liên tiếp</LabelText>
                  <ValueText>{roundNumber(data.avgDurationInSecond, 6)}s</ValueText>
                </ItemInfoWrapper>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <ItemInfoWrapper>
                  <LabelText>Thời gian có chuyển động trung bình giữa 2 GPS point</LabelText>
                  <ValueText>{roundNumber(data.avgMovingTimeInSecond, 6)}s</ValueText>
                </ItemInfoWrapper>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <ItemInfoWrapper>
                  <LabelText>Tốc độ trung bình giữa 2 GPS point liên tiếp</LabelText>
                  <ValueText>{roundNumber(data.avgSpeedInKmH, 6)}km/h</ValueText>
                </ItemInfoWrapper>
              </Col>

              <Col xl={12} md={12} xs={24}>
                <ItemInfoWrapper>
                  <LabelText>Gia tốc trung bình giữa 2 GPS point liên tiếp</LabelText>
                  <ValueText>{roundNumber(data.avgAccelerationInKmH, 6)}km/h/s</ValueText>
                </ItemInfoWrapper>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <ItemInfoWrapper>
                  <LabelText>Biến thiên độ cao trung bình giữa 2 GPS point liên tiếp</LabelText>
                  <ValueText>{roundNumber(data.avgDeltaAltitudeInMeter, 6)}m</ValueText>
                </ItemInfoWrapper>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <ItemInfoWrapper>
                  <LabelText>Trung bình tăng độ cao giữa 2 GPS point liên tiếp</LabelText>
                  <ValueText>{roundNumber(data.avgElevationGainInMeter, 6)}m</ValueText>
                </ItemInfoWrapper>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <ItemInfoWrapper>
                  <LabelText>Trung bình giảm độ cao giữa 2 GPS point liên tiếp</LabelText>
                  <ValueText>{roundNumber(data.avgElevationLossInMeter, 6)}m</ValueText>
                </ItemInfoWrapper>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <ItemInfoWrapper>
                  <LabelText>Trung bình thay đổi hướng giữa 2 GPS point liên tiếp</LabelText>
                  <ValueText>{roundNumber(data.avgDeltaAzimuthInDegree, 6)}độ</ValueText>
                </ItemInfoWrapper>
              </Col>
            </Row>
          </GeneralInfoContainer>
        </Panel>
      </Collapse>
    </GeneralInfoWrapper>
  );
});
