import { API } from '../configs/APIEndPoint';
import { LOCAL_KEY } from '../configs/AppKey';
import APIHelper, { handleError } from '../helpers/APIHelper';
import { ILoginEmail, ILoginResponse } from './../types/ILogin';
import BaseUnAuthService from './BaseUnAuthService';
class AuthService extends BaseUnAuthService {
  private static instance: AuthService;

  public static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }

  login = async (data: ILoginEmail) => {
    try {
      const response = await APIHelper.post(API.AUTHENTICATION.LOGIN, data);
      return this.handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  };

  //get user from localStorage if available
  getCurrentUser = (): ILoginResponse | null => {
    const userStr = localStorage.getItem(LOCAL_KEY.CURRENT_USER);
    if (userStr) {
      const objUser: ILoginResponse = JSON.parse(userStr);
      return objUser;
    }
    return null;
  };
}

export default AuthService;
