import { DatePicker } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '../../../../providers/RootStoreProvider';
import { STATS_TIME_TYPE } from '../../../../types/IGlobal';
import { convertLongDuration } from '../../../../utils/FeedUtils';
import { formatDistance, formatNumber } from '../../../../utils/FormatUtils';
//styles
import {
  ArrowButton,
  ContentWrapper,
  HeaderWrapper,
  LabelText,
  StatsInfoContainer,
  StatsInfoWrapper,
  TextContentWrapper,
  TextHeaderWrapper,
  ValueText,
} from './StatsInfo.styles';
interface Props {
  statsType: STATS_TIME_TYPE;
  onChangeTimeValue: (value: any) => void;
}

export const StatsInfo: ComponentType<Props> = observer(({ statsType, onChangeTimeValue }) => {
  const store = useRootStore();
  const { t } = useTranslation();

  const { feedUserStats } = store.userStore;

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState('week');

  let thisLabel: string = '';
  let lastLabel: string = '';

  switch (statsType) {
    case STATS_TIME_TYPE.WEEKLY: {
      thisLabel = t('userProfile:timerStats.curWeek');
      lastLabel = t('userProfile:timerStats.preWeek');
      break;
    }
    case STATS_TIME_TYPE.MONTHLY: {
      thisLabel = t('userProfile:timerStats.curMonth');
      lastLabel = t('userProfile:timerStats.preMonth');
      break;
    }
    case STATS_TIME_TYPE.YEARLY: {
      thisLabel = t('userProfile:timerStats.curYear');
      lastLabel = t('userProfile:timerStats.preYear');
      break;
    }
  }

  const onDatePickerChange = (date: any, value: any) => {
    setOpen(!open);
    onChangeTimeValue(value);
  };

  return (
    <StatsInfoWrapper>
      <StatsInfoContainer>
        <HeaderWrapper>
          <TextHeaderWrapper span={8}>
            <LabelText>{t('userProfile:feedStats.info')}</LabelText>
          </TextHeaderWrapper>
          <TextHeaderWrapper span={8}>
            <LabelText>{thisLabel}</LabelText>
          </TextHeaderWrapper>
          <TextHeaderWrapper span={8}>
            <LabelText>{lastLabel}</LabelText>
            {statsType === STATS_TIME_TYPE.WEEKLY && (
              <DatePicker
                open={open}
                picker='week'
                format='yyyyww'
                style={{
                  visibility: 'hidden',
                  width: 0,
                  height: 0,
                }}
                //onOpenChange={(open) => setOpen(open)}
                onChange={(date, value) => onDatePickerChange(date, value)}
              />
            )}
            {statsType === STATS_TIME_TYPE.MONTHLY && (
              <DatePicker
                open={open}
                picker='month'
                format='yyyyMM'
                style={{
                  visibility: 'hidden',
                  width: 0,
                  height: 0,
                }}
                onChange={(date, value) => onDatePickerChange(date, value)}
              />
            )}
            {statsType === STATS_TIME_TYPE.YEARLY && (
              <DatePicker
                open={open}
                picker='year'
                style={{
                  visibility: 'hidden',
                  width: 0,
                  height: 0,
                }}
                onChange={(date, value) => onDatePickerChange(date, value)}
              />
            )}
            <ArrowButton onClick={() => setOpen(!open)} />
          </TextHeaderWrapper>
        </HeaderWrapper>

        <ContentWrapper>
          <TextContentWrapper span={8}>
            <LabelText>{t('userProfile:feedStats.distance')}</LabelText>
          </TextContentWrapper>
          <TextContentWrapper span={8}>
            <ValueText>
              {feedUserStats?.current.dis && formatDistance(feedUserStats?.current.dis, 2)}
            </ValueText>
          </TextContentWrapper>
          <TextContentWrapper span={8}>
            <ValueText>
              {feedUserStats?.other.dis && formatDistance(feedUserStats?.other.dis, 2)}
            </ValueText>
          </TextContentWrapper>
        </ContentWrapper>
        <ContentWrapper>
          <TextContentWrapper span={8}>
            <LabelText>{t('userProfile:feedStats.timeActivity')}</LabelText>
          </TextContentWrapper>
          <TextContentWrapper span={8}>
            <ValueText>{convertLongDuration(feedUserStats?.current.dur!, true)}</ValueText>
          </TextContentWrapper>
          <TextContentWrapper span={8}>
            <ValueText>{convertLongDuration(feedUserStats?.other.dur!, true)}</ValueText>
          </TextContentWrapper>
        </ContentWrapper>
        <ContentWrapper>
          <TextContentWrapper span={8}>
            <LabelText>{t('userProfile:feedStats.numberActivity')}</LabelText>
          </TextContentWrapper>
          <TextContentWrapper span={8}>
            <ValueText>{formatNumber(feedUserStats?.current.act!)}</ValueText>
          </TextContentWrapper>
          <TextContentWrapper span={8}>
            <ValueText>{formatNumber(feedUserStats?.other.act!)}</ValueText>
          </TextContentWrapper>
        </ContentWrapper>
      </StatsInfoContainer>
    </StatsInfoWrapper>
  );
});
