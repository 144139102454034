export enum IMAGE_TYPE {
  AVATAR = 1,
  COVER = 2,
}

export enum ALERT_TYPE {
  INFO,
  SUCCESS,
  ERROR,
  WARNING,
}

export enum FOLLOW_CATEGORY_TYPE {
  FOLLOWING,
  FOLLOWER,
}

export enum SORT_ORDER_TYPE {
  ASC,
  DESC,
}

export enum STATS_TIME_TYPE {
  WEEKLY,
  MONTHLY,
  YEARLY,
}

export enum APP_BUTTON_TYPE {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SECONDARY_BORDER = 'secondary-border',
  LIGHT = 'light',
  DISABLED = 'disabled',
  TRANSPARENT = 'transparent',
}

export interface IUploadImage {
  uid: number;
  image: IMAGE_TYPE;
}

export interface ITabMenu {
  id: number;
  title: any;
}

export interface IDataResponseList<T> {
  total: number;
  list: T;
}
