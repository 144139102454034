import { observer } from 'mobx-react-lite';
import { ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { MemberFollower, MemberFollowing } from '../../../../models/Member';
import { useRootStore } from '../../../../providers/RootStoreProvider';
import { FOLLOW_CATEGORY_TYPE } from '../../../../types/IGlobal';
import {
  IGetListMemberFollowerRequest,
  IGetListMemberFollowingRequest,
} from '../../../../types/IMember';
//styles
import { DialogUserContainer, DialogUserWrapper, UsersModal } from './DialogUsers.styles';
//views
import { TabDialog } from './TabDialog/TabDialog';
import { UserList } from './UserList/UserList';

interface Props {
  userId: any;
  visible: boolean;
  followType: FOLLOW_CATEGORY_TYPE;
  onClose: () => void;
  onChangeFollowType: (type: FOLLOW_CATEGORY_TYPE) => void;
}

export const DialogUsers: ComponentType<Props> = observer(
  ({ userId, visible, followType, onClose, onChangeFollowType }) => {
    const store = useRootStore();
    const { t } = useTranslation();

    const { getListMemberFollower, getListMemberFollowing } = store.memberStore;

    useEffect(() => {
      if (followType === FOLLOW_CATEGORY_TYPE.FOLLOWING) onGetListMemberFollowing(false);
      else onGetListMemberFollower(false);
    }, [followType]);

    const onGetListMemberFollower = async (isLoadMore: boolean) => {
      const params: IGetListMemberFollowerRequest = {
        data: {
          feid: userId,
        },
      };
      const result = await getListMemberFollower(params, isLoadMore);
    };

    const onGetListMemberFollowing = async (isLoadMore: boolean) => {
      const params: IGetListMemberFollowingRequest = {
        data: {
          fiid: userId,
        },
      };
      const result = await getListMemberFollowing(params, isLoadMore);
    };

    const onChangeTab = (tab: FOLLOW_CATEGORY_TYPE) => {
      onChangeFollowType(tab);
    };

    const onViewUserProfile = (member: MemberFollowing | MemberFollower) => {
      onClose();
    };

    return (
      <DialogUserWrapper>
        <DialogUserContainer>
          <UsersModal centered onCancel={onClose} visible={visible} footer={null}>
            <TabDialog onChangeTab={onChangeTab} followType={followType} />
            <UserList
              followType={followType}
              onViewUserProfile={onViewUserProfile}
              onLoadMoreFollowingList={() => onGetListMemberFollowing(true)}
              onLoadMoreFollowerList={() => onGetListMemberFollower(true)}
            />
          </UsersModal>
        </DialogUserContainer>
      </DialogUserWrapper>
    );
  },
);
