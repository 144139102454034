import { Col, Row } from 'antd';
import styled from 'styled-components';

import { device } from '../../../../configs/MediaQuery';

export const TopBlockContainer = styled(Row)`
  border: 1px solid #e7eaec;
  border-radius: 10px;

  @media ${device.mobileS} {
    padding: 8px 12px;
  }
  @media ${device.tablet} {
    min-height: 114px;
    padding: 0;
  }
`;

export const ColContainer = styled(Col)`
  display: flex;

  @media ${device.mobileS} {
    flex-direction: row;
    justify-content: space-between;
  }
  @media ${device.tablet} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ColSubContainer = styled(Col)`
  display: flex;

  @media ${device.mobileS} {
    flex-direction: column;
  }
  @media ${device.tablet} {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:nth-child(2) {
      border-right: 1px solid #e7eaec;
      border-left: 1px solid #e7eaec;
    }
  }
`;

export const RowSubContainer = styled(Row)`
  display: flex;
  width: 100%;

  @media ${device.mobileS} {
    flex-direction: row;
    justify-content: space-between;
  }
  @media ${device.tablet} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 57px;

    &:nth-child(1) {
      border-bottom: 1px solid #e7eaec;
    }
  }
`;

export const LabelText = styled.p`
  margin: 0;
  line-height: 15px;
  padding: 3px 3px 0;
  color: #797979;
  font-size: 12px;
`;

export const ValueText = styled.p`
  margin: 0;
  margin-top: 3px;
  font-weight: 600;

  @media ${device.mobileS} {
    line-height: 21px;
    font-size: 16px;
  }
  @media ${device.tablet} {
    font-size: 22px;
    line-height: 29px;
  }
`;
