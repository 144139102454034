import { SCREEN_YOUR_EVENT } from '../configs/Screens';
import { SORT_ORDER_TYPE } from './IGlobal';
import { LEADERBOARD_TYPE } from './ILeaderBoard';
import { IRequestBase } from './ITypeBase';

// public enum of event
export enum EVENT_TIME_TYPE {
  ALL,
  REGISTERING,
  RUNNING,
  FINISHED,
  SUGGEST,
}

export enum EVENT_STATUS {
  ALL,
  REGISTERING,
  RUNNING,
  FINISHED,
  PAUSED,
  CANCEL,
  INACTIVE,
}

export enum EVENT_OFFICIAL {
  ALL,
  UNOFFICIAL,
  OFFICIAL,
}

export enum EVENT_LOCK_MEMBER_TYPE {
  NONE,
  NORMAL,
  BANNED,
  MUTED,
  DELETED,
}

export enum EVENT_TYPE {
  ALL,
  SUPPER_CHALLENGE,
  CHALLENGE,
  VIRTUAL_RACE,
}

export enum EVENT_PUBlIC_TYPE {
  PUBLIC,
  PROTECTED,
  PRIVATE,
}

export enum EVENT_JOIN_STATUS {
  NONE,
  REQUESTING,
  JOINED,
  ADMIN,
  INVITING,
}

export enum EVENT_FORM_TYPE {
  ALL,
  GROUP,
  PERSONAL,
}

export enum EVENT_SORT_TYPE {
  PERSONAL = 7,
  START_TIME = 4,
  END_TIME = 6,
  MEMBER = 3,
}

export enum EVENT_ACTION_TYPE {
  JOIN,
  LEAVE,
  USER_REQUEST_JOIN,
  USER_CANCEL_JOIN,
  ADMIN_APPROVE_JOIN,
  ADMIN_REJECT_JOIN,
  OWNER_GIVE_ADMIN,
  OWNER_UNGIVE_ADMIN,
  DELETE_MEMBER,
  CHANGE_OWNER,
  INVITE_MEMBER,
  CANCEL_INVITE,
  MEMBER_ACCEPT_INVITE,
  MEMBER_CANCEL_INVITE,
}

//public interface event
export interface IEvent {
  spons2: any;
  cpsts: number;
  rptm: number;
  offical: number;
  evrl: any;
  rsc2: IEventBanner;
  crtm: number;
  cptm: number;
  cert: boolean;
  revgrcnt: number;
  dis: number;
  cover: string;
  descen: string;
  ownm: string;
  certurl: string;
  fuid: number;
  donate: number;
  evgrcnt: number;
  evid: number;
  pevid: number;
  league: any;
  revcnt: number;
  owif: any;
  sts: number;
  wdesc: string;
  name: string;
  jsts: number;
  desc: string;
  evtp: number;
  mnmvtm: number;
  rsc: any;
  jpri: number;
  ava: string;
  entm: number;
  tmnm: string;
  rgrid: number;
  rpsts: number;
  betm: number;
  mem: number;
  dvsts: number;
  rgtm: number;
  wdescen: string;
  owner: number;
  tmid: number;
  mnday: number;
  rdis: number;
  team: number;
  url: string;
  mbls: any[];
  acrl: number;
  dyls: any;
  form: number;
  lsts: number;
  fund: IFunds;
  grid: number;
  tgid: number;
  sctp: number;
  sport: number;
  mndis: number;
  spons: any;
  evdis: number;
  pevdis: number;
}

export interface IEventBanner {
  chooseteam: string;
  wbanner: string;
  banner: string;
}

export interface IEventFund {
  rdis: number;
  title: string;
  ava: string;
  url: string;
  dis: number;
  wurl: string;
  descen: string;
  sts: number;
  mem: number;
  titleen: string;
  name: string;
  fuid: number;
  donate: number;
  desc: string;
}

interface IFunds {
  [key: string]: IEventFund;
}

export interface IRankMeRequest extends IRequestBase {
  data: {
    evid: number;
    type: LEADERBOARD_TYPE;
    value: number;
  };
}

export interface IGetEventDetailRequest extends IRequestBase {
  data: {
    evid: any;
  };
}
export interface IDismissEventSuggestRequest extends IGetEventDetailRequest {}

export interface IDeleteEventRequest extends IGetEventDetailRequest {}

export interface IActionEventRequest {
  data: {
    evid: number;
    action: EVENT_ACTION_TYPE;
    pfid?: number;
  };
}

export interface IGetListEventBlockRequest extends IRequestBase {
  data: {
    sts: EVENT_STATUS;
    size: number;
  };
}

export interface IGetYourEventRequest extends IRequestBase {
  data: {
    metab: SCREEN_YOUR_EVENT;
    sts: EVENT_STATUS;
    pfid: number;
  };
}

export interface IGetListEventFilterRequest extends IRequestBase {
  data: {
    name: string;
    sts: EVENT_STATUS;
    from?: number;
    size?: number;
    sort: number;
    order: SORT_ORDER_TYPE;
    offical: EVENT_OFFICIAL;
  };
}

export enum SPONSOR_EVENT {
  CO_TITLE,
  RACE,
  OTHER,
  DONOR,
}

export enum SPONSOR_TYPE {
  CO_TITLE = 'cotitle',
  RACE = 'race',
  OTHER = 'others',
  DONOR = 'donor',
}
