import { observer } from 'mobx-react-lite';
import React from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useRootStore } from '../../../providers/RootStoreProvider';
import { IEventStats } from '../../../types/IJson';
import { SectionEventStats, SwiperContainer } from './EventStats.styles';
import { EventStatsItem } from './EventStatsItem';

const data = [
  {
    id: 1,
    title_vi: '2017',
    title_en: '2017',
    desc_vi: 'Thành lập',
    desc_en: 'Year of establishment',
    active: true,
  },
  {
    id: 3,
    title_vi: '632 nghìn',
    title_en: '632 thousand',
    desc_vi: 'Số lượng người dùng',
    desc_en: 'Number of users',
    active: true,
  },
  {
    id: 4,
    title_vi: '23.4 triệu',
    title_en: '23.4 million',
    desc_vi: 'Tổng số km',
    desc_en: 'Total kilometers',
    active: true,
  },
  {
    id: 5,
    title_vi: '50',
    title_en: '50',
    desc_vi: 'Đối tác đồng hành cùng UpRace',
    desc_en: 'Partners',
    active: true,
  },
  {
    id: 6,
    title_vi: '85.000+',
    title_en: '85.000+',
    desc_vi: 'Người dùng hoạt động trong tháng',
    desc_en: 'Monthly active users',
    active: true,
  },
];

export const EventStats: React.FC = observer(() => {
  const store = useRootStore();
  const { language } = store.globalStore;

  return (
    <SectionEventStats>
      <SwiperContainer>
        <Swiper
          observer={true} //fix issue slider not resize when change breakpoints
          spaceBetween={50}
          slidesPerView={3}
          speed={4000}
          freeMode={true}
          lazy={true}
          breakpoints={{
            320: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
          autoplay={{
            delay: 100,
            disableOnInteraction: false,
          }}
          loop={true}
          modules={[Autoplay]}>
          {data &&
            data.map((item: IEventStats) => {
              return (
                <SwiperSlide key={item.id}>
                  <EventStatsItem data={item} key={item.id} language={language} />
                </SwiperSlide>
              );
            })}
        </Swiper>
      </SwiperContainer>
    </SectionEventStats>
  );
});
