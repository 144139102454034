export const API_STATUS = {
  OK: 0,
  LOGIN_SUCCESS: 1,
  OVER_QUOTA: 122,
  EVENT: {
    EVENT_READY: 115,
    EVENT_FINISHED: 116,
    EVENT_NOT_EXIST: 112,
    EVENT_NOT_READER: 113,
  },
  TEAM: {
    OWNER_CODE: 119,
    LEAVE_TEAM_EVENT_READY: 120,
    NOT_EXIST: 106,
  },
  LOGIN: {
    CAPTCHA_INVALID: 1009,
    USER_NOT_FOUND: 2000,
    USER_LOGIN_FAIL: 2017,
  },
  REPORT_ACTIVITY: {
    REVIEW_YOUR_ACT: 84,
    YOUR_REPORTED: 85,
    NOT_PERMISSION: 126,
  },
  USER: {
    DELETE_ACCOUNT: 63,
  },
};
