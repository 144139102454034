import { observer } from 'mobx-react-lite';
import { ComponentType } from 'react';

import { Feed } from '../../../models/Feed';
//styles
import { MapActivityContainer, MapActivityImage, MapActivityWrapper } from './MapActivity.styles';

interface Props {
  feedData: Feed;
}

export const MapActivity: ComponentType<Props> = observer(({ feedData }) => {
  return (
    <MapActivityWrapper>
      <MapActivityContainer>
        <MapActivityImage
          src={feedData.mapImage.replace('.png', 'w.png').replace('.jpeg', 'w.jpeg')}
        />
      </MapActivityContainer>
    </MapActivityWrapper>
  );
});
