import { Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  ColContainer,
  ContainerFooter,
  CopyContainer,
  CopySection,
  CopyText,
  DownloadButtonContainer,
  DownloadButtonImage,
  FooterHref,
  FooterLink,
  FooterText,
  FooterTitle,
  Logo,
  Section,
  SocialContact,
  SocialIcon,
} from './Footer.styles';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { observer } from 'mobx-react-lite';
import { APP_LANGUAGE } from '../../../configs/GlobalConst';

export const Footer: FC = observer(() => {
  const { t } = useTranslation();
  const store = useRootStore();
  const { language } = store.globalStore;

  const linkFAQ = language === APP_LANGUAGE.VIETNAMES ? 'https://uprace.gitbook.io/uprace-ho-tro' : 'https://uprace.gitbook.io/uprace-ho-tro';
  return (
    <>
      <Section>
        <ContainerFooter>
          <Row>
            <ColContainer xl={6} md={12} sm={24} xs={24}>
              <Logo src='https://uprace2.vcdn.vn/web/uprace/logo/brand.svg' />
              <FooterText>{t('footer:followUs')}</FooterText>
              <SocialContact>
                <li>
                  <a href='https://www.facebook.com/UpRace.Official' target='_blank'>
                    <SocialIcon src='https://uprace2.vcdn.vn/web/uprace/icons/facebook.svg' />
                  </a>
                </li>
                <li>
                  <Link to={'/'}>
                    <SocialIcon src='https://uprace2.vcdn.vn/web/uprace/icons/globe-icon.svg' />
                  </Link>
                </li>
                <li>
                  <a href='https://www.tiktok.com/@uprace.org' target='_blank'>
                    <SocialIcon src='https://uprace2.vcdn.vn/web/uprace/icons/tiktok.svg' />
                  </a>
                </li>
              </SocialContact>
            </ColContainer>
            <ColContainer xl={6} md={12} sm={24} xs={24}>
              <FooterTitle>{t('global:info')}</FooterTitle>

              <FooterLink to={'/about'}>
                <FooterText>{t('global:about')}</FooterText>
              </FooterLink>
              {/* <FooterHref href='https://faq.uprace.org/' target='_blank'>
                <FooterText>{t('global:support')}</FooterText>
              </FooterHref> */}
              <FooterHref href={linkFAQ} target='_blank'>
                <FooterText>{t('global:help')}</FooterText>
              </FooterHref>
              {/* <FooterLink to={'/join-now'}>
                                <FooterText>{t('global:guide')}</FooterText>
                            </FooterLink> */}
            </ColContainer>
            <ColContainer xl={6} md={12} sm={24} xs={24}>
              <FooterTitle>{t('global:terms')}</FooterTitle>

              <FooterHref href='https://uprace2.vcdn.vn/web/uprace/html/terms.html' target='_blank'>
                <FooterText>{t('footer:terms')}</FooterText>
              </FooterHref>
              <FooterHref href='https://uprace2.vcdn.vn/web/uprace/html/policy.html' target='_blank'>
                <FooterText>{t('global:policy')}</FooterText>
              </FooterHref>
            </ColContainer>
            <ColContainer xl={6} md={12} sm={24} xs={24}>
              <FooterTitle>{t('global:contact')}</FooterTitle>
              <FooterText>support@uprace.org</FooterText>
              <FooterText
                dangerouslySetInnerHTML={{
                  __html: t('footer:address'),
                }}></FooterText>
              <DownloadButtonContainer>
                <a href='https://apps.apple.com/us/app/id1476173971'>
                  <DownloadButtonImage src='https://uprace2.vcdn.vn/web/uprace/icons/app-store.svg' />
                </a>
                <a href='https://play.google.com/store/apps/details?id=vn.vng.uprace'>
                  <DownloadButtonImage src='https://uprace2.vcdn.vn/web/uprace/icons/google-play.svg' />
                </a>
              </DownloadButtonContainer>
            </ColContainer>
          </Row>
        </ContainerFooter>
      </Section>
      <CopySection>
        <CopyContainer>
          <CopyText>© 2022 UpRace. All rights reserved</CopyText>
        </CopyContainer>
      </CopySection>
    </>
  );
});
