import { Spin } from 'antd';
import { ComponentType } from 'react';
import pure from 'recompose/pure';

//styles
import { SpinnerContainer, SpinnerWrapper } from './Spinner.style';

interface Props {}

export const Spinner: ComponentType<Props> = pure(({}) => {
  return (
    <SpinnerWrapper>
      <SpinnerContainer>
        <Spin size='large' />
        <p>Đang tải dữ liệu...</p>
      </SpinnerContainer>
    </SpinnerWrapper>
  );
});
