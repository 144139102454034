import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LOCAL_KEY } from '../../../configs/AppKey';
import { MENU_LANGUAGE } from '../../../data/menu';
import { useRootStore } from '../../../providers/RootStoreProvider';

export const MenuLanguage: React.FC = () => {
  const store = useRootStore();
  const { t, i18n } = useTranslation();

  const { changeLanguage } = store.globalStore;

  const localLanguage = localStorage.getItem(LOCAL_KEY.USER_LANGUAGE);
  const defaultLanguage = !localLanguage ? 'VN' : localLanguage.toUpperCase();

  const [languageText, setLanguageText] = useState(defaultLanguage);

  const onChangeLanguage = (data: any) => {
    i18n.changeLanguage(data.id);
    changeLanguage(data.id);
    setLanguageText(data.name);
  };

  const MenuItem = (props: any) => {
    const { data } = props;
    return (
      <a
        style={{ cursor: 'pointer' }}
        onClick={() => onChangeLanguage(data)}
        className='dropdown-item'>
        <img src={data.icon} className='me-1 dropdown-item-img' />
        <span className=''>{data.title}</span>
      </a>
    );
  };

  return (
    <li className='nav-item'>
      <div className='dropdown dropdown-language'>
        <a className='nav-link dropdown-toggle' data-bs-toggle='dropdown' href='#'>
          <img
            src='https://uprace2.vcdn.vn/web/uprace/icons/languages.svg'
            alt='languages'
            className='me-1'
            height='20'
            width='20'
          />
          <span className='align-middle d-inline-block'>{languageText}</span>
        </a>
        <div className='dropdown-menu dropdown-menu-end'>
          {MENU_LANGUAGE.map((item) => {
            return <MenuItem key={item.id} data={item} />;
          })}
        </div>
      </div>
    </li>
  );
};
