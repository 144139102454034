import styled from 'styled-components';

export const LapChartWrapper = styled.div`
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 30px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  background: #dbdfe2;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const HeaderText = styled.p`
  margin: 0;
  line-height: 21px;
  font-size: 16px;
`;

export const ChartContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const ChartItemContainer = styled.div`
  display: flex;
  margin-bottom: 6px;
`;

export const DistanceContainer = styled.div`
  width: 50px;
  display: flex;
  padding: 3px 0 0 16px;
  font-size: 14px;
`;

export const PageContainer = styled.div`
  padding-top: 3px;
  width: 50px;
  font-size: 14px;
`;
export const ChartBarContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const ChartBar = styled.span`
  display: block;
  background-color: #5edfff;
  height: 22px;
`;
