import styled from 'styled-components';

import { UpraceInput } from '../../../../components/UpraceInput/UpraceInput';
import { AppButton } from '../../../../styles/ButtonStyles';
import { defaultTheme } from '../../../../themes';
import { device } from './../../../../configs/MediaQuery';

export const ToolbarWrapper = styled.div`
  max-width: 1080px;
  margin: 0 auto;
`;

export const ToolbarContainer = styled.div`
  display: flex;
  height: 62px;
  align-items: flex-end;
  border-bottom: solid 1px #e7eaec;

  @media ${device.mobileS} {
    position: relative;
    padding-top: 100px;
  }
  @media ${device.laptop} {
    padding: 0;
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 24px;
`;

export const TabContainer = styled.ul`
  display: flex;
  flex-grow: 1;
  margin: 0;
  padding-left: 0;
  list-style-type: none;
`;

export const TabItem = styled.li`
  @media ${device.mobileS} {
    width: 50%;
  }
  @media ${device.laptop} {
    width: auto;
    padding-right: 20px;
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

export const MarkLabelButton = styled(AppButton)`
  padding: 10px;
  min-width: 150px;
  font-size: 16px;
  font-weight: 400;
`;

export const LogoutButton = styled(AppButton)``;

export const SearchInputText = styled(UpraceInput)`
  height: 40px;
  border-radius: 8px;
  border: 1px solid #dbdfe2;
  display: flex;

  @media ${device.mobileS} {
    width: 95%;
  }

  @media ${device.laptop} {
    width: 340px;
  }
`;

export const EmptyTitle = styled.h1`
  font-weight: 400;
  color: #f7391b;
  margin: 0;
  margin-left: 10px;

  @media ${device.mobileS} {
    font-size: 14px;
    line-height: 24px;
  }

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 27px;
  }
`;

interface TabLinkProps {
  active: boolean;
}

export const TabLink = styled.a.attrs((props: TabLinkProps) => ({
  className: props.active,
}))`
  padding: 17px 17px 17px 17px;
  text-decoration: none;
  font-weight: 500;
  color: ${defaultTheme.colors.gray};
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    color: ${defaultTheme.colors.primary};
    font-weight: 600;
    border-bottom: solid 3px ${defaultTheme.colors.primary};
  }

  @media ${device.mobileS} {
    font-size: 16px;
    line-height: 24px;
    width: 100%;
  }

  @media ${device.laptop} {
    font-size: 20px;
    line-height: 30px;
  }
`;
