const moment = require('moment');

export function formatDate(format: string, date: Date): string {
  const result = moment(new Date()).format(format);
  try {
    if (date == undefined || date == null) return result;
    if (!moment(date).isValid()) return result;
    return moment(new Date(date)).format(format);
  } catch (err) {
    return result;
  }
}

//convert timestamp to date
export function convertTimestampToDate(timestamp: number, format: string): string {
  const date = new Date(timestamp);
  return formatDate(format, date);
}

//convert date to timestamp
export function convertDateToTimestamp(date: Date, time: TimeRanges): number {
  const dateStr = moment(date).format('MM/DD/YYYY');
  const timeStr = moment(time).format('HH:mm');
  const fullDate = dateStr + ' ' + timeStr;

  const dateVal = new Date(fullDate);
  return moment(dateVal).format('x');
}

//convert a time string with format 00h00m or 00m00s from seconds number
export function timeActivityFromSecond(second: number): string {
  const hours: number = Math.floor(second / 3600);
  const minutes: number = Math.floor((second - hours * 3600) / 60);
  const seconds: number = second - hours * 3600 - minutes * 60;

  let strHours, strMinutes, strSeconds;

  if (hours == 0) strHours = '';
  else strHours = hours < 10 ? '0' + hours + 'h ' : hours + 'h ';

  if (minutes == 0) strMinutes = '00m';
  else strMinutes = minutes < 10 ? '0' + minutes + 'm ' : minutes + 'm ';

  if (seconds == 0) strSeconds = '00s';
  else strSeconds = seconds < 10 ? '0' + seconds + 's ' : seconds + 's ';

  if (strHours.length > 0) return strHours + strMinutes;
  else return strMinutes + strSeconds;
}

export function humanTime(timestamp: number, lang: string) {
  const yesterday = formatDate('DD-MM-YYYY', moment().subtract(1, 'days'));
  const today = formatDate('DD-MM-YYYY', new Date());
  const runDate = formatDate('DD-MM-YYYY', new Date(timestamp));

  //get hour in date
  const hour = formatDate('hh:mm A', new Date(timestamp));

  const strToday = lang == 'en' ? 'Today at ' : 'Hôm nay lúc ';
  const strYesterday = lang == 'en' ? 'Yesterday at ' : 'Hôm qua lúc ';
  const strOther = lang == 'en' ? ' at ' : ' lúc ';

  if (runDate == today) return strToday + hour;
  else if (runDate == yesterday) return strYesterday + hour;
  else return runDate + strOther + hour;
}
