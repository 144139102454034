import { API } from '../configs/APIEndPoint';
import { handleError } from '../helpers/APIHelper';
import { IGetEventDetailRequest } from '../types/IEvent';
import { IGetEventRankingRequest } from '../types/ILeaderBoard';
import BaseAuthService from './BaseAuthService';

class EventInfoService extends BaseAuthService {
  private static instance: EventInfoService;

  public static getInstance(): EventInfoService {
    if (!EventInfoService.instance) {
      EventInfoService.instance = new EventInfoService();
    }
    return EventInfoService.instance;
  }

  getEventDetail = async (params: IGetEventDetailRequest) => {
    try {
      const response = await this.postData(API.EVENT.DETAIL, params);
      return this.handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  };

  getEventRanking = async (params: IGetEventRankingRequest) => {
    try {
      const response = await this.postData(API.EVENT.EVENT_RAKING, params);
      return this.handleResponseList(response);
    } catch (error: any) {
      return handleError(error);
    }
  };
}
export default EventInfoService;
