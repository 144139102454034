import styled from 'styled-components';

import { AppButton } from './../../../../styles/ButtonStyles';

export const ChartContainer = styled.div`
  border: solid 1px rgba(0, 0, 0, 0.1);
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const LineChartContainer = styled(ChartContainer)``;

export const SpinContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 400px;
`;

export const BarChartContainer = styled(ChartContainer)``;

export const TooltipContainer = styled.div`
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  overflow-y: auto;
  max-height: 400px;
  margin-top: 10px;
`;

export const TooltipHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TooltipDetailContainer = styled.div`
  border-top: solid 1px #33ccee;
  padding: 10px;
`;

export const TooltipVal = styled.p`
  font-weight: 600;
  font-size: 16px;
  margin: 4px;
`;

export const TooltipText = styled.p`
  font-weight: 400;
  font-size: 14px;
  margin: 4px;
`;

export const TooltipDetailItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TooltipDetailVal = styled.p`
  font-weight: 600;
  font-size: 14px;
  margin: 4px;
`;

export const TooltipDetailText = styled.p`
  font-weight: 400;
  font-size: 12px;
  margin: 4px;
`;

export const FilterContainer = styled.div`
  display: flex;
`;

export const ZoomButton = styled(AppButton)`
  margin-left: 10px;
  height: 30px;
`;
