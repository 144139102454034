import { Collapse } from 'antd';
import { ComponentType } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import pure from 'recompose/pure';

import { IAcceleration } from '../../../../types/IFeed';
//styles
import {
  LineChartContainer,
  TooltipContainer,
  TooltipHeaderContainer,
  TooltipText,
  TooltipVal,
} from './Charts.styles';

interface Props {
  data: IAcceleration[];
}

const { Panel } = Collapse;

export const AccelerationStats: ComponentType<Props> = pure(({ data }) => {
  //check if data not exist...
  if (!data || data.length === 0) return null;

  const CustomTooltip = ({ active, payload, label }: TooltipProps<any, any>) => {
    if (active) {
      return (
        <TooltipContainer>
          <TooltipHeaderContainer>
            <TooltipText>Tổng số </TooltipText>
            <TooltipVal>{Math.round(payload?.[0].value)}</TooltipVal>
            <TooltipText>GPS ở gia tốc</TooltipText>
            <TooltipVal>{label}</TooltipVal>
            <TooltipText>x0.1 km/h/s</TooltipText>
          </TooltipHeaderContainer>
        </TooltipContainer>
      );
    }
    return null;
  };

  const chartWidth = data.length * 30 < 1024 ? 1024 : data.length * 30;

  return (
    <Collapse style={{ marginBottom: 10 }}>
      <Panel header='Phân bổ GPS theo gia tốc' key={1}>
        <LineChartContainer>
          <AreaChart
            width={chartWidth}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}>
            <defs>
              <linearGradient id='colorAcceleration' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='5%' stopColor='#f77e2d' stopOpacity={0.8} />
                <stop offset='95%' stopColor='#f77e2d' stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='key' allowDataOverflow={false}>
              <Label value='Gia tốc (0.1 km/h/s)' offset={-1} position='insideBottomLeft' />
            </XAxis>
            <YAxis dataKey='val' allowDataOverflow={false}>
              <Label value='GPS (point)' offset={0} angle={-90} position='insideLeft' />
            </YAxis>
            <Tooltip cursor={{ stroke: '#f77e2d', strokeWidth: 1 }} content={<CustomTooltip />} />
            <Area
              type='monotone'
              dataKey='val'
              stroke='#f77e2d'
              fillOpacity={1}
              activeDot={{ r: 8 }}
              fill='url(#colorAcceleration)'
            />
          </AreaChart>
        </LineChartContainer>
      </Panel>
    </Collapse>
  );
});
