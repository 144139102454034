import { Collapse } from 'antd';
import { ComponentType, useEffect, useState, useTransition } from 'react';
import { Bar, BarChart, CartesianGrid, Label, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import pure from 'recompose/pure';

import { Spinner } from '../../../../components/Spinner/Spinner';
import { ISpeed } from '../../../../types/IFeed';
//styles
import {
  BarChartContainer,
  TooltipContainer,
  TooltipHeaderContainer,
  TooltipText,
  TooltipVal,
} from './Charts.styles';

interface Props {
  data: ISpeed[];
}

const { Panel } = Collapse;

export const SpeedStats: ComponentType<Props> = pure(({ data }) => {
  //check if data not exist...
  if (!data || data.length === 0) return null;

  const [isPending, startTransition] = useTransition();
  const [chartData, setChartData] = useState<ISpeed[]>([]);

  useEffect(() => {
    startTransition(() => setChartData(data));
  }, [data]);

  const CustomTooltip = ({ active, payload, label }: TooltipProps<any, any>) => {
    if (active) {
      return (
        <TooltipContainer>
          <TooltipHeaderContainer>
            <TooltipText>Tổng số </TooltipText>
            <TooltipVal>{Math.round(payload?.[0].value)}</TooltipVal>
            <TooltipText>GPS ở vận tốc</TooltipText>
            <TooltipVal>{label}</TooltipVal>
            <TooltipText>km/h</TooltipText>
          </TooltipHeaderContainer>
        </TooltipContainer>
      );
    }
    return null;
  };

  const renderChart = () => {
    return (
      <BarChart
        width={chartWidth}
        height={300}
        data={chartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='key'>
          <Label value='Tốc độ (km/h)' offset={-1} position='insideBottomLeft' />
        </XAxis>
        <YAxis dataKey='val'>
          <Label value='GPS (point)' offset={0} angle={-90} position='insideLeft' />
        </YAxis>
        <Tooltip cursor={{ stroke: '#f77e2d', strokeWidth: 1 }} content={<CustomTooltip />} />

        <Bar dataKey='val' fill='#f77e2d' />
      </BarChart>
    );
  };

  const chartWidth = data.length * 40 < 1024 ? 1024 : data.length * 40;

  return (
    <Collapse defaultActiveKey={1} style={{ marginBottom: 10 }}>
      <Panel header='Biểu đồ phân bổ GPS theo tốc độ' key={1}>
        <BarChartContainer>{isPending ? <Spinner /> : renderChart()}</BarChartContainer>
      </Panel>
    </Collapse>
  );
});
