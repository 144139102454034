import React, { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import { Footer } from './Footer/Footer';
import { Header } from './Header/Header';

// define interface to represent component props
interface Props {
  children?: ReactNode;
}

const MainLayout: FC<Props> = ({ children }) => {
  return (
    <div>
      <Header></Header>
      {/* <main className="main" role="main">
                {children}
            </main> */}
      <Outlet />
      <Footer />
    </div>
  );
};

export default MainLayout;
