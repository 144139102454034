import { action, makeObservable, observable, runInAction, toJS } from 'mobx';

import { APIResponse } from '../models/APIResponse';
import { ListEventRanking } from '../models/Event';
import { IEvent, IGetEventDetailRequest } from '../types/IEvent';
import RootStore from './RootStore';
import { IGetEventRankingRequest } from '../types/ILeaderBoard';
import EventInfoService from '../services/EventInfoService';

class EventInfoStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  //declare variables
  @observable currentEvent: IEvent | null = null;
  @observable rankingData: any | null = null;

  @action getEventDetail = async (params: IGetEventDetailRequest) => {
    const result = (await EventInfoService.getInstance().getEventDetail(
      params,
    )) as APIResponse<IEvent>;

    if (result && result.isSuccess()) {
      console.log('result.data', result.data);
      runInAction(() => {
        this.currentEvent = result.data!;
      });
    }
    return result;
  };

  @action getEventRanking = async (params: IGetEventRankingRequest) => {
    const result = (await EventInfoService.getInstance().getEventRanking(
      params,
    )) as APIResponse<any>;

    if (result && result.isSuccess()) {
      console.log('result.data', result.data);
      runInAction(() => {
        this.rankingData = result.data!;
        console.log('rankingData', toJS(this.rankingData));
      });
    }
    return result;
  };
}
export default EventInfoStore;
