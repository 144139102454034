import styled from 'styled-components';

import { MapImage } from '../../../components/Feed/MapImage';

export const MapActivityWrapper = styled.div`
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-left: 20px;
  margin-top: 30px;
`;

export const MapActivityContainer = styled.div``;

export const MapActivityImage = styled(MapImage)`
  width: 100%;
  object-fit: cover;
`;
