import { Modal } from 'antd';
import styled, { css } from 'styled-components';

import { defaultTheme } from '../../themes';

export const ModalButton = css`
  border-radius: 10px;
  font-weight: 600;
  height: 40px;
`;

export const ModalStyle = styled(Modal)`
  .ant-modal-header {
    border-radius: 10px;
  }
  .ant-modal-body {
    text-align: center;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-btn-default {
    ${ModalButton}
    background-color: ${defaultTheme.colors.secondary};
    border: solid 1px ${defaultTheme.colors.primary};
    color: ${defaultTheme.colors.black};
  }
  .ant-btn-primary {
    ${ModalButton}
    background-color: ${defaultTheme.colors.primary};
  }
`;
