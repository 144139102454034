import { Navigate, Route, Routes } from 'react-router-dom';

//protected router with private resources must be login
import ProtectedRoute from '../components/ProtectedRoute';
//import layouts
import MainLayout from '../layouts/MainLayout/MainLayout';
import { AboutUs } from '../views/AboutUs/AboutUs';
import { ContactUs } from '../views/ContactUs/ContactUs';
import { EventRules } from '../views/EventRules/EventRules';
import { FeedDetail } from '../views/FeedDetail/FeedDetail';
//UpRace 2023
import { FundsInfo } from '../views/FundsInfo/FundsInfo';
//import views
import { LandingPage } from '../views/LandingPage/LandingPage';
import { ActivityStats } from '../views/Reports/ActivityStats/ActivityStats';
import { TermsView } from '../views/TermsView/TermsView';
import { JoinNow } from '../views/UpRace22/JoinNow';
import { NewsDetail } from '../views/UpRace22/NewsDetail/NewsDetail';
//views for UpRace 2022
import { UpRace22LandingPage } from '../views/UpRace22/UpRace22LandingPage';
import { UpraceNews } from '../views/UpraceNews/UpraceNews';
import { UserProfile } from '../views/UserProfile/UserProfile';
import EventInfo from '../views/EventInfo/EventInfo';
import { NewsPage } from '../views/NewsPage';

const AppRouter = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path='/' element={<LandingPage />} />
        <Route path='/login' element={<LandingPage />} />
        <Route path='/about' element={<AboutUs />} />
        <Route path='/contact' element={<ContactUs />} />
        {/* <Route path='/uprace24' element={<UpRace22LandingPage />} />
        <Route path='/event' element={<EventInfo />} /> */}
        <Route path='/join-now' element={<JoinNow />} />
        <Route path='/news-detail' element={<NewsDetail />} />
        <Route path='/feed-detail' element={<FeedDetail />} />
        <Route path='/user-profile' element={<UserProfile />} />
        <Route path='/reports/activity-stats' element={<ActivityStats />} />
        <Route path='*' element={<Navigate to='/' />} />
        <Route path='/news' element={<NewsPage />} />
        <Route path="/event/:id" element={<EventInfo />} />
      </Route>
      <Route path='/terms-v2' element={<TermsView />} />
      {/* <Route path='/event/rules' element={<EventRules />} /> */}
      <Route path='/fund/basic' element={<FundsInfo />} />
    </Routes>
  );
};


export default AppRouter;
