import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Collapse, message, Modal, Popover, Row, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

import { SPORT_MARK_LABEL_FEED } from '../../../../data/sportType';
import { APIResponse } from '../../../../models/APIResponse';
import { FeedAnalysis } from '../../../../models/Feed';
import { useRootStore } from '../../../../providers/RootStoreProvider';
import {
  FEED_POWER_TYPE,
  FEED_PREDICTION_RUNNING,
  IMarkLabelRequest,
} from '../../../../types/IFeed';
import { APP_BUTTON_TYPE, ITabMenu } from '../../../../types/IGlobal';
import { IResponseBase } from '../../../../types/ITypeBase';
//styles
import {
  LabelText,
  MarkLabelButton,
  MarkLabelContainer,
  MarkLabelWrapper,
  ValueText,
} from './MarkLabel.styles';

interface Props {
  data: FeedAnalysis;
}

const { Panel } = Collapse;
const { Option } = Select;

const LABEL_RUNNING: ITabMenu[] = [
  {
    id: FEED_PREDICTION_RUNNING.NONE,
    title: 'feedDetail:markLabelSport.none',
  },
  {
    id: FEED_PREDICTION_RUNNING.CLEAN,
    title: 'feedDetail:markLabelSport.normal',
  },
  {
    id: FEED_PREDICTION_RUNNING.FUZZY,
    title: 'feedDetail:markLabelSport.unClear',
  },
  {
    id: FEED_PREDICTION_RUNNING.CHEAT,
    title: 'feedDetail:markLabelSport.cheat',
  },
];

const LABEL_POWER: ITabMenu[] = [
  {
    id: FEED_POWER_TYPE.UNKNOWN,
    title: 'feedDetail:markLabelPower.unknown',
  },
  {
    id: FEED_POWER_TYPE.NORMAL,
    title: 'feedDetail:markLabelPower.normal',
  },
  {
    id: FEED_POWER_TYPE.OVERLOAD,
    title: 'feedDetail:markLabelPower.overload',
  },
];

const content = (
  <div>
    <p>
      Chạy bộ: tốc độ {'>'} 6km/h (pace {'<'} 10:00)
    </p>
    <p>
      Đi bộ: tốc độ {'<='} 6km/h (pace {'>='} 10:00)
    </p>
    <p>Phối hợp: đi/chạy bộ kết hợp đạp/xe máy...</p>
    <p>GPS lỗi: Không có GPS, GPS lag quá nhiều,GPS fake...</p>
  </div>
);

export const MarkLabel: ComponentType<Props> = observer(({ data }) => {
  const store = useRootStore();
  const { t } = useTranslation();

  const { markLabel } = store.feedStore;

  const [labelRunning, setLabelRunning] = React.useState(
    data.labelRunning ? data.labelRunning : -1,
  );
  const [labelSport, setLabelSport] = React.useState(data.labelSport ? data.labelSport : -1);
  const [labelPower, setLabelPower] = React.useState(data.labelPower ? data.labelPower : -1);

  useEffect(() => {
    //reset values
    setLabelRunning(data.labelRunning ? data.labelRunning : -1);
    setLabelSport(data.labelSport ? data.labelSport : -1);
    setLabelPower(data.labelPower ? data.labelPower : -1);
  }, [data]);

  const onChangeLabelRunning = (value: any) => {
    setLabelRunning(value);
    data.setLabelRunning(value);
  };

  const onChangeLabelPower = (value: any) => {
    setLabelPower(value);
    data.setLabelPower(value);
  };

  const onChangeLabelSport = (value: any) => {
    setLabelSport(value);
    data.setLabelSport(value);
  };

  const renderExtra = () => {
    return (
      <MarkLabelButton
        onClick={(event: any) => {
          event.stopPropagation();
          onMarkLabel();
        }}
        typeButton={APP_BUTTON_TYPE.LIGHT}>
        Gắn nhãn
      </MarkLabelButton>
    );
  };

  const onMarkLabel = async () => {
    const params: IMarkLabelRequest = {
      data: {
        atid: data.atid,
        labelRunning: labelRunning,
        labelSport: labelSport,
        labelPower: labelPower,
      },
    };
    const result: APIResponse<IResponseBase> = await markLabel(params);
    if (result.isError()) {
      Modal.error({
        title: t('errors:actionFailure'),
        content: result.getDescription(),
        centered: true,
      });
    } else {
      /*Modal.success({
                title: t('errors:actionSuccess'),
                centered: true
            });*/
      message.success(t('errors:actionSuccess'));
    }
  };

  return (
    <MarkLabelWrapper>
      <Collapse defaultActiveKey={1} style={{ marginBottom: 10 }}>
        <Panel header='Dự báo hoạt động' key={1} extra={renderExtra()}>
          <MarkLabelContainer>
            <Row>
              <Col span={8}>
                <LabelText>Dự báo đi/chạy bộ</LabelText>
                <ValueText>{data.getPredictionRunningString()}</ValueText>
              </Col>
              <Col span={8}>
                <LabelText>Dự báo loại hoạt động</LabelText>
                <ValueText>{data.getPredictionSportString()}</ValueText>
              </Col>
              <Col span={8}>
                <LabelText>Dự báo thể lực</LabelText>
                <ValueText>{data.getPredictionPowerString()}</ValueText>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <LabelText>Gán nhãn chạy/đi bộ</LabelText>

                <Select
                  defaultValue={data.labelRunning}
                  value={data.labelRunning ? data.labelRunning : null}
                  style={{ width: 150, paddingTop: 5 }}
                  onChange={onChangeLabelRunning}>
                  {LABEL_RUNNING.map((item: any) => {
                    return (
                      <Option value={item.id} key={item.id}>
                        {t(item.title)}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
              <Col span={8}>
                <LabelText>Gắn nhãn loại hoạt động</LabelText>

                <Select
                  defaultValue={data.labelSport}
                  value={data.labelSport ? data.labelSport : null}
                  style={{ width: 150, paddingTop: 5 }}
                  onChange={onChangeLabelSport}>
                  {SPORT_MARK_LABEL_FEED.map((sport: any) => {
                    return (
                      <Option value={sport.id} key={sport.id}>
                        {t(sport.title)}
                      </Option>
                    );
                  })}
                </Select>
                <Popover content={content} title='' trigger='click'>
                  <InfoCircleOutlined
                    style={{
                      fontSize: '20px',
                      color: '#e27f0e',
                      marginLeft: '10px',
                      cursor: 'pointer',
                    }}
                  />
                </Popover>
              </Col>
              <Col span={8}>
                <LabelText>Gán nhãn thể lực</LabelText>

                <Select
                  defaultValue={data.labelPower}
                  value={data.labelPower ? data.labelPower : null}
                  style={{ width: 150, paddingTop: 5 }}
                  onChange={onChangeLabelPower}>
                  {LABEL_POWER.map((item: any) => {
                    return (
                      <Option value={item.id} key={item.id}>
                        {t(item.title)}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
          </MarkLabelContainer>
        </Panel>
      </Collapse>
    </MarkLabelWrapper>
  );
});
