import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { ComponentType, useEffect } from 'react';

import { LoadMore } from '../../../components/LoadMore/LoadMore';
import { Feed } from '../../../models/Feed';
import { UserProfile } from '../../../models/UserProfile';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { FEED_SOURCE_TYPE, IGetListFeedRequest } from '../../../types/IFeed';
import { formatNumber } from '../../../utils/FormatUtils';
//views
import { ActivityItem } from '../ActivityItem/ActivityItem';
import { TitleBold, TitleContainer, TitleNormal } from '../UserProfile.styles';
//styles
import { ActivityListContainer, ActivityListWrapper } from './ActivityList.styles';

interface Props {
  userProfile: UserProfile;
}

export const ActivityList: ComponentType<Props> = observer(({ userProfile }) => {
  const store = useRootStore();

  const { listFeed, getListFeed } = store.feedStore;

  useEffect(() => {
    onGetListFeed(false);
  }, [userProfile.userId]);

  const onGetListFeed = async (isLoadMore: boolean) => {
    const params: IGetListFeedRequest = {
      data: {
        feed: FEED_SOURCE_TYPE.ME,
        value: userProfile.userId,
      },
    };
    const result = await getListFeed(params, isLoadMore);
  };

  const renderHeader = () => {
    if (listFeed?.isLoading) return <Spin />;
    else
      return (
        <>
          <TitleBold>Hoạt động</TitleBold>
          <TitleNormal>({listFeed && formatNumber(listFeed.totalRecord)})</TitleNormal>
        </>
      );
  };

  return (
    <ActivityListWrapper>
      <ActivityListContainer>
        <TitleContainer>{renderHeader()}</TitleContainer>
        {listFeed &&
          listFeed.list.map((feed: Feed) => {
            return <ActivityItem feedData={feed} key={feed.feedId} />;
          })}
      </ActivityListContainer>
      {listFeed?.isLoadMore ? (
        <LoadMore onClick={() => onGetListFeed(true)} loading={listFeed.isLoading} />
      ) : null}
    </ActivityListWrapper>
  );
});
