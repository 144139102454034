import './TypedEffect.css';

import { ComponentType } from 'react';
import { pure } from 'recompose';
import Typewriter from 'typewriter-effect';

interface Props {
  strings: string[];
}

export const TypedEffect: ComponentType<Props> = pure(({ strings }) => {
  return (
    <Typewriter
      options={{
        strings,
        autoStart: true,
        loop: true,
        delay: 70,
      }}
    />
  );
});
