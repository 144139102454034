import React from 'react';
import { useTranslation } from 'react-i18next';

export const AnnualEventSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className='annual-event-section'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='card d-none d-md-block'>
              <div className='card-body d-none d-md-flex'>
                <div className='row'>
                  <div className='col-12 col-md-5'>
                    <h1 className='heading'>{t('landing:annualEvent.title')}</h1>
                    <div className='desc'>{t('landing:annualEvent.description')}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card d-flex d-md-none'>
              <div className='card-img-overlay'>
                <div className='row'>
                  <div className='col-12 col-md-5'>
                    <h1 className='heading'>{t('landing:annualEvent.title')}</h1>
                    <div className='desc'>{t('landing:annualEvent.description')}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
