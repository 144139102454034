import { API } from '../configs/APIEndPoint';
import { handleError } from '../helpers/APIHelper';
import {
  IGetFeedAnalysisRequest,
  IGetFeedDetailRequest,
  IGetListFeedRequest,
  IMarkLabelRequest,
} from '../types/IFeed';
import BaseAuthService from './BaseAuthService';

class FeedService extends BaseAuthService {
  private static instance: FeedService;

  public static getInstance(): FeedService {
    if (!FeedService.instance) {
      FeedService.instance = new FeedService();
    }
    return FeedService.instance;
  }

  //methods api
  getFeedDetail = async (params: IGetFeedDetailRequest) => {
    try {
      const response = await this.postData(API.SOCIAL.FEED.DETAILS, params);
      return this.handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  };

  getFeedAnalysis = async (params: IGetFeedAnalysisRequest) => {
    try {
      const response = await this.postData(API.SOCIAL.FEED.ANALYSIS, params);
      return this.handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  };

  markLabel = async (params: IMarkLabelRequest) => {
    try {
      const response = await this.postData(API.SOCIAL.FEED.MARK_LABEL, params);
      return this.handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  };

  getListFeed = async (params: IGetListFeedRequest) => {
    try {
      const response = await this.postData(API.SOCIAL.FEED.LIST, params);
      return this.handleResponseList(response);
    } catch (error: any) {
      return handleError(error);
    }
  };
}
export default FeedService;
