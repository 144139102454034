import React from 'react';
import { useTranslation } from 'react-i18next';

import { Donate } from './Donate';
import { Runner } from './Runner';
import { Sponsor } from './Sponsor';

export const HowToJoinSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className='how-join-section'>
        <div className='container'>
          <div className='row how-join-header justify-content-center'>
            <div className='col-md-8 text-center'>
              <h1 className='heading'>{t('uprace2022:howToJoin.heading')}</h1>

              <p className='desc'>{t('uprace2022:howToJoin.description')}</p>
            </div>
          </div>
          <div className='row justify-content-md-center'>
            <div className='col-12 col-md-9'>
              <ul
                className='nav nav-pills nav-sponsor nav-justified justify-content-center mb-4'
                role='tablist'>
                <li className='nav-item me-2 me-sm-5 mb-2' role='presentation'>
                  <button
                    className='nav-link mb-2 mb-md-0 active'
                    id='join-pills-tab-1'
                    data-bs-toggle='pill'
                    data-bs-target='#join-pills-tabs-1'
                    type='button'
                    role='tab'
                    aria-controls='join-pills-tabs-1'
                    aria-selected='true'>
                    <span className='icon icon-run'></span>
                    <br />
                    <span>{t('uprace2022:howToJoin.runner.title')}</span>
                  </button>
                </li>
                <li className='nav-item me-2 me-sm-5 mb-2' role='presentation'>
                  <button
                    className='nav-link mb-2 mb-md-0'
                    id='join-pills-tab-2'
                    data-bs-toggle='pill'
                    data-bs-target='#join-pills-tabs-2'
                    type='button'
                    role='tab'
                    aria-controls='join-pills-tabs-2'
                    aria-selected='false'>
                    <span className='icon icon-sponsor'></span>
                    <br />
                    <span>{t('uprace2022:howToJoin.sponsor.title')}</span>
                  </button>
                </li>
                <li className='nav-item mb-2' role='presentation'>
                  <button
                    className='nav-link mb-2 mb-md-0'
                    id='join-pills-tab-3'
                    data-bs-toggle='pill'
                    data-bs-target='#join-pills-tabs-3'
                    type='button'
                    role='tab'
                    aria-controls='join-pills-tabs-3'
                    aria-selected='false'>
                    <span className='icon icon-donate'></span>
                    <br />
                    <span>{t('uprace2022:howToJoin.donate.title')}</span>
                  </button>
                </li>
              </ul>
              <div className='tab-content'>
                <div
                  className='tab-pane fade show active'
                  id='join-pills-tabs-1'
                  role='tabpanel'
                  aria-labelledby='join-pills-tabs-1'
                  tabIndex={0}>
                  <Runner />
                </div>
                <div
                  className='tab-pane fade'
                  id='join-pills-tabs-2'
                  role='tabpanel'
                  aria-labelledby='join-pills-tabs-2'
                  tabIndex={0}>
                  <Sponsor />
                </div>
                <div
                  className='tab-pane fade'
                  id='join-pills-tabs-3'
                  role='tabpanel'
                  aria-labelledby='join-pills-tabs-3'
                  tabIndex={0}>
                  <Donate />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
