import styled from 'styled-components';

import { device } from '../../../../configs/MediaQuery';

export const TabTimerWrapper = styled.div`
  width: 100%;
  padding-top: 18px;
`;

export const TabTimerContainer = styled.div`
  display: flex;
  height: 50px;
  align-items: flex-end;
  border-bottom: solid 1px #e7eaec;

  @media ${device.mobileS} {
    position: relative;
    padding-top: 100px;
  }
  @media ${device.laptop} {
    padding: 0;
  }
`;

export const TabContainer = styled.ul`
  display: flex;
  flex-grow: 1;
  margin: 0;
  padding-left: 0;
  list-style-type: none;
`;

export const TabItem = styled.li`
  display: flex;
  flex-basis: 33.33%;
`;

interface TabLinkProps {
  active: boolean;
}

export const TabLink = styled.a.attrs((props: TabLinkProps) => ({
  className: props.active,
}))`
  padding: 17px 17px 17px 17px;
  text-decoration: none;
  font-weight: 500;
  color: #314154;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    color: #0254dc;
    font-weight: 600;
    border-bottom: solid 3px #0254dc;
  }

  @media ${device.mobileS} {
    font-size: 16px;
    line-height: 20px;
    width: 100%;
  }

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 24px;
  }
`;
