import styled from 'styled-components';

import { device } from '../../configs/MediaQuery';

export const FundItemWrapper = styled.div``;

export const FundItemContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const FundTitleWrapper = styled.div``;

export const FundTitleText = styled.h1`
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  @media ${device.mobileS} {
    font-size: 22px;
  }
  @media ${device.laptop} {
    font-size: 30px;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const LogoImage = styled.img`
  @media ${device.mobileS} {
    height: 150px;
  }
  @media ${device.laptop} {
    height: 200px;
  }
`;

export const CoverWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  margin-bottom: 50px;
`;

export const CoverImage = styled.img`
  @media ${device.mobileS} {
    width: 100%;
  }
  @media ${device.laptop} {
    max-width: 500px;
  }
`;

export const InfoWrapper = styled.div``;

export const InfoText = styled.p``;
