import styled from 'styled-components';

export const TeamListWrapper = styled.div`
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-left: 30px;
  border-radius: 8px;
  margin-top: 30px;
  background: #fff;
`;

export const TeamListContainer = styled.div`
  padding: 20px;
`;

export const LineDivider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: -10px;
  margin-bottom: 10px;
  background-color: #e7eaec;
`;
