import styled from 'styled-components';

import { device } from '../configs/MediaQuery';

export const FlexContainerRowCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const TextCenterContainer = styled.div`
  text-align: center;
`;

export const TextLeftCenterContainer = styled.div`
  @media ${device.mobileS} {
    text-align: center;
  }

  @media ${device.tablet} {
    text-align: left;
  }
`;

export const AppCard = styled.div`
  color: #212b36;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border: 0;
  text-align: left;
  border-radius: 8px;

  @media ${device.mobileS} {
    max-width: 100%;
    margin: 10px;
  }

  @media ${device.laptop} {
    max-width: 100%;
    margin: 10px;
  }
`;
