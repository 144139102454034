import './index.css';
import './i18n';
import 'moment/locale/en-gb'; // important!

//fix locale start week from Monday all antd calendar component
import { ConfigProvider } from 'antd';
import en_GB from 'antd/lib/locale-provider/en_GB';
import moment from 'moment';
import ReactDOM from 'react-dom/client';

import App from './App';
import { GlobalModalProvider } from './providers/GlobalModalProvider';
import { RootStoreProvider } from './providers/RootStoreProvider';
import reportWebVitals from './reportWebVitals';
moment.locale('en-gb'); // important!

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <RootStoreProvider>
    <GlobalModalProvider>
      <ConfigProvider locale={en_GB}>
        <App />
      </ConfigProvider>
    </GlobalModalProvider>
  </RootStoreProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
