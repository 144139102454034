import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { AvatarImage } from '../../../../../components/User/AvatarImage';
import { device } from '../../../../../configs/MediaQuery';
import { AppButton } from '../../../../../styles/ButtonStyles';

export const UserItemWrapper = styled.div`
  position: relative;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: solid 1px #e7eaec;
  }

  &:hover {
    background-color: #e7eaec;
  }
`;

export const UserItemContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
`;

export const AvatarContainer = styled.div`
  margin-right: 8px;
`;

export const AvatarTeam = styled(AvatarImage)`
  border-radius: 50%;
  object-fit: cover;
  @media ${device.mobileS} {
    width: 40px;
    height: 40px;
  }
  @media ${device.tablet} {
    width: 50px;
    height: 50px;
  }
  @media ${device.laptop} {
    width: 50px;
    height: 50px;
  }
`;

export const InfoContainer = styled.div`
  flex-grow: 1;
  text-align: left;
`;

export const LabelText = styled.p`
  margin: 0;
  font-weight: 400;
  color: #797979;

  @media ${device.mobileS} {
    font-size: 12px;
    line-height: 18px;
  }
  @media ${device.tablet} {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const ValueText = styled(LabelText)`
  margin: 0;
  font-weight: 600;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  @media ${device.mobileS} {
    font-size: 14px;
    line-height: 24px;
  }
  @media ${device.tablet} {
    font-size: 16px;
    line-height: 30px;
  }
`;

export const UserLink = styled(Link)`
  text-decoration: none;
  display: inline-block;
  width: 100%;
`;

export const FollowButton = styled(AppButton)`
  height: 35px;
  width: 100px;
  position: absolute;
  right: 16px;
  top: calc(50% - 16px);
`;
