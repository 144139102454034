import { Card, Col, Row } from 'antd';
import styled from 'styled-components';

import { device } from '../../../configs/MediaQuery';
import { ButtonPrimary } from '../../../styles/ButtonStyles';
import { Container } from '../../../styles/LandingPageStyle';
import { TextHeadingH2, TextHeadingH5 } from '../../../styles/TypographyStyles';

export const RowContainer = styled(Container)`
  position: relative;
  padding: 0;
`;
export const BannerWrapper = styled.div`
  height: 650px;
  @media ${device.mobileS} {
    padding-left: 0;
    padding-right: 0;
    height: auto;
  }
  @media ${device.laptop} {
    padding-left: 41px;
  }
`;

export const BannerImage = styled.img`
  max-width: 100%;
  height: auto;
`;

export const ContactFormWrapper = styled(Card)`
  color: #212b36;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
  border: 0;
  border-radius: 10px;
  margin-top: -90px;
  margin-left: 16px;
  margin-right: 16px;

  @media ${device.laptop} {
    position: absolute;
    max-width: 490px;
    width: 100%;
    height: 550px;
    padding: 8px;
    top: 50px;
    left: 0;
    margin: 0;
  }
`;

export const FormTitleContainer = styled.div`
  display: flex;
`;

export const ContactFormTitle = styled(TextHeadingH2)``;

export const ContactFormTitleSub = styled.p`
  margin-top: 12px;
  margin-bottom: 16px;
`;

export const FormInput = styled.form``;

export const FormInputTitle = styled(TextHeadingH5)``;

export const FormInputContainer = styled(Row)`
  margin-left: -6px;
  margin-right: -6px;
`;

export const TextInputContainer = styled(Col)`
  padding: 6px;
`;

export const FormActionContainer = styled.div`
  text-align: right;
  margin-top: 10px;
  padding: 6px;
`;

export const SendButton = styled(ButtonPrimary)`
  height: 44px;
  font-weight: 500;
  font-size: 16px;

  @media ${device.mobileS} {
    width: 100%;
  }

  @media ${device.laptop} {
    width: 130px;
  }
`;
