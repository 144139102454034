import _ from 'lodash';
import { ComponentType } from 'react';
import { Navigate } from 'react-router-dom';
import pure from 'recompose/pure';

import { AsvhoFund } from './FundItem/AsvhoFund';
//components
import { GreenViet } from './FundItem/GreenViet';
import { VuADinh } from './FundItem/VuADinh';
//styles
import { FundsInfoContainer, FundsInfoWrapper } from './FundsInfo.styles';
import { SoSinhVN } from './FundItem/SoSinhVN';

const queryString = require('query-string');

interface Props {}

export const FundsInfo: ComponentType<Props> = pure(({}) => {
  const paramsQuery = queryString.parse(window.location.search);
  if (_.isEmpty(paramsQuery)) return <Navigate to='/'></Navigate>;

  const id = paramsQuery.fuid;
  const lang = paramsQuery.lang;

  const renderFund = () => {
    if (id == 3) return <GreenViet lang={lang} />;
    else if (id == 8) return <AsvhoFund lang={lang} />;
    else if (id == 10) return <VuADinh lang={lang} />;
    else if (id == 11) return <SoSinhVN lang={lang} />;
  };

  return (
    <FundsInfoWrapper>
      <FundsInfoContainer>{renderFund()}</FundsInfoContainer>
    </FundsInfoWrapper>
  );
});
