import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { AuthHeader } from '../helpers/AuthHeader';
import { EVENT_LOCK_MEMBER_TYPE } from '../types/IEvent';
import { IEvent } from '../types/IEvent';
import { LOGIN_TYPE } from '../types/ILogin';
import { ACCOUNT_ROLE, ACCOUNT_STATUS, FOLLOW_REQUEST_STATUS } from '../types/IMember';
import { IUserProfile } from '../types/IUserProfile';
import Team from './Team';

export class UserProfile {
  userId: number;
  name: string;
  code: string;
  avatar: string;
  sex: number;
  city: string;
  country: string;
  status: ACCOUNT_STATUS;
  accountRole: ACCOUNT_ROLE;
  teamName: string;
  loginType: LOGIN_TYPE;
  totalFollowing: number;
  totalFollower: number;
  teamList: Array<any>;
  eventList: IEvent[];
  followerStatus: FOLLOW_REQUEST_STATUS;
  eventLockStatus: EVENT_LOCK_MEMBER_TYPE;

  //observable properties
  @observable followingStatus: FOLLOW_REQUEST_STATUS;

  constructor(
    userId: number,
    name: string,
    code: string,
    avatar: string,
    sex: number,
    city: string,
    country: string,
    status: ACCOUNT_STATUS,
    accountRole: ACCOUNT_ROLE,
    teamName: string,
    loginType: LOGIN_TYPE,
    totalFollowing: number,
    totalFollower: number,
    teamList: Array<any>,
    eventList: IEvent[],
    followingStatus: FOLLOW_REQUEST_STATUS,
    followerStatus: FOLLOW_REQUEST_STATUS,
    eventLockStatus: EVENT_LOCK_MEMBER_TYPE,
  ) {
    makeObservable(this);

    this.userId = userId;
    this.name = name;
    this.code = code;
    this.avatar = avatar;
    this.sex = sex;
    this.city = city;
    this.country = country;
    this.status = status;
    this.accountRole = accountRole;
    this.teamName = teamName;
    this.loginType = loginType;
    this.totalFollowing = totalFollowing;
    this.totalFollower = totalFollower;
    this.teamList = teamList;
    this.eventList = eventList;
    this.followingStatus = followingStatus;
    this.followerStatus = followerStatus;
    this.eventLockStatus = eventLockStatus;
  }

  static fromJson = (json: IUserProfile): UserProfile => {
    const userProfile = new UserProfile(
      json.uid,
      json.name,
      json.code,
      json.ava,
      json.sex,
      json.city,
      json.ctry,
      json.sts,
      json.acrl,
      json.tmnm,
      json.type,
      json.ficnt,
      json.fecnt,
      json.tmls,
      json.evls,
      json.fists,
      json.fests,
      json.evlsts,
    );
    //generate team list object
    const listTeam = new Array<Team>();
    json.tmls.forEach((item) => {
      listTeam.push(Team.fromJson(item));
    });
    userProfile.teamList = listTeam;
    return userProfile;
  };

  @computed get isFollowing(): boolean {
    return this.followingStatus === FOLLOW_REQUEST_STATUS.APPROVED;
  }

  @action setFollowingStatus(status: FOLLOW_REQUEST_STATUS) {
    runInAction(() => {
      this.followingStatus = status;
    });
  }

  isDelete = (): boolean => {
    return this.status === ACCOUNT_STATUS.DELETED || this.status === ACCOUNT_STATUS.DELETE_PENDING;
  };

  isMe = (): boolean => {
    return this.userId === AuthHeader()?.userId;
  };
}
