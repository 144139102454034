import { Row } from 'antd';
import React from 'react';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import pure from 'recompose/pure';

import { SPORT_CATEGORY } from '../../../../data/sportType';
import { FEED_SPORT_TYPE } from '../../../../types/IFeed';
import { APP_BUTTON_TYPE } from '../../../../types/IGlobal';
//styles
import { SportTypeButton, SportTypeContainer, SportTypeWrapper } from './SportType.styles';

interface Props {
  onChangeSport: (sport: FEED_SPORT_TYPE) => void;
}

export const SportType: ComponentType<Props> = pure(({ onChangeSport }) => {
  const { t } = useTranslation();

  const [sportTypeIndex, setSportTypeIndex] = React.useState(FEED_SPORT_TYPE.ALL);

  const onSportItemChange = (sport: FEED_SPORT_TYPE) => {
    //update local state
    setSportTypeIndex(sport);
    //trigger onChangeSport of parent component
    onChangeSport(sport);
  };

  const renderSportItem = (sport: any) => {
    const typeButton: APP_BUTTON_TYPE =
      sport.id === sportTypeIndex ? APP_BUTTON_TYPE.PRIMARY : APP_BUTTON_TYPE.TRANSPARENT;
    return (
      <SportTypeContainer xl={6} md={12} sm={12} xs={12} key={sport.id}>
        <SportTypeButton typeButton={typeButton} onClick={() => onSportItemChange(sport.id)}>
          {t(sport.title)}
        </SportTypeButton>
      </SportTypeContainer>
    );
  };

  return (
    <SportTypeWrapper>
      <Row>
        {SPORT_CATEGORY.map((sport: any) => {
          return renderSportItem(sport);
        })}
      </Row>
    </SportTypeWrapper>
  );
});
