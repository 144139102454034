import { computed, observable, runInAction } from 'mobx';

import { EVENT_JOIN_STATUS, EVENT_OFFICIAL, EVENT_PUBlIC_TYPE } from '../types/IEvent';
import { IGroup } from './../types/IGroup';
import { DataList } from './DataList';
import { ModelBase } from './ModelBase';

export class Group extends ModelBase {
  groupId: number;
  eventId: number;
  groupName: string;
  description: string;
  avatar: string;
  cover: string;
  eventJoinStatus: EVENT_JOIN_STATUS;
  totalMember: number;
  totalRequest: number;
  ownerId: number;
  ownerName: string;
  offical: EVENT_OFFICIAL;
  status: number;
  privacyType: EVENT_PUBlIC_TYPE;

  //observable properties
  @observable userJoinStatus: EVENT_JOIN_STATUS;

  constructor(
    groupId: number,
    eventId: number,
    groupName: string,
    description: string,
    avatar: string,
    cover: string,
    eventJoinStatus: EVENT_JOIN_STATUS,
    totalMember: number,
    totalRequest: number,
    ownerId: number,
    ownerName: string,
    offical: EVENT_OFFICIAL,
    status: number,
    privacyType: EVENT_PUBlIC_TYPE,
    userJoinStatus: EVENT_JOIN_STATUS,
  ) {
    super();
    this.groupId = groupId;
    this.eventId = eventId;
    this.groupName = groupName;
    this.description = description;
    this.avatar = avatar;
    this.cover = cover;
    this.eventJoinStatus = eventJoinStatus;
    this.totalMember = totalMember;
    this.totalRequest = totalRequest;
    this.ownerId = ownerId;
    this.ownerName = ownerName;
    this.offical = offical;
    this.status = status;
    this.privacyType = privacyType;
    this.userJoinStatus = userJoinStatus;
  }

  //return true if user already join group and else return false
  @computed get isUserJoined() {
    return (
      this.userJoinStatus != EVENT_JOIN_STATUS.NONE &&
      this.userJoinStatus != EVENT_JOIN_STATUS.INVITING &&
      this.userJoinStatus != EVENT_JOIN_STATUS.REQUESTING
    );
  }

  //return true if group already join event and else return false
  @computed get isEventJoined() {
    return (
      this.eventJoinStatus != EVENT_JOIN_STATUS.NONE &&
      this.eventJoinStatus != EVENT_JOIN_STATUS.INVITING &&
      this.eventJoinStatus != EVENT_JOIN_STATUS.REQUESTING
    );
  }

  isOwner(memberId: number): boolean {
    return this.ownerId === memberId;
  }
  isAdmin(): boolean {
    return this.userJoinStatus === EVENT_JOIN_STATUS.ADMIN;
  }
  isPublic(): boolean {
    throw new Error('Method not implemented.');
  }
  isAllowView(): boolean {
    throw new Error('Method not implemented.');
  }
  isChooseLeader(memberId: number): boolean {
    return this.isOwner(memberId) && this.totalMember > 1;
  }
  setUserJoinStatus(status: EVENT_JOIN_STATUS) {
    this.userJoinStatus = status;
  }
  setEventJoinStatus(status: EVENT_JOIN_STATUS) {
    this.eventJoinStatus = status;
  }

  static fromJson = (json: IGroup): Group => {
    return new Group(
      json.grid,
      json.evid,
      json.name,
      json.desc,
      json.ava,
      json.cover,
      json.gejsts,
      json.mem,
      json.rmem,
      json.owner,
      json.ownm,
      json.offical,
      json.sts,
      json.jpri,
      json.jsts,
    );
  };
}

export class GroupList extends DataList<Group> {
  addGroup = (data: Group) => {
    runInAction(() => {
      this.list.push(data);
    });
  };

  static fromJson = (jsonArray: Array<IGroup>): Array<Group> => {
    const data = new Array<Group>();
    jsonArray.forEach((event) => {
      data.push(Group.fromJson(event));
    });
    return data;
  };
}
