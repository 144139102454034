import styled from 'styled-components';

import { device } from '../../configs/MediaQuery';

export const FundsInfoWrapper = styled.div``;

export const FundsInfoContainer = styled.div`
  padding-top: 20px;
  @media ${device.mobileS} {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
  @media ${device.laptop} {
    width: 1100px;
    margin: 0 auto;
  }
`;
