import { Collapse } from 'antd';
import { observer } from 'mobx-react-lite';
import { ComponentType } from 'react';

import { FeedAnalysis } from '../../../../models/Feed';
import { PowerDailyStats } from './PowerDailyStats';
import { PowerMonthlyStats } from './PowerMonthlyStats';
import { PowerWeeklyStats } from './PowerWeeklyStats';
//styles

interface Props {
  data: FeedAnalysis;
}

const { Panel } = Collapse;

export const PowerStats: ComponentType<Props> = observer(({ data }) => {
  return (
    <Collapse style={{ marginBottom: 10 }}>
      <Panel header='Biểu đồ thể lực' key={1}>
        <PowerDailyStats
          data={data.powerDaily}
          limitCalo={data.thresholdDailyCalo}
          limitMet={data.thresholdAvgMET}
        />
        <PowerWeeklyStats
          data={data.powerWeekly}
          limitCalo={data.thresholdWeeklyCalo}
          limitMet={data.thresholdAvgMET}
        />
        <PowerMonthlyStats
          data={data.powerMonthly}
          limitCalo={data.thresholdMonthlyCalo}
          limitMet={data.thresholdAvgMET}
        />
      </Panel>
    </Collapse>
  );
});
