import { Buffer } from 'buffer';
import { v4 } from 'uuid';

import { LoginToken } from '../models/User';
import numeral from 'numeral';

export function getUUID() {
  return v4().toString();
}

export function getTimestamp() {
  return Math.floor(Date.now() / 1000).toString();
}

export function stripedHtml(val: any) {
  if (!val) return null;
  val = val.replace(/<[^>]+>/g, '');
  //val = val.replace(/[^a-zA-Z0-9,/ ]/g, '');
  return val;
}

export function parseJWT(jwt: string): LoginToken | null {
  try {
    const json = Buffer.from(jwt.split('.')[1], 'base64').toString();
    return LoginToken.fromJson(JSON.parse(json));
  } catch {
    return null;
  }
}

export function formatNumber(value: any) {
  if (value == undefined || value == null || value == '') return 0;
  return numeral(value).format('0,0');
}

export function formatDistanceKMFloor(mValue: any, round: any) {
  const km = Math.floor(mValue / 1000);
  if (round) return numeral(km).format('0,0');
  else return numeral(km).format('0,0.[00]');
}

export function getAvatarImg(img: string) {
  return img.replace('.png', '@2x.png').replace('.jpeg', '@2x.jpeg').replace('.gif', '@2x.gif');
}
