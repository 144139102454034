import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

import { SportIcon } from '../../../components/Icons/SportIcon';
import { Feed } from '../../../models/Feed';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { humanTime } from '../../../utils/DateUtils';
//styles
import {
  ActivityItemContainer,
  ActivityItemWrapper,
  AppSourceIcon,
  AvatarContainer,
  AvatarUser,
  CreateDate,
  FeedTitle,
  InfoContainer,
  LabelText,
  LineDivider,
  MapActivityContainer,
  MapActivityImage,
  MiddleContainer,
  TopContainer,
  UserName,
  ValueText,
  ViewDetailLink,
} from './ActivityItem.styles';

interface Props {
  feedData: Feed;
}

export const ActivityItem: ComponentType<Props> = observer(({ feedData }) => {
  const store = useRootStore();
  const { t } = useTranslation();

  const { language } = store.globalStore;

  return (
    <ActivityItemWrapper>
      <ViewDetailLink to={`/reports/activity-stats?fid=${feedData.feedId}`}>
        <ActivityItemContainer>
          <TopContainer>
            <AvatarContainer>
              <AvatarUser src={feedData.avatar!}></AvatarUser>
            </AvatarContainer>
            <InfoContainer>
              <UserName>{feedData.name}</UserName>
              <CreateDate>
                <SportIcon type={feedData.feedType} />
                {humanTime(feedData.createTime!, language!)}
                <AppSourceIcon source={feedData.sourceApp} />
              </CreateDate>
            </InfoContainer>
          </TopContainer>
          <MiddleContainer>
            <FeedTitle>{feedData.title}</FeedTitle>
            <LineDivider />
            <Row>
              <Col span={8}>
                <LabelText>Thời gian</LabelText>
                <ValueText>{feedData.buildTimeActivity()}</ValueText>
              </Col>
              <Col span={8}>
                <LabelText>Tốc độ</LabelText>
                <ValueText>{feedData.buildAvgPace(feedData.duration)}</ValueText>
              </Col>
              <Col span={8}>
                <LabelText>Quãng đường</LabelText>
                <ValueText>{feedData.buildDistance(true)}</ValueText>
              </Col>
            </Row>
            <MapActivityContainer>
              <MapActivityImage
                src={feedData.mapImage.replace('.png', 'w.png').replace('.jpeg', 'w.jpeg')}
              />
            </MapActivityContainer>
          </MiddleContainer>
        </ActivityItemContainer>
      </ViewDetailLink>
    </ActivityItemWrapper>
  );
});
