import React from 'react';
import { useTranslation } from 'react-i18next';

interface Step {
  id: number;
  title: string;
}

export const Donate: React.FC = () => {
  const { t } = useTranslation();
  const qrCode = 'https://uprace2.vcdn.vn/web/uprace/images/2022/qr-uprace.jpg';
  const steps: Step[] = [
    {
      id: 1,
      title: t('uprace2022:howToJoin.donate.step1Title'),
    },
    {
      id: 2,
      title: t('uprace2022:howToJoin.donate.step2Title'),
    },
    {
      id: 3,
      title: t('uprace2022:howToJoin.donate.step3Title'),
    },
  ];

  return (
    <>
      <div className='card tab-content-card donate'>
        <div className='card-body'>
          <p className='card-text mb-md-4'>{t('uprace2022:howToJoin.donate.desc')}</p>
          <div className='row align-items-center'>
            <div className='col-md-6'>
              {steps.map((item: Step) => (
                <div className='d-flex align-items-center' key={item.id}>
                  <div className='me-3'>
                    <div className='big-text text-orange'>{item.id}</div>
                  </div>
                  <div className='flex-1 desc'>{item.title}</div>
                </div>
              ))}
            </div>
            <div className='col-md-6'>
              <img src={qrCode} alt='QR' className='img-fluid' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
