import { AxiosResponse } from 'axios';

import { API_STATUS } from '../configs/APIStatus';
import APIHelper from '../helpers/APIHelper';
import { AuthHeader } from '../helpers/AuthHeader';
import { IRequestBase, IResponseBase, IResponseListBase } from '../types/ITypeBase';
import { getTimestamp, getUUID } from '../utils';
import { HTTP_STATUS } from './../configs/HttpStatus';
import BaseService from './BaseService';

class BaseAuthService extends BaseService {
  public constructor() {
    super();
    //override default baseURL and headers of API service
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=UTF-8',
      'Accept-Language': 'vi',
      'App-Client-Version': 10000, //process.env.REACT_APP_CLIENT_VERSION!,
      Authorization: 'Bearer ' + AuthHeader()?.accesstoken,
    };
    APIHelper.defaults.headers.common = headers;
    APIHelper.defaults.baseURL = process.env.REACT_APP_BASE_URL_2;
  }

  protected postData = (api: string, params: IRequestBase) => {
    APIHelper.defaults.baseURL = process.env.REACT_APP_BASE_URL_2;
    params.trid = getUUID();
    params.trtm = getTimestamp();
    params.data.uid = AuthHeader()?.userId;

    return APIHelper.post(api, params);
  };

  //handles response and error from server
  protected handleResponse = (response: AxiosResponse): IResponseBase => {
    if (response.status != HTTP_STATUS.OK) return null!;

    const result: IResponseBase = {
      data: response.data.data,
      ercd: response.data.ercd,
      desc: response.data.desc,

      responseCode: function (): number {
        return this.ercd!;
      },
      isSuccess: function (): boolean {
        return this.ercd === API_STATUS.OK || this.ercd === 112;
      },
      isError: function (): boolean {
        return this.ercd != API_STATUS.OK;
      },
      getDescription: function (): string {
        return this.desc!;
      },
    };

    return result;
  };

  //handles response list data
  protected handleResponseList = (response: AxiosResponse): IResponseListBase => {
    if (response.status != HTTP_STATUS.OK) return null!;

    const result: IResponseListBase = {
      //in response list data will be included total record and list data
      //describe by type IDataResponseList<T>
      data: response.data.data,
      ercd: response.data.ercd,
      desc: response.data.desc,

      responseCode: function (): number {
        return this.ercd!;
      },
      isSuccess: function (): boolean {
        return this.ercd === API_STATUS.OK;
      },
      isError: function (): boolean {
        return this.ercd != API_STATUS.OK;
      },
      getDescription: function (): string {
        return this.desc!;
      },
      totalRecord: function (): number {
        return response.data.data.total;
      },
    };
    return result;
  };
}

export default BaseAuthService;
