import { Col, Row } from 'antd';
import styled from 'styled-components';

import { device } from '../../../configs/MediaQuery';

export const BannerContainer = styled.div`
  @media ${device.mobileS} {
  }
  @media ${device.laptop} {
  }
`;

export const BannerImage = styled.img`
  object-fit: cover;
  width: 100%;
`;

export const RowContainer = styled(Row)`
  padding-top: 48px;
`;

export const LeftContainer = styled(Col)`
  @media ${device.mobileS} {
    text-align: center;
  }
  @media ${device.laptop} {
    text-align: left;
  }
`;

export const RightContainer = styled(Col)``;

export const HandIconContainer = styled.div`
  width: 112px;
  @media ${device.mobileS} {
    margin: 0 auto;
  }
  @media ${device.laptop} {
    margin: 0;
  }
`;

export const HandIconImage = styled.img`
  width: 100%;
  object-fit: cover;
`;
