import styled from 'styled-components';

import { device } from '../../../configs/MediaQuery';
import { AppButton } from '../../../styles/ButtonStyles';

export const ActivityStatsWrapper = styled.section`
  width: 100%;
  min-height: 1080px;
`;

export const ActivityStatsContainer = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 100px;

  @media ${device.mobileS} {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media ${device.tablet} {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media ${device.laptop} {
  }
`;

export const LoginWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 400px;
  flex-direction: column;
`;
export const LoginButton = styled(AppButton)`
  padding: 20px;
  margin: 20px;
`;
