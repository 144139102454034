import { observer } from 'mobx-react-lite';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

//views
import { AvatarList } from '../../../components/AvatarList/AvatarList';
import { SportIcon } from '../../../components/Icons/SportIcon';
import { Feed } from '../../../models/Feed';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { humanTime } from '../../../utils/DateUtils';
//styles
import {
  AppSourceIcon,
  AvatarContainer,
  AvatarUser,
  CommentContainer,
  CreateDate,
  FeedTitle,
  InfoContainer,
  LikeContainer,
  SocialContainer,
  UserInfoContainer,
  UserInfoWrapper,
  UserName,
  ViewProfileLink,
} from './UserInfo.styles';

interface Props {
  feedData: Feed;
}

export const UserInfo: ComponentType<Props> = observer(({ feedData }) => {
  const store = useRootStore();
  const { t } = useTranslation();

  const { language } = store.globalStore;

  return (
    <UserInfoWrapper>
      <UserInfoContainer>
        <ViewProfileLink to={`/user-profile?uid=${feedData.memberId}`}>
          <AvatarContainer>
            <AvatarUser src={feedData.avatar!}></AvatarUser>
          </AvatarContainer>
          <InfoContainer>
            <UserName>{feedData.name}</UserName>
            <CreateDate>
              <SportIcon type={feedData.feedType} />
              {humanTime(feedData.createTime!, language!)}
              <AppSourceIcon source={feedData.sourceApp} />
            </CreateDate>
            <FeedTitle>{feedData.title}</FeedTitle>
          </InfoContainer>
        </ViewProfileLink>
      </UserInfoContainer>
      <SocialContainer>
        <LikeContainer>
          <AvatarList
            list={feedData.listLike}
            text={`${feedData.totalLike.toString()} ${t('social:totalLikeText')}`}
            clickable={true}
          />
        </LikeContainer>
        <CommentContainer>comment</CommentContainer>
      </SocialContainer>
    </UserInfoWrapper>
  );
});
