import React from 'react';
import { useTranslation } from 'react-i18next';

export const InfoSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className='contact-growth-stat-section position-relative'>
      <div className='growth-stat-bg-bottom'>
        <img
          src='https://uprace2.vcdn.vn/web/uprace/contact-us/growth-stat-bg-bottom-sm.svg'
          alt=''
          className='bg-img bg-img__sm'
        />
        <img
          src='https://uprace2.vcdn.vn/web/uprace/contact-us/growth-stat-bg-bottom-md.svg'
          alt=''
          className='bg-img bg-img__md'
        />
        <img
          src='https://uprace2.vcdn.vn/web/uprace/contact-us/growth-stat-bg-bottom-xxl.svg'
          alt=''
          className='bg-img bg-img__xxl'
        />
      </div>
      <div className='growth-stat-bg-top'>
        <img
          src='https://uprace2.vcdn.vn/web/uprace/contact-us/growth-stat-bg-top-sm.svg'
          alt=''
          className='bg-img bg-img__sm'
        />
        <img
          src='https://uprace2.vcdn.vn/web/uprace/contact-us/growth-stat-bg-top-xl.svg'
          alt=''
          className='bg-img bg-img__md'
        />
        <img
          src='https://uprace2.vcdn.vn/web/uprace/contact-us/growth-stat-bg-top-xxl.svg'
          alt=''
          className='bg-img bg-img__xxl'
        />
      </div>
      <div className='container position-relative'>
        <div className='bg-phone-frame'></div>
        <div className='row justify-content-center heading-block'>
          <div className='col-12 col-md-7 text-center'>
            <div className='dash'></div>
            <h1 className='title'>{t('contact:info.title')}</h1>
          </div>
        </div>
        <div className='row g-3 g-lg-4 row-cols-1 row-cols-md-3 position-relative h-100'>
          <div className='order-1 order-md-2 col h-100 col-phone-swiper'>
            <div className='container position-relative'>
              <div className='row position-relative'>
                <div className='phone-frame iphone'>
                  <img
                    src='https://uprace2.vcdn.vn/web/uprace/contact-us/phone-frame.png'
                    className='phone-frame__img'
                    alt='Phone frame'
                  />
                </div>
                <div className='phone-frame runner'>
                  <img
                    src='https://uprace2.vcdn.vn/web/uprace/contact-us/runner-illustrations-1-1x.svg'
                    className='img-fluid mx-auto'
                    alt='Runner'
                  />
                </div>
                <div className='swiper growth-stat-swiper'>
                  <div className='swiper-wrapper'>
                    <div className='swiper-slide'>
                      <img
                        src='https://uprace2.vcdn.vn/web/uprace/contact-us/growth-stat-1-2x.png'
                        className='growth-stat-swiper__img'
                        alt='Growth stat'
                      />
                    </div>
                    <div className='swiper-slide'>
                      <img
                        src='https://uprace2.vcdn.vn/web/uprace/contact-us/growth-stat-2-1x.png'
                        className='growth-stat-swiper__img'
                        alt='Growth stat'
                      />
                    </div>
                    <div className='swiper-slide'>
                      <img
                        src='https://uprace2.vcdn.vn/web/uprace/contact-us/growth-stat-3-1x.png'
                        className='growth-stat-swiper__img'
                        alt='Growth stat'
                      />
                    </div>
                    <div className='swiper-slide text-white'>
                      <img
                        src='https://uprace2.vcdn.vn/web/uprace/contact-us/growth-stat-4-1x.png'
                        className='growth-stat-swiper__img'
                        alt='Growth stat'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='order-2 order-md-1 growth-stat-block py-lg-5'>
            <div className='growth-stat-item mb-growth-stat-item'>
              <h1 className='stat'>23.4 {t('global:million').toLowerCase()}</h1>
              <p className='desc'>{t('contact:info.totalKMRun')}</p>
            </div>

            <div className='growth-stat-item'>
              <h1 className='stat'>85.000+</h1>
              <p className='desc'>{t('contact:info.totalUserActiveMonth')}</p>
            </div>
          </div>
          <div className='order-3 growth-stat-block col'>
            <div className='d-flex flex-column flex-wrap py-lg-5'>
              <div className='growth-stat-item mb-growth-stat-item'>
                <h1 className='stat'>632.400+</h1>
                <p className='desc'>{t('contact:info.totalUser')}</p>
              </div>

              <div className='growth-stat-item'>
                <h1 className='stat'>50+</h1>
                <p className='desc'>{t('contact:info.totalPartner')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
