export const MENU_LANGUAGE = [
  {
    id: 'vi',
    name: 'VN',
    title: 'VN',
    icon: 'https://uprace2.vcdn.vn/web/uprace/icons/flags-vietnam.svg',
  },
  {
    id: 'en',
    name: 'EN',
    title: 'EN',
    icon: 'https://uprace2.vcdn.vn/web/uprace/icons/flags_usa.svg',
  },
];
