import styled from 'styled-components';

import { TitleDescription, TitleText } from '../../../styles/LandingPageStyle';
import { defaultTheme } from '../../../themes';

export const SwiperContainer = styled.div`
  max-width: 1160px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SectionEventStats = styled.section`
  max-width: 100%;
  height: 146px;
  background-color: ${defaultTheme.colors.primary};
`;

export const StatsItemContainer = styled.div``;

export const ItemTitle = styled(TitleText)`
  color: white;
  padding: 0px;
`;

export const ItemDescription = styled(TitleDescription)`
  color: white;
`;
