import { API } from '../configs/APIEndPoint';
import { handleError } from '../helpers/APIHelper';
import {
  ICreatePostRequest,
  IDeletePostRequest,
  IGetListPostRequest,
  IPinPostRequest,
  IUpdatePostRequest,
} from './../types/ISocial';
import BaseAuthService from './BaseAuthService';

class SocialService extends BaseAuthService {
  private static instance: SocialService;

  public static getInstance(): SocialService {
    if (!SocialService.instance) {
      SocialService.instance = new SocialService();
    }
    return SocialService.instance;
  }

  //methods api
  getListPost = async (params: IGetListPostRequest) => {
    try {
      const response = await this.postData(API.SOCIAL.POST.LIST, params);
      return this.handleResponseList(response);
    } catch (error: any) {
      return handleError(error);
    }
  };
  deletePost = async (params: IDeletePostRequest) => {
    try {
      const response = await this.postData(API.SOCIAL.POST.DELETE, params);
      return this.handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  };

  createPost = async (params: ICreatePostRequest) => {
    try {
      const response = await this.postData(API.SOCIAL.POST.CREATE, params);
      return this.handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  };

  updatePost = async (params: IUpdatePostRequest) => {
    try {
      const response = await this.postData(API.SOCIAL.POST.UPDATE, params);
      return this.handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  };

  pinPost = async (params: IPinPostRequest) => {
    try {
      const response = await this.postData(API.SOCIAL.POST.PIN, params);
      return this.handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  };
}
export default SocialService;
