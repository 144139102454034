import _ from 'lodash';
import { parse } from 'query-string';
import { ComponentType, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import pure from 'recompose/pure';

import { APIResponse } from '../../models/APIResponse';
import { useRootStore } from '../../providers/RootStoreProvider';
import { IFeed, IGetFeedDetailRequest } from '../../types/IFeed';
import { stripedHtml } from '../../utils';
//styles
import { FeedDetailContainer, FeedDetailWrapper } from './FeedDetail.styles';
//views
import { FeedInfo } from './FeedInfo/FeedInfo';

interface Props {}

export const FeedDetail: ComponentType<Props> = pure(({}) => {
  //get fid param from query string and if not navigate to not found page
  const paramsQuery = parse(window.location.search);
  if (_.isEmpty(paramsQuery) || !paramsQuery.atid) return <Navigate to='/not-found'></Navigate>;

  const store = useRootStore();
  const navigate = useNavigate();

  const { getFeedDetail } = store.feedStore;

  useEffect(() => {
    onGetFeedDetail();
  }, []);

  const onGetFeedDetail = async () => {
    const params: IGetFeedDetailRequest = {
      data: {
        atid: stripedHtml(paramsQuery.atid),
      },
    };
    const result: APIResponse<IFeed> = await getFeedDetail(params);
    //check if data not exist then navigate to not found page
    if (result.isError()) navigate('/not-found');
  };
  return (
    <FeedDetailWrapper>
      <FeedDetailContainer>
        <FeedInfo />
      </FeedDetailContainer>
    </FeedDetailWrapper>
  );
});
