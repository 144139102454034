import styled, { css } from 'styled-components';

import { defaultTheme } from '../themes';
import { ButtonStyleBase } from './BaseStyle';

//styles
const ButtonPrimaryStyle = css`
  color: #fff;
  background-color: ${defaultTheme.colors.primary};
`;
const ButtonSecondaryStyle = css`
  border: 0;
  color: ${defaultTheme.colors.primary};
  background-color: ${defaultTheme.colors.secondary};
`;
const ButtonSecondaryBorderStyle = css`
  color: ${defaultTheme.colors.primary};
  border-color: ${defaultTheme.colors.primary};
  background-color: ${defaultTheme.colors.secondary};
`;
const ButtonLightStyle = css`
  color: ${defaultTheme.colors.primary};
  border-color: ${defaultTheme.colors.primary};
  background-color: ${defaultTheme.colors.white};
`;
const ButtonDisabledStyle = css`
  border: 0;
  font-weight: 400;
  color: ${defaultTheme.colors.disabledText};
  background-color: ${defaultTheme.colors.disabledButton};
`;
const ButtonTransparentStyle = css`
  font-weight: 400;
  background-color: transparent;
  border-color: ${defaultTheme.colors.darkLight};
`;

export const ButtonPrimary = styled.button`
  ${ButtonStyleBase}
  ${ButtonPrimaryStyle}
`;

export const ButtonSecondary = styled.button`
  ${ButtonStyleBase}
  ${ButtonSecondaryStyle}
`;

export const ButtonLight = styled.button`
  ${ButtonStyleBase}
  ${ButtonLightStyle}
`;

export const ButtonTransparent = styled.button`
  ${ButtonStyleBase}
  ${ButtonTransparentStyle}
`;

interface AppButtonProps {
  typeButton: boolean;
}

export const AppButton = styled.button.attrs((props: AppButtonProps) => ({
  className: props.typeButton,
}))`
  ${ButtonStyleBase}
  &.primary {
    ${ButtonPrimaryStyle}
  }
  &.secondary {
    ${ButtonSecondaryStyle}
  }
  &.secondary-border {
    ${ButtonSecondaryBorderStyle}
  }
  &.light {
    ${ButtonLightStyle}
  }
  &.disabled {
    ${ButtonDisabledStyle}
    pointer-events: none;
  }
  &.transparent {
    ${ButtonTransparentStyle}
  }
`;
