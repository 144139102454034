import React from 'react';
import { useTranslation } from 'react-i18next';

import { APP_CONFIG } from '../../../configs/GlobalConst';

interface Step {
  id: number;
  title: string;
  desc: string;
  icon: string;
}

export const Runner: React.FC = () => {
  const { t } = useTranslation();

  const steps: Step[] = [
    {
      id: 1,
      title: t('uprace2022:howToJoin.runner.step1Title'),
      desc: t('uprace2022:howToJoin.runner.step1Desc'),
      icon: `${APP_CONFIG.STATIC_URL}/images/2022/dangky.png`,
    },
    {
      id: 2,
      title: t('uprace2022:howToJoin.runner.step2Title'),
      desc: t('uprace2022:howToJoin.runner.step2Desc'),
      icon: `${APP_CONFIG.STATIC_URL}/images/2022/thanhtich.png`,
    },
    {
      id: 3,
      title: t('uprace2022:howToJoin.runner.step3Title'),
      desc: t('uprace2022:howToJoin.runner.step3Desc'),
      icon: `${APP_CONFIG.STATIC_URL}/images/2022/reward.png`,
    },
  ];

  return (
    <>
      <div className='card tab-content-card'>
        <div className='card-body'>
          <p className='card-text mb-md-5'>{t('uprace2022:howToJoin.runner.desc')}</p>

          {steps.map((item: Step) => (
            <div
              className='mb-4 mb-md-5 d-md-flex align-items-md-center text-center text-md-start'
              key={item.id}>
              <img src={item.icon} alt={item.title} className='icon mb-2 mb-md-0' />
              <div className='ms-md-4'>
                <h4 className='mb-0'>{item.title}</h4>
                <p className='mb-md-0'>{item.desc}</p>
              </div>
              {item.id === 1 && (
                <div className='d-grid gap-2 ms-md-4 d-sm-flex'>
                  <a
                    href='https://apps.apple.com/us/app/id1476173971'
                    className='btn-pulse-hover'
                    target='_blank'
                    rel='noreferrer'>
                    <img
                      src='https://uprace2.vcdn.vn/web/uprace/icons/app-store.svg'
                      className='img-fluid app-store'
                      alt='app-store'
                    />
                  </a>
                  <a
                    href='https://play.google.com/store/apps/details?id=vn.vng.uprace'
                    className='d-inline-block ms-md-2 btn-pulse-hover'
                    target='_blank'
                    rel='noreferrer'>
                    <img
                      src='https://uprace2.vcdn.vn/web/uprace/icons/google-play.svg'
                      className='img-fluid google-play'
                      alt='google-play'
                    />
                  </a>
                </div>
              )}
            </div>
          ))}

          <div className='ratio ratio-16x9'>
            <iframe
              src='https://www.youtube.com/embed/xZ1VpxVTerk'
              title='YouTube video'
              width='420'
              height='315'
              allowFullScreen></iframe>
          </div>
        </div>
      </div>
    </>
  );
};
