import { observer } from 'mobx-react-lite';
import { FC } from 'react';

interface Props {
    src: string;
    onClick?: () => void;
    className?: any;
}

export const EventImage: FC<Props> = observer(({ src, onClick, className }) => {
    return <img src={src} onClick={onClick} className={className} />;
});
