import { Col, Row } from 'antd';
import styled from 'styled-components';

import { device } from '../../../configs/MediaQuery';

export const RowContainer = styled(Row)``;

export const LeftContainer = styled(Col)`
  @media ${device.mobileS} {
    padding-right: 0px;
  }
  @media ${device.laptop} {
    padding-right: 60px;
  }
`;

export const RightContainer = styled(Col)`
  max-height: 568px;
`;

export const InfoDescription = styled.div`
  p {
  }

  li {
  }
`;

export const InfoBanner = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 12px;
`;
