import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import pure from 'recompose/pure';

import { useRootStore } from '../../../providers/RootStoreProvider';
import { stripedHtml } from '../../../utils';
import { HeroSection } from '../HeroSection/HeroSection';
//styles
import { NewsDetailContainer, NewsDetailWrapper, TitleHeader } from './NewsDetail.styles';

const queryString = require('query-string');

interface Props {}

export const NewsDetail: ComponentType<Props> = pure(
  observer(({}) => {
    const store = useRootStore();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const paramsQuery = queryString.parse(window.location.search);
    if (_.isEmpty(paramsQuery) || !paramsQuery.evid || !paramsQuery.id)
      return <Navigate to='/uprace22'></Navigate>;

    const { newsContent, getNewsContent, listNewsUp22, getListNewsUp22 } = store.jsonStore;

    useEffect(() => {
      if (!listNewsUp22) getListNewsUp22();
      else onGetNewsContent();
    }, [listNewsUp22]);

    const onGetNewsContent = () => {
      if (!_.isEmpty(paramsQuery)) {
        const data = _.find(listNewsUp22, (item) => {
          return item.id == stripedHtml(paramsQuery.id);
        });
        if (data) {
          getNewsContent(data.url_vi);
        }
      }
    };

    return (
      <div className='uprace_2022'>
        <HeroSection />
        <NewsDetailWrapper>
          <NewsDetailContainer>
            <TitleHeader>{t('uprace2022:news.heading')}</TitleHeader>
            <div
              dangerouslySetInnerHTML={{
                __html: newsContent!,
              }}></div>
          </NewsDetailContainer>
        </NewsDetailWrapper>
      </div>
    );
  }),
);
