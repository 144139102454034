import { API } from '../configs/APIEndPoint';
import { handleError, handleResponse } from '../helpers/APIHelper';
import { IGetListEventBlockRequest, IRankMeRequest } from '../types/IEvent';
import { IGetEventRankingRequest } from '../types/ILeaderBoard';
import { ICreateEventRequest } from './../types/ICreateEvent';
import {
  IActionEventRequest,
  IDeleteEventRequest,
  IDismissEventSuggestRequest,
  IGetEventDetailRequest,
  IGetListEventFilterRequest,
  IGetYourEventRequest,
} from './../types/IEvent';
import BaseAuthService from './BaseAuthService';

class EventService extends BaseAuthService {
  private static instance: EventService;

  public static getInstance(): EventService {
    if (!EventService.instance) {
      EventService.instance = new EventService();
    }
    return EventService.instance;
  }

  async getRankMe(params: IRankMeRequest) {
    try {
      const response = await this.postData(API.EVENT.RANK_ME, params);
      return handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  }

  getEventDetail = async (params: IGetEventDetailRequest) => {
    try {
      const response = await this.postData(API.EVENT.DETAIL, params);
      return this.handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  };

  getListEventBlock = async (params: IGetListEventBlockRequest) => {
    try {
      const response = await this.postData(API.EVENT.LIST, params);
      return this.handleResponseList(response);
    } catch (error: any) {
      return handleError(error);
    }
  };

  getListYourEvent = async (params: IGetYourEventRequest) => {
    try {
      const response = await this.postData(API.EVENT.LIST, params);
      return this.handleResponseList(response);
    } catch (error: any) {
      return handleError(error);
    }
  };

  createEvent = async (params: ICreateEventRequest) => {
    try {
      const response = await this.postData(API.EVENT.CREATE, params);
      return this.handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  };

  //action event such as JOIN, LEAVE, APPROVE MEMBER...
  actionEvent = async (params: IActionEventRequest) => {
    try {
      const response = await this.postData(API.EVENT.REQUEST_JOIN, params);
      return this.handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  };

  deleteEvent = async (params: IDeleteEventRequest) => {
    try {
      const response = await this.postData(API.EVENT.DELETE, params);
      return this.handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  };

  dismissEventSuggest = async (params: IDismissEventSuggestRequest) => {
    try {
      const response = await this.postData(API.EVENT.DISMISS, params);
      return this.handleResponse(response);
    } catch (error: any) {
      return handleError(error);
    }
  };

  getListEventFilter = async (params: IGetListEventFilterRequest) => {
    try {
      const response = await this.postData(API.EVENT.LIST, params);
      return this.handleResponseList(response);
    } catch (error: any) {
      return handleError(error);
    }
  };
}

export default EventService;
