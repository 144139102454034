import '../../assets/css/pages/contact-us.css';

import { PhoneOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Fab } from 'react-tiny-fab';

import { DownloadSection } from '../LandingPage/DownloadSection/DownloadSection';
import { ContactSection } from './ContactSection/ContactSection';
import { InfoSection } from './InfoSection/InfoSection';
import { WelcomeSection } from './WelcomeSection/WelcomeSection';

export const ContactUs: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <ContactSection />
      <InfoSection />
      {/* <PartnerSection /> */}
      <WelcomeSection />
      <DownloadSection />

      <Helmet>
        <script src={process.env.REACT_APP_CDN_URL + 'js/contact-us.js'}></script>
      </Helmet>
      {/* <a href='tel:0966912914'>
        <Fab
          alwaysShowTitle={false}
          text='Hotline'
          onClick={() => {}}
          style={{ bottom: -10, right: -10 }}
          mainButtonStyles={{
            backgroundColor: '#eb4034',
            cursor: 'pointer',
          }}
          icon={<PhoneOutlined />}></Fab>
      </a> */}
    </>
  );
};
