import styled from 'styled-components';

import { device } from '../../configs/MediaQuery';
import { AvatarImage } from '../User/AvatarImage';

export const AvatarListWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const AvatarListContainer = styled.div`
  display: inline-flex;
  flex-direction: row-reverse;
`;

export const AvatarContainer = styled.div`
  border-radius: 50%;

  &:not(:last-child) {
    margin-left: -8px;
  }
  @media ${device.mobileS} {
    width: 18px;
    height: 18px;
  }
  @media ${device.tablet} {
    width: 24px;
    height: 24px;
  }
`;

export const AvatarUser = styled(AvatarImage)`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #aab2bf;
`;

export const TextOther = styled.p`
  margin: 0;
  margin-left: 5px;
  font-size: 12px;
  line-height: 14px;
  color: #979797;
`;
