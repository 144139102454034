import React, { useEffect } from 'react';

import { AnnualEventSection } from './AnnualEventSection/AnnualEventSection';
import { BannerSection } from './BannerSection/BannerSection';
import { EventStats } from './EventStats/EventStats';
import { InfoSection } from './InfoSection/InfoSection';

export const AboutUs: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <BannerSection />
      <EventStats />
      <InfoSection />
      {/* <AnnualEventSection /> */}
      {/* <PartnerSection /> */}
    </>
  );
};
