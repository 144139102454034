import React from 'react';
import { useTranslation } from 'react-i18next';

export const FeatureSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className='features-section' style={{ backgroundColor: 'aliceblue' }}>
      <div className='container'>
        <div className='row features-header justify-content-center'>
          <div className='col-12 col-md-8 text-center'>
            <h1 className='title'>{t('global:feature')}</h1>

            <p className='desc'>{t('landing:features.description')}</p>
          </div>
        </div>
        <div className='features-content'>
          <div className='row g-4 features-content-item'>
            <div className='col-12 col-md-6'>
              <img
                src='https://uprace2.vcdn.vn/web/uprace/illustrations/record-activities-stably.png'
                alt='...'
                className='illustration-item'
              />
            </div>
            <div className='col-12 col-md-6 desc-right'>
              <h4 className='title'>
                {t('landing:features.sub1.title')}
                <br />
              </h4>

              <ul className='widget-list'>
                <li className='widget-list-item'>
                  <i className='fi fi-check'></i>
                  <span className='text'>{t('landing:features.sub1.desc1')}</span>
                </li>
                <br />
                <li className='widget-list-item'>
                  <i className='fi fi-check'></i>
                  <span className='text'>{t('landing:features.sub1.desc2')}</span>
                </li>
                <br />
                <li className='widget-list-item'>
                  <i className='fi fi-check'></i>
                  <span className='text'>{t('landing:features.sub1.desc3')}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className='row g-4 features-content-item justify-content-md-end'>
            <div className=' col-12 col-md-6 order-md-2'>
              <img
                src='https://uprace2.vcdn.vn/web/uprace/illustrations/build-your-own-race.png'
                alt='...'
                className='illustration-item'
              />
            </div>
            <div className='col-12 col-md-6 col-lg-5 features-content-item order-md-1'>
              <h4 className='title'>{t('landing:features.sub2.title')}</h4>

              <ul className='widget-list'>
                <li className='widget-list-item'>
                  <i className='fi fi-check'></i>
                  <span className='text'>{t('landing:features.sub2.desc1')}</span>
                </li>
                <li className='widget-list-item'>
                  <i className='fi fi-check'></i>
                  <span className='text'>{t('landing:features.sub2.desc2')}</span>
                </li>
                <li className='widget-list-item'>
                  <i className='fi fi-check'></i>
                  <span className='text'>{t('landing:features.sub2.desc3')}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className='row g-4 features-content-item'>
            <div className='col-12 col-md-6'>
              <img
                src='https://uprace2.vcdn.vn/web/uprace/illustrations/organizing-race.png'
                alt='...'
                className='illustration-item'
              />
            </div>
            <div className='col-12 col-md-6 desc-right'>
              <h4 className='title'>{t('landing:features.sub3.title')}</h4>

              <ul className='widget-list'>
                <li className='widget-list-item'>
                  <span className='text'>{t('landing:features.sub3.desc1')}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
