import { observer } from 'mobx-react-lite';
import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

import { FOLLOW_CATEGORY } from '../../../../../data/followType';
import { FOLLOW_CATEGORY_TYPE, ITabMenu } from '../../../../../types/IGlobal';
//styles
import {
  TabContainer,
  TabDialogContainer,
  TabDialogWrapper,
  TabItem,
  TabLink,
} from './TabDialog.styles';

interface Props {
  followType: FOLLOW_CATEGORY_TYPE;
  onChangeTab: (tab: FOLLOW_CATEGORY_TYPE) => void;
}

export const TabDialog: ComponentType<Props> = observer(({ followType, onChangeTab }) => {
  const { t } = useTranslation();

  const [tabIndex, setTabIndex] = React.useState(followType);

  const onTabItemChange = (tab: FOLLOW_CATEGORY_TYPE) => {
    setTabIndex(tab);
    onChangeTab(tab);
  };

  const renderTabItem = (tab: ITabMenu) => {
    const active = tab.id === tabIndex ? 'active' : 'disabled';
    return (
      <TabItem key={tab.id} onClick={() => onTabItemChange(tab.id)}>
        <TabLink active={active}>{t(tab.title)}</TabLink>
      </TabItem>
    );
  };

  return (
    <TabDialogWrapper>
      <TabDialogContainer>
        <TabContainer>
          {FOLLOW_CATEGORY.map((tab: ITabMenu) => {
            return renderTabItem(tab);
          })}
        </TabContainer>
      </TabDialogContainer>
    </TabDialogWrapper>
  );
});
