import { Col } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { device } from '../../../configs/MediaQuery';
import { Container } from '../../../styles/LandingPageStyle';

export const Section = styled.footer`
  width: 100%;
  background: #212b36;
  color: #fff;

  @media ${device.mobileS} {
    height: auto;
  }
  @media ${device.laptop} {
    height: auto;
  }
`;

export const ContainerFooter = styled(Container)`
  padding-bottom: 0;
  @media ${device.mobileS} {
    padding-top: 60px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const CopySection = styled.div`
  background: #314154;
  width: 100%;
`;

export const CopyContainer = styled.div`
  max-width: 1160px;
  margin: 0 auto;
  height: 32px;
  width: 100%;
`;

export const FooterTitle = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #fff;
`;

export const FooterLink = styled(Link)`
  text-decoration: none;
`;

export const FooterHref = styled.a`
  text-decoration: none;
`;

export const FooterText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
`;

export const CopyText = styled(FooterText)`
  padding: 5px;
  margin: 0;

  @media ${device.mobileS} {
    text-align: center;
  }
  @media ${device.tablet} {
    text-align: left;
  }
`;

export const ColContainer = styled(Col)`
  padding-bottom: 32px;
  @media ${device.mobileS} {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  @media ${device.tablet} {
    text-align: left;
    display: block;
  }
`;

export const Logo = styled.img`
  max-width: 100%;
  height: auto;
  padding-bottom: 32px;
`;

export const SocialContact = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: row;
  @media ${device.tablet} {
    padding-left: 0;
  }
`;

export const SocialIcon = styled.img`
  padding-right: 25px;
  height: 25px;
  object-fit: cover;
`;

export const DownloadButtonContainer = styled.div`
  padding-bottom: 18px;

  @media ${device.mobileS} {
    display: flex;
    flex-direction: column;
  }
  @media ${device.tablet} {
    display: flex;
    flex-direction: row;
  }
`;

export const DownloadButtonImage = styled.img`
  width: 118px;

  @media ${device.mobileS} {
    padding-bottom: 10px;
  }
  @media ${device.tablet} {
    padding-right: 10px;
  }
`;
