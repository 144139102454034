import styled from 'styled-components';

export const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadMoreButton = styled.a`
  text-decoration: none;
  font-weight: 600;
  color: #0046c2;
`;
