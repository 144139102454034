import { STATS_TIME_TYPE } from './IGlobal';
import { IComment, ILike, ISocial } from './ISocial';
import { IRequestBase } from './ITypeBase';

export enum FEED_SPORT_TYPE {
  ALL,
  CYCLING,
  RUNNING,
  WALKING,
  TRIATHLON,
  SWIMMING,
  OTHER,
}

export enum FEED_SOURCE_APP {
  UPRACE,
  STRAVA,
  GARMIN,
  APPLE,
  COROS,
  SUUNTO,
  HUAWEI,
  FITBIT,
  POLAR,
}

export enum FEED_SOURCE_TYPE {
  ME,
  FOLLOWING,
  TEAM,
  ALL,
}

export enum FEED_PREDICTION_RUNNING {
  NONE,
  CLEAN,
  FUZZY,
  CHEAT,
}

export enum FEED_POWER_TYPE {
  UNKNOWN,
  NORMAL,
  OVERLOAD,
}

export enum FEED_GPS_STATUS_INVALID {
  NONE,
  INVALID_TIME,
  INVALID_LOCATION,
}

export enum FEED_PREDICTION_SPORT {
  UNKNOWN,
  CYCLING,
  RUNNING,
  WALKING,
  TRIATHLON,
  SWIMMING,
  OTHERS,
}
export interface ILap {
  index: number;
  time: number;
  distance: number;
  pace: number;
  percent?: any;
}

export interface ISplit {
  dur: number;
  elel: number;
  dazi: number;
  eleg: number;
  spd: number;
  pace: number;
  dalt: number;
  tdis: number;
  dis: number;
  mov: number;
  tmov: number;
  tdur: number;
  splitIndex: number;
  bpm: number;
  spm: number;
}

export interface IGps {
  acc: number;
  dur: number;
  dazi: number;
  line: number;
  spd: number;
  pace: number;
  alt: number;
  lon: number;
  dalt: number;
  utm: any;
  dis: number;
  azi: number;
  spdat: number;
  mov: number;
  acle: number;
  lat: number;
  tdis: number;
  tdur: number;
  tmov: number;
  bpm: number;
  spm: number;
}

interface IMap {
  key: number;
  val: number;
}

interface IPower {
  period: string;
  scalo: number;
  amet: number;
}

export interface IPowerDaily extends IPower {}

export interface IPowerWeekly extends IPower {}

export interface IPowerMonthly extends IPower {}

export interface IDeltaAltitude extends IMap {}

export interface ISpeed extends IMap {}

export interface IDeltaAzimuth extends IMap {}

export interface IAcceleration extends IMap {}

export interface IFeed extends ISocial {
  dur: number;
  evrp: any;
  img: string;
  splits: any;
  envw: boolean;
  crtm: number;
  lktp: number;
  laps: ILap[];
  apri: number;
  cotm: number;
  title: string;
  entm: number;
  photos: any[];
  tmnm: string;
  dis: number;
  calo: number;
  mvtm: number;
  betm: number;
  cmcnt: number;
  event: Event;
  bpm: number;
  cmls: IComment[];
  atid: number;
  src: number;
  sex: number;
  display: any;
  clid: string;
  lkcnt: number;
  owif: any;
  spm: number;
  lkls: ILike[];
  elev: number;
  sport: number;
  desc: string;
}

export interface IFeedAnalysis {
  avgHeartRateInBeat: number;
  splits: ISplit[];
  avgMovingTimeInSecond: number;
  avgElevationGainInMeter: number;
  totalElevationLossInMeter: number;
  deltaAltitude: IDeltaAltitude[];
  totalCalo: number;
  totalDurationInSecond: number;
  speed: ISpeed[];
  uid: number;
  acceleration: IAcceleration[];
  totalDistanceInMeter: number;
  avgDistanceInMeter: number;
  hasCalo: boolean;
  deltaAzimuth: IDeltaAzimuth[];
  avgElevationLossInMeter: number;
  map: string;
  atid: number;
  totalDeltaAzimuthInDegree: number;
  hasAltitude: boolean;
  src: number;
  totalMovingTimeInSecond: number;
  gps: IGps[];
  totalElevationGainInMeter: number;
  avgCadence: number;
  hasCadence: boolean;
  avgAccelerationInKmH: number;
  avgSpeedInKmH: number;
  hasHeartRate: boolean;
  avgDurationInSecond: number;
  avgDeltaAltitudeInMeter: number;
  sport: number;
  avgDeltaAzimuthInDegree: number;
  predictionRunning: FEED_PREDICTION_RUNNING;
  predictionSport: FEED_PREDICTION_SPORT;
  predictionPower: FEED_POWER_TYPE;

  totalGpsPoint: number;
  totalDistanceInMeterBaseGps: number;
  totalDurationInSecondBaseGps: number;
  totalMovingTimeInSecondBaseGps: number;
  invalidGpsSts: number;
  invalidGps: number;
  invalidGpsDistanceInMeter: number;
  lagGps: number;
  lagGpsDistanceInMeter: number;
  labelSport: number;
  labelRunning: number;
  labelPower: number;

  thresholdDailyCalo: number;
  thresholdWeeklyCalo: number;
  thresholdMonthlyCalo: number;
  thresholdAvgMET: number;
  powerDaily: IPowerDaily[];
  powerWeekly: IPowerWeekly[];
  powerMonthly: IPowerMonthly[];
}

export interface IFeedStats {
  dur: number;
  act: number;
  dis: number;
}

export interface IFeedUserStats {
  cur: IFeedStats;
  other: IFeedStats;
}

export interface IGetFeedDetailRequest extends IRequestBase {
  data: {
    atid: any;
  };
}

export interface IGetFeedAnalysisRequest extends IRequestBase {
  data: {
    atid: any;
    size?: number;
  };
}

export interface IMarkLabelRequest extends IRequestBase {
  data: {
    atid: any;
    labelUid?: number;
    labelRunning: number;
    labelSport: number;
    labelPower?: number;
  };
}

export interface IGetListFeedRequest extends IRequestBase {
  data: {
    from?: number;
    size?: number;
    feed: FEED_SOURCE_TYPE;
    value: number;
  };
}

export interface IGetFeedUserStats extends IRequestBase {
  data: {
    sport: FEED_SPORT_TYPE;
    term: STATS_TIME_TYPE;
    value: string | null;
    pfid: number;
  };
}
