import styled from 'styled-components';

import { device } from '../../../configs/MediaQuery';

export const PaceInfoWrapper = styled.div`
  @media ${device.mobileS} {
    padding: 16px;
  }
  @media ${device.tablet} {
    padding: 24px;
  }
`;

export const PaceInfoContainer = styled.div``;
