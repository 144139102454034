import { Modal } from 'antd';
import styled from 'styled-components';

import { device } from '../../../../configs/MediaQuery';

export const DialogUserWrapper = styled.div``;

export const DialogUserContainer = styled.div``;

export const UsersModal = styled(Modal)`
  @media ${device.mobileS} {
    .ant-modal-body {
      min-height: 100%;
      max-height: 100%;
      padding: 0;
      margin: 0;
    }
  }
  @media ${device.laptop} {
    .ant-modal-body {
      min-height: 650px;
      max-height: 650px;
      padding: 0;
      margin: 0;
    }
  }
`;
