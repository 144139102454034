import { ITabMenu, STATS_TIME_TYPE } from '../types/IGlobal';

export const TIME_STATS_CATEGORY: ITabMenu[] = [
  {
    id: STATS_TIME_TYPE.WEEKLY,
    title: 'userProfile:timerStats.weekly',
  },
  {
    id: STATS_TIME_TYPE.MONTHLY,
    title: 'userProfile:timerStats.monthly',
  },
  {
    id: STATS_TIME_TYPE.YEARLY,
    title: 'userProfile:timerStats.yearly',
  },
];
