import { Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

import { APIResponse } from '../../../models/APIResponse';
import { UserProfile } from '../../../models/UserProfile';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { APP_BUTTON_TYPE, FOLLOW_CATEGORY_TYPE } from '../../../types/IGlobal';
import { FOLLOW_MEMBER_TYPE, IFollowMemberRequest } from '../../../types/IMember';
import { IResponseBase } from '../../../types/ITypeBase';
//views
import { DialogUsers } from './DialogUsers/DialogUsers';
//styles
import {
  AvatarContainer,
  AvatarUser,
  FollowButton,
  FollowButtonWrapper,
  FollowInfoWrapper,
  LabelText,
  LoginTypeImage,
  PersonalInfoContainer,
  PersonalInfoWrapper,
  UserNameWrapper,
  ValueText,
} from './PersonalInfo.styles';

interface Props {
  userProfile: UserProfile;
}

export const PersonalInfo: ComponentType<Props> = observer(({ userProfile }) => {
  const store = useRootStore();
  const { t } = useTranslation();

  const [showUsersDialog, setShowUsersDialog] = React.useState(false);
  const [followType, setFollowType] = React.useState(FOLLOW_CATEGORY_TYPE.FOLLOWING);

  const { followMember } = store.memberStore;

  const onToggleFollow = async () => {
    const action: FOLLOW_MEMBER_TYPE = userProfile.isFollowing
      ? FOLLOW_MEMBER_TYPE.UNFOLLOW
      : FOLLOW_MEMBER_TYPE.FOLLOW;
    const params: IFollowMemberRequest = {
      data: {
        pfid: userProfile.userId,
        action,
      },
    };
    const result: APIResponse<IResponseBase> = await followMember(params);
    if (result && result.isSuccess()) {
      const data: any = result.data;
      userProfile.setFollowingStatus(data?.fists);
    }
  };

  const onShowDialogUsers = (followType: FOLLOW_CATEGORY_TYPE) => {
    setFollowType(followType);
    setShowUsersDialog(true);
  };

  const onChangeFollowType = (followType: FOLLOW_CATEGORY_TYPE) => {
    setFollowType(followType);
  };

  return (
    <PersonalInfoWrapper>
      <PersonalInfoContainer>
        <AvatarContainer>
          <AvatarUser src={userProfile.avatar}></AvatarUser>
          <LoginTypeImage type={userProfile.loginType} />
        </AvatarContainer>
        <UserNameWrapper>
          <ValueText>{userProfile.name}</ValueText>
          <LabelText>{userProfile.code}</LabelText>
        </UserNameWrapper>

        <Row>
          <FollowInfoWrapper
            span={12}
            onClick={() => onShowDialogUsers(FOLLOW_CATEGORY_TYPE.FOLLOWING)}>
            <ValueText>{userProfile.totalFollowing}</ValueText>
            <LabelText>{t('social:following')}</LabelText>
          </FollowInfoWrapper>
          <FollowInfoWrapper
            span={12}
            onClick={() => onShowDialogUsers(FOLLOW_CATEGORY_TYPE.FOLLOWER)}>
            <ValueText>{userProfile.totalFollower}</ValueText>
            <LabelText>{t('social:follower')}</LabelText>
          </FollowInfoWrapper>
        </Row>

        {!userProfile.isMe() ? (
          <FollowButtonWrapper>
            <FollowButton
              typeButton={
                userProfile.isFollowing ? APP_BUTTON_TYPE.SECONDARY : APP_BUTTON_TYPE.PRIMARY
              }
              onClick={() => onToggleFollow()}>
              {userProfile.isFollowing ? t('social:unFollow') : t('social:follow')}
            </FollowButton>
          </FollowButtonWrapper>
        ) : (
          <FollowButtonWrapper></FollowButtonWrapper>
        )}
      </PersonalInfoContainer>

      {showUsersDialog ? (
        <DialogUsers
          followType={followType}
          onChangeFollowType={onChangeFollowType}
          userId={userProfile.userId}
          visible={showUsersDialog}
          onClose={() => setShowUsersDialog(false)}
        />
      ) : null}
    </PersonalInfoWrapper>
  );
});
