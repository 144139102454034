import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { APP_CONFIG } from '../../configs/GlobalConst';

interface Props {
  src: string;
  onClick?: () => void;
  className?: any;
}

export const AvatarImage: FC<Props> = observer(({ src, onClick, className }) => {
  src = src.replace('.png', '@2x.png').replace('.jpg', '@2x.jpg').replace('.jpeg', '@2x.jpeg');

  const onLoadImageError = (event: any) => {
    event.target.src = `${APP_CONFIG.STATIC_URL}/images/defaults/user_avatar_default.png`;
    event.onerror = null;
  };

  return <img src={src} onClick={onClick} onError={onLoadImageError} className={className} />;
});
