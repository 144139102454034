import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Section, TitleDescription, TitleText } from '../../../styles/LandingPageStyle';
import {
  BannerContainer,
  BannerImage,
  HandIconContainer,
  HandIconImage,
  LeftContainer,
  RightContainer,
  RowContainer,
} from './BannerSection.styles';

export const BannerSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <Container>
        <BannerContainer>
          <BannerImage src='https://uprace2.vcdn.vn/web/uprace/about-us/hero-banner-1x5.jpg' />
        </BannerContainer>

        <RowContainer>
          <LeftContainer lg={12} md={12} sm={24} xs={24}>
            <HandIconContainer>
              <HandIconImage src='https://uprace2.vcdn.vn/web/uprace/about-us/victory.png'></HandIconImage>
            </HandIconContainer>
            <TitleText>{t('about:banner.title')}</TitleText>
          </LeftContainer>
          <RightContainer lg={12} md={12} sm={24} xs={24}>
            <TitleDescription
              dangerouslySetInnerHTML={{
                __html: t('about:banner.description'),
              }}></TitleDescription>
          </RightContainer>
        </RowContainer>
      </Container>
    </Section>
  );
};
