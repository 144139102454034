import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const Sponsor: React.FC = () => {
  const { t } = useTranslation();
  const mailTo = 'sponsor@UpRace.org';
  const fanPageFacebook = 'https://www.facebook.com/uprace.org/';
  const hoSoHopTac = 'https://bit.ly/UpRace2022-Hosohoptac';

  return (
    <>
      <div className='card tab-content-card'>
        <div className='card-body'>
          <p className='card-text mb-md-5'>{t('uprace2022:howToJoin.sponsor.desc')}</p>

          <div className='d-flex flex-column flex-sm-row justify-content-around mb-md-5'>
            <a href={`mailTo:${mailTo}`} className='link-ink-basic text-center h5 me-sm-4 mb-3'>
              <img
                src='https://uprace2.vcdn.vn/web/uprace/icons/gmail-96px.png'
                className='Email'
                alt='Gmail'
              />
              <div>{t('uprace2022:howToJoin.sponsor.subDes1')}</div>
              <div>{mailTo}</div>
            </a>
            <a href={fanPageFacebook} className='link-ink-basic text-center h5 mb-3'>
              <img
                src='https://uprace2.vcdn.vn/web/uprace/icons/facebook-96px.png'
                className='Facebook'
                alt='Facebook'
              />
              <div>{t('uprace2022:howToJoin.sponsor.subDes2')}</div>
              <div>{t('uprace2022:howToJoin.sponsor.subDes3')}</div>
            </a>
          </div>

          <p>
            <span className='me-1'>{t('uprace2022:howToJoin.sponsor.subDes4')}</span>
            <Link to={'/contact'} className='link'>
              [{t('global:contact')}]
            </Link>
          </p>

          <form className='needs-validation' style={{ display: 'none' }}>
            <div className='row mb-4 g-3'>
              <div className='col-12'>
                <label htmlFor='name' className='form-label'>
                  {t('uprace2022:howToJoin.sponsor.name')}
                </label>
                <input
                  type='email'
                  className='form-control is-invalid'
                  id='name'
                  placeholder={t('uprace2022:howToJoin.sponsor.name')}
                />
                <div className='invalid-feedback'>
                  Display error when class "is-invalid" add input
                </div>
              </div>

              <div className='col-12'>
                <label htmlFor='email' className='form-label'>
                  {t('uprace2022:howToJoin.sponsor.email')}
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='email'
                  placeholder={t('uprace2022:howToJoin.sponsor.email')}
                />
              </div>

              <div className='col-12'>
                <label htmlFor='phone' className='form-label'>
                  {t('uprace2022:howToJoin.sponsor.phone')}
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='phone'
                  placeholder={t('uprace2022:howToJoin.sponsor.phone')}
                />
              </div>

              <div className='col-12'>
                <label htmlFor='message' className='form-label'>
                  {t('uprace2022:howToJoin.sponsor.message')}
                </label>
                <textarea className='form-control' id='message' rows={3}></textarea>
              </div>
            </div>
            <div className='text-md-end'>
              <button className='px-5 btn btn-primary text-md-end' type='submit'>
                {t('uprace2022:howToJoin.sponsor.send')}
              </button>
            </div>
          </form>

          <p>
            <span className='me-1'>{t('uprace2022:howToJoin.sponsor.subDes5')}</span>
            <a href={hoSoHopTac} className='link'>
              {t('uprace2022:howToJoin.sponsor.download')}
            </a>
          </p>
        </div>
      </div>
    </>
  );
};
