import './uprace-2022.css';

import React, { useEffect } from 'react';

import { HeroSection } from './HeroSection/HeroSection';
import { HowToJoinSection } from './JoinBy/HowToJoinSection';

export const JoinNow: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <div className='uprace_2022 join_now'>
        <HeroSection />
        <HowToJoinSection />
      </div>
    </>
  );
};
