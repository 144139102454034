import { AxiosResponse } from 'axios';

import { HTTP_STATUS } from '../configs/HttpStatus';
import APIHelper from '../helpers/APIHelper';
import { IRequestBase, IResponseBase } from '../types/ITypeBase';
import { API_STATUS } from './../configs/APIStatus';
import BaseService from './BaseService';

class BaseUnAuthService extends BaseService {
  public constructor() {
    super();
    //override default baseURL and headers of API service
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=UTF-8',
      CustomAuth: 'vwZ7XiHnN4uAlFHFyfCKyvOzrk8=',
    };
    APIHelper.defaults.headers.common = headers;
    APIHelper.defaults.baseURL = process.env.REACT_APP_BASE_URL_1;
  }
  protected postData(api: string, params: IRequestBase) {
    throw new Error('Method not implemented.');
  }

  //handles response and error from server
  protected handleResponse = (response: AxiosResponse): IResponseBase => {
    if (response.status != HTTP_STATUS.OK) return null!;
    const result: IResponseBase = {
      data: response.data,
      ercd: response.data.code,
      desc: '',

      responseCode: function (): number {
        return this.ercd!;
      },
      isSuccess: function (): boolean {
        return this.ercd === API_STATUS.OK || this.ercd === API_STATUS.LOGIN_SUCCESS;
      },
      isError: function (): boolean {
        return this.ercd != API_STATUS.OK && this.ercd != API_STATUS.LOGIN_SUCCESS;
      },
      getDescription: function (): string {
        return this.desc!;
      },
    };

    return result;
  };
}

export default BaseUnAuthService;
