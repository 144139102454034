import React, { FC } from 'react';
import {
  Done,
  InfoAboutEvent,
  InfoTitle,
  Road,
  RuleBox,
  RulesList,
  RulesTitle,
  Speed,
  Team2,
  X2,
} from './EventInfo.styles';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useRootStore } from '../../providers/RootStoreProvider';
import { IEvent } from '../../types/IEvent';
import { Remarkable } from 'remarkable';
import { Link } from 'react-router-dom';
interface Props {
  data: IEvent;
}

const ContestRules: FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const store = useRootStore();
  const md = new Remarkable({ breaks: true });
  const { language } = store.globalStore;

  const contentRules: string = language == 'vi' ? data.desc : data.descen;

  let textMarkdown = md.render(contentRules);
  textMarkdown = textMarkdown.replaceAll('<br>', '<br/>- ');
  textMarkdown = textMarkdown.replace('<p>', '<p>- ');
  console.log('textMarkdown', textMarkdown);
  return (
    <InfoAboutEvent>
      <InfoTitle>{t('eventInfo:rules.infoEvent')}</InfoTitle>
      <RuleBox>
        <RulesTitle>{t('eventInfo:rules.rules')}</RulesTitle>
        <div>
          <RulesList>
            <div
              dangerouslySetInnerHTML={{
                __html: textMarkdown,
              }}
            ></div>
          </RulesList>
        </div>
        <div className='button-container'>
          <a
            className='button-detail'
            href={`/event/rules?lang=${language}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='button-detail' type='primary'>
              {t('eventInfo:rules.viewDetails')}
            </Button>
          </a>
        </div>
      </RuleBox>
    </InfoAboutEvent>
  );
};

export default ContestRules;
