import { observer } from 'mobx-react-lite';
import React from 'react';
import { ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { UserProfile } from '../../../models/UserProfile';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { FEED_SPORT_TYPE, IGetFeedUserStats } from '../../../types/IFeed';
import { STATS_TIME_TYPE } from '../../../types/IGlobal';
import { TitleBold, TitleContainer } from '../UserProfile.styles';
//styles
import { ActivityContainer, ActivityStatsWrapper } from './ActivityStats.styles';
//views
import { SportType } from './SportType/SportType';
import { StatsInfo } from './StatsInfo/StatsInfo';
import { TabTimer } from './TabTimer/TabTimer';

interface Props {
  userProfile: UserProfile;
}

export const ActivityStats: ComponentType<Props> = observer(({ userProfile }) => {
  const store = useRootStore();
  const { t } = useTranslation();

  const { getFeedUserStats } = store.userStore;

  const [sport, setSport] = React.useState(FEED_SPORT_TYPE.ALL);
  const [term, setTerm] = React.useState(STATS_TIME_TYPE.WEEKLY);
  const [timeValue, setTimeValue] = React.useState(null);

  useEffect(() => {
    onGetFeedUserStats();
  }, [userProfile.userId, sport, term, timeValue]);

  const onChangeSport = (value: FEED_SPORT_TYPE) => {
    setSport(value);
  };

  const onChangeTimeTerm = (value: STATS_TIME_TYPE) => {
    setTimeValue(null);
    setTerm(value);
  };

  const onChangeTimeValue = (value: any) => {
    setTimeValue(value);
  };

  const onGetFeedUserStats = async () => {
    const params: IGetFeedUserStats = {
      data: {
        pfid: userProfile.userId,
        sport,
        term,
        value: timeValue,
      },
    };
    const result = await getFeedUserStats(params);
  };

  return (
    <ActivityStatsWrapper>
      <ActivityContainer>
        <TitleContainer>
          <TitleBold>{t('userProfile:feedStats.title')}</TitleBold>
        </TitleContainer>
        <SportType onChangeSport={onChangeSport} />
        <TabTimer onChangeTimeTerm={onChangeTimeTerm} />
        <StatsInfo onChangeTimeValue={onChangeTimeValue} statsType={term} />
      </ActivityContainer>
    </ActivityStatsWrapper>
  );
});
