import { FEED_SPORT_TYPE } from '../types/IFeed';

export const SPORT_CATEGORY = [
  {
    id: FEED_SPORT_TYPE.ALL,
    title: 'sport:all',
  },
  {
    id: FEED_SPORT_TYPE.RUNNING,
    title: 'sport:running',
  },
  {
    id: FEED_SPORT_TYPE.CYCLING,
    title: 'sport:cycling',
  },
  {
    id: FEED_SPORT_TYPE.SWIMMING,
    title: 'sport:swimming',
  },
];

export const SPORT_MARK_LABEL_FEED = [
  {
    id: FEED_SPORT_TYPE.ALL,
    title: 'sport:unknown',
  },
  {
    id: FEED_SPORT_TYPE.CYCLING,
    title: 'sport:cycling',
  },
  {
    id: FEED_SPORT_TYPE.RUNNING,
    title: 'sport:running',
  },
  {
    id: FEED_SPORT_TYPE.WALKING,
    title: 'sport:walking',
  },
  {
    id: FEED_SPORT_TYPE.TRIATHLON,
    title: 'sport:triathlon',
  },
  {
    id: FEED_SPORT_TYPE.SWIMMING,
    title: 'sport:swimming',
  },
  {
    id: FEED_SPORT_TYPE.OTHER,
    title: 'sport:other',
  },
];
