import React from 'react';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '../../../hooks/useMediaQuery';

export const DownloadSection: React.FC = () => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery('(min-width: 960px)');

  return (
    <section className='download-app-section' id='download-app-section'>
      {isDesktop ? (
        <div className='left'>
          <h1 className='heading'>{t('landing:download.title')}</h1>
          <div className='desc'>{t('landing:download.description')}</div>
          <div className='d-flex'>
            <img
              src='https://uprace2.vcdn.vn/web/uprace/illustrations/qr-code.svg'
              alt='qr-code'
              className='qr-code'
            />
            <div className='d-grid ms-4'>
              <a href='https://apps.apple.com/vn/app/id1476173971' className='align-self-start'>
                <img
                  src='https://uprace2.vcdn.vn/web/uprace/icons/app-store.svg'
                  className='app-store img-fluid'
                  alt='app-store'
                />
              </a>
              <a
                href='https://play.google.com/store/apps/details?id=vn.vng.uprace'
                className='align-self-end'>
                <img
                  src='https://uprace2.vcdn.vn/web/uprace/icons/google-play.svg'
                  className='google-play img-fluid'
                  alt='google-play'
                />
              </a>
            </div>
          </div>
        </div>
      ) : null}
      <div className='right'>
        <img
          src='https://uprace2.vcdn.vn/web/uprace/illustrations/ill-qr-code.png'
          alt='illustration'
          className='illustration-thumbnail img-fluid'
        />
      </div>
    </section>
  );
};
