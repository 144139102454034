import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { APP_CONFIG } from '../../configs/GlobalConst';

interface Props {
  src: string;
  onClick?: () => void;
  className?: any;
}

export const MapImage: FC<Props> = observer(({ src, onClick, className }) => {
  const onLoadImageError = (event: any) => {
    event.target.src = `${APP_CONFIG.STATIC_URL}/images/defaults/feed_map_default.jpg`;
    event.onerror = null;
  };

  return <img src={src} onClick={onClick} onError={onLoadImageError} className={className} />;
});
