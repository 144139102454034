import React, { FC, useEffect, useState, useTransition } from 'react';
import {
    AchieveBox,
    Group,
    Ico,
    InfoAchieve,
    InfoTitle,
    JoinedList,
    JoinListBox,
    Km,
    Lb,
    LbJoinListBox,
    Num,
    NumJoinListBox,
    ProcessContainer,
    Team,
    Unit
} from './EventInfo.styles';
import { Progress, Tooltip } from 'antd';
import { IEvent } from '../../types/IEvent';
import { formatDistanceKMFloor, formatNumber } from '../../utils';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';

interface Props {
    eventInfo: IEvent;
}

const InfoAchieveSection: FC<Props> = ({ eventInfo }) => {
    const [percentDisOld, setPercentDisOld] = useState(0);
    const [percentDis, setPercentDis] = useState(0);
    const [percentDisOver, setPercentDisOver] = useState(0);

    const { t } = useTranslation();

    useEffect(() => {
        if (eventInfo) calcEventInfo();
    }, [eventInfo]);

    const calcEventInfo = () => {
        const target = eventInfo.evdis / 1000,
            targetOld = eventInfo.pevdis / 1000;
        const totalDis = Math.round(eventInfo.dis / 1000);

        const percentDisOld =
            (targetOld / target) * 100 > 100 ? 100 : (targetOld / target) * 100;
        const percentDis =
            (totalDis / target) * 100 > percentDisOld
                ? percentDisOld + 3
                : (totalDis / target) * 100;
        let percentDisOver = 0;
        if (percentDis >= percentDisOld)
            percentDisOver =
                (totalDis / target) * 100 > 100
                    ? 100
                    : (totalDis / target) * 100;

        setPercentDisOld(percentDisOld);
        setPercentDis(percentDis);
        setPercentDisOver(percentDisOver);
    };

    return (
        <InfoAchieve>
            <InfoTitle>{t('eventInfo:achievement.title')}</InfoTitle>
            <AchieveBox>
                <Km>
                    <Lb>{t('eventInfo:achievement.achieved')}</Lb>

                    <CountUp
                        start={0}
                        end={Math.floor(eventInfo.dis / 1000)}
                        formattingFn={(num) => num.toLocaleString()}
                        delay={0}
                        duration={2}>
                        {({ countUpRef }) => (
                            <Num ref={countUpRef}>
                                {formatDistanceKMFloor(eventInfo.dis, true)}
                            </Num>
                        )}
                    </CountUp>

                    <Unit>km</Unit>
                </Km>
                <ProcessContainer>
                    <Tooltip
                        title={
                            <div>
                                <p>{t('eventInfo:achievement.achievedOld')}</p>
                                <p
                                    style={{
                                        fontWeight: 600,
                                        fontSize: 20
                                    }}>
                                    {formatNumber(eventInfo.pevdis/1000)}<span> km</span>
                                </p>
                            </div>
                        }>
                        <Progress
                            percent={percentDis}
                            strokeColor={{
                                from: '#108ee9',
                                to: '#87d068'
                            }}
                        />
                    </Tooltip>
                </ProcessContainer>
                <JoinedList>
                    <Team>
                        <JoinListBox>
                            <Ico icon="\f0c0"></Ico>
                            <NumJoinListBox>
                                {formatNumber(eventInfo.mem)}
                            </NumJoinListBox>
                            <LbJoinListBox>{t('eventInfo:achievement.members')}</LbJoinListBox>
                        </JoinListBox>
                    </Team>
                    <Group>
                        <JoinListBox>
                            <Ico icon="\e537"></Ico>
                            <NumJoinListBox>
                                {formatNumber(eventInfo.team)}
                            </NumJoinListBox>
                            <LbJoinListBox>{t('eventInfo:achievement.teams')}</LbJoinListBox>
                        </JoinListBox>
                    </Group>
                </JoinedList>
            </AchieveBox>
        </InfoAchieve>
    );
};

export default InfoAchieveSection;
