import { action, makeObservable, observable, runInAction } from 'mobx';

import { LOCAL_KEY } from './../configs/AppKey';
import RootStore from './RootStore';

class GlobalStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  //declare variables
  @observable language: string | null = null;
  @observable isClickNews: boolean = false;

  @action setIsClickNews = async (value: boolean) => {
    runInAction(() => {
      this.isClickNews = value;
    });
  };

  @action changeLanguage = async (language: string) => {
    runInAction(() => {
      this.language = language;
    });
    localStorage.setItem(LOCAL_KEY.USER_LANGUAGE, language);
  };
}
export default GlobalStore;
