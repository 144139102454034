import { css } from 'styled-components';

import { defaultTheme } from '../themes';

export const InputTextBase = css`
  background: ${defaultTheme.colors.secondary};
  border-radius: 5px;
  width: 100%;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  height: 40px;
  border: 0px;
  padding: 10px;
  outline: none;
`;

export const TextBaseBlack = css`
  color: ${defaultTheme.colors.black};
  margin: 0;
`;

export const TextBaseGray = css`
  color: ${defaultTheme.colors.grayLight};
  margin: 0;
`;

export const ButtonStyleBase = css`
  border: 1px solid ${defaultTheme.colors.primary};
  font-weight: 600;
  border-radius: 8px;
  height: 40px;
  padding: 10px;
  margin: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
